import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationsComponent} from './components';
import {RouterModule} from '@angular/router';
import {AuthGuardService, ConfigService, ModalService, NotificationService, UserService} from './services';
import {CardModule} from 'primeng/card';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {DropdownModule} from 'primeng/dropdown';
import {FileUploadModule} from 'primeng/fileupload';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {MenuModule} from 'primeng/menu';
import {TabMenuModule} from 'primeng/tabmenu';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {TableModule} from 'primeng/table';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ToolbarModule} from 'primeng/toolbar';
import {MessageService} from 'primeng/api';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastModule} from 'primeng/toast';
import {GoogleMapsModule} from '@angular/google-maps';
import {KeycloakService} from 'keycloak-angular';
import {RippleModule} from 'primeng/ripple';
import {OrderListModule} from 'primeng/orderlist';
import {SplitButtonModule} from 'primeng/splitbutton';
import {HasRoleDirective} from './directives/has-role/has-role.directive';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {TabViewModule} from 'primeng/tabview';
import {PendingChangesGuard} from './guards/pending-change.guard';
import {SidebarModule} from 'primeng/sidebar';
import {BadgeModule} from 'primeng/badge';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {AvatarModule} from 'primeng/avatar';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputSwitchModule} from 'primeng/inputswitch';
import {TagModule} from 'primeng/tag';
import {MenubarModule} from "primeng/menubar";

@NgModule({
  imports: [
    /** PrimeNG START**/
    CardModule,
    ScrollPanelModule,
    PanelModule,
    MenuModule,
    TabMenuModule,
    TableModule,
    PaginatorModule,
    ButtonModule,
    ToolbarModule,
    DropdownModule,
    DividerModule,
    InputTextModule,
    InputSwitchModule,
    InputTextareaModule,
    CalendarModule,
    ChipModule,
    AutoCompleteModule,
    CheckboxModule,
    DialogModule,
    DynamicDialogModule,
    ToastModule,
    ToggleButtonModule,
    MessagesModule,
    MessageModule,
    FileUploadModule,
    OrderListModule,
    ProgressSpinnerModule,
    SplitButtonModule,
    MenubarModule,
    TabViewModule,
    TagModule,
    /** PrimeNG END**/
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    MultiSelectModule,
  ],
  exports: [
    /** PrimeNG START**/
    CardModule,
    ScrollPanelModule,
    PanelModule,
    MenuModule,
    TabMenuModule,
    TableModule,
    ChipModule,
    BadgeModule,
    AvatarModule,
    AvatarGroupModule,
    PaginatorModule,
    DividerModule,
    ButtonModule,
    ToolbarModule,
    DropdownModule,
    InputTextModule,
    InputSwitchModule,
    InputTextareaModule,
    CalendarModule,
    AutoCompleteModule,
    CheckboxModule,
    SidebarModule,
    DialogModule,
    DynamicDialogModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    FileUploadModule,
    ProgressSpinnerModule,
    OrderListModule,
    RippleModule,
    SplitButtonModule,
    GoogleMapsModule,
    MenubarModule,
    TabViewModule,
    TagModule,
    /** PrimeNG END**/

    NotificationsComponent,
    BrowserAnimationsModule,
    HasRoleDirective,
    MultiSelectModule,
  ],
  declarations: [NotificationsComponent, HasRoleDirective],
  providers: [
    NotificationService,
    ModalService,
    DialogService,
    AuthGuardService,
    PendingChangesGuard,
    UserService,
    ConfigService,
    KeycloakService,
    MessageService,
  ],
})
export class CoreModule {}
