import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Ticket} from '../../../../../api/model/Ticket.model';
import {ApiService} from '../../../../../api/service/api.service';
import {SignatureModel} from '../../../models/Signature.model';
import {SignatureComponent} from '../signature.component';

@Component({
  selector: 'tj-signature-viewer',
  templateUrl: './signature-viewer.component.html',
  styleUrls: ['./signature-viewer.component.scss'],
})
export class SignatureViewerComponent implements OnInit {
  signature: SignatureModel;

  @Input() galleryId: number;

  @Input() ticket: Ticket;

  @Output() saveReport = new EventEmitter();

  @ViewChild(SignatureComponent) signatureComponent: SignatureComponent;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    if (this.galleryId && this.galleryId > 0) {
      this.apiService.signatureApi
        .getSignature(this.ticket.id)
        .subscribe((signatures: SignatureModel) => {
          this.signature = signatures;
        });
    }
  }

  updateSignature(signature) {
    this.signature.id = signature.response.id;
    this.signature.url = signature.response.url;
    this.signature.path = signature.response.path;
  }

  saveReportAndSignature() {
    this.signatureComponent.saveSignature();
    this.saveReport.emit();
  }
}
