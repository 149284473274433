<p-dialog header="Edit image"
          [(visible)]="display"
          [draggable]="false"
          [resizable]="false"
          [blockScroll]="true"
          [dismissableMask]="true"
          [maximizable]="false"
          (onHide)="onHide()"
          (onShow)="onShow()">

  <div class="col-12" *ngIf="imageFlow === 'CROP'">
    <tj-image-cropper (crop)="crop($event)" [imageUrl]="getImageUrl()" #cropperComponent></tj-image-cropper>
  </div>

  <div class="col-12" *ngIf="imageFlow === 'OVERLAY'">
    <tj-image-overlay [croppedUrl]="getImageCroppedUrl()" #overlayComponent></tj-image-overlay>
  </div>

  <p-footer>
    <div class="lg:col-offset-8 lg:col-4" *ngIf="imageFlow === 'OVERLAY'">
      <button pButton label="Save" class="p-button-raised p-button-info" (click)="saveImage()"></button>
    </div>
  </p-footer>

</p-dialog>
