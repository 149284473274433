import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TjKeycloakService} from '../../core/services';
import {HEADER_NAMES, HEADER_VALUES} from '../endpoints/tj-request-headers';

@Injectable()
export class RequestService {
  constructor(
    private http: HttpClient,
    private keycloakService: TjKeycloakService,
  ) {}

  createAuthorizationHeader() {
    // console.log(this.keycloakService.getToken());
    return {};
  }

  get(url: string, options?): Observable<any> {
    const headers = this.createAuthorizationHeader();
    if (options?.background) {
      headers[HEADER_NAMES.REQUEST_TYPE] = HEADER_VALUES.REQUEST_TYPE.BACKGROUND;
    }
    return this.http.get(
      url,
      Object.assign(
        {
          headers: headers,
        },
        options,
      ),
    );
  }

  post(url: string, body?: any, options?): Observable<any> {
    const headers = this.createAuthorizationHeader();
    return this.http.post(
      url,
      body,
      Object.assign(
        {
          headers: headers,
        },
        options,
      ),
    );
  }

  upload(url: string, files: File[], galleryId: number, options?): Observable<any> {
    const headers = this.createAuthorizationHeader();
    const formData: FormData = new FormData();
    for (const file of files) {
      formData.append('file', file);
    }
    if (galleryId) {
      formData.append('galleryId', galleryId.toString());
    }
    return this.http.post(
      url,
      formData,
      Object.assign(
        {
          headers: headers,
        },
        options,
      ),
    );
  }

  patch(url: string, body: any, options?): Observable<any> {
    const headers = this.createAuthorizationHeader();
    return this.http.patch(
      url,
      body,
      Object.assign(
        {
          headers: headers,
        },
        options,
      ),
    );
  }

  put(url: string, body: any, options?): Observable<any> {
    const headers = this.createAuthorizationHeader();
    return this.http.put(
      url,
      body,
      Object.assign(
        {
          headers: headers,
        },
        options,
      ),
    );
  }

  remove(url: string, options?): Observable<any> {
    const headers = this.createAuthorizationHeader();
    return this.http.delete(
      url,
      Object.assign(
        {
          headers: headers,
        },
        options,
      ),
    );
  }

  download(url: string, body?: any): Observable<Blob> {
    const headers = this.createAuthorizationHeader();
    return this.http.get(url, {
      headers: headers,
      responseType: 'blob',
      params: body,
    });
  }

  uploadCsv(url: string, file: File): Observable<any> {
    const headers = this.createAuthorizationHeader();
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post(
      url,
      formData,
      Object.assign({
        headers: headers,
      }),
    );
  }
}
