import {Type} from 'class-transformer';
import {AddressDetails} from '../Address.model';
import {Vacation} from '../Vacation.model';
import {WeeklyOff} from '../WeeklyOff.model';

export class CalendarTechnicianModel {
  id: number;
  name: string;
  personnelCode: string;
  phoneNumber: string;
  email: string;
  showOnCalendar: boolean;
  notes: string;
  speciality: string;
  maxStops: number;
  maxWorkHours: number;

  @Type(() => AddressDetails)
  address: AddressDetails; // Assuming 'addressFormatted' is part of 'AddressDetails'

  vacations: Vacation[];
  weeklyOffs: WeeklyOff[];
}
