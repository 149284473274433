import {catchError, map} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Comment, CommentListItem} from '../../../../api/model/TicketComments.model';
import {User} from '../../../../api/model/User.model';
import {ApiService} from '../../../../api/service/api.service';
import {ConfigService, NotificationService, TjKeycloakService} from '../../../../core/services';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {of} from 'rxjs';
import {ContactType} from '../../../shared/enums/contact.type';
import {TicketEditContextService} from '../../../ticket/services/ticket-edit-context.service';
import {TicketTechnician} from '../../../../api/model/TicketTechnicians.model';

@Component({
  selector: 'tj-ticket-comments',
  templateUrl: './ticket-comments.component.html',
  styleUrls: ['./ticket-comments.component.scss'],
})
export class TicketCommentsComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() chatId: number;
  @Input() technicians: TicketTechnician[];
  @Input() chatType: string;
  showTechnicianModal = false;
  selectedTechnicians: number[] = [];

  @ViewChild('commentContainer') commentContainer: ElementRef;
  commentForm: UntypedFormGroup;
  comment: Comment;
  comments: CommentListItem[];
  user: User;
  expanded: boolean;

  constructor(
    private api: ApiService,
    private notificationService: NotificationService,
    private tjKeycloakService: TjKeycloakService,
    private context: TicketEditContextService,
    public config: ConfigService,
  ) {}

  ngOnInit() {
    this.tjKeycloakService.getUser().subscribe(user => (this.user = user));
    this.initializeComment();
    this.expanded = false;
    this.commentForm = new UntypedFormGroup({});
    if (this.isTechChat()) {
      this.markAllTechSelected();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chatId || this.chatId !== changes.chatId?.previousValue) {
      this.getChatComments();
    }
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.commentContainer.nativeElement.scrollTop =
          this.commentContainer.nativeElement.scrollHeight;
      }, 0);
    } catch (err) {
      console.error('Could not scroll to bottom:', err);
    }
  }

  onCommentSave() {
    const formValue = this.commentForm.value;
    if (!this.validateAssignments()) {
      return;
    }
    if (this.needToShowTechSelectionDialog()) {
      this.openTechSelectionDialogIfTechChat();
    } else if (this.commentForm.valid) {
      const updateComment = {...this.comment, ...formValue.comment};
      this.saveComment(updateComment).subscribe(() => {
        this.scrollToBottom(); // Scroll down after saving a new comment
      });
    }
    FormHelpers.validateAllFormFields(this.commentForm);
  }

  onExpand() {
    this.expanded = !this.expanded;
  }

  initializeComment() {
    const comment = {} as Comment;
    comment.userId = this.user.id;
    comment.chatId = this.chatId;
    comment.message = '';
    this.comment = comment;
  }

  private getChatComments() {
    return this.api.comment
      .getChatComments(this.chatId)
      .pipe(
        map((comments: CommentListItem[]) => {
          this.comments = comments;
          setTimeout(() => this.scrollToBottom(), 0); // Scroll down after loading comments
        }),
      )
      .subscribe();
  }

  private saveComment(comment) {
    return this.api.comment.save(comment).pipe(
      map((savedComment: CommentListItem) => {
        this.comments.push(savedComment);
        this.initializeComment();
        this.commentForm.reset();
        this.buildForm();
        return comment;
      }),
      catchError(error => {
        console.log(error);
        this.notificationService.error('Comment has not been sent');
        return of(null);
      }),
    );
  }

  private buildForm() {
    const formControls = {
      message: new UntypedFormControl(this.comment.message || ''),
      chatId: new UntypedFormControl(this.comment.chatId),
      userId: new UntypedFormControl(this.comment.userId),
    };
    this.commentForm = new UntypedFormGroup(formControls);
  }

  validateAssignments(): boolean {
    const hasCustomerEmailContact = this.context.customer$
      .getValue()
      ?.contactPersons.some(contactPerson =>
        contactPerson.contacts.some(contact => contact.type === ContactType.EMAIL),
      );

    if (!hasCustomerEmailContact && this.chatType === 'ADMIN_CUSTOMER') {
      this.notificationService.warningCenter('Customer does not have an email contact.');
      return false;
    }

    const hasAttachedPartner = !!this.context.partner$.getValue();
    if (!hasAttachedPartner && this.chatType === 'ADMIN_PARTNER') {
      this.notificationService.warningCenter('Account is not attached.');
      return false;
    }

    const hasAssignedTechnicians = this.technicians && this.technicians.length > 0;
    if (!hasAssignedTechnicians && this.chatType === 'ADMIN_TECHNICIAN') {
      this.notificationService.warningCenter('No technicians are assigned.');
      return false;
    }

    return true;
  }

  confirmTechnicianSelection(): void {
    this.showTechnicianModal = false;
    const formValue = this.commentForm.value.comment;
    formValue.technicians = this.selectedTechnicians;
    if (this.commentForm.valid) {
      this.saveComment(formValue).subscribe(() => {
        this.scrollToBottom();
      });
    }
  }

  private isTechChat() {
    return this.chatType === 'ADMIN_TECHNICIAN';
  }

  private needToShowTechSelectionDialog() {
    return this.isTechChat() && this.technicians.length > 1;
  }

  private markAllTechSelected() {
    this.selectedTechnicians = this.technicians.map(it => it.id);
  }

  private openTechSelectionDialogIfTechChat() {
    this.showTechnicianModal = true;
  }
}
