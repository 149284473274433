import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileUpload} from 'primeng/fileupload';

@Component({
  selector: 'tj-zipCode-menu-component',
  templateUrl: './zip-codes-menu.component.html',
  styleUrls: ['./zip-codes-menu.component.scss'],
})
export class ZipCodesMenuComponent implements OnInit {
  @Output('onImportInfo') importInfoEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output('onImportZipCodes') importZipCodes: EventEmitter<any> = new EventEmitter<any>();
  @Output('onNewInsertZipCode') newInsertZipCode: EventEmitter<any> = new EventEmitter<any>();
  @Output('onExportAllZipCodes') exportAllZipCodes: EventEmitter<any> = new EventEmitter<any>();
  @Output('onNewZipCode') newZipCode: EventEmitter<any> = new EventEmitter<any>();
  @Input() isSaving: boolean;

  ngOnInit(): void {}

  onExportAllZipCodes(): void {
    this.exportAllZipCodes.emit();
  }

  onImportZipCodes($event: any, fileUpload: FileUpload) {
    this.importZipCodes.emit({fileUploader: fileUpload, event: $event});
    fileUpload.remove($event, 0);
  }

  onImportInfo() {
    this.importInfoEmitter.emit();
  }

  onNewInsertZipCode($event: MouseEvent) {
    this.newInsertZipCode.emit();
  }

  onNewZipCode() {
    this.newZipCode.emit();
  }
}
