import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CalendarSearchCriteria} from './calendar-search-criteria';

@Injectable({
  providedIn: 'root',
})
export class CalendarSearchService {
  private searchCriteria = new BehaviorSubject<CalendarSearchCriteria>({});

  setSearchCriteria(criteria: CalendarSearchCriteria) {
    this.searchCriteria.next(criteria);
  }

  getSearchCriteria() {
    return this.searchCriteria.asObservable();
  }

  constructor() {}
}
