<div class="tj-field">
  <label
    *ngIf="label"
    [ngClass]="{'tj-label-error': control && control.touched && control.invalid}"
  >
    {{ label }} {{ control?.errors?.required ? '*' : '' }}
  </label>

  <ng-container>
    <ng-content select="textarea"></ng-content>
    <ng-content select="input"></ng-content>
    <ng-content select="p-dropdown"></ng-content>
    <ng-content select="p-checkbox"></ng-content>
    <ng-content select="p-autoComplete"></ng-content>
    <ng-content select="p-inputNumber"></ng-content>
    <ng-content select="p-multiSelect"></ng-content>
    <ng-content select="tj-date-range-picker"></ng-content>
    <ng-content select="p-inplace"></ng-content>
    <ng-content select="p-triStateCheckbox"></ng-content>
    <ng-content select="select"></ng-content>
    <ng-content select="tj-time"></ng-content>
    <ng-content select="tj-datepicker"></ng-content>
    <ng-content select="tj-custom-input"></ng-content>
    <ng-content select="tj-money-input"></ng-content>
    <ng-content select="tj-percentage-input"></ng-content>
    <ng-content select="tj-tag-combobox"></ng-content>
    <ng-content select="tj-partner-combobox"></ng-content>
    <ng-content select="tj-partner-type-combobox"></ng-content>
    <ng-content select="tj-technician-combobox"></ng-content>
    <ng-content select=".help-text"></ng-content>
    <ng-content select=".p-inputgroup"></ng-content>
  </ng-container>

  <tj-error *ngIf="control && control.touched && control.invalid && control.hasError('required')"
    >The field is required
  </tj-error>
  <tj-error
    *ngIf="control && control.touched && control.invalid && control.hasError('timeParseError')"
    >Unable to parse time
  </tj-error>
  <tj-error *ngIf="control && control.touched && control.invalid && control.hasError('maxlength')">
    {{ getMaxLengthMessageText(control.errors) }}
  </tj-error>
  <tj-error *ngIf="control && control.touched && control.invalid && control.hasError('email')">
    Enter a valid email address
  </tj-error>
</div>
