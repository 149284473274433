import {PayType} from '../../main/shared/enums/pay.type';
import {Area} from './Area.model';
import {WeeklyOff} from './WeeklyOff.model';
import {Vacation} from './Vacation.model';
import {Type} from 'class-transformer';
import {AddressDetails} from './Address.model';
import {ContactPersonMinDetailsModel} from './ContactPersonMinDetails.model';

export class TechnicianMinimalListItem {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  @Type(() => AddressDetails)
  address: AddressDetails;
  active: boolean;
  payType: PayType;
  percent: number;
  percentType: string;
  unit: number;
  speciality: string;
  notes: string;
  areas: Area[];
  showOnCalendar: boolean;
  visibleInsideTicket: boolean;
  companyName: string;
  personnelCode: string;
  maxStops: number;
  maxWorkHours: number;
  weeklyOffs: WeeklyOff[];
  vacations: Vacation[];
  displayName?: string;
  contactPersons: ContactPersonMinDetailsModel[];
}
