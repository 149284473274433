import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {Invoice} from '../../../../api/model/Invoice.model';
import {ConfigService, NotificationService, TjKeycloakService} from '../../../../core/services';
import {ContactInfoList} from '../../../../api/model/ContactValueList.model';
import {Dropdown} from 'primeng/dropdown';
import {Router} from '@angular/router';
import {InvoiceService} from '../../service/invoice.service';
import {ConfirmationService} from 'primeng/api';
import * as dayjs from 'dayjs';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'tj-invoice-grid',
  templateUrl: './invoice-grid.component.html',
  styleUrls: ['./invoice-grid.component.scss'],
})
export class InvoiceGridComponent implements OnInit {
  @Input() invoice: Invoice;

  @Input() forPrint = false;

  @Output() downloadPDFReport: EventEmitter<any> = new EventEmitter<any>();

  @Output() printReport: EventEmitter<any> = new EventEmitter<any>();

  @Output() sendToEmail: EventEmitter<string> = new EventEmitter<string>();
  @Output() sendToPartner: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendToCustomer: EventEmitter<any> = new EventEmitter<any>();

  @Output() changeStatusEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  configs;

  mainPartnerContactPerson: ContactPerson;

  partnerInvoiceReceiverContacts: ContactInfoList[];

  customEmailDialog = false;
  customEmailForm: UntypedFormGroup;
  downloadSpinner = false;

  sendtoOptions = [
    {
      label: 'Send to Account/3rd Party',
      command: () => {
        this.onSendPdfToPartner();
      },
    },
    {
      label: 'Send to customer',
      command: () => {
        this.onSendPdfToCustomer();
      },
    },
    {
      label: 'Send to email',
      command: () => {
        this.openCustomEmailDialog();
      },
    },
  ];

  constructor(
    public configService: ConfigService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService,
    private fb: UntypedFormBuilder,
    protected invoiceService: InvoiceService,
    private keycloakServices: TjKeycloakService,
  ) {}

  ngOnInit() {
    this.invoice?.items?.sort((a, b) => dayjs(b.serviceDate).unix() - dayjs(a.serviceDate).unix());
    this.configs = this.configService.globalConfigs$.getValue();
    this.setInvoiceContacts();
  }

  setInvoiceContacts() {
    const contactPersons: ContactInfoList[] = [];
    const contactPersonIds = new Set<number>();

    this.invoice?.items.forEach(item => {
      const contactPersonId = item.mainPartnerContactPerson?.id;
      if (contactPersonId !== undefined && !contactPersonIds.has(contactPersonId)) {
        contactPersonIds.add(contactPersonId);
        const newContacts = this.invoice.partner?.getContactPersonsData(
          item.mainPartnerContactPerson,
        );
        if (newContacts) {
          contactPersons.push(...newContacts);
        }
      }
    });

    this.partnerInvoiceReceiverContacts = contactPersons;
  }

  downloadPDF(): void {
    this.downloadPDFReport.emit();
  }

  print(): void {
    this.printReport.emit();
  }

  changeStatus($event: any, invoiceStatus: Dropdown) {
    this.changeStatusEventEmitter.emit({$event, invoiceStatus});
  }

  canEditStatus(): boolean {
    return this.keycloakServices.hasRole('INVOICE_EDIT');
  }

  config(code: string): string {
    return this.configs.find(x => x.code === code)?.value;
  }

  toPaymentPage() {
    const toPayment = () =>
      this.router.navigate(['/payment'], {
        queryParams: {itemType: 'invoice', itemId: this.invoice.id},
        queryParamsHandling: 'merge',
      });
    this.showApprovalConfirmationDialog(toPayment);
  }

  private showApprovalConfirmationDialog(cb: () => any) {
    const approvalRequired = this.invoice.partner?.payment?.approvalRequired;
    if (approvalRequired) {
      this.confirmationService.confirm({
        message: 'This account requires approval prior to charging',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        key: 'confirm',
        accept: () => cb(),
      });
    } else {
      cb();
    }
  }

  private onSendPdfToPartner() {
    this.sendToPartner.emit();
  }

  private onSendPdfToCustomer() {
    this.sendToCustomer.emit();
  }

  onSendPdfToEmail() {
    this.customEmailDialog = false;
    const customEmailFormControl = this.getCustomEmailFormControl('email');
    const email = customEmailFormControl.value
      ? customEmailFormControl.value
      : this.openCustomEmailDialog();
    this.sendToEmail.emit(email);
  }

  openCustomEmailDialog() {
    this.customEmailDialog = true;
    this.customEmailForm = this.fb.group({
      email: new UntypedFormControl('', Validators.email),
    });
  }

  getCustomEmailFormControl(control: string): UntypedFormControl {
    return this.customEmailForm.get(control) as UntypedFormControl;
  }
}
