<div class="layout-topbar no-print">
  <div class="topbar-left">
    <ul class="topbar-menu">
      <li class="profile-item static sm:relative">
        <button
          (click)="back()"
          class="p-button-outlined mr-2 p-button-info"
          label="Back"
          type="button"
          icon="pi pi-arrow-circle-left"
          pButton
          pRipple
        ></button>
      </li>
    </ul>
    <a #menubutton tabindex="0" class="menu-button p-trigger" (click)="onMenuButtonClick()">
      <i class="pi pi-chevron-left"></i>
    </a>
    <span class="topbar-separator"></span>
    <tj-breadcrumb></tj-breadcrumb>
  </div>

  <tj-sidebar></tj-sidebar>
  <div class="layout-mask modal-in"></div>

  <div class="topbar-right">
    <ul class="topbar-menu">
      <div
        class="col lg:flex-column flex justify-content-left align-items-center text-left ng-star-inserted pt-0"
        *ngIf="companyInfo"
      >
        <span class="pb-0 mb-0" [ngClass]="hideCompanyInfo ? ' pr-2' : 'py-2'">
          <span
            style="font-size: 1em"
            class="font-semibold"
            *ngIf="!hideCompanyInfo"
            [cdkCopyToClipboard]="getValueByCode('NAME')"
            (cdkCopyToClipboardCopied)="onCopySuccess($event)"
          >
            {{ getValueByCode('NAME') }}
          </span>

          <img
            *ngIf="hideCompanyInfo"
            [cdkCopyToClipboard]="getValueByCode('NAME')"
            (cdkCopyToClipboardCopied)="onCopySuccess($event)"
            style="height: 25px"
            src="{{ getValueByCode('LOGO') }}"
            alt="Logo"
          />
        </span>
        <div class="flex justify-content-center align-items-center pl-2">
          <strong
            *ngIf="getValueByCode('PHONE')"
            style="color: var(--primary-color)"
            [ngClass]="getValueByCode('PHONE') ? 'border-right-1' : ''"
            class="flex justify-content-center align-items-center mr-2 cursor-pointer pr-2"
            [cdkCopyToClipboard]="getValueByCode('PHONE')"
            (cdkCopyToClipboardCopied)="onCopySuccess($event)"
          >
            <i class="pi pi-phone mr-2" pTooltip="{{ getValueByCode('PHONE') }}"></i>
            <span *ngIf="!hideCompanyInfo">{{ getValueByCode('PHONE') }}</span>
          </strong>

          <strong
            *ngIf="companyInfo[2] && companyInfo[2].value"
            style="color: var(--primary-color)"
            class="flex justify-content-center align-items-center cursor-pointer"
            [cdkCopyToClipboard]="getValueByCode('EMAIL')"
            (cdkCopyToClipboardCopied)="onCopySuccess($event)"
          >
            <i class="pi pi-envelope mr-2" pTooltip="{{ getValueByCode('EMAIL') }}"></i>
            <span *ngIf="!hideCompanyInfo">{{ getValueByCode('EMAIL') }}</span>
          </strong>
        </div>
      </div>
      <div class="flex align-items-center justify-content-center timeZoneCofig">
        {{ timeZoneLabel }} ({{ timeZone }})
      </div>
      <li class="profile-item static sm:relative">
        <a
          tabindex="0"
          pStyleClass="@next"
          enterClass="hidden"
          enterActiveClass="scalein"
          leaveToClass="hidden"
          leaveActiveClass="fadeout"
          [hideOnOutsideClick]="true"
        >
          <img
            src="{{ user.picture }}"
            *ngIf="user.picture"
            alt="diamond-layout"
            class="profile-image"
          />
          <span class="profile-name">{{ displayName }}</span>
        </a>

        <ul
          class="list-none p-3 m-0 border-round shadow-2 absolute surface-overlay hidden origin-top w-full sm:w-12rem mt-2 left-0 top-auto"
        >
          <li>
            <a
              pRipple
              (click)="this.logout()"
              class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
            >
              <i class="pi pi-power-off mr-3"></i>
              <span class="flex flex-column">
                <span class="font-semibold">Logout</span>
              </span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
