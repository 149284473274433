<p-panel header="Filters" styleClass="mb-1">
  <tj-calendar-search-form
    [form]="form"
    (showAll)="showAll()"
    (searchFilter)="searchFilter()"
    (resetFilter)="resetFilter()"
  ></tj-calendar-search-form>
</p-panel>
<div class="lg:flex md:flex sm:flex-row justify-content-center align-items-center p-1">
  <div class="flex sm:mt-2 lg:mt-0 md:mt-0 sm:col-12 lg:col-3 md:col-3 justify-content-start">
    <span
      class="p-2 border-blue-700 border-3"
      [ngStyle]="{height: '35px', width: '80px', display: 'table'}"
      >Blue Box</span
    >
    <span class="flex align-items-center justify-content-center ml-2">
      – Current Scheduled Ticket Date</span
    >
  </div>
  <div
    class="flex mt-2 lg:mt-0 md:mt-0 sm:col-12 lg:col-3 md:col-3 sm:justify-content-start lg:justify-content-center md:justify-content-center"
  >
    <span
      class="p-2 border-red-600 border-3"
      [ngStyle]="{height: '35px', width: '80px', display: 'table'}"
      >Red Box</span
    >
    <span class="flex align-items-center justify-content-center ml-2">
      – Technicians Scheduled in Ticket Area</span
    >
  </div>
  <div
    class="flex mt-2 lg:mt-0 md:mt-0 sm:col-12 lg:col-3 md:col-3 sm:justify-content-start lg:justify-content-center md:justify-content-center"
  >
    <span
      class="p-2 border-white border-3"
      [ngStyle]="{
        height: '36px',
        'background-color': Colors.TECHNICIAN_OFF,
        width: '80px',
        display: 'table',
      }"
    ></span>
    <span class="flex align-items-center justify-content-center ml-2"> – Tech Off / Vacation</span>
  </div>
  <div
    class="flex mt-2 lg:mt-0 md:mt-0 sm:col-12 lg:col-3 md:col-3 sm:justify-content-start lg:justify-content-center md:justify-content-center"
  >
    <span
      class="p-2 border-white border-3"
      [ngStyle]="{
        height: '36px',
        'background-color': Colors.TECHNICIAN_STOPS_WORK_CRITICAL,
        width: '80px',
        display: 'table',
      }"
    ></span>
    <span class="flex align-items-center justify-content-center ml-2"> – Day is Full</span>
  </div>
</div>
<div class="flex flex-wrap p-1 justify-content-end">
  <div>
    <button pButton (click)="prev()" icon="pi pi-arrow-left"></button>
  </div>
  <div class="ml-2">
    <button pButton (click)="today()">Today</button>
  </div>
  <div class="ml-2">
    <button pButton (click)="next()" icon="pi pi-arrow-right"></button>
  </div>
</div>
<full-calendar [options]="calendarOptions" class="table-row-bottom-border">
  <ng-template #eventContent let-arg>
    <div class="event-container flex flex-column">
      <div class="flex align-items-center justify-content-between m-1" *ngIf="displayTagsBlock">
        <!-- Calendar Title -->
        <strong
          tooltipPosition="top"
          [pTooltip]="getTechnicianInfo(arg.event.extendedProps.technician)"
        >{{ arg.event.title }} -{{ arg.event.extendedProps.stops.length }}</strong
        >
        <!-- P/D, G/B, M/T, SHOP -->
        <div class="flex align-items-center">
          <span class="text-blue-500" pTooltip="Pick up / Delivery" *ngIf="arg.event.extendedProps.recallGoingBack"
          >P/D&nbsp;</span
          >
          <span class="text-blue-500" pTooltip="Recall / Going Back" *ngIf="arg.event.extendedProps.recallGoingBack"
          >G/B&nbsp;</span
          >
          <span class="text-blue-500" [pTooltip]="allTechnicians" *ngIf="arg.event.extendedProps.technicians.length > 0"
          >M/T&nbsp;</span
          >
          <span class="text-blue-500" pTooltip="Shop" *ngIf="arg.event.extendedProps.shop">Shop</span>
        </div>
      </div>
      <!-- Vacation -->
      <ng-container *ngIf="displayVacationBlock && arg.event.extendedProps['technician'].vacations.length">
        <ng-container *ngFor="let vacation of arg.event.extendedProps['technician'].vacations">
          <ng-container *ngIf="isCurrentDateWithinVacation(arg.event.start, vacation.startDate, vacation.endDate)">
            <div class="pl-1 flex flex-wrap text-red-500 font-bold">
              Vacation: {{ vacation.startDate | date: 'shortDate' }}-{{
              vacation.endDate | date: 'shortDate'
              }}
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Weekly Off and Time Off (only if no vacation is shown) -->

      <ng-container
        *ngIf="displayWeeklyOffBlock && !hasCurrentDateVacation(arg.event.start, arg.event.extendedProps['technician'].vacations)">
        <ng-container *ngIf="arg.event.extendedProps['technician'].weeklyOffs.length">
          <div *ngFor="let weeklyOff of arg.event.extendedProps['technician'].weeklyOffs">
            <ng-container *ngIf="isMatchingDate(arg.event.start, weeklyOff.weekDay)">
              <!-- Check if the whole day is off -->
              <div class="text-red-500 pl-1 font-bold" *ngIf="weeklyOff.timeOff?.wholeDay">
                Day off
              </div>
              <!-- Check if there are specific time ranges for the day, and no day off or vacation -->
              <div *ngIf="!weeklyOff.timeOff?.wholeDay && weeklyOff.timeOff?.timeRange.length">
                <div
                  class="pl-1 flex align-items-center flex-wrap"
                  *ngIf="weeklyOff.timeOff?.timeRange.length"
                >
                  <span>T/off:</span>
                  <div *ngFor="let timeRange of weeklyOff.timeOff.timeRange; let last = last">
                    {{ formatTime(timeRange.startTime) }}-{{ formatTime(timeRange.endTime) }}
                    <ng-container *ngIf="!last">,</ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <!-- Chips -->
      <div class="flex flex-wrap py-1 px-0 truncate-width" *ngIf="displayAreaChipsBlock">
        <p-chip
          styleClass="px-1"
          *ngFor="let ticketsPerArea of arg.event.extendedProps.ticketsPerArea | keyvalue | slice: 0 : 3"
          class="mr-1 mb-1 tags-wrapper"
        >
          <ng-container *ngIf="arg.event.extendedProps.ticketsPerArea.length <= 3; else overflow">
            <span *ngIf="ticketsPerArea.value[0]" class="text-xs" [pTooltip]="getTooltipText(ticketsPerArea.value)">
              {{ getTruncatedName(ticketsPerArea.value[0]) }}:<b>{{ ticketsPerArea.value[1].count }}</b>
            </span>
          </ng-container>

          <ng-template #overflow>
            <span *ngIf="ticketsPerArea.value[0]" class="text-xs" [pTooltip]="getTooltipText(ticketsPerArea.value)">
              {{ getTruncatedName(ticketsPerArea.value[0], 3) }}:<b>{{ ticketsPerArea.value[1].count }}</b>
            </span>
          </ng-template>
        </p-chip>

        <p-chip *ngIf="arg.event.extendedProps.ticketsPerArea.length > 3">
          <span [pTooltip]="arg.event.extendedProps.allArea" class="text-xs">...</span>
        </p-chip>
      </div>

      <div class="flex justify-content-around align-items-center p-1 truncate-width">
        <div
          class="col p-0"
          *ngIf="arg.event.extendedProps.eventDetail"
          tooltipPosition="top"
          [pTooltip]="arg.event.extendedProps.eventDetail.note"
        >
          <span
            >{{
              arg.event.extendedProps.eventDetail.note?.substring(
                0,
                EVENT_NOTE_MAX_VISIBLE_CHAR_LIMIT - 1
              )
            }}
            <ng-container
              *ngIf="
                arg.event.extendedProps.eventDetail.note?.length >=
                EVENT_NOTE_MAX_VISIBLE_CHAR_LIMIT
              "
            >
              <span>...</span>
            </ng-container>
          </span>
        </div>
        <div class="col py-0 text-right" *ngIf="arg.event?.extendedProps.routeId">
          <a [routerLink]="['/routes', arg.event.extendedProps.routeId]" class="list-link">
            <i class="pi pi-map text-blue-700 font-bold"></i> Route
          </a>
        </div>
      </div>
    </div>
  </ng-template>
</full-calendar>

<p-dialog
  [(visible)]="showStopsDialog"
  [style]="{width: '1000px'}"
  header="Stops"
  [modal]="true"
  (onHide)="hideDialog()"
  [draggable]="false"
  styleClass="p-fluid stopsDialog"
>
  <ng-template pTemplate="header" class="mb-0">
    <div class="col-11 flex-column mb-0">
      <div class="flex col-12 pb-0 align-items-center">
        <h3 class="mb-0">
          {{ selectedTechnician.name }} - {{ selectedDate | date: configService.dateFormat }}
        </h3>
      </div>
      <ng-container
        *ngIf="!canEditCalendarEvent() && eventClickInfo?.event?.extendedProps['eventDetail']?.note"
      >
        <div class="col-12 flex justify-content-start align-items-center">
          <span class="text-xl text-red-700"
            >Day note:
            {{ eventClickInfo?.event?.extendedProps['eventDetail']?.note || ' - ' }}</span
          >
        </div>
        <div class="col-12 py-0">
          <p-divider styleClass="my-0"></p-divider>
        </div>
      </ng-container>
      <ng-container *ngIf="canEditCalendarEvent()">
        <div class="flex align-items-center">
          <form [formGroup]="areaNoteForm" class="flex align-items-center flex-wrap gap-2 w-full">
            <div class="flex flex-column flex-grow-1 min-max-widths">
              <p-multiSelect
                [options]="formattedAreas$ | async"
                formControlName="intermediateAreas"
                optionLabel="nameCode"
                [filter]="true"
                showClear="true"
                placeholder="Areas Name / Code"
                display="chip"
                class="w-full"
              ></p-multiSelect>
            </div>
            <div class="flex flex-column flex-grow-1 min-max-widths">
              <input
                pInputText
                type="text"
                placeholder="Notes for the day"
                id="dayNote"
                formControlName="dayNote"
                class="w-full"
              />
            </div>
          </form>
          <div class="col-1">
            <button
              pButton
              type="button"
              label="Save"
              class="flex justify-content-center"
              (click)="onSaveCalendarEvent()"
            ></button>
          </div>
        </div>

        <div class="col-12">
          <span class="text-center"
            >{{ eventClickInfo?.event?.extendedProps['eventDetail']?.modifiedBy }} -</span
          >
          <span class="ml-1">{{
            eventClickInfo?.event?.extendedProps['eventDetail']?.updatedAt
              | date: configService.dateFormatFull
          }}</span>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <tj-technician-stop-form-scheduler
      [selectedStops]="selectedStops"
      [selectedTechnician]="selectedTechnician"
      [selectedDate]="selectedDate"
    ></tj-technician-stop-form-scheduler>
  </ng-template>
</p-dialog>
