<div class="col-12 text-center">
  <button
    pButton
    *ngIf="customerSignature.id"
    icon="pi pi-trash"
    class="p-button-danger mr-4"
    (click)="removeSelected()"
  ></button>
  <button
    pButton
    class="p-button-danger mr-4"
    label="Clear"
    type="reset"
    (click)="clear()"
  ></button>
  <div class="col-12 flex justify-content-center">
    <signature-pad
      #signature
      [options]="signaturePadOptions"
      style="background-color: white"
    ></signature-pad>
  </div>
  <div class="buttons flex justify-content-center"></div>
  <h4>Customer signature</h4>
</div>
