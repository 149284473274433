import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CommunicationService} from '../service/communication.service';
import {PayPalCaptureResult} from '../model/PayPalCaptureResult.model';
import {PaymentOrderModel} from '../model/payment/payment.order.model';

@Injectable()
export class PaymentApi extends CommunicationService {
  protected apiEndpoint = this.env.apiEndpoint + 'payment';

  createPaypalOrder(extraData?, sandbox = true): Observable<any> {
    const url = `${this.apiEndpoint}/paypal/order?sandbox=${sandbox}`;

    return this.requestService
      .post(url, {
        ...extraData,
      })
      .pipe(
        map(response => response.response),
        catchError(this.handleError),
      );
  }

  capturePaypalOrder(orderId: string, sandbox = true): Observable<PayPalCaptureResult> {
    const url = `${this.apiEndpoint}/paypal/order/${orderId}/capture?sandbox=${sandbox}`;
    return this.requestService.post(url, {}).pipe(
      map(response => response.response),
      catchError(this.handleError),
    );
  }

  createStripeOrder(extraData): Observable<any> {
    const url = `${this.apiEndpoint}/stripe/order`;

    return this.requestService
      .post(url, {
        ...extraData,
      })
      .pipe(
        map(response => response.response),
        catchError(this.handleError),
      );
  }

  getStripeOrder(orderId: string): Observable<PaymentOrderModel> {
    const url = `${this.apiEndpoint}/stripe/order/${orderId}`;
    return this.requestService.get(url).pipe(
      map(response => response.response),
      catchError(this.handleError),
    );
  }

  getPaypalOrder(orderId: string): Observable<PaymentOrderModel> {
    const url = `${this.apiEndpoint}/paypal/order/${orderId}`;
    return this.requestService.get(url).pipe(
      map(response => response.response),
      catchError(this.handleError),
    );
  }
}
