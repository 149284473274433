<p-tabView (onChange)="handleChange($event.index)">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <span
        [ngStyle]="{
          'font-weight': ticket.relatedTicketCounts.customerNameCount > 0 ? 'bold' : 'normal',
          color: ticket.relatedTicketCounts.customerNameCount > 0 ? 'black' : '',
        }"
        pTooltip="{{ ticket.relatedTicketCounts.customerNameCount }}"
        tooltipPosition="top"
        >Customer</span
      >
    </ng-template>
    <p-table
      [autoLayout]="true"
      [rows]="5"
      [paginator]="tickets.length > 0"
      [responsive]="true"
      [value]="tickets"
    >
      <ng-template pTemplate="body" let-ticket>
        <tr>
          <td>
            <a routerLink="/tickets/{{ ticket.id }}" target="_blank"
               class="flex justify-content-between align-items-center">
              <span># {{ ticket.id + ' ' + ticket.customer.name }}</span>
              <tj-ticket-status [status]="ticket.status"></tj-ticket-status>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <ng-container *ngIf="!tickets"> No Match </ng-container>
  </p-tabPanel>

  <p-tabPanel>
    <ng-template pTemplate="header">
      <span
        [ngStyle]="{
          'font-weight': ticket.relatedTicketCounts.addressCount > 0 ? 'bold' : 'normal',
          color: ticket.relatedTicketCounts.addressCount > 0 ? 'black' : '',
        }"
        pTooltip="{{ ticket.relatedTicketCounts.addressCount }}"
        tooltipPosition="top"
        >Addr.</span
      >
    </ng-template>
    <p-table
      [autoLayout]="true"
      [rows]="5"
      [paginator]="tickets.length > 0"
      [responsive]="true"
      [value]="tickets"
    >
      <ng-template pTemplate="body" let-ticket>
        <tr>
          <td>
            <a routerLink="/tickets/{{ ticket.id }}" target="_blank"
               class="flex justify-content-between align-items-center">
              <span># {{ ticket.id + ' ' + ticket.customer.name }}</span>
              <tj-ticket-status [status]="ticket.status"></tj-ticket-status>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <ng-container *ngIf="!tickets"> No Match </ng-container>
  </p-tabPanel>

  <p-tabPanel>
    <ng-template pTemplate="header">
      <span
        [ngStyle]="{
          'font-weight': ticket.relatedTicketCounts.customerContactsCount > 0 ? 'bold' : 'normal',
          color: ticket.relatedTicketCounts.customerContactsCount > 0 ? 'black' : '',
        }"
        pTooltip="{{ ticket.relatedTicketCounts.customerContactsCount }}"
        tooltipPosition="top"
        >Phone / Email</span
      >
    </ng-template>
    <p-table
      [autoLayout]="true"
      [rows]="5"
      [paginator]="tickets.length > 0"
      [responsive]="true"
      [value]="tickets"
    >
      <ng-template pTemplate="body" let-ticket>
        <tr>
          <td>
            <a routerLink="/tickets/{{ ticket.id }}" target="_blank"
               class="flex justify-content-between align-items-center">
              <span># {{ ticket.id + ' ' + ticket.customer.name }}</span>
              <tj-ticket-status [status]="ticket.status"></tj-ticket-status>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <ng-container *ngIf="!tickets"> No Match </ng-container>
  </p-tabPanel>

  <p-tabPanel *ngIf="ticket.externalReferenceCode">
    <ng-template pTemplate="header">
      <span
        [ngStyle]="{
          'font-weight': ticket.relatedTicketCounts.workOrderCount > 0 ? 'bold' : 'normal',
          color: ticket.relatedTicketCounts.workOrderCount > 0 ? 'black' : '',
        }"
        pTooltip="{{ ticket.relatedTicketCounts.workOrderCount }}"
        tooltipPosition="top"
        >PO/WO/SALES</span
      >
    </ng-template>
    <p-table
      [autoLayout]="true"
      [rows]="5"
      [paginator]="tickets.length > 0"
      [responsive]="true"
      [value]="tickets"
    >
      <ng-template pTemplate="body" let-ticket>
        <tr>
          <td>
            <a routerLink="/tickets/{{ ticket.id }}" target="_blank"
               class="flex justify-content-between align-items-center">
              <span># {{ ticket.id + ' ' + ticket.customer.name }}</span>
              <tj-ticket-status [status]="ticket.status"></tj-ticket-status>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <ng-container *ngIf="!tickets"> No Match </ng-container>
  </p-tabPanel>
</p-tabView>

<p class="text-center" *ngIf="tickets.length === 0">No related ticket</p>
