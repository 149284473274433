import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CommunicationService} from '../service/communication.service';
import {Observable} from 'rxjs';
import {User} from '../model/User.model';

@Injectable()
export class UserApi extends CommunicationService {
  protected apiEndpoint = this.env.apiEndpoint + 'auth/';

  findCurrentUser(): Observable<User> {
    const url = this.apiEndpoint + 'user';
    return this.requestService.get(url).pipe(
      map(response => response as User),
      catchError(this.handleError),
    );
  }
}
