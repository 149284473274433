<div [formGroup]="currentFormGroup" class="grid align-items-center">

    <div class="grid justify-content-between">
      <div class="lg:col-1 sm:col-12 col-12">
        <tj-field label="Ticket #" [control]="fcId">
          <input pInputText placeholder="42" id="id" formControlName="id"/>
        </tj-field>
      </div>
      <div class="lg:col-1 sm:col-12 col-12">
        <tj-field label="WO/PO/SALES" [control]="fcExternalReferenceCode">
          <input pInputText type="text"
                 placeholder="WO/42"
                 id="externalReferenceCode"
                 formControlName="externalReferenceCode"/>
        </tj-field>
      </div>
      <div class="lg:col-1 sm:col-6 col-12">
        <tj-field label="Status" [control]="fcStatus">
          <p-dropdown id="status" [options]="ticketStatuses" placeholder="Status"
                      formControlName="status" [autoWidth]="false" optionLabel="label" optionValue="value">
            <ng-template let-option pTemplate="selectedItem">
              <div class="status-item">
                <span class="status-label font-bold">{{ option?.label }}</span>
              </div>
            </ng-template>
          </p-dropdown>
        </tj-field>
      </div>
      <div class="lg:col-2 sm:col-12 col-12">
        <tj-field label="Customer" [control]="fcCustomer">
          <input pInputText type="text"
                 placeholder="Customer"
                 id="customer"
                 formControlName="customer"/>
        </tj-field>
      </div>
      <div class="lg:col-2 sm:col-12 col-12">
        <tj-field label="Customer contacts" [control]="fcContact">
          <input pInputText type="text"
                 placeholder="Email / Phone"
                 id="contact"
                 formControlName="contact"/>
        </tj-field>
      </div>
      <div class="col-12 text-right" *ngIf="isMobile">
        <button pButton label="Advanced search" (click)="handleToggleSearchExpansions()" class="p-button-link"></button>
      </div>

      <ng-container *ngIf="expanded || !isMobile">
        <div class="lg:col-1 sm:col-12 col-12">
          <tj-field label="Invoice #" [control]="fcInvoiceId">
            <input pInputText type="text"
                  placeholder="Invoice #"
                  id="invoiceId"
                  formControlName="invoiceId"/>
          </tj-field>
        </div>
        <div class="lg:col-2 sm:col-12 col-12" *tjHasRole="'PARTNER_VIEW'">
          <tj-field label="Account" [control]="fcPartnerId">
            <tj-partner-combobox formControlName="partnerId" [initPrivatePartner]="false"></tj-partner-combobox>
          </tj-field>
        </div>

        <div class="lg:col-2 sm:col-12 col-12">
          <tj-field label="Technician" [control]="fcTechnicianId">
            <tj-technician-combobox formControlName="technicianId"></tj-technician-combobox>
          </tj-field>
        </div>

      </ng-container>
  </div>

  <div class="flex-1 text-right" *ngIf="!isMobile">
      <button pButton label="Advanced search" (click)="handleToggleSearchExpansions()" class="p-button-link"></button>
  </div>

  <div class="lg:col-12" *ngIf="expanded">
    <div class="grid">
      <div class="lg:col-2 sm:col-12 col-12">
        <tj-field label="Address" [control]="fcAddress">
          <input pInputText type="text"
                 placeholder="Address"
                 id="address"
                 formControlName="address"/>
        </tj-field>
      </div>
      <ng-container *ngIf="configService.isEnabledTerritory()">
        <div class="lg:col-2 sm:col-12 col-12 p-fluid">
          <tj-field label="Areas" [control]="fcAreas">
            <p-multiSelect
              [options]="formattedAreas$ | async"
              formControlName="areas"
              optionLabel="nameCode"
              optionValue="id"
              [filter]="true"
              appendTo="body"
              showClear="true"
              placeholder="Select Areas"
              display="chip"
            >
            </p-multiSelect>
          </tj-field>
        </div>
      </ng-container>
      <div class="lg:col-2 sm:col-12 col-12">
        <tj-field label="Content" [control]="fcContent">
          <input pInputText type="text"
                 placeholder="Content"
                 id="content"
                 formControlName="content"/>
        </tj-field>
      </div>

      <div class="lg:col-2 sm:col-12 col-12">
        <tj-field label="Tags" [control]="fcTags">
          <tj-tag-combobox formControlName="tags"></tj-tag-combobox>
        </tj-field>
      </div>

      <div class="lg:col-1 sm:col-12 col-12">
        <tj-field label="Comment" [control]="fcComment">
          <input pInputText type="text"
                 placeholder="Comment"
                 id="comment"
                 formControlName="comment"/>
        </tj-field>
      </div>


      <div class="lg:col-1 sm:col-12 col-12">
        <tj-field label="Invoice amount" [control]="fcInvoiceAmount">
          <input pInputText type="text"
                 placeholder="Amount"
                 id="invoiceAmount"
                 formControlName="invoiceAmount"/>
        </tj-field>
      </div>

      <div class="lg:col-2 sm:col-12 col-12">
        <tj-field label="Service date" [control]="fcServiceDate">
          <tj-date-range-picker formControlName="serviceDate" dataType="string"
                                [ngClass]="fcServiceDate.value ? 'selected-item-bold' : ''"
                                placeholder="Service date or range"></tj-date-range-picker>
        </tj-field>
      </div>

      <div class="lg:col-2 sm:col-12 col-12">
        <tj-field label="Create date" [control]="fcCreateDate">
          <tj-datepicker [onlyFuture]="false"
                         id="createDate"
                         placeholder="MM/DD/YY"
                         formControlName="createDate"
                         [ngClass]="fcCreateDate.value ? 'selected-item-bold' : ''"></tj-datepicker>
        </tj-field>
      </div>

      <div class="lg:col-2 sm:col-12 col-12">
        <tj-field label="Contact date" [control]="fcContactDate">
          <tj-datepicker [onlyFuture]="false"
                         id="contactDate"
                         placeholder="MM/DD/YY"
                         formControlName="contactDate"
                         [ngClass]="fcContactDate.value ? 'selected-item-bold' : ''"></tj-datepicker>
        </tj-field>
      </div>
      <div class="lg:col-2 sm:col-12 col-12">
        <tj-field label="Type of Service" [control]="fcCategory">
          <input pInputText type="text"
                 placeholder="Type of Service / Lead / Note"
                 id="category"
                 formControlName="category"/>
        </tj-field>
      </div>
      <div class="lg:col-1 sm:col-6 private-checkbox-container" *tjHasRole="'TICKET_PRINT_ADMIN'">
        <p-checkbox formControlName="notPrintedByAdmin" label="Not Printed" binary="true"></p-checkbox>
      </div>
      <div class="lg:col-1 sm:col-6 private-checkbox-container" *tjHasRole="'TICKET_PRINT_PARTNER'">
        <p-checkbox formControlName="notPrintedByPartner" label="Not Printed" binary="true"></p-checkbox>
      </div>
      <div class="lg:col-1 sm:col-6 private-checkbox-container" *tjHasRole="'TICKET_PRINT_TECHNICIAN'">
        <p-checkbox formControlName="notPrintedByTechnician" label="Not Printed" binary="true"></p-checkbox>
      </div>
      <div class="lg:col-1 sm:col-6 private-checkbox-container">
        <p-checkbox binary="true" formControlName="isPrivate" label="Private"></p-checkbox>
      </div>
      <div class="lg:col-2 sm:col-6 private-checkbox-container">
        <p-checkbox binary="true" formControlName="privateRegistered"
                    label="Private - 3rd Party/None Acct"></p-checkbox>
      </div>
      <div class="lg:col-2 sm:col-12 col-12">
        <tj-field label="Private - 3rd Party/None Acct" [control]="fcPrivateRegisteredAccount">
          <input pInputText type="text"
                 placeholder="Name/Contact person/Phone/Email"
                 id="privateRegisteredAccount"
                 formControlName="privateRegisteredAccount"/>
        </tj-field>
      </div>
      <div class="lg:col-1 sm:col-6 private-checkbox-container">
        <p-checkbox binary="true" formControlName="deleted" label="Deleted"></p-checkbox>
      </div>
      <div class="lg:col-fixed sm:col-6 flex align-items-end justify-content-start">
        <p-checkbox binary="true" formControlName="isExtraProcess" label="Extra-Process"></p-checkbox>
      </div>
    </div>
  </div>
</div>
