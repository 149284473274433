<h3 *ngIf="userInvoices?.name">{{ userInvoices.name }}</h3>
<p *ngIf="userInvoices?.email">
  <strong>Email: </strong>
  <span
    class="cursor-pointer"
    style="color: var(--primary-color)"
    [cdkCopyToClipboard]="userInvoices.email"
    (cdkCopyToClipboardCopied)="onCopySuccess($event)"
  >{{ userInvoices.email }}</span
  >
</p>
<p *ngIf="userInvoices?.phoneNumber">
  <strong>Phone: </strong>
  <span
    class="cursor-pointer"
    style="color: var(--primary-color)"
    [cdkCopyToClipboard]="userInvoices.phoneNumber"
    (cdkCopyToClipboardCopied)="onCopySuccess($event)"
  >{{ userInvoices.phoneNumber }}</span
  >
</p>

<p-table *ngIf="userInvoices" [value]="[userInvoices]" [tableStyle]="{'min-width': '50rem'}">
  <ng-template pTemplate="header">
    <tr>
      <th>Total unpaid</th>
      <th>Total due</th>
      <th>Total overdue</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td>{{ item.unpaid?.amount | currency: 'USD' }} / {{ item.unpaid?.count }}</td>
      <td>{{ item.due?.amount | currency: 'USD' }} / {{ item.due?.count }}</td>
      <td>{{ item.overdue?.amount | currency: 'USD' }} / {{ item.overdue?.count }}</td>
    </tr>
  </ng-template>
</p-table>

<hr />

<p-toolbar styleClass="my-4">
  <ng-template pTemplate="left">
    <p-dropdown
      [showClear]="true"
      (onChange)="setBatchAction($event)"
      [options]="batchActions"
      (onClear)="clear()"
      placeholder="Select an action"
    ></p-dropdown>
  </ng-template>

  <ng-template pTemplate="right">
    <button
      pButton
      class="p-button-success mr-2 mb-2"
      [disabled]="selectedInvoices.length === 0"
      (click)="sendToPartner()"
      *ngIf="type !== 'PRIVATE'"
      label="Sent to partner"
    ></button>
    <button
      pButton
      class="p-button-success mr-2 mb-2"
      [disabled]="selectedInvoices.length === 0"
      (click)="sendToCustomer()"
      *ngIf="type === 'PRIVATE'"
      label="Sent to customer"
    ></button>
    <button
      pButton
      class="p-button-success mr-2 mb-2"
      [disabled]="selectedInvoices.length === 0"
      (click)="sendToEmail()"
      label="Sent to email"
    ></button>
    <button
      pButton
      class="p-button-success mr-2 mb-2"
      [disabled]="selectedInvoices.length === 0"
      (click)="downloadStatement()"
      icon="pi pi-file-pdf"
      label="PDF"
    ></button>
  </ng-template>
</p-toolbar>

<p-table
  *ngIf="userInvoices"
  [(selection)]="selectedInvoices"
  [autoLayout]="true"
  [responsive]="true"
  [value]="userInvoices.invoices"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th>Invoice #</th>
      <th>Ticket #</th>
      <th>Extra Process
        <p-tag value="CRM" class="ml-2"/>
      </th>
      <th>Payment Term</th>
      <th>Net term</th>
      <th>Invoiced on</th>
      <th>Total amount</th>
      <th>Status</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-invoice>
    <tr>
      <td>
        <p-tableCheckbox [value]="invoice"></p-tableCheckbox>
      </td>
      <td>
        <a [routerLink]="['/invoice', invoice.id]" class="list-link">
          <strong>{{ invoice.id }}</strong>
        </a>
      </td>
      <td>
        <a
          *ngFor="let item of invoice.items; let last = last"
          [routerLink]="['/tickets', item.ticketId]"
        >
          <strong>{{ item.ticketId }}</strong
          ><span *ngIf="!last">, &nbsp;</span>
        </a>
      </td>
      <td>
        <p-tag *ngIf="invoice.partnerExtraProcess"
               value="CRM"
               class="ml-6"
               [pTooltip]="canViewPartnerExtraProcessNote() ? invoice.partnerExtraProcessNote : ''"
        />
      </td>
      <td>
        <ng-container *ngIf="invoice.paymentType; else notSpecifiedPayment">
          <span
            *ngIf="invoice.paymentType.netTermType === 'CREDIT_CARD_ON_FILE'"
            class="pi pi-credit-card ml-5"
            [ngStyle]="{color: invoice.approvalRequired ? 'red' : 'var(--primary-color)'}"
            style="color: var(--primary-color); font-size: 1.5rem"
            pTooltip="This account pays by credit card"
            tooltipPosition="top"
          >
          </span>
          <span class="ml-3" *ngIf="invoice.paymentType.netTermType !== 'CREDIT_CARD_ON_FILE'">
            {{ invoice.paymentType | netTermTypePipeText }}
          </span>
        </ng-container>
        <ng-template #notSpecifiedPayment> Not specified </ng-template>
      </td>
      <td>{{ invoice.netTerm ? invoice.netTerm + ' Days' : invoice.netTerm + ' Day' }}</td>
      <td class="white-space-nowrap">
        {{ invoice.invoiceDate | date: configService.dateFormatFull }}
      </td>
      <td>{{ invoice.totalAmount | currency: 'USD' }}</td>
      <td>
        <p-dropdown
          [disabled]="true"
          class="custom-invoice-dropdown"
          [ngClass]="{
            'status-unpaid': invoice.status === 'UNPAID',
            'status-overdue': invoice.status === 'OVERDUE',
          }"
          [ngModel]="invoice.status"
          [options]="configService.invoiceStatuses$ | async"
        ></p-dropdown>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-confirmDialog appendTo="body" key="confirmation"></p-confirmDialog>
