<div [formGroup]="form" class="grid">
  <div [formGroupName]="group">
    <div class="col-12">
      <tj-address-search
        (addressChange)="addressChanged($event)"
        [address]="address"
        [mapHeight]="400"
      ></tj-address-search>
    </div>
    <div class="col-12">
      <div class="grid">
        <div class="col-8">
          <tj-field [control]="getControl('street')" label="Address">
            <input
              formControlName="street"
              id="street"
              pInputText
              placeholder="Address"
              type="text"
            />
          </tj-field>
        </div>

        <div class="col-4">
          <tj-field [control]="getControl('apt')" label="UNIT#" class="font-bold">
            <input
              formControlName="apt"
              id="apt"
              pInputText
              placeholder="UNIT#"
              type="text"
              class="border-black-alpha-90"
              (input)="validateAptInput($event)"
            />
          </tj-field>
        </div>

        <div class="col-4">
          <tj-field [control]="getControl('city')" label="City">
            <input formControlName="city" id="city" pInputText placeholder="City" type="text" />
          </tj-field>
        </div>

        <div class="col-4">
          <tj-field [control]="getControl('state')" label="State">
            <input formControlName="state" id="state" pInputText placeholder="State" type="text" />
          </tj-field>
        </div>

        <div class="col-4">
          <tj-field [control]="getControl('postalCode')" label="Zip Code">
            <input
              formControlName="postalCode"
              id="postalCode"
              pInputText
              placeholder="Zip Code"
              type="text"
            />
          </tj-field>
          <ng-content></ng-content>
        </div>
      </div>

      <div class="grid">
        <div class="col-12">
          <tj-field [control]="getControl('additionalAddress')" label="Additional address / Notes">
            <textarea
              formControlName="additionalAddress"
              id="additionalAddress"
              pInputTextarea
              placeholder="Address address"
            ></textarea>
          </tj-field>
        </div>
      </div>
    </div>
  </div>
</div>
