<p-table
  *ngIf="routes"
  (onSort)="sort($event)"
  [(selection)]="selectedRoutes"
  [responsive]="true"
  [value]="routes"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th pSortableColumn="id">
        Route
        <p-sortIcon field="id"></p-sortIcon>
      </th>
      <th pSortableColumn="technicianId">
        Technician
        <p-sortIcon field="technicianId"></p-sortIcon>
      </th>
      <th pSortableColumn="date">
        Route Date
        <p-sortIcon field="date"></p-sortIcon>
      </th>
      <th pSortableColumn="sendDate">
        Send Date
        <p-sortIcon field="sendDate"></p-sortIcon>
      </th>
      <th pSortableColumn="sendDate">
        Send By
        <p-sortIcon field="sendBy"></p-sortIcon>
      </th>
    </tr>
  </ng-template>

  <ng-template let-route pTemplate="body">
    <tr [pSelectableRow]="route" [ngClass]="route.isEdited ? 'bg-red-300' : ''">
      <td>
        <p-tableCheckbox [value]="route"></p-tableCheckbox>
      </td>
      <td>
        <a [routerLink]="['/routes', route.id]">{{ route.id }}</a>
      </td>
      <td>
        <a [routerLink]="['/routes', route.id]">{{ route.technicianName }}</a>
      </td>
      <td>
        {{ route.date | date: config.dateFormat }}
      </td>
      <td>
        {{ route.sendDate | date: config.dateFormatFull }}
      </td>
      <td>
        {{ route.sendBy }}
      </td>
    </tr>
  </ng-template>
</p-table>
