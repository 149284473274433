<div class="reviews-container">
  <p-toolbar styleClass="mb-4 gap-2" *tjHasRole="'REVIEW_ADD'">
    <ng-template pTemplate="left"> </ng-template>
    <ng-template pTemplate="right">
      <button
        pButton
        label="Add Link"
        icon="pi pi-plus"
        class="p-button-success"
        (click)="showForm = true"
      ></button>
    </ng-template>
  </p-toolbar>

  <p-dialog
    [(visible)]="showForm"
    header="Add New Review"
    [modal]="true"
    [closable]="true"
    [responsive]="true"
  >
    <tj-review-form
      (reviewCreated)="addReview($event)"
      (reviewCanceled)="cancelReview()"
    ></tj-review-form>
  </p-dialog>

  <p-table
    [value]="reviews"
    [responsiveLayout]="'scroll'"
    *ngIf="reviews"
    [rows]="25"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[25, 50, 75, 100]"
    [paginator]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Name</th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-review let-i="rowIndex">
      <tr>
        <td>
          <a [href]="review.url" target="_blank">{{ review.name }}</a>
        </td>
        <td style="width: 150px">
          <button
            *tjHasRole="'REVIEW_DELETE'"
            pButton
            icon="pi pi-trash"
            class="p-button-danger mr-2"
            (click)="deleteReview(i)"
            title="Delete"
          ></button>

          <button
            pButton
            icon="pi pi-copy"
            [cdkCopyToClipboard]="review.url"
            (cdkCopyToClipboardCopied)="onCopySuccess()"
            title="Copy URL"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
