<div class="col-12 card">
  <p-table
    [value]="domains"
    [paginator]="true"
    [sortField]="'count'"
    [sortOrder]="-1"
    [rows]="20"
    [rowsPerPageOptions]="[10, 20, 50, 100]"
    responsiveLayout="stack"
    [breakpoint]="'960px'"
    [tableStyle]="{'min-width': '50rem'}"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="domain">
          Domain
          <p-sortIcon field="domain"></p-sortIcon>
        </th>
        <th pSortableColumn="count">
          Tickets
          <p-sortIcon field="count"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>
          <a target="_blank" class="list-link" [href]="'https://' + item.domain">
            {{ item.domain }}
          </a>
        </td>
        <td>
          <span class="text-l font-bold">{{ item.count }}</span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
