import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ConfigService} from '../../../../core/services';

@Component({
  selector: 'tj-partner-type-combobox',
  templateUrl: './partner-type-combobox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PartnerTypeComboboxComponent),
      multi: true,
    },
  ],
})
export class PartnerTypeComboboxComponent implements OnInit, ControlValueAccessor {
  @Output() typeChange = new EventEmitter();

  @Input() forceSelection = false;

  @Input() placeholder = 'Select industry / type';

  types: string[] = [];

  filteredTypes;

  innerValue: string;

  private onTouchedCallback: Function;
  private onChangeCallback: Function;

  constructor(protected configService: ConfigService) {}

  get value(): any {
    return this.innerValue;
  }

  set value(value: string) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.onChangeCallback(value);
    }
  }

  ngOnInit() {
    this.configService.partnerTypes$.subscribe(types => {
      this.types = types.map(it => it.label);
    });
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any): void {
    const searchedValue = this.types.find(p => p === value);
    this.innerValue = searchedValue ? searchedValue : value;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onFilterSuggestions(event) {
    const query = event.query;
    const values = this.types;
    const filtered: any[] = [];
    for (const value of values) {
      if (value.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(value);
      }
    }
    this.filteredTypes = filtered;
  }
}
