import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseFormGroup} from '../../../shared/utils/base-form-group';
import {Ticket} from '../../../../api/model/Ticket.model';
import {TicketTechnician} from '../../../../api/model/TicketTechnicians.model';
import {TechnicianMinimalListItem} from '../../../../api/model/TechnicianMinimalListItem';
import {ConfigService, TjKeycloakService} from '../../../../core/services';
import * as dayjs from 'dayjs';
import {PayType} from '../../../shared/enums/pay.type';
import {ApiService} from '../../../../api/service/api.service';
import {ConfirmationService} from 'primeng/api';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {WeekDays} from '../../../shared/enums/weekDays';
import {WeeklyOff} from '../../../../api/model/WeeklyOff.model';
import {Vacation} from '../../../../api/model/Vacation.model';
import {TicketStatus} from '../../../../api/status/ticketStatus';
import {
  TechRoutes,
  TicketRouteModel,
} from '../../../../api/model/ticket/ticket.route.details.model';
import {RouteService} from '../../../routes/route.service';

@Component({
  selector: 'tj-ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss'],
})
export class TicketFormComponent extends BaseFormGroup implements OnInit, OnDestroy {
  private readonly CATEGORY_MAX_LENGTH: number = 40;
  private unsubscribe$ = new Subject<void>();
  protected readonly TicketStatus = TicketStatus;

  @Input() ticket: Ticket;

  @Input() isSaving = false;
  @Input() readonly = false;
  @Input() disableActions = false;

  @Input() isPayFormed = false;

  @Input() sendToPayFormClicked = false;

  @Input() form: UntypedFormGroup;

  @Input() group: string;

  @Input() areas: string[];

  @Output() statusChange = new EventEmitter();

  @Output() sendToPayForm = new EventEmitter();
  @Output() updatePayForm = new EventEmitter();

  @Output() onSaveAndNotify = new EventEmitter();

  @Output() reportChange = new EventEmitter();

  technicians: TechnicianMinimalListItem[];
  supportedTechnicianByAreaAndDate: number[] = [];
  formControls;
  availableRoutesDialog = false;
  availableRoutes: TicketRouteModel[] = [];
  statusMap;

  ticketEstimations;

  payFormButtonState = {
    cancel: {
      visible: false,
      for: [] as number[],
    },
    update: {
      visible: false,
      for: [] as number[],
    },
    edit: {
      visible: true,
      for: [] as number[],
    },
  };

  showAppointmentServiceDate = true;

  private confirmationQueue: any[] = [];
  private isConfirmationDialogVisible = false;

  techLatestRouteMap = new Map<number, TicketRouteModel>();

  techRoutesByDateMap = new Map<number, TechRoutes>();

  constructor(
    private fb: UntypedFormBuilder,
    public configService: ConfigService,
    private keycloakService: TjKeycloakService,
    private routeService: RouteService,
    private apiService: ApiService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
  ) {
    super();

    this.ticketEstimations = configService.ticketEstimations.map(value => {
      return {label: value, value};
    });
  }

  ngOnInit() {
    const ticket = this.ticket;
    this.formControls = {
      status: this.fb.control(ticket.status),
      serviceDate: this.fb.control(ticket.serviceDate),
      serviceTimeStart: this.fb.control(ticket.serviceTimeStart),
      serviceTimeEnd: this.fb.control(ticket.serviceTimeEnd),
      ticketPartnerStatus: this.fb.control(ticket.ticketPartnerStatus),
      technicians: this.fb.array(this.createTechniciansGroups(this.ticket), [
        this.validateTechniciansUniqueness,
        this.checkMissingTechnicianIds,
      ]),

      pickUpDelivery: this.fb.control({
        value: ticket.pickUpDelivery,
        disabled: !this.canEditPickUpDelivery(),
      }),

      shop: this.fb.control({
        value: ticket.shop,
        disabled: !this.canEditShop(),
      }),

      recallGoingBack: this.fb.control({
        value: ticket.recallGoingBack,
        disabled: !this.canEditRecallGoingBack(),
      }),

      timeNeeded: this.fb.control({
        value: ticket.timeNeeded || this.configService.defaultTicketEstimation,
        disabled: !this.keycloakService.hasRole('TICKET_EDIT_TIME_NEEDED'),
      }),

      checkReceived: this.fb.control({
        value: ticket.checkReceived,
        disabled: this.canEditCheckReceived(),
      }),

      appointmentDetails: this.fb.control({
        value: ticket.appointmentDetails,
        disabled: !this.keycloakService.hasRole('TICKET_EDIT_APPOINTMENT_DETAILS'),
      }),

      issue: this.fb.control(
        {
          value: ticket.issue,
          disabled: !this.keycloakService.hasRole('TICKET_EDIT_ISSUE'),
        },
        Validators.required,
      ),

      category: this.fb.control(
        {
          value: ticket.category,
          disabled: !this.keycloakService.hasRole('TICKET_EDIT_CATEGORY'),
        },
        Validators.maxLength(this.CATEGORY_MAX_LENGTH),
      ),

      report: this.fb.control({
        value: ticket.report,
        disabled: !this.keycloakService.hasRole('TICKET_EDIT_REPORT'),
      }),

      additionalNotes: this.fb.control({
        value: ticket.additionalNotes,
        disabled: !this.keycloakService.hasRole('TICKET_EDIT_ADDITIONAL_NOTES'),
      }),

      internalInfo: this.fb.control({
        value: ticket.internalInfo,
        disabled: !this.keycloakService.hasRole('TICKET_EDIT_INTERNAL'),
      }),

      externalReferenceCode: this.fb.control({
        value: ticket.externalReferenceCode,
        disabled: !this.keycloakService.hasRole('TICKET_EDIT_EXTERNAL_CODE'),
      }),
    };

    if (this.configService.isEnabledWarranty()) {
      this.formControls = {
        ...this.formControls,
        deliveryDate: this.fb.control(ticket.deliveryDate),
        warrantyTerms: this.fb.control(ticket.warrantyTerms),
      };
      if (!this.keycloakService.hasRole('TICKET_EDIT_WARRANTY')) {
        this.formControls.deliveryDate.disable();
        this.formControls.warrantyTerms.disable();
      }
    }
    this.buildForm(this.form, this.formControls, this.group);
    this.defineGetters(this.formControls);
    this.currentFormGroup.validator = this.validateTimeRange('serviceTimeStart', 'serviceTimeEnd');

    this.configService.technicians$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.technicians = value;
    });

    if (
      !this.keycloakService.hasRole('TICKET_EDIT_SCHEDULE') &&
      !(
        this.keycloakService.hasRole('TICKET_EDIT_APPOINTMENT_DATE') &&
        this.ticket.status === TicketStatus.ASSIGNED
      )
    ) {
      this.formControls.serviceDate.disable();
      this.formControls.serviceTimeStart.disable();
      this.formControls.serviceTimeEnd.disable();
    } else if (
      this.keycloakService.hasRole('TICKET_EDIT_APPOINTMENT_DATE') &&
      this.ticket.status === TicketStatus.ASSIGNED
    ) {
      this.formControls.serviceDate.enable();
      this.formControls.serviceTimeStart.enable();
      this.formControls.serviceTimeEnd.enable();
    }

    if (this.loggedInUserIsHeadTech(ticket)) {
      this.formControls.report.enable();
    }

    this.onServiceDateTimeChange();

    this.initRoutes();

    this.getSupportedTechniciansByAreasAndDate(ticket?.serviceDate);
  }

  onStatusChange(value) {
    this.statusChange.emit(value);
  }

  onAddTechnician() {
    this.form.markAsDirty();
    this.addTechnician();
  }

  canEditCheckReceived(): boolean {
    return !this.keycloakService.hasRole('TICKET_EDIT_CHECK_RECEIVED');
  }

  canViewCheckReceived(): boolean {
    return this.ticket.technicians.some(tech => {
      return tech.check;
    });
  }

  onSendToPayForm() {
    this.sendToPayForm.emit();
  }

  onRemoveTechnician(index: number) {
    const technicians: UntypedFormArray = this.formControls.technicians;
    this.form.markAsDirty();
    const removingHead = technicians.at(index).get('head').value === true;
    // if the page wasn't refreshed after adding a technician then deletion should be explicitly propagated to the parent form
    (this.currentFormGroup.get('technicians') as UntypedFormArray).removeAt(index);
    if (technicians.length && removingHead) {
      this.formControls.technicians.at(0).get('head').setValue(true);
    }
    this.recheckHeadSelection();
  }

  onTechnicianHeadChange(index) {
    this.currentFormGroup
      .get('technicians')
      ['controls'].forEach((currentTechnicianGroup, currentIndex) => {
        if (currentIndex !== index) {
          currentTechnicianGroup.get('head').setValue(false);
        } else {
          this.onTechnicianShowCalendarForTicket(index, {checked: true});
          this.onTechnicianShowRouteForTicket(index, {checked: true});
        }
      });
  }

  onTechnicianPartsPickupChange(index) {
    this.currentFormGroup
      .get('technicians')
      ['controls'].forEach((currentTechnicianGroup, currentIndex) => {
        if (currentIndex === index) {
          if (!currentTechnicianGroup.get('partsPickup').value) {
            currentTechnicianGroup.get('expenses').setValue(null);
            currentTechnicianGroup.get('expensesDescription').setValue(null);
            currentTechnicianGroup.get('technicianExpenses').setValue(null);
            currentTechnicianGroup.get('technicianExpensesDescription').setValue(null);
          }
        }
      });
  }

  private updateTechnicianProperty(index: number, property: string, value: any): void {
    const technicianControls = this.currentFormGroup.get('technicians')['controls'];
    if (technicianControls[index]) {
      technicianControls[index].get(property)?.setValue(value);
    }
  }

  onTechnicianShowCalendarForTicket(index: number, event: any): void {
    this.updateTechnicianProperty(index, 'showCalendarForTicket', event.checked);
  }

  onTechnicianShowRouteForTicket(index: number, event: any): void {
    this.updateTechnicianProperty(index, 'showRouteForTicket', event.checked);
  }

  onClearDate() {
    this.formControls.serviceTimeStart.setValue(null);
    this.formControls.serviceTimeEnd.setValue(null);
    this.formControls.timeNeeded.setValue(this.configService.defaultTicketEstimation);
  }

  onServiceDateChange(serviceDate: any) {
    if (!serviceDate) {
      return;
    }

    const pastServiceDateWarningIsEnabled = this.configService.isConfigEnabled(
      'SHOW_WARNING_ON_PAST_SERVICE_DATE',
    );
    if (!pastServiceDateWarningIsEnabled) {
      return;
    }

    const today = dayjs().startOf('day');
    const selectedDate = dayjs(serviceDate).startOf('day');

    if (selectedDate.isBefore(today)) {
      this.confirmationService.confirm({
        message: 'You have selected already past day. Are you sure you want to proceed?',
        header: 'Last Day Confirmation',
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmLastDay',
        accept: () => {
          this.technicianAvailableRouts(serviceDate);
        },
        reject: () => {
          this.formControls.serviceDate.setValue(null);
        },
        closeOnEscape: false,
        blockScroll: true,
      });

      // Trigger change detection to ensure the dialog is displayed
      this.cdr.detectChanges();
    } else {
      this.processServiceDateChange(serviceDate);
      this.technicianAvailableRouts(serviceDate);
    }

    this.getSupportedTechniciansByAreasAndDate(serviceDate);
  }

  getSupportedTechniciansByAreasAndDate(serviceDate: any) {
    if (!this.configService.isEnabledTerritory() || !serviceDate) {
      this.supportedTechnicianByAreaAndDate = [];
      return;
    }

    const areaIds = this.ticket?.customer?.address?.areas?.map(value => value.id) || [];
    if (areaIds.length === 0) {
      console.warn('No areas available for the ticket.');
      return;
    }

    this.apiService.technician.getTechniciansByAreasAndDate(areaIds, serviceDate).subscribe({
      next: technicianIds => {
        this.supportedTechnicianByAreaAndDate = technicianIds;
      },
      error: error => {
        console.error('Error fetching supported technicians:', error);
      },
    });
  }

  private processServiceDateChange(serviceDate: any) {
    if (this.form.get('serviceTimeStart').value === null) {
      const serviceStartTime = dayjs(serviceDate)
        .set('hour', 8)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)
        .tz('UTC', true);

      this.form.get('serviceTimeStart').setValue(serviceStartTime);
    }
    if (this.form.get('serviceTimeEnd').value === null) {
      const serviceEndTime = dayjs(serviceDate)
        .set('hour', 20)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)
        .tz('UTC', true);

      this.form.get('serviceTimeEnd').setValue(serviceEndTime);
    }

    this.checkedTechnicianWorkLimits(serviceDate, null);
  }

  private technicianAvailableRouts(serviceDate: any) {
    const techniciansControls: UntypedFormArray = this.formControls.technicians;
    const technicianIds = techniciansControls.controls.map(
      technicianControl => technicianControl.value.id,
    );

    if (!Array.isArray(technicianIds) || technicianIds.length === 0) {
      console.log('Skipping request due to invalid or empty technicianIds');
      return;
    }

    this.apiService.routeApi.getAvailableRoutes(serviceDate, technicianIds).subscribe(
      response => {
        if (response && response.length > 0) {
          this.availableRoutesDialog = true;
          this.availableRoutes = response;
        }
      },
      error => {
        console.error('Error fetching available routes:', error);
      },
    );
  }

  closeAvailableRoutesDialog() {
    console.log('close available dialog');
    this.availableRoutesDialog = false;
    this.availableRoutes = [];
  }

  openInNewTab(event: Event, routeId: number) {
    event.preventDefault();
    window.open('/routes/' + routeId, '_blank');
  }

  changeTimeNeeded(timeNeeded: any) {
    this.checkedTechnicianWorkLimitsWhenChangeTimeNeeded(timeNeeded.value);
  }

  private checkedTechnicianWorkLimits(serviceDate: any, timeNeeded: any): void {
    const techniciansControls: UntypedFormArray = this.formControls.technicians;

    techniciansControls.controls.forEach(control => {
      const technicianId = control.value.id;
      const foundTechnician = this.technicians?.find(t => t.id === technicianId);

      if (foundTechnician) {
        this.technicianChange(foundTechnician, this.ticket.id, serviceDate, timeNeeded);
      }
    });
  }

  onServiceDateTimeChange() {
    let previousServiceTimeStart = this.form.get('serviceTimeStart').value;
    let previousServiceTimeEnd = this.form.get('serviceTimeEnd').value;

    const extractTime = dateTime => {
      return dateTime ? new Date(dateTime).toTimeString().split(' ')[0] : null;
    };

    this.form.get('serviceDate').valueChanges.subscribe(() => {
      // Reset previous time values when the service date changes
      previousServiceTimeStart = this.form.get('serviceTimeStart').value;
      previousServiceTimeEnd = this.form.get('serviceTimeEnd').value;
    });

    this.form.get('serviceTimeStart').valueChanges.subscribe(newServiceTimeStart => {
      if (extractTime(newServiceTimeStart) !== extractTime(previousServiceTimeStart)) {
        previousServiceTimeStart = newServiceTimeStart; // Update the previous value
        this.checkAvailabilityForAllAttachTechnicians();
      }
    });

    this.form.get('serviceTimeEnd').valueChanges.subscribe(newServiceTimeEnd => {
      if (extractTime(newServiceTimeEnd) !== extractTime(previousServiceTimeEnd)) {
        previousServiceTimeEnd = newServiceTimeEnd; // Update the previous value
        this.checkAvailabilityForAllAttachTechnicians();
      }
    });

    this.form.get('report').valueChanges.subscribe(newReport => {
      this.reportChange.emit(newReport);
    });
  }

  private checkedTechnicianWorkLimitsWhenChangeTimeNeeded(timeNeeded: any) {
    const techniciansControls: UntypedFormArray = this.formControls.technicians;
    for (const technicianControl of techniciansControls.controls) {
      const technicianId = technicianControl.value.id;
      const foundTechnician = this.technicians?.find(t => t.id === technicianId);
      if (foundTechnician) {
        this.checkTechnicianAvailability(
          foundTechnician,
          false,
          false,
          true,
          this.ticket.id,
          null,
          timeNeeded,
        );
      }
    }
  }

  private checkAvailabilityForAllAttachTechnicians() {
    const techniciansControls: UntypedFormArray = this.formControls.technicians;
    techniciansControls.controls.forEach(technicianControl => {
      const technicianId = technicianControl.value.id;
      const foundTechnician = this.technicians?.find(t => t.id === technicianId);
      if (foundTechnician) {
        this.checkTechnicianAvailability(foundTechnician, false, true, false);
      }
    });
  }

  canChangeTechnician() {
    return this.keycloakService.hasRole('TICKET_EDIT_TECHNICIAN');
  }

  canViewTechnicianName() {
    return !this.canChangeTechnician() && this.keycloakService.hasRole('TICKET_VIEW_ASSIGNED');
  }

  canViewTechnicianWorkPrice() {
    return this.keycloakService.hasRole('TICKET_VIEW_TECHNICIAN_WORK_PRICE');
  }

  canViewTechnicianRouteForTicketCheckbox() {
    return this.keycloakService.hasRole('TICKET_FOR_TECHNICIAN_ROUTE_VIEW');
  }

  canViewTechnicianCalendarForTicketCheckbox() {
    return this.keycloakService.hasRole('TICKET_FOR_TECHNICIAN_CALENDAR_VIEW');
  }

  canViewCustomerNotifyButton() {
    return this.keycloakService.hasRole('TICKET_EDIT_NOTIFY_CUSTOMER');
  }

  isTechnicianHourBased(technicianId) {
    const technician =
      this.ticket.technicians.find(t => t.id === technicianId) ||
      this.technicians.find(t => t.id === technicianId);
    return technician && technician.payType && technician.payType === PayType.HOUR_BASED;
  }

  isTechnicianCommissionBased(technicianId) {
    const technician =
      this.ticket.technicians.find(t => t.id === technicianId) ||
      this.technicians.find(t => t.id === technicianId);
    return technician && technician.payType && technician.payType === PayType.COMMISSION_BASED;
  }

  getTechnicianUnitValue(technicianId) {
    const ticketTechnician = this.ticket.technicians.find(
      technician => technician.id === technicianId,
    );
    if (ticketTechnician) {
      return ticketTechnician.unit;
    }
    return this.technicians.find(technician => technician.id === technicianId)?.unit;
  }

  getTechnicianPercentValue(technicianId) {
    const ticketTechnician = this.ticket.technicians.find(
      technician => technician.id === technicianId,
    );
    if (ticketTechnician) {
      return ticketTechnician.percent;
    }
    return this.technicians.find(technician => technician.id === technicianId)?.percent;
  }

  private validateTechniciansUniqueness(techniciansGroupArray: UntypedFormArray) {
    const selectedIds = techniciansGroupArray.value
      .map(technician => technician.id)
      .filter(id => !!id);

    // check if ids are not unique
    if (new Set(selectedIds).size !== selectedIds.length) {
      return {
        uniqueness: {
          valid: false,
        },
      };
    }
    return null;
  }

  private checkMissingTechnicianIds(
    techniciansGroupArray: UntypedFormArray,
  ): {[key: string]: any} | null {
    const invalidRows = techniciansGroupArray.controls
      .map((control, index) => (!control.get('id')?.value ? index : null))
      .filter(index => index !== null);

    if (invalidRows.length > 0) {
      return {
        technicianIsRequired: {
          valid: false,
          invalidRows,
        },
      };
    }

    return null;
  }

  private validateTimeRange(startTimeControlName: string, endTimeControlName: string) {
    return (group: UntypedFormGroup): {[key: string]: any} => {
      const startTimeControl = group.get(startTimeControlName);
      const endTimeControl = group.get(endTimeControlName);
      if (startTimeControl && endTimeControl) {
        if (startTimeControl.value && endTimeControl.value) {
          const startTime = dayjs(startTimeControl.value);
          const endTime = dayjs(endTimeControl.value);
          const isValid = startTime.isBefore(endTime);
          return isValid ? null : {timeRangeError: {valid: false}};
        }
      }
      return null;
    };
  }

  private createTechnicianGroup(
    technician: TicketTechnician,
    isHead: boolean = false,
    loggedInUserIsHeadTech: boolean = false,
    disabled: boolean = false,
  ) {
    const group = this.fb.group({
      id: this.fb.control(technician.id, Validators.required),
      head: this.fb.control({value: isHead, disabled}, Validators.required),
      incomeCash: this.fb.control(technician.incomeCash),
      bill: this.fb.control(technician.bill),
      payableHours: this.fb.control(technician.payableHours || 0),
      check: this.fb.control(technician.check),
      creditCard: this.fb.control(technician.creditCard),
      expenses: this.fb.control(technician.expenses),
      expensesDescription: this.fb.control(technician.expensesDescription),
      technicianExpenses: this.fb.control(technician.technicianExpenses),
      technicianExpensesDescription: this.fb.control(technician.technicianExpensesDescription),
      partsPickup: this.fb.control(technician.partsPickup || false),
      showCalendarForTicket: this.fb.control(technician.showCalendarForTicket || false),
      showRouteForTicket: this.fb.control(technician.showRouteForTicket || false),
    });

    if (technician.payFormId && technician.payFormCreatedAt) {
      group.disable();
      this.payFormButtonState.edit.for.push(technician.id);
      return group;
    }

    if (loggedInUserIsHeadTech) {
      group.enable();
      group.get('id').disable();
      group.get('head').disable();
      group.get('showCalendarForTicket').disable();
      group.get('showRouteForTicket').disable();
      return group;
    }

    if (!this.keycloakService.hasRole('TICKET_EDIT_TECHNICIAN')) {
      group.disable();
    }

    return group;
  }

  private createTechniciansGroups(ticket: Ticket) {
    if (!ticket.technicians) {
      return [];
    }

    const loggedInUserIsHeadTech = this.loggedInUserIsHeadTech(ticket);

    const theOnlyTechnician = ticket.technicians.length === 1;
    return ticket.technicians.map((technician: TicketTechnician) => {
      return this.createTechnicianGroup(
        technician,
        technician.head,
        loggedInUserIsHeadTech,
        theOnlyTechnician,
      );
    });
  }

  public loggedInUserIsHeadTech(ticket: Ticket): boolean {
    return ticket.technicians.some(technician => {
      return technician.username === this.keycloakService.user.username && technician.head;
    });
  }

  private recheckHeadSelection() {
    const technicians: UntypedFormArray = this.formControls.technicians;
    // adding second technician will enable head selection control for the first one
    if (technicians.length === 0) {
      return;
    }
    if (technicians.length > 1) {
      technicians.at(0).get('head').enable();
    } else {
      technicians.at(0).get('head').disable();
      technicians.at(0).get('showCalendarForTicket').setValue(true);
      technicians.at(0).get('showRouteForTicket').setValue(true);
    }
  }

  private addTechnician() {
    const isHead = this.formControls.technicians.length === 0;
    this.formControls.technicians.push(this.createTechnicianGroup({} as TicketTechnician, isHead));
    this.recheckHeadSelection();
  }

  saveAndNotify() {
    this.onSaveAndNotify.emit();
  }

  isSetServiceDateTime(): boolean {
    if (
      !(
        !!this.ticket.serviceDate ||
        !!this.ticket.serviceTimeStart ||
        !!this.ticket.serviceTimeEnd
      ) &&
      this.keycloakService.hasRole('TICKET_CREATE_AUTO_ASSIGNED_PARTNER')
    ) {
      this.showAppointmentServiceDate = false;
      return true;
    }
    this.showAppointmentServiceDate = true;
    return false;
  }

  private showNextConfirmation() {
    if (this.confirmationQueue.length === 0) {
      this.isConfirmationDialogVisible = false;
      return;
    }

    if (this.isConfirmationDialogVisible) {
      return;
    }

    const currentConfirmation = this.confirmationQueue.shift();
    this.isConfirmationDialogVisible = true;

    this.confirmationService.confirm({
      ...currentConfirmation,
      accept: () => {
        if (currentConfirmation.accept) {
          currentConfirmation.accept();
        }
        this.isConfirmationDialogVisible = false;
        setTimeout(() => this.showNextConfirmation(), 300); // Delay to ensure proper UI handling
      },
      reject: () => {
        if (currentConfirmation.reject) {
          currentConfirmation.reject();
        }
        this.isConfirmationDialogVisible = false;
        setTimeout(() => this.showNextConfirmation(), 300); // Delay to ensure proper UI handling
      },
      closeOnEscape: false,
      blockScroll: true,
    });
  }

  technicianChange(
    event: any,
    ticketId?: number,
    changedServiceDate?: Date,
    changedTimeNeeded?: number,
  ) {
    this.checkTechnicianAvailability(
      event,
      true,
      true,
      true,
      ticketId,
      changedServiceDate,
      changedTimeNeeded,
    );
  }

  private isTechnicianWeeklyOff(
    technician: TechnicianMinimalListItem,
    serviceDate: Date,
    startTime: Date,
    endTime: Date,
  ): {
    condition: boolean;
    weeklyOff?: WeeklyOff;
  } {
    const dayOfWeek = dayjs(serviceDate).weekday();
    const dayEnum = Object.values(WeekDays)[dayOfWeek]?.toUpperCase();
    const weeklyOff = technician.weeklyOffs.find(off => off.weekDay === dayEnum);

    if (weeklyOff) {
      const timeOff = weeklyOff.timeOff;
      if (timeOff && timeOff.wholeDay) {
        return {condition: true, weeklyOff};
      }
      if (timeOff && timeOff.timeRange) {
        const serviceStartTime = startTime.getHours() * 60 + startTime.getMinutes();
        const serviceEndTime = endTime.getHours() * 60 + endTime.getMinutes();

        for (const range of timeOff.timeRange) {
          const rangeStartTime =
            new Date(range.startTime).getHours() * 60 + new Date(range.startTime).getMinutes();
          const rangeEndTime =
            new Date(range.endTime).getHours() * 60 + new Date(range.endTime).getMinutes();

          if (serviceStartTime < rangeEndTime && serviceEndTime > rangeStartTime) {
            return {condition: true, weeklyOff};
          }
        }
      }
    }
    return {condition: false};
  }

  private isTechnicianOnVacation(
    technician: TechnicianMinimalListItem,
    serviceDate: Date,
  ): {condition: boolean; vacation?: Vacation} {
    for (const vacation of technician.vacations) {
      if (
        (dayjs(serviceDate).isSame(dayjs(vacation.startDate)) ||
          dayjs(serviceDate).isAfter(dayjs(vacation.startDate))) &&
        (dayjs(serviceDate).isSame(dayjs(vacation.endDate)) ||
          dayjs(serviceDate).isBefore(dayjs(vacation.endDate)))
      ) {
        return {condition: true, vacation};
      }
    }
    return {condition: false};
  }

  private async checkTechnicianAvailability(
    event: any,
    checkVacation: boolean,
    checkWeeklyOff: boolean,
    checkWorkLimits: boolean,
    ticketId?: number,
    changedServiceDate?: Date,
    changedTimeNeeded?: number,
  ) {
    const serviceDate = changedServiceDate || this.currentFormGroup.get('serviceDate').value;
    const startTime = new Date(serviceDate);
    startTime.setHours(new Date(this.currentFormGroup.get('serviceTimeStart').value).getHours());
    startTime.setMinutes(
      new Date(this.currentFormGroup.get('serviceTimeStart').value).getMinutes(),
    );

    const endTime = new Date(serviceDate);
    endTime.setHours(new Date(this.currentFormGroup.get('serviceTimeEnd').value).getHours());
    endTime.setMinutes(new Date(this.currentFormGroup.get('serviceTimeEnd').value).getMinutes());

    let technicianOnVacation: {condition: boolean; vacation?: Vacation} = {
      condition: false,
      vacation: null,
    };
    if (checkVacation) {
      technicianOnVacation = this.isTechnicianOnVacation(event, serviceDate);
    }

    let technicianWeeklyOff: {condition: boolean; weeklyOff?: WeeklyOff} = {
      condition: false,
      weeklyOff: null,
    };
    if (checkWeeklyOff) {
      technicianWeeklyOff = this.isTechnicianWeeklyOff(event, serviceDate, startTime, endTime);
    }

    let technicianWorkLimits: {condition: boolean; workLimitMessage?: string} = {
      condition: false,
      workLimitMessage: null,
    };
    if (checkWorkLimits) {
      technicianWorkLimits = await this.checkWeeklyOff(
        event,
        ticketId,
        changedServiceDate,
        changedTimeNeeded,
      );
    }

    if (
      (checkVacation && technicianOnVacation.condition) ||
      (checkWeeklyOff && technicianWeeklyOff.condition) ||
      (checkWorkLimits && technicianWorkLimits.condition)
    ) {
      let warningMessage = '';
      if (checkVacation && technicianOnVacation.condition) {
        warningMessage += this.getVacationMessage(technicianOnVacation);
      }

      if (checkWeeklyOff && technicianWeeklyOff.condition && technicianWeeklyOff.weeklyOff) {
        const weeklyOff = technicianWeeklyOff.weeklyOff;
        let timeOffString = '';

        if (weeklyOff.timeOff && !weeklyOff.timeOff.wholeDay && weeklyOff.timeOff.timeRange) {
          const displayTimeOptions: Intl.DateTimeFormatOptions = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // This will include AM/PM
          };
          timeOffString = this.getTimeOffString(weeklyOff, displayTimeOptions);
        }
        warningMessage += this.getWeeklyOffMessage(serviceDate, timeOffString);
      }

      if (
        checkWorkLimits &&
        technicianWorkLimits.condition &&
        technicianWorkLimits.workLimitMessage
      ) {
        warningMessage += technicianWorkLimits.workLimitMessage;
      }

      this.confirmationQueue.push({
        message: warningMessage,
        header: `Technician Unavailable: ${event.name} | ${event.personnelCode}`,
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmAddTechnician',
        accept: () => {},
        reject: () => {
          const technicians: UntypedFormArray = this.formControls.technicians;
          const index = technicians.controls.findIndex(tech => tech.value.id === event.id);
          technicians.removeAt(index);
        },
      });

      if (!this.isConfirmationDialogVisible) {
        this.showNextConfirmation();
      }
    }
  }

  private checkWeeklyOff(
    event: any,
    ticketId?: number,
    changedServiceDate?: Date,
    changedTimeNeeded?: number,
  ): Promise<{
    condition: boolean;
    workLimitMessage?: string;
  }> {
    return new Promise((resolve, reject) => {
      const serviceDate = changedServiceDate || this.currentFormGroup.get('serviceDate').value;
      const maxStops = event.maxStops;
      const maxWorkHours = event.maxWorkHours;
      const timeNeeded = changedTimeNeeded || this.currentFormGroup.get('timeNeeded').value;

      if (!serviceDate || (!maxStops && !maxWorkHours)) {
        resolve({condition: false});
        return;
      }

      this.apiService.technician
        .getTechnicianWorkLimits(ticketId, [event.id], serviceDate)
        .subscribe(
          technicianWorkLimits => {
            if (technicianWorkLimits.length === 0) {
              resolve({condition: false});
              return;
            }
            const ticketsCount = technicianWorkLimits[0].ticketsCount;
            const stopsCount = ticketsCount + 1;
            const ticketsTimeNeededSum = technicianWorkLimits[0].ticketsTimeNeededSum;
            const newAllTimeNeeded = ticketsTimeNeededSum + timeNeeded;
            if (maxStops < stopsCount || maxWorkHours < newAllTimeNeeded) {
              const techMaxWorkHoursMessage = maxWorkHours
                ? `set to <span class="font-bold text-color-green">${maxWorkHours}</span>`
                : 'not set';
              const techMaxStopsMessage = maxStops
                ? `set to <span class="font-bold text-color-green">${maxStops}</span>`
                : 'not set';
              const maxStopsClass =
                !maxStops || maxStops >= stopsCount ? 'text-color-green' : 'text-color-red';
              const maxWorkHoursClass =
                !maxWorkHours || maxWorkHours >= newAllTimeNeeded
                  ? 'text-color-green'
                  : 'text-color-red';
              const message = this.getWorkLimitMessage(
                techMaxWorkHoursMessage,
                techMaxStopsMessage,
                ticketsTimeNeededSum,
                ticketsCount,
                maxWorkHoursClass,
                newAllTimeNeeded,
                maxStopsClass,
                stopsCount,
              );

              resolve({condition: true, workLimitMessage: message});
            } else {
              resolve({condition: false});
            }
          },
          error => reject(error),
        );
    });
  }

  private getVacationMessage(technicianOnVacation: {condition: boolean; vacation?: Vacation}) {
    const formatVacation = vacation => {
      if (vacation) {
        const dateRange = `${dayjs(vacation.startDate).format('MM/DD/YYYY')} to ${dayjs(vacation.endDate).format('MM/DD/YYYY')}`;
        return `<strong>${dateRange}</strong> <br>`;
      } else {
        return '';
      }
    };
    return (
      `<div  class="font-bold">The technician is vacationing on <span class="text-color-red">` +
      formatVacation(technicianOnVacation.vacation) +
      `</span></div>`
    );
  }

  private getWeeklyOffMessage(serviceDate, timeOffString: string) {
    return `<hr><div class="font-bold">The technician is off every <span class="text-color-red">${dayjs(serviceDate).format('dddd')}</span></div> <br>${timeOffString}`;
  }

  private getWorkLimitMessage(
    techMaxWorkHoursMessage: string,
    techMaxStopsMessage: string,
    ticketsTimeNeededSum: number,
    ticketsCount: number,
    maxWorkHoursClass: string,
    newAllTimeNeeded,
    maxStopsClass: string,
    stopsCount: number,
  ): string {
    return (
      `<hr>Tech max work hours ${techMaxWorkHoursMessage}, and max stops per day ${techMaxStopsMessage}.<br>` +
      `Currently tech has already <span class="font-bold">${ticketsTimeNeededSum}</span> hours’ worth of work and <span class="font-bold">${ticketsCount}</span> stops scheduled.<br>` +
      `With adding this stop tech will be overbooked as total hours will be <span class="font-bold ${maxWorkHoursClass}">${newAllTimeNeeded}</span>, and total stops will be <span class="font-bold ${maxStopsClass}">${stopsCount}</span>!`
    );
  }

  private getTimeOffString(weeklyOff: WeeklyOff, displayTimeOptions: Intl.DateTimeFormatOptions) {
    return (
      `During following periods: <br>` +
      `<div class="ml-5">` +
      weeklyOff.timeOff.timeRange
        .map(
          range =>
            `${new Date(range.startTime).toLocaleTimeString([], displayTimeOptions)} - ${new Date(range.endTime).toLocaleTimeString([], displayTimeOptions)}<br>`,
        )
        .join('') +
      `</div>`
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  canEditPickUpDelivery(): boolean {
    return this.keycloakService.hasRole('TICKET_EDIT_PICK_UP_DELIVERY');
  }

  canEditRecallGoingBack(): boolean {
    return this.keycloakService.hasRole('TICKET_EDIT_RECALL_GOING_BACK');
  }

  canEditShop(): boolean {
    return this.keycloakService.hasRole('TICKET_EDIT_SHOP');
  }

  private initRoutes() {
    const ticketAreas = this.ticket.customer.address?.areas;
    const techSameDateRoutes = this.ticket.routes?.technicianRoutes;
    const techRoutes = this.ticket.routes?.routes || [];

    this.techLatestRouteMap = this.routeService.createMapOfTechAndLatestRoute(techRoutes);
    this.techRoutesByDateMap = this.routeService.createMapOfTechAndSameDayRoutesWithMatchingArea(
      techSameDateRoutes,
      ticketAreas,
    );
  }

  shouldShowButton(type: 'edit' | 'update' | 'cancel', technicianId: number): boolean {
    return (
      this.payFormButtonState[type]?.visible &&
      this.payFormButtonState[type]?.for.includes(technicianId)
    );
  }

  editPayForm(technicianId: number): void {
    this.payFormButtonState.cancel.visible = true;
    this.payFormButtonState.update.visible = true;
    this.payFormButtonState.cancel.for.push(technicianId);
    this.payFormButtonState.update.for.push(technicianId);
    this.payFormButtonState.edit.for = this.payFormButtonState.edit.for.filter(
      id => id !== technicianId,
    );

    const technician = this.formControls.technicians.controls.find(
      tech => tech.value.id === technicianId,
    );
    if (technician) {
      [
        'incomeCash',
        'bill',
        'payableHours',
        'check',
        'creditCard',
        'expenses',
        'expensesDescription',
        'technicianExpenses',
        'technicianExpensesDescription',
        'partsPickup',
      ].forEach(field => technician.get(field)?.enable());
    }
  }

  onCancelPayFormChanges(technicianId: number): void {
    const technician = this.formControls.technicians.controls.find(
      tech => tech.get('id').value === technicianId,
    );
    if (technician) {
      const ticketTechnician = this.ticket.technicians.find(tech => tech.id === technicianId);
      if (ticketTechnician) {
        technician.reset(ticketTechnician);
        technician.disable();
      }
    }

    ['cancel', 'update'].forEach(type => {
      this.payFormButtonState[type].for = this.payFormButtonState[type].for.filter(
        id => id !== technicianId,
      );
    });
    this.payFormButtonState.edit.for.push(technicianId);
  }

  onUpdatePayForm(technicianId: number): void {
    ['cancel', 'update'].forEach(type => {
      this.payFormButtonState[type].for = this.payFormButtonState[type].for.filter(
        id => id !== technicianId,
      );
    });
    this.payFormButtonState.edit.for.push(technicianId);
    this.updatePayForm.emit();
  }
}
