import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {BaseFormGroup} from '../../../shared/utils/base-form-group';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';
import {ConfigService} from '../../../../core/services';
import {LayoutService} from '../../../../layout/service/app.layout.service';
import {Area} from '../../../../api/model/Area.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'tj-ticket-list-filter-form',
  templateUrl: './ticket-filter-form.component.html',
  styleUrls: ['./ticket-filter-form.component.scss'],
})
export class TicketListFilterFormComponent extends BaseFormGroup implements OnInit, OnChanges {
  @Input() ticketFilter;
  @Input() form: UntypedFormGroup;
  @Input() group: string;

  expanded = false;

  private formControls;
  ticketStatuses;
  partnerStatuses;

  isMobile = false;

  areas$: Observable<Area[]> = this.configService.areas$;

  formattedAreas$: Observable<{id: number; nameCode: string}[]> = this.areas$.pipe(
    map((areas: Area[]) =>
      areas.map(area => ({
        id: area.id,
        nameCode: `${area.name} / ${area.code}`,
      })),
    ),
  );

  constructor(
    public configService: ConfigService,
    private layoutService: LayoutService,
  ) {
    super();
    this.ticketStatuses = this.configService.ticketStatuses$.getValue();
    this.partnerStatuses = TicketPartnerStatus;
  }

  ngOnInit() {
    this.isMobile = this.layoutService.isMobile();
    const ticketFilter = this.ticketFilter || {};
    this.formControls = {
      id: new UntypedFormControl(ticketFilter.id),
      customer: new UntypedFormControl(ticketFilter.customer || ''),
      status: new UntypedFormControl(ticketFilter.status),
      contact: new UntypedFormControl(ticketFilter.contact || ''),
      address: new UntypedFormControl(ticketFilter.address || ''),
      comment: new UntypedFormControl(ticketFilter.comment || ''),
      partnerId: new UntypedFormControl(parseInt(ticketFilter.partnerId, 10) || null),
      technicianId: new UntypedFormControl(parseInt(ticketFilter.technicianId, 10) || null),
      serviceDate: new UntypedFormControl(ticketFilter.serviceDate || null),
      contactDate: new UntypedFormControl(ticketFilter.contactDate || null),
      createDate: new UntypedFormControl(ticketFilter.createDate || null),
      invoiceId: new UntypedFormControl(ticketFilter.invoiceId || null),
      invoiceAmount: new UntypedFormControl(ticketFilter.invoiceAmount || null),
      isPrivate: new UntypedFormControl(ticketFilter.isPrivate),
      privateRegistered: new UntypedFormControl(ticketFilter.privateRegistered),
      privateRegisteredAccount: new UntypedFormControl(ticketFilter.privateRegisteredAccount),
      deleted: new UntypedFormControl(ticketFilter.deleted),
      content: new UntypedFormControl(ticketFilter.content),
      tags: new UntypedFormControl(ticketFilter.tags || []),
      notPrintedByAdmin: new UntypedFormControl(ticketFilter.notPrintedByAdmin),
      notPrintedByTechnician: new UntypedFormControl(ticketFilter.notPrintedByTechnician),
      notPrintedByPartner: new UntypedFormControl(ticketFilter.notPrintedByPartner),
      isExtraProcess: new UntypedFormControl(ticketFilter.isExtraProcess),
      externalReferenceCode: new UntypedFormControl(ticketFilter.externalReferenceCode || ''),
      category: new UntypedFormControl(ticketFilter.category || ''),
    };

    if (this.configService.isEnabledTerritory()) {
      this.formControls = {
        ...this.formControls,
        areas: new UntypedFormControl(ticketFilter.areas || []),
      };
    }
    this.buildForm(this.form, this.formControls, this.group);
    this.defineGetters(this.formControls);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ticketFilter && changes.ticketFilter.currentValue) {
      this.ticketFilter = {};
    }
  }

  handleToggleSearchExpansions() {
    this.expanded = !this.expanded;
  }
}
