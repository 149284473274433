<div class="p-0 lg:flex md:flex sm:flex-row col-12 custom-autocomplete-style">
  <div class="p-inputgroup">
    <!-- AutoComplete Input -->
    <p-autoComplete dataKey="id"
                    field="displayName"
                    [placeholder]="'Technician'"
                    [(ngModel)]="value"
                    [disabled]="disabled"
                    [suggestions]="filteredTechnicians"
                    [dropdown]="true"
                    (onSelect)="onTechnicianChange($event)"
                    [forceSelection]="true"
                    (completeMethod)="onFilterSuggestions($event)"
                    [ngClass]="addStyleClass"
                    appendTo="body">
      <ng-template let-value pTemplate="item">
        <div class="flex flex-column">
        <span [class]="showSpeciality ? 'font-semibold' : ''">
          <i class="pi"
             [ngClass]="{'pi-calendar font-semibold': hasSupportedArea(value.id), 'pi-map-marker': !hasSupportedArea(value.id)}"
             *ngIf="configService.isEnabledTerritory() && (hasAreaCode(value) || hasSupportedArea(value.id))">
          </i>
          {{value.name}}
          <ng-container *ngIf="value.personnelCode">
            / {{value.personnelCode}}
          </ng-container>
          <ng-container *ngIf="value.companyName">
            / {{value.companyName}}
          </ng-container>
        </span>
          <span class="white-space-normal ml-3 md:text-sm sm:text-xs"
                *ngIf="showSpeciality && value.speciality != null && value.speciality != ''">
          {{value.speciality}}
        </span>
        </div>
      </ng-template>
    </p-autoComplete>
    <ng-container *ngIf="showInfo && value">
      <button pButton icon="pi pi-envelope" class="ml-1 cursor-pointer text-center p-button-sm "
              [cdkCopyToClipboard]="value.email"
              (cdkCopyToClipboardCopied)="onCopySuccess($event)"></button>
      <button pButton class="ml-1 font-semibold p-button-sm text-center col-fixed"
              [disabled]="!value.personnelCode"
              [cdkCopyToClipboard]="value.personnelCode"
              (cdkCopyToClipboardCopied)="onCopyTechnicianCode($event)"
              [pTooltip]="getTechnicianInfo()">{{value.personnelCode || 'Not set'}}</button>
    </ng-container>
  </div>
</div>
