import {Component, OnDestroy, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import * as chroma from 'chroma-js';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ApiService} from '../../../../api/service/api.service';
import {StatsRequestModel} from '../../../../api/model/stats/stats.model';
import {ChartModel} from '../../../../api/model/stats/chart.model';

@Component({
  selector: 'tj-tickets-per-account-stats-chart',
  templateUrl: './tickets-per-account-stats.component.html',
})
export class TicketsPerAccountStatsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.loadTicketsPerAccount();
  }

  private loadTicketsPerAccount(): void {
    const requestModel = new StatsRequestModel();
    this.api.stats
      .getTicketsPerAccount(requestModel)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: chartModel => {
          this.showTicketsPerAccount(chartModel);
        },
        error: err => {
          console.error('Error loading tickets per account:', err);
        },
      });
  }

  private showTicketsPerAccount(chartModel: ChartModel) {
    const colorScale = chroma
      .scale(['#1f78b4', '#33a02c', '#e31a1c', '#ff7f00', '#6a3d9a', '#b15928'])
      .mode('lab')
      .colors(chartModel.items.length);
    const chartOptions = this.getChartOptions(colorScale, chartModel, 'Tickets by Account');
    Highcharts.chart('account', chartOptions);
  }

  private getChartOptions(colorScale, chartModel: ChartModel, title: string): Highcharts.Options {
    const {items, total} = chartModel;

    const data = items.map(item => {
      return {
        name: item.name ? item.name : 'Private Lead-to-Job',
        y: item.count,
        color: colorScale[items.indexOf(item)],
        percentage: item.percentage.toFixed(2),
      };
    });

    return {
      chart: {
        type: 'pie',
        width: null,
        events: {
          load: function () {
            const chart = this;
            const centerX = chart.plotLeft + chart.plotWidth / 2;
            const centerY = chart.plotTop + chart.plotHeight / 2;

            chart.renderer
              .text(`Total<br>${total}`, centerX, centerY)
              .css({
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#000',
                textAnchor: 'middle',
              })
              .attr({
                align: 'center',
              })
              .add();
          },
        },
      },
      title: {
        text: title,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
        layout: 'vertical',
        width: null,
        align: 'left',
        useHTML: true,
        verticalAlign: 'middle',
        labelFormatter: function () {
          const maxLength = 15;
          const truncatedName =
            this.name.length > maxLength ? this.name.substring(0, maxLength) + '...' : this.name;
          const formattedPercentage = this['percentage'] ? this['percentage'].toFixed(2) : '0.00';

          return `<span style="display: inline-block; width: 150px;">${truncatedName}</span>
                <span style="display: inline-block; width: 50px; text-align: right;">${formattedPercentage}%</span>`;
        },
        itemStyle: {
          fontSize: '14px',
          whiteSpace: 'pre-wrap',
        },
      },
      plotOptions: {
        pie: {
          innerSize: '50%',
          size: '100%',
          cursor: 'pointer',
          showInLegend: true,
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Count',
          data: data,
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
              },
            },
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
