<div class="autocomplete-wrapper">
  <p-autoComplete
    dataKey="id"
    field="label"
    [(ngModel)]="value"
    (onSelect)="onTagAdd($event.value)"
    (onUnselect)="onTagRemove($event.value)"
    [suggestions]="filteredTags"
    (completeMethod)="onFilterSuggestions($event)"
    [completeOnFocus]="true"
    [unique]="true"
    scrollHeight="100"
    [forceSelection]="true"
    [multiple]="true"
    [disabled]="disabled"
    [dropdown]="true"
    placeholder="Select Tags"
  >
    <ng-template let-searchItem pTemplate="selectedItem">
      <div
        [ngStyle]="{'background-color': searchItem.color}"
        class="ui-helper-clearfix w-fit status-pill"
      >
        <span>{{ searchItem.label }}</span>
      </div>
    </ng-template>
  </p-autoComplete>
</div>
