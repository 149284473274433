import {Component, OnInit} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'tj-email-details',
  templateUrl: 'email-details.component.html',
})
export class EmailDetailsComponent implements OnInit {
  customEmailForm: UntypedFormGroup;

  constructor(
    public ref: DynamicDialogRef,
    private fb: UntypedFormBuilder,
  ) {
    this.customEmailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {}

  submit() {
    this.ref.close({
      value: this.customEmailForm.get('email').getRawValue(),
    });
  }

  getCustomEmailFormControl(control: string): UntypedFormControl {
    return this.customEmailForm.get(control) as UntypedFormControl;
  }
}
