import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {RouterModule} from '@angular/router';
import {AuthGuardService} from '../../core/services';
import {ConfigurationsPageComponent} from './pages/configurations-page/configurations-page.component';
import {EditorModule} from 'primeng/editor';
import {
  AreaConfigurationsComponent,
  AreaToZipCodeListComponent,
  AreaToZipCodeListFilterComponent,
  AreaToZipCodeListFilterFormComponent,
  AreaToZipCodePageComponent,
  AreaToZipCodeSearchChipsComponent,
  ConfigurationsComponent,
  ServiceCategoriesComponent,
  TagConfigurationsComponent,
  ZipCodeChipsFormComponent,
  ZipCodeCoveredInfoComponent,
  ZipCodeFormComponent,
  ZipCodeImportExampleComponent,
  ZipCodeInsertExampleComponent,
  ZipCodeListComponent,
  ZipCodesConfigurationsComponent,
  ZipCodesMenuComponent,
} from './components';
import {AreaFormComponent} from './components/area-configurations-component/area-form/area-form.component';
import {AreaListComponent} from './components/area-configurations-component/area-list/area-list.component';
import {ChipsModule} from 'primeng/chips';
import {KeyFilterModule} from 'primeng/keyfilter';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    EditorModule,
    SharedModule,
    AutoCompleteModule,
    RouterModule.forRoot([
      {
        path: 'configurations',
        component: ConfigurationsPageComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['GLOBAL_CONFIG_VIEW_MENU'],
        },
        children: [
          {
            path: 'main',
            component: ConfigurationsComponent,
          },
          {
            path: 'service-categories',
            component: ServiceCategoriesComponent,
          },
          {
            path: 'tags',
            component: TagConfigurationsComponent,
          },
          {
            path: 'areas',
            component: AreaConfigurationsComponent,
          },
          {
            path: 'area/zipCodes',
            component: AreaToZipCodePageComponent,
          },
          // {
          //   path: 'zipcodes',
          //   component: ZipCodesConfigurationsComponent
          // }
        ],
      },
    ]),
    ChipsModule,
    KeyFilterModule,
  ],
  declarations: [
    AreaToZipCodeListComponent,
    AreaToZipCodeListFilterComponent,
    AreaToZipCodeListFilterFormComponent,
    AreaToZipCodeSearchChipsComponent,
    ZipCodeListComponent,
    AreaListComponent,
    ZipCodesMenuComponent,
    ConfigurationsComponent,
    ServiceCategoriesComponent,
    TagConfigurationsComponent,
    AreaConfigurationsComponent,
    AreaToZipCodePageComponent,
    ZipCodeImportExampleComponent,
    ZipCodeCoveredInfoComponent,
    ZipCodeInsertExampleComponent,
    ZipCodesConfigurationsComponent,
    ConfigurationsPageComponent,
    AreaFormComponent,
    ZipCodeChipsFormComponent,
    ZipCodeFormComponent,
  ],
  exports: [ZipCodeCoveredInfoComponent],
})
export class ConfigurationsModule {}
