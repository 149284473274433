<form
  novalidate
  autocomplete="off"
  [formGroup]="filterForm"
  (reset)="onSearchReset()"
  (ngSubmit)="onFilterRoutes()"
>
  <div class="grid">
    <div class="col-12">
      <tj-route-list-filter-form
        [routeFilter]="routeFilter"
        [form]="filterForm"
        group="routeFilter"
      ></tj-route-list-filter-form>
    </div>
    <div class="col-12 flex">
      <tj-route-search-chips
        [routeFilter]="routeFilter"
        (remove)="onRemoveFilter($event)"
      ></tj-route-search-chips>
    </div>

    <div class="col-12 align-content-end flex justify-content-between">
      <div class="flex align-items-end">
        <span *ngIf="page">Total: {{ page.totalElements }}</span>
      </div>
      <div>
        <button class="p-button-secondary mr-2" label="Reset" pButton type="reset"></button>
        <button pButton type="submit" label="Search" icon="pi pi-search"></button>
      </div>
    </div>
  </div>
</form>
