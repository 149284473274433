import {Invoice} from '../../../../api/model/Invoice.model';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../../api/service/api.service';
import {BaseFormGroup} from '../../../shared/utils/base-form-group';
import {NotificationService} from '../../../../core/services';
import {download} from '../../../../api/model/shared/functions';
import {Messages} from '../../../../common/messages';
import {BreadcrumbService} from '../../../../layout/service/app.breadcrumb.service';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';
import {ActionDetailsComponent} from '../../../shared/components';
import {filter, map} from 'rxjs/operators';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'tj-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent extends BaseFormGroup implements OnInit {
  invoiceId: number;
  invoice: Invoice;
  showInvoices: boolean;
  mediaDialogRef: DynamicDialogRef;

  constructor(
    private api: ApiService,
    private router: Router,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private breadcrumbService: BreadcrumbService,
  ) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.invoiceId = parseInt(params.id, 10);
      this.getInvoice();
    });
  }

  onChangeStatus(status): void {
    if (status.$event === 'VOID') {
      return this.voidStatus();
    } else if (status.$event === 'PREPAID') {
      return this.prepaidStatus();
    }

    this.changeStatus(status);
  }

  changeStatus(invoiceStatusDropdown): void {
    this.api.invoice.changeStatus(this.invoiceId, invoiceStatusDropdown.$event).subscribe(
      () => {
        this.notificationService.success(Messages.STATUS_UPDATED);
      },
      error => {
        invoiceStatusDropdown.invoiceStatus.selectedOption = this.invoice.status;
        this.notificationService.error(Messages.STATUS_NOT_UPDATED, error);
      },
    );
  }

  voidStatus() {
    this.showVoidReasonDialog().subscribe((reason: string) => {
      if (reason) {
        this.api.invoice.voidStatus(this.invoiceId, reason).subscribe(
          () => {
            this.notificationService.success(Messages.STATUS_UPDATED);
          },
          error => {
            this.notificationService.error(Messages.STATUS_NOT_UPDATED, error);
          },
        );
      }
    });
  }

  prepaidStatus() {
    this.showPrepaidReasonDialog().subscribe((reason: string) => {
      if (reason) {
        this.api.invoice.prepaidStatus(this.invoiceId, reason).subscribe(
          () => {
            this.notificationService.success(Messages.STATUS_UPDATED);
          },
          error => {
            this.notificationService.error(Messages.STATUS_NOT_UPDATED, error);
          },
        );
      }
    });
  }

  private getInvoice(): void {
    this.api.invoice.getInvoice(this.invoiceId).subscribe((invoice: Invoice) => {
      this.invoice = invoice;
      this.showInvoices = true;

      if (invoice) {
        const label = `Invoice #${this.invoiceId}`;

        this.breadcrumbService.addBreadcrumb({label});
        this.breadcrumbService.enableCopyToClipboard();
      }
    });
  }

  downloadPDF(): void {
    const invoiceId = this.invoiceId;
    const invoiceType = this.invoice.status === 'ESTIMATE' ? 'Estimate' : 'Invoice';
    const accountName = this.invoice.partner ? this.invoice.partner.name : 'PRIVATE';
    const timestamp = new Date().toISOString().slice(0, -5);

    const fileName = `${invoiceType}_${invoiceId}_(${accountName})_${timestamp}`;
    this.api.invoice.download([this.invoiceId]).subscribe((value: Blob) => {
      download(value, fileName);
    });
  }

  printReport() {
    const printUrl = `/invoices/print?id=${this.invoiceId}`;
    this.api.invoice.markAsPrinted(this.invoiceId).subscribe(() => {
      window.open(printUrl, '_parent');
    });
  }

  isPrivateBusiness(): boolean {
    return this.invoice?.ticketPartnerStatus === TicketPartnerStatus.PRIVATE_BUSINESS;
  }

  isPrivate(): boolean {
    return this.invoice?.ticketPartnerStatus === TicketPartnerStatus.PRIVATE;
  }

  showVoidReasonDialog() {
    this.mediaDialogRef = this.dialogService.open(ActionDetailsComponent, {
      header: `Invoice void reason`,
      contentStyle: {
        maxWidth: '100%',
        overflow: 'hidden',
      },
      data: {
        reason: 'reason',
      },
    });
    return this.mediaDialogRef.onClose.pipe(
      filter(data => data !== undefined),
      map(data => {
        return data.value;
      }),
    );
  }

  showPrepaidReasonDialog() {
    this.mediaDialogRef = this.dialogService.open(ActionDetailsComponent, {
      header: `Invoice prepaid reason`,
      contentStyle: {
        maxWidth: '100%',
        overflow: 'hidden',
      },
      data: {
        reason: 'reason',
      },
    });
    return this.mediaDialogRef.onClose.pipe(
      filter(data => data !== undefined),
      map(data => {
        return data.value;
      }),
    );
  }

  protected sendToCustomer() {
    this.api.invoice.sendInvoiceToCustomer(this.invoiceId).subscribe(
      value => {
        this.notificationService.success(Messages.INVOICE_EMAIL_SENT);
      },
      error => {
        this.notificationService.error(Messages.INVOICE_EMAIL_SENT_ERROR, error);
      },
    );
  }

  protected sendToPartner() {
    this.api.invoice.sendInvoiceToPartner(this.invoiceId).subscribe(
      value => {
        this.notificationService.success(Messages.INVOICE_EMAIL_SENT);
      },
      error => {
        this.notificationService.error(Messages.INVOICE_EMAIL_SENT_ERROR, error);
      },
    );
  }

  protected sendToEmail(email: string) {
    this.api.invoice.sendInvoiceToEmail(this.invoiceId, email).subscribe(
      value => {
        this.notificationService.success(Messages.INVOICE_EMAIL_SENT);
      },
      error => {
        this.notificationService.error(Messages.INVOICE_EMAIL_SENT_ERROR, error);
      },
    );
  }
}
