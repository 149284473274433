<p-dialog
  [(visible)]="importInfoDialog"
  [style]="{width: '670px'}"
  [header]="title"
  [modal]="true"
  (onHide)="hide()"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <div class="field">
      <p>1) Imported file should be in <strong> .csv </strong> format</p>
      <p>2) The CSV file should consist of only two columns (Zip Code and Additional Travel Fee)</p>
      <p>
        3) Please note: to avoid losses of any 0's in the beginning of the zip codes it is
        recommended to select format of the columns as texts In the Excel file, before exporting the
        file to CSV format.
      </p>
    </div>
    <hr />
    <div class="col-12">
      <h3 class="text-center font-bold">Example of Additional Travel Fee Entry options:</h3>
      <div class="col-12 flex text-center justify-content-center">
        <div
          class="p-fluid col-3"
          style="display: flex; align-items: center; flex-direction: column"
        >
          <p>Zip Code</p>
          19891<br />
          19891<br />
          19891<br />
          19891<br />
          19891<br />
          19891<br />
          19891<br />
        </div>
        <div
          class="p-fluid col-3"
          style="display: flex; align-items: center; flex-direction: column"
        >
          <p>Additional Travel Fee</p>
          <br />
          0<br />
          $0<br />
          125<br />
          $125<br />
          125.5<br />
          $125.50<br />
        </div>
      </div>
    </div>
    <hr />
  </ng-template>
</p-dialog>
