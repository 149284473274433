import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {ConfigService, TjKeycloakService} from '../../../../core/services';

@Component({
  selector: 'tj-configurations-page',
  templateUrl: './configurations-page.component.html',
  styleUrls: ['./configurations-page.component.scss'],
})
export class ConfigurationsPageComponent implements OnInit {
  items: MenuItem[];

  constructor(
    public configService: ConfigService,
    private keycloakService: TjKeycloakService,
  ) {}

  ngOnInit(): void {
    this.items = [
      {label: 'Configs', routerLink: 'main', icon: 'pi pi-fw pi-cog'},
      {label: 'Tags', routerLink: 'tags', icon: 'pi pi-fw pi-tags'},
    ];

    if (
      this.configService.isEnabledTerritory() &&
      this.keycloakService.hasRole('TERRITORY_MANAGEMENT_VIEW')
    ) {
      this.items.push({label: 'Areas / Territory', routerLink: 'areas', icon: 'pi pi-fw pi-tags'});
      this.items.push({
        label: 'Areas / Zip Codes',
        routerLink: 'area/zipCodes',
        icon: 'pi pi-fw pi-map-marker',
      });
      // this.items.push({label: 'Zip Codes', routerLink: 'zipcodes', icon: 'pi pi-fw pi-map-marker'});
    }
    this.items.push({
      label: 'Service Categories',
      routerLink: 'service-categories',
      icon: 'pi pi-fw pi-cog',
    });
  }
}
