import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {BaseFormGroup} from '../../../../../shared/utils/base-form-group';
import {ConfigService} from '../../../../../../core/services';

@Component({
  selector: 'tj-area-to-zip-code-list-filter-form',
  templateUrl: './area-to-zip-code-filter-form.component.html',
  styleUrls: ['./area-to-zip-code-filter-form.component.scss'],
})
export class AreaToZipCodeListFilterFormComponent
  extends BaseFormGroup
  implements OnInit, OnChanges
{
  @Input() areaToZipCodeFilter;

  @Input() form: UntypedFormGroup;

  @Input() group: string;

  private formControls;

  isMobile = false;

  constructor(public configService: ConfigService) {
    super();
  }

  ngOnInit() {
    const filter = this.areaToZipCodeFilter || {};
    this.formControls = {
      zipCode: new UntypedFormControl(filter['zipCode'] || ''),
      travelFee: new UntypedFormControl(filter['travelFee'] || ''),
      areaCode: new UntypedFormControl(filter['areaCode'] || ''),
    };
    this.buildForm(this.form, this.formControls, this.group);
    this.defineGetters(this.formControls);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.routeFilter && changes.routeFilter.currentValue) {
      this.areaToZipCodeFilter = {};
    }
  }
}
