import {catchError, map} from 'rxjs/operators';
import {Comment, CommentListItem} from '../model/TicketComments.model';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CommunicationService} from '../service/communication.service';

@Injectable()
export class CommentApi extends CommunicationService {
  protected apiEndpoint = this.env.apiEndpoint;

  save(comment: Comment): Observable<CommentListItem> {
    const url = this.getChatAwareUrl(comment.chatId);
    return this.requestService.post(url, comment).pipe(
      map(response => response.response as CommentListItem),
      catchError(this.handleError),
    );
  }

  getChatComments(chatId: number): Observable<CommentListItem[]> {
    const url = this.getChatAwareUrl(chatId) + '?order=ASC';
    return this.requestService.get(url).pipe(
      map(response => response.response as CommentListItem[]),
      catchError(this.handleError),
    );
  }

  private getChatAwareUrl(chatId: number) {
    return `${this.apiEndpoint}chats/${chatId}/comments`;
  }
}
