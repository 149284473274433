<p-dialog
  (onHide)="onHide()"
  (onShow)="onShow()"
  [(visible)]="visible"
  [autoZIndex]="false"
  [contentStyle]="{'overflow-y': 'scroll'}"
  [maximizable]="true"
  [minY]="70"
  [responsive]="true"
  blockScroll="true"
  header="Edit customer"
  maskStyleClass="mask-style-dialog"
  styleClass="dialog"
>
  <form #customerForm *ngIf="form" [formGroup]="form" id="customerForm">
    <tj-customer-edit-form
      [customer]="customer"
      [form]="form"
      [mainContactPerson]="mainContactPerson"
      group="customer"
    ></tj-customer-edit-form>
  </form>
  <ng-template pTemplate="footer">
    <p-button
      (click)="onHide()"
      class="p-button-secondary"
      icon="pi pi-times"
      label="Cancel"
    ></p-button>
    <p-button
      (click)="saveAction()"
      [disabled]="isSaving || !customer"
      icon="pi pi-check"
      label="Save"
      [loading]="isSaving"
    ></p-button>
  </ng-template>
</p-dialog>
<p-dialog
  [(visible)]="associatedRoutesByTicket"
  [style]="{width: '800px'}"
  header="Route address updated in Route(s)"
  [modal]="true"
  styleClass="p-fluid"
>
  <div class="col-12 text-red-500">Check if route order needs adjustment and resend.</div>
  <p-table
    [value]="routeInfo"
    [paginator]="true"
    [rows]="10"
    responsiveLayout="scroll"
    class="mobile-table"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Technician</th>
        <th>Route ID</th>
        <th>Sent By</th>
        <th>Send Date</th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-route>
      <tr class="mobile-row">
        <td data-label="Technician">
          <div class="flex flex-column">
            <span class="font-semibold">{{ route.technicianName }}</span>
            <small class="text-muted">({{ route.technicianPersonnelCode }})</small>
          </div>
        </td>
        <td data-label="Route ID">
          <a
            href="#"
            (click)="openInNewTab($event, route.routeId)"
            class="list-link cursor-pointer"
          >
            #{{ route.routeId }}
          </a>
        </td>
        <td data-label="Sent By">{{ route.sendBy || '-' }}</td>
        <td data-label="Send Date">
          {{ route.sendDate ? (route.sendDate | date: configService.dateFormatFull) : '-' }}
        </td>
        <td data-label="Actions">
          <button
            pButton
            type="button"
            icon="pi pi-eye"
            class="p-button-text"
            (click)="openInNewTab($event, route.routeId)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template pTemplate="footer">
    <p-button
      (click)="cancelCustomerChanges()"
      class="p-button-secondary"
      icon="pi pi-times"
      label="Cancel"
    ></p-button>
    <p-button (click)="saveCustomerChanges()" icon="pi pi-check" label="Ok"></p-button>
  </ng-template>
</p-dialog>
