<div class="grid">
  <div class="sm:col-12" *ngIf="partner">
    <ng-container *ngIf="isPrivateBusiness(); else privateOnly">
      <span class="private">PRIVATE</span> - 3rd Party - None Account
      <hr />
    </ng-container>
    <ng-template #privateOnly>
      <ng-container *ngIf="isPrivate()">
        <span class="private">PRIVATE</span>
        <hr />
      </ng-container>
    </ng-template>
  </div>
  <div class="sm:col-12" *ngIf="partner">
    <span class="name">
      {{ partner.name }}
    </span>
    <span class="partner-type"> / {{ partner.partnerType }}</span>
    <ng-container *ngIf="partner.hasOverdueInvoices">
      <span
        class="ml-2 font-semibold text-red-600 cursor-pointer hover:underline"
        (click)="showAccountOverdueInvoiceList()"
        >(Overdue)</span
      >
    </ng-container>
    <br />
    <ng-container *ngIf="partner.notes">
      <hr class="m-0" />
      <span class="name py-2">
        <strong>Notes: </strong>
        <div class="ml-3">
          {{ partner.notes }}
        </div>
      </span>
      <hr class="m-0" />
    </ng-container>
    <div class="sm:col-12" *ngIf="mainContactPerson">
      <tj-contact-person-details
        *ngIf="mainContactPerson"
        [contactPerson]="mainContactPerson"
      ></tj-contact-person-details>
      <div *ngIf="showOnlyMain && secondaryContactPersons.length > 0">
        <button
          *ngIf="!showSecondaryContactPersons"
          pButton
          type="button"
          (click)="onShowSecondaryContactPersons()"
          icon="pi pi-angle-down"
          label="More contact persons available"
          class="p-button-link pl-0 p-button-sm"
        ></button>
        <button
          *ngIf="showSecondaryContactPersons"
          pButton
          type="button"
          (click)="onShowSecondaryContactPersons()"
          icon="pi pi-angle-up"
          label="Hide additional contact persons"
          class="p-button-link pl-0 p-button-sm"
        ></button>
      </div>
      <ng-container *ngIf="showOnlyMain && showSecondaryContactPersons">
        <div *ngFor="let person of secondaryContactPersons" class="mt-2 mb-4">
          <tj-contact-person-details
            *ngIf="!person.isMain"
            [contactPerson]="person"
          ></tj-contact-person-details>
        </div>
      </ng-container>
    </div>
    <hr class="m-0" />
    <ng-container *ngIf="partner.address">
      <span class="font-bold mt-1" style="font-size: 16px">Address:</span>
      <div class="ml-3" style="font-size: 14px !important">
        <tj-address-link [address]="partner.address"></tj-address-link>
      </div>
      {{ partner.address.additionalAddress }}
    </ng-container>
  </div>
  <div class="sm:col-12" *ngIf="!partner">No account defined</div>
</div>
