<div class="grid flex-column">
  <div class="col-12">
    <p-toolbar>
      <div class="p-toolbar-group-left"></div>
      <div class="p-toolbar-group-right">
        <button
          pButton
          pRipple
          (click)="onCreateNewPartner()"
          icon="pi pi-plus"
          class="p-button-success"
          label="New account"
        ></button>
      </div>
    </p-toolbar>
  </div>
  <div class="col-12">
    <p-panel header="Filter" [toggleable]="true">
      <tj-partner-search
        [filter]="searchFilter"
        (selectPartner)="onSelectPartner($event)"
      ></tj-partner-search>
    </p-panel>
  </div>
  <div class="grid">
    <div *ngIf="page" class="col-6">
      <div class="grid">
        <div class="col-12">
          <p-paginator
            (onPageChange)="onGoToPage($event)"
            [rows]="env.rowsPerPage[0]"
            [rowsPerPageOptions]="env.rowsPerPage"
            [first]="pageQuery.number * pageQuery.size"
            [totalRecords]="page.totalElements"
          ></p-paginator>
        </div>
        <div class="col-12">
          <tj-partner-list
            (onSort)="sort($event)"
            (selectPartner)="onSelectPartner($event)"
            [partners]="partners"
          ></tj-partner-list>
        </div>
      </div>
    </div>

    <div class="col-6">
      <ng-container *ngIf="selectedPartner">
        <tj-partner-edit (onSave)="onSave($event)" [partner]="selectedPartner"></tj-partner-edit>
      </ng-container>
      <ng-container *ngIf="!selectedPartner">
        <p class="text-center">No account selected</p>
      </ng-container>
    </div>
  </div>
</div>
