<div class="col-12">
  <p-table
    #dt
    [value]="configs"
    [rows]="env.rowsPerPage[0]"
    [paginator]="true"
    [globalFilterFields]="['value', 'name']"
    styleClass="p-datatable-gridlines p-datatable-striped"
    [rowsPerPageOptions]="env.rowsPerPage"
    [rowHover]="true"
    dataKey="id"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="caption">
      <div class="flex">
        <button
          pButton
          label="Clear"
          class="p-button-outlined"
          icon="pi pi-filter-slash"
          (click)="dt.clear()"
        ></button>
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search keyword"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name" class="config-name">
          Config name
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="value">
          Value
          <p-sortIcon field="value"></p-sortIcon>
        </th>
        <th style="width: 75px"></th>
      </tr>
      <tr>
        <th>
          <p-columnFilter type="text" field="name" [matchMode]="'contains'"></p-columnFilter>
        </th>
        <th>
          <p-columnFilter type="text" field="value" [matchMode]="'contains'"></p-columnFilter>
        </th>
        <th style="width: 75px"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-config>
      <tr>
        <td>{{ config.name }}</td>
        <td *ngIf="config.type === 'HTML'" [innerHTML]="config.value"></td>
        <td *ngIf="config.type === 'STRING'">{{ config.value }}</td>
        <td *ngIf="config.type === 'NUMBER'">{{ config.value }}</td>
        <td *ngIf="config.type === 'PERCENT'">{{ config.value }}</td>
        <td *ngIf="config.type === 'SELECT'">{{ config.value }}</td>
        <td *ngIf="config.type === 'BOOLEAN'">
          {{ config.value === 'true' ? 'Enabled' : 'Disabled' }}
        </td>

        <td class="text-center">
          <button
            *tjHasRole="'GLOBAL_CONFIG_EDIT'"
            pButton
            pRipple
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success"
            (click)="editProduct(config)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="configDialog"
  [style]="{width: '700px'}"
  header="Config Details"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <p-dropdown
      [options]="configType"
      [(ngModel)]="config.type"
      optionLabel="name"
      optionValue="code"
      [disabled]="
        selectedConfigType === 'BOOLEAN' ||
        selectedConfigType === 'NUMBER' ||
        selectedConfigType === 'PERCENT' ||
        selectedConfigType === 'SELECT'
      "
      (onChange)="configTypeChange($event)"
    ></p-dropdown>

    <div class="field">
      <label for="name">Name</label>
      <input type="text" pInputText id="name" [(ngModel)]="config.name" required autofocus />
    </div>
    <ng-template [ngIf]="selectedConfigType === 'HTML'">
      <p-editor [(ngModel)]="config.value" [style]="{height: '320px'}"></p-editor>
    </ng-template>
    <ng-template [ngIf]="selectedConfigType === 'STRING'">
      <textarea pInputTextarea [(ngModel)]="config.value"></textarea>
    </ng-template>
    <ng-template [ngIf]="selectedConfigType === 'BOOLEAN'">
      <p-dropdown [options]="booleanValues" [(ngModel)]="config.value" appendTo="body"></p-dropdown>
    </ng-template>

    <ng-template [ngIf]="selectedConfigType === 'SELECT'">
      <p-dropdown
        [options]="getValues(config.allowedValues)"
        [(ngModel)]="config.value"
        [disabled]="config.allowedValues.length === 0"
        appendTo="body"
      >
      </p-dropdown>
      <p *ngIf="config.allowedValues.length === 0" class="text-error">
        No allowed values available.
      </p>
    </ng-template>

    <ng-template [ngIf]="selectedConfigType === 'NUMBER'">
      <input pInputText type="number" [(ngModel)]="config.value" />
    </ng-template>

    <ng-template [ngIf]="selectedConfigType === 'PERCENT'">
      <div class="p-inputgroup">
        <input pInputText type="number" [(ngModel)]="config.value" />
        <span class="p-inputgroup-addon">%</span>
      </div>
    </ng-template>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Cancel"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideDialog()"
    ></button>
    <button
      pButton
      pRipple
      label="Save"
      icon="pi pi-check"
      class="p-button-text"
      (click)="saveProduct()"
    ></button>
  </ng-template>
</p-dialog>
