<div class="grid grid-nogutter tj-header-wrapper">
  <div class="col">
    <span class="tj-title"><ng-content select="tj-page-title"></ng-content></span>
    <span class="tj-subtitle"><ng-content select="tj-page-subtitle"></ng-content></span>
  </div>
  <div class="col-fixed">
    <ng-content select="tj-page-title-right"></ng-content>
  </div>
  <div class="col-12">
    <span class="tj-title-misc"><ng-content select="tj-page-title-misc"></ng-content></span>
  </div>
</div>
