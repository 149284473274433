<form [formGroup]="formGroup" (ngSubmit)="search()" (reset)="reset()">
  <div class="grid">
    <div class="lg:col-3 md:col-6 sm:col-12 col-12">
      <tj-field>
        <tj-datepicker
          [onlyFuture]="false"
          formControlName="startDate"
          placeholder="Start Date"
        ></tj-datepicker>
      </tj-field>
    </div>
    <div class="lg:col-3 md:col-6 sm:col-12 col-12">
      <tj-field>
        <tj-datepicker
          [onlyFuture]="false"
          formControlName="endDate"
          placeholder="End Date"
        ></tj-datepicker>
      </tj-field>
    </div>

    <div class="lg:col-3 md:col-6 sm:col-12 col-12" *tjHasRole="'PARTNER_VIEW'">
      <tj-field>
        <tj-partner-combobox
          formControlName="partnerId"
          [initPrivatePartner]="false"
        ></tj-partner-combobox>
      </tj-field>
    </div>

    <div class="lg:col-3 md:col-6 sm:col-12 col-12 flex justify-content-end">
      <button class="p-button-secondary mr-2" label="Reset" pButton type="reset"></button>
      <button pButton type="submit" label="Search" icon="pi pi-search"></button>
    </div>
  </div>
  <div class="grid pt-3" *ngIf="stats">
    <div class="md:col sm:col-6 col-12">
      <tj-metric [text]="'Total tickets served'" [value]="stats.ticketsCount"></tj-metric>
    </div>

    <div class="md:col sm:col-6 col-12">
      <tj-metric
        [text]="'Avg Turn Around'"
        [value]="
          (
            stats.avgTurnAround /
            86400 /
            (stats.ticketsCount === 0 ? 1 : stats.ticketsCount)
          ).toFixed(2) + ' Days'
        "
      ></tj-metric>
    </div>

    <div class="md:col sm:col-6 col-12">
      <tj-metric
        [text]="'Avg completion rate'"
        tooltip="Rate of completed (invoiced) jobs based on inspection limit based on value of config 'Inspection max value'"
        [value]="stats.avgCompletionRate + ' %'"
      ></tj-metric>
    </div>

    <div class="md:col sm:col-6 col-12">
      <tj-metric [text]="'Total Unpaid Invoices'" [value]="stats.totalUnpaid.count"></tj-metric>
    </div>

    <div class="md:col sm:col-6 col-12">
      <tj-metric
        [text]="'Total amount of unpaid invoices'"
        [value]="stats.totalUnpaid.amount | currency: 'USD'"
      ></tj-metric>
    </div>
  </div>
</form>

<p-table
  *ngIf="stats?.items.length > 0; else emptyInvoice"
  [value]="stats.items"
  [tableStyle]="{'min-width': '60rem'}"
  selectionMode="single"
  (onRowSelect)="onRowSelect($event)"
  styleClass="p-datatable-sm p-datatable-gridlines"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="partner.name">
        Account
        <p-sortIcon field="partner.name"></p-sortIcon>
      </th>
      <th pSortableColumn="partner.name">
        Extra Process
        <p-tag
          value="CRM"
          class="ml-2"
        />
        <p-sortIcon field="partner.extraProcess"></p-sortIcon>
      </th>
      <th pSortableColumn="partner.payment.netTermType">
        Payment Term
        <p-sortIcon field="partner.payment.netTermType"></p-sortIcon>
      </th>
      <th pSortableColumn="ticketsCount">
        Tickets
        <p-sortIcon field="ticketsCount"></p-sortIcon>
      </th>
      <th pSortableColumn="paid.count">
        Paid ( count / amount)
        <p-sortIcon field="paid.count"></p-sortIcon>
      </th>
      <th pSortableColumn="totalUnpaid.count">
        Unpaid
        <p-sortIcon field="totalUnpaid.count"></p-sortIcon>
      </th>
      <th pSortableColumn="due.count">
        Due
        <p-sortIcon field="due.count"></p-sortIcon>
      </th>
      <th pSortableColumn="overdue.count">
        Overdue
        <p-sortIcon field="overdue.count"></p-sortIcon>
      </th>
      <th pSortableColumn="unpaid.amount">
        Total owed
        <p-sortIcon field="unpaid.amount"></p-sortIcon>
      </th>
      <th pSortableColumn="overdue.amount">
        Overdue owed
        <p-sortIcon field="overdue.amount"></p-sortIcon>
      </th>
      <th pSortableColumn="oldestUnpaidDate">
        Oldest unpaid
        <p-sortIcon field="oldestUnpaidDate"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-index="rowIndex">
    <tr
      [pSelectableRow]="item"
      [pSelectableRowIndex]="index"
      [pSelectableRowDisabled]="
        (item.totalUnpaid.count === 0)
      "
    >
      <td>
        <span *ngIf="item.type === 'PRIVATE'">{{
          '(PRIVATE) ' + (item.customer?.name || 'All')
          }}</span>
        <span *ngIf="item.type === 'PRIVATE_BUSINESS'">{{
          '(PRIVATE / 3-RD PARTY) ' + (item.partner?.name || 'All')
          }}</span>
        <span *ngIf="item.type === 'REGISTERED'">{{ item.partner?.name }}</span>
      </td>
      <td class="text-center">
        <ng-container *ngIf="item?.partner?.extraProcess; else notSpecifiedExtraProcess">
          <p-tag value="CRM" class="ml-2"
                 [pTooltip]="canViewPartnerExtraProcessNote() ? item.partner?.extraProcessNote : ''"/>
        </ng-container>
        <ng-template #notSpecifiedExtraProcess>
          Not specified
        </ng-template>
      </td>
      <td class="text-center">
        <ng-container *ngIf="item.customer?.id !== 0 && item.partner?.id !== 0">
          <ng-container *ngIf="item.partner?.payment?.netTermType === 'CREDIT_CARD_ON_FILE'">
            <span
              class="pi pi-credit-card"
              [ngStyle]="{
                color: item.partner.payment.approvalRequired ? 'red' : 'var(--primary-color)',
              }"
              pTooltip="This account pays by credit card"
              tooltipPosition="top"
            >
            </span>
          </ng-container>

          <ng-container *ngIf="item.partner?.payment?.netTermType !== 'CREDIT_CARD_ON_FILE'">
            <span>{{
              item.partner
                ? (item.partner.payment | netTermTypePipeText)
                : 'Net Term / ' + item.netTerm
            }}</span>
          </ng-container>

          <strong *ngIf="item.partner?.netTerm > 0"> / {{ item.partner.netTerm }} Day(s)</strong>
        </ng-container>
      </td>
      <td>{{ item.ticketsCount }}</td>
      <td>{{ item.paid.count }} / {{ item.paid.amount | currency: 'USD' }}</td>
      <td>{{ item.totalUnpaid.count }}</td>
      <td>{{ item.due.count }}</td>
      <td>{{ item.overdue.count }}</td>
      <td>{{ item.totalUnpaid.amount | currency: 'USD' }}</td>
      <td>{{ item.overdue.amount | currency: 'USD' }}</td>
      <td>
        <a [routerLink]="['/invoice', item.oldestUnpaidId]"
          >{{ item.oldestUnpaidDate | date: config.dateFormat }}
        </a>
        <span *ngIf="item.oldestStatus === 'OVERDUE'" style="color: var(--red-500)">(Overdue)</span>
      </td>
    </tr>
    <tr class="font-bold" *ngIf="index === this.stats.items.length - 1">
      <td>Total</td>
      <td></td>
      <td></td>
      <td>{{ stats.ticketsCount }}</td>
      <td>{{ stats.paid.count }} / {{ stats.paid.amount | currency: 'USD' }}</td>
      <td>{{ stats.totalUnpaid.count }}</td>
      <td>{{ stats.due.count }}</td>
      <td>{{ stats.overdue.count }}</td>
      <td>{{ stats.totalUnpaid.amount | currency: 'USD' }}</td>
      <td>{{ stats.overdue.amount | currency: 'USD' }}</td>
      <td></td>
    </tr>
  </ng-template>
</p-table>
<ng-template #emptyInvoice>
  <h2 class="text-center">No unpaid invoices</h2>
</ng-template>
