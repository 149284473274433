import {Component, OnDestroy, OnInit} from '@angular/core';
import {Config} from '../../../../api/model/Config.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfigService, NotificationService} from '../../../../core/services';
import {ApiService} from '../../../../api/service/api.service';
import {Messages} from '../../../../common/messages';
import {DropdownModel} from '../../../../api/model/shared/dropdown.model';
import {environment} from '../../../../../environments/environment';

interface ConfigType {
  name: string;
  code: string;
}

@Component({
  selector: 'tj-configurations-component',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss'],
})
export class ConfigurationsComponent implements OnInit, OnDestroy {
  protected readonly env = environment;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  configDialog: boolean;

  config: Config;

  booleanValues: DropdownModel[] = [
    {label: 'Enabled', value: 'true'},
    {label: 'Disabled', value: 'false'},
  ];

  configs: Config[];

  configType: ConfigType[];
  selectedConfigType;

  constructor(
    private api: ApiService,
    private notificationService: NotificationService,
    private configService: ConfigService,
  ) {
    this.configType = [
      {name: 'HTML', code: 'HTML'},
      {name: 'Boolean', code: 'BOOLEAN'},
      {name: 'String', code: 'STRING'},
      {name: 'Number', code: 'NUMBER'},
      {name: 'Percent', code: 'PERCENT'},
      {name: 'SELECT', code: 'SELECT'},
    ];
  }

  ngOnInit() {
    this.api.globalConfig
      .findAll()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        r => {
          this.configs = r;
        },
        errorMessage => {
          this.notificationService.error(errorMessage);
        },
      );
  }

  editProduct(conf: Config) {
    this.selectedConfigType = conf.type;
    this.config = {...conf};
    this.configDialog = true;
  }

  hideDialog() {
    this.configDialog = false;
  }

  saveProduct() {
    if (this.config.id) {
      this.configs[this.findIndexById(this.config.id)] = this.config;
      this.api.globalConfig
        .update(this.config)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            const globalConfigs = this.configService.globalConfigs$.getValue();
            globalConfigs[this.findIndexById(this.config.id)] = this.config;
            this.configService.globalConfigs$.next(globalConfigs);
            this.notificationService.success(Messages.CONFIG_CREATED);
          },
          errorMessage => {
            this.notificationService.error(errorMessage);
          },
        );
    }

    this.configs = [...this.configs];
    this.configDialog = false;
    // this.config = {};
  }

  findIndexById(id: number): number {
    let index = -1;
    for (let i = 0; i < this.configs.length; i++) {
      if (this.configs[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  configTypeChange($event: any) {
    this.selectedConfigType = $event.value;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public getValues(allowedValues: string[]) {
    return allowedValues.map(value => ({label: value, value: value}));
  }
}
