import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TechnicianSearchFilterModel} from '../../models/technician-search-filter.model';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'tj-technician-search-labels',
  templateUrl: './technician-search-labels.component.html',
  styleUrls: ['./technician-search-labels.component.scss'],
})
export class TechnicianSearchLabelsComponent {
  @Input() technicianFilter: TechnicianSearchFilterModel;

  @Input() filterForm: UntypedFormGroup;

  @Output() remove = new EventEmitter();

  filterNames = {
    name: 'Name',
    personnelCode: 'Code',
    companyName: 'Company name',
    visibleInsideTicket: 'Hiding on ticket',
    email: 'Email',
    phone: 'Phone',
    deleted: 'Deleted ',
  };

  constructor() {}

  getLabel(filter: {key: string; value: any}) {
    const label = this.filterNames[filter.key];
    return label ? `${label}: ${filter.value}` : `Unknown Filter: ${filter.value}`;
  }

  onRemoveFilter(filterName: string) {
    if (!this.technicianFilter[filterName]) return;

    const updatedFilter = {...this.technicianFilter, [filterName]: null};

    this.filterForm?.get(`technicianFilter.${filterName}`)?.setValue(null);

    this.remove.emit(updatedFilter);
  }
}
