<div class="grid" [formGroup]="form">
  <div class="col-12 print-only">
    <h2>{{ getTechnicianName() }}</h2>
  </div>
  <div class="col-12 no-print" *ngIf="!this.payForm.id">
    <div class="grid">
      <div class="col-12 md:col-4 lg:col-3">
        <tj-field label="Technician">
          <tj-technician-combobox
            (technicianChange)="updatePercent($event)"
            formControlName="technicianId"
          ></tj-technician-combobox>
        </tj-field>
        <div *ngIf="isTechnicianHourBased(form.get('technicianId').value)">
          <small class="font-semibold"
            >Technician hourly rate is:
            {{ getTechnicianUnitValue(form.get('technicianId').value) | currency: 'USD' }}. Use the
            Bill/Hour to calculate the technician payment.</small
          >
        </div>
      </div>
      <div class="col-6 md:col-3 lg:col-2">
        <tj-field label="Start Date">
          <tj-datepicker [onlyFuture]="false" formControlName="startDate"></tj-datepicker>
        </tj-field>
      </div>
      <div class="col-6 md:col-3 lg:col-2">
        <tj-field label="End Date">
          <tj-datepicker [onlyFuture]="false" formControlName="endDate"></tj-datepicker>
        </tj-field>
      </div>
      <div class="col-6 md:col-2 lg:col-2">
        <tj-field label="Percent %">
          <input pInputText formControlName="percent" />
        </tj-field>
      </div>
      <div class="col-6 md:col-2 lg:col-2" *ngIf="!payForm.id">
        <button
          pButton
          type="button"
          label="Create"
          (click)="createNewForm()"
          style="margin-top: 19px"
        ></button>
      </div>
    </div>
  </div>

  <div class="col-12 flex flex-wrap">
    <div
      class="col-fixed rectangle mr-2 mb-3 card"
      formArrayName="payFormItems"
      *ngFor="let model of form.get('payFormItems')['controls']; let i = index"
    >
      <ng-container [formGroup]="model">
        <div class="flex mb-2 justify-content-between">
          <div class="text-lg font-medium align-self-center">
            {{ payForm.payFormItems[i].date | date: 'EE, MMM d' }}
          </div>
          <div>
            <button
              pButton
              *tjHasRole="'PAYFORM_EDIT'"
              (click)="createTicket(i, model)"
              type="button"
              [disabled]="readonly"
              icon="pi pi-plus"
              label="Add ticket"
              class="p-button-sm"
            ></button>
          </div>
        </div>
        <div class="mb-1">
          <input pInputText type="text" formControlName="area" placeholder="Area" />
        </div>
        <div>
          <table class="payform-table">
            <thead>
              <tr>
                <th>Ticket</th>
                <th>Check</th>
                <th>Cash</th>
                <th>Com/Exp</th>
                <th>Tech/Exp</th>
                <th *tjHasRole="'PAYFORM_EDIT'"></th>
              </tr>
            </thead>

            <ng-container *ngIf="model.get('payFormItemTickets')['controls'].length === 0">
              <tbody>
                <tr>
                  <td colspan="5" class="py-3">No tickets yet.</td>
                </tr>
              </tbody>
            </ng-container>

            <ng-container *ngIf="model.get('payFormItemTickets')['controls'].length > 0">
              <ng-container
                *ngFor="let ticket of model.get('payFormItemTickets')['controls']; let j = index"
                [formArrayName]="'payFormItemTickets'"
              >
                <tbody>
                  <tr [formGroup]="ticket">
                    <td>
                      <ng-container *ngIf="ticket.get('ticketId').value">
                        <a
                          class="ticket-link"
                          *ngIf="ticket.get('ticketId').value"
                          [ngClass]="{
                            'exist-ticket': ticket.get('ticketId').value === routerTicketId,
                          }"
                          [routerLink]="['/tickets', ticket.get('ticketId').value]"
                          >{{ ticket.get('ticketId').value }}</a
                        >
                      </ng-container>
                      <ng-container *ngIf="!ticket.get('ticketId').value">
                        <input
                          pInputText
                          [pTooltip]="ticket.get('note')?.value"
                          tooltipPosition="top"
                          type="text"
                          formControlName="note"
                          (keyup.enter)="onEnterPress()"
                        />
                      </ng-container>
                    </td>
                    <td>
                      <input
                        pInputText
                        type="text"
                        formControlName="incomeCheck"
                        (click)="selectText($event)"
                        (keyup.enter)="onEnterPress()"
                      />
                    </td>
                    <td>
                      <input
                        pInputText
                        type="text"
                        formControlName="incomeCash"
                        (click)="selectText($event)"
                        (keyup.enter)="onEnterPress()"
                      />
                    </td>
                    <td>
                      <input
                        pInputText
                        type="text"
                        formControlName="expenses"
                        (click)="selectText($event)"
                        (keyup.enter)="onEnterPress()"
                      />
                    </td>
                    <td>
                      <input
                        pInputText
                        type="text"
                        formControlName="technicianExpenses"
                        (click)="selectText($event)"
                        (keyup.enter)="onEnterPress()"
                      />
                    </td>
                    <td *tjHasRole="'PAYFORM_EDIT'">
                      <button
                        pButton
                        [disabled]="readonly"
                        (click)="confirmDelete(i, j)"
                        type="button"
                        class="p-button-danger"
                        icon="pi pi-trash"
                      ></button>
                      <p-confirmDialog
                        appendTo="body"
                        [key]="'confirmDeletion-' + i + '-' + j"
                      ></p-confirmDialog>
                    </td>
                  </tr>
                </tbody>
              </ng-container>
            </ng-container>

            <tfoot>
              <tr>
                <td>Total</td>
                <td>
                  <input pInputText formControlName="totalIncomeCheck" type="text" />
                </td>
                <td>
                  <input pInputText formControlName="totalIncomeCash" type="text" />
                </td>
                <td>
                  <input pInputText formControlName="totalExpense" type="text" />
                </td>
                <td>
                  <input pInputText formControlName="totalTechnicianExpense" type="text" />
                </td>
              </tr>
              <tr>
                <td>Tech</td>
                <td>
                  <input pInputText type="text" formControlName="technicianTotalIncomeCheck" />
                </td>
                <td>
                  <input pInputText type="text" formControlName="technicianTotalIncomeCash" />
                </td>
                <td>
                  <input pInputText type="text" formControlName="technicianTotalExpense" />
                </td>
                <td>
                  <input
                    pInputText
                    type="text"
                    formControlName="technicianTotalTechnicianExpense"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="2">Pay out</td>
                <td colspan="3">
                  <input pInputText type="text" formControlName="payOff" />
                  <input type="hidden" formControlName="incomeDayTotal" />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="payForm.id">
    <div class="col-12 mb-8">
      <div class="grid">
        <div class="col-3">Gross revenue</div>
        <div class="col-9">
          <tj-field>
            <tj-money-input formControlName="balance" [disabled]="true"></tj-money-input>
          </tj-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-3">Tech gross income</div>
        <div class="col-9">
          <tj-field>
            <tj-money-input formControlName="inCome" [disabled]="true"></tj-money-input>
          </tj-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-3">Tech cash received</div>
        <div class="col-9">
          <tj-field>
            <tj-money-input formControlName="cashTotal" [disabled]="true"></tj-money-input>
          </tj-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <tj-pay-form-info
            [form]="form"
            [info]="payForm.getInfo()"
            (onEnterPress)="onEnterPress()"
          ></tj-pay-form-info>
        </div>
        <ng-container *ngIf="canViewPayInFull()">
          <div class="col-12 flex justify-content-end align-items-center">
            <button
              pButton
              [disabled]="readonly"
              type="button"
              class="p-button-info justify-content-center"
              (click)="payInFull()"
            >
              Pay in Full
            </button>
          </div>
        </ng-container>
      </div>
      <div class="grid">
        <div class="col-3">Balance to / Pay out tech</div>
        <div class="col-4">
          <tj-field>
            <tj-money-input formControlName="payBalance"></tj-money-input>
          </tj-field>
        </div>
        <ng-container *tjHasRole="'PAYFORM_EDIT'">
          <div class="col-5 text-left">
            <button
              type="button"
              (click)="transfer()"
              [disabled]="disableTransferButton || readonly"
              class="p-button-info"
              [label]="'Transfer to next week'"
              pButton
            ></button>
          </div>
        </ng-container>
      </div>
      <div class="grid">
        <div class="col-12 no-print">
          <hr />
        </div>
      </div>
      <div class="grid">
        <div class="col-1 md:col-1 print-only">
          <h3>{{ form.get('status').value }}</h3>
        </div>
        <div class="col-7 md:col-4 lg:col-2 no-print">
          <tj-field label="Status">
            <p-dropdown
              [options]="configService.payFormStatuses"
              [disabled]="readonly"
              formControlName="status"
            ></p-dropdown>
          </tj-field>
        </div>

        <div
          *ngIf="payForm.id"
          class="lg:col-offset-8 md:col-offset-5 col-5 md:col-3 lg:col-2 no-print text-right"
        >
          <button
            *tjHasRole="'PAYFORM_EDIT'"
            (click)="update()"
            label="Save"
            pButton
            style="margin-top: 19px"
            [disabled]="readonly"
            type="button"
            [loading]="isSaving"
          ></button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
