import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {ConfigService} from '../../../core/services/config.service';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';

import * as customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

const TIME_FORMAT = 'h:mmA';

@Component({
  selector: 'tj-time',
  template: `
    <p-dropdown
      #time
      aria-label="Number"
      [options]="availableTimes"
      (onChange)="onInputChange($event)"
      appendTo="body"
      [(ngModel)]="innerValue"
      [autoWidth]="false"
      [disabled]="isDisabled || readonly"
      [placeholder]="placeholder"
    >
      <ng-template let-option pTemplate="selectedItem">
        <div class="times-item">
          <span class="times-label font-bold">{{ option?.label }}</span>
        </div>
      </ng-template>
    </p-dropdown>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TjTimeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TjTimeComponent),
      multi: true,
    },
  ],
})
export class TjTimeComponent implements ControlValueAccessor, Validator, OnChanges, OnInit {
  @Input() currentDate;

  @Input() placeholder;

  @Input() readonly = false;

  @ViewChild('time') time;

  private propagateChanges;

  private isValid = true;

  isDisabled = false;

  // keeps the current value e.g 1:30pm
  innerValue;

  availableTimes;

  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
    this.availableTimes = this.configService.times;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentDate) {
      const currentValueDate = dayjs(changes.currentDate.currentValue);
      const previousValueDate = dayjs(changes.currentDate.previousValue);

      if (changes.currentDate.firstChange || !currentValueDate.isSame(previousValueDate)) {
        this.changeTime(this.innerValue);
      }
    }
  }

  onInputChange(event) {
    this.changeTime(event.value);
  }

  registerOnChange(fn: any): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: string): void {
    if (!value) {
      this.innerValue = null;
      return;
    }

    const currentDate = dayjs(value).utc();
    this.validateNewTime(currentDate);
    this.innerValue = currentDate.format(TIME_FORMAT);
  }

  validate(control: AbstractControl): ValidationErrors | any {
    if (this.isValid) {
      return null;
    } else {
      return {
        timeParseError: {
          valid: false,
        },
      };
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private changeTime(value) {
    if (!value) {
      return;
    }
    this.innerValue = value;
    const newTime = dayjs(value, [TIME_FORMAT]);
    this.validateNewTime(newTime);
    const currentDate = this.currentDate || Date.now();
    const newDate = dayjs(currentDate)
      .set('hour', newTime.get('hour'))
      .set('minute', newTime.get('minute'))
      .set('second', 0)
      .set('millisecond', 0)
      .tz('UTC', true);

    if (this.propagateChanges) {
      this.propagateChanges(newDate.toISOString());
    }
  }

  private validateNewTime(time) {
    this.isValid = time.isValid();
  }
}
