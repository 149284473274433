import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CommunicationService} from '../service/communication.service';
import {ChatListItemModel} from '../model/chat.model';

@Injectable()
export class ChatApi extends CommunicationService {
  protected apiEndpoint = this.env.apiEndpoint;

  findChats(ticketId: number): Observable<ChatListItemModel[]> {
    const url = this.getTicketAwareUrl(ticketId);
    return this.requestService.get(url).pipe(
      map(response => response.response as ChatListItemModel[]),
      catchError(this.handleError),
    );
  }

  private getTicketAwareUrl(ticketId: number): string {
    return `${this.apiEndpoint}tickets/${ticketId}/chats`;
  }
}
