<form [formGroup]="reviewForm" (ngSubmit)="submitForm()">
  <div class="grid">
    <div class="col-12">
      <tj-field>
        <input id="name" pInputText formControlName="name" placeholder="Enter review place" />
      </tj-field>
    </div>

    <div class="col-12">
      <tj-field>
        <input id="url" pInputText formControlName="url" placeholder="Enter review URL" />
      </tj-field>
    </div>

    <div class="col-12 text-right">
      <button
        pButton
        type="button"
        label="Cancel"
        (click)="cancel()"
        class="p-button-secondary"
      ></button>
      <button pButton type="submit" label="Save" class="ml-2"></button>
    </div>
  </div>
</form>
