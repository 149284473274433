import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ConfigService} from '../../../../core/services';
import * as dayjs from 'dayjs';

@Component({
  selector: 'tj-date-range-picker',
  template: `
    <p-calendar
      [(ngModel)]="value"
      dateFormat="mm/dd/yy"
      (onSelect)="onSelect($event)"
      appendTo="body"
      [placeholder]="placeholder"
      selectionMode="range"
    ></p-calendar>
  `,
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true,
    },
  ],
})
export class DateRangePickerComponent implements ControlValueAccessor {
  @Input('placeholder') placeholder = '';

  value: any;

  private onChange: (value: any) => void;
  private onTouched: () => void;

  options;

  constructor(public config: ConfigService) {
    this.options = {...config.datePickerOptions};
  }

  writeValue(value: any): void {
    if (value) {
      this.value = Array.isArray(value)
        ? value.map(date => this.formatDate(dayjs(date)))
        : this.formatDate(dayjs(value));
    } else {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle the disabled state if needed
  }

  onSelect(event: any) {
    if (Array.isArray(this.value)) {
      const utcDates = this.value.map(date => this.toUTCDateOnlyString(date));
      this.onChange(utcDates.join('|'));
    }
  }

  formatDate(date: dayjs.Dayjs): string {
    return date.format('MM/DD/YYYY');
  }

  toUTCDateOnlyString(date: Date): string {
    if (date) {
      const utcDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 13, 0, 0, 0),
      );
      return utcDate.toISOString();
    } else {
      const singleDate = this.value[0];
      const utcDate = new Date(
        Date.UTC(
          singleDate.getFullYear(),
          singleDate.getMonth(),
          singleDate.getDate(),
          13,
          0,
          0,
          0,
        ),
      );
      return utcDate.toISOString();
    }
  }
}
