import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CommunicationService} from '../service/communication.service';
import {EntityListResponseModel} from '../model/shared/Response.model';
import {ServiceCategory} from '../model/servicecategory/service-category.model';

@Injectable()
export class ServiceCategoryApi extends CommunicationService {
  protected apiEndpoint = this.env.apiEndpoint + 'service-categories';

  findAll(): Observable<EntityListResponseModel<ServiceCategory>> {
    return this.requestService.get(`${this.apiEndpoint}`).pipe(
      map(response => response as EntityListResponseModel<ServiceCategory>),
      catchError(this.handleError),
    );
  }

  update(categoryId: number, supported: boolean): Observable<void> {
    const url = `${this.apiEndpoint}/${categoryId}`;
    return this.requestService
      .patch(url, {
        supported,
      })
      .pipe(catchError(this.handleError));
  }
}
