import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {InventoryComponent} from './components/domain-list/inventory.component';
import {ConfirmPopupModule} from 'primeng/confirmpopup';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    SharedModule,
    RouterModule.forRoot([
      {
        path: 'inventory',
        component: InventoryComponent,
        data: {},
      },
    ]),
    ConfirmPopupModule,
  ],
  declarations: [InventoryComponent],
  providers: [],
  exports: [InventoryComponent],
})
export class InventoryModule {}
