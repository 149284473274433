import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {Injectable, OnDestroy} from '@angular/core';

@Injectable()
export class BaseFormGroup implements OnDestroy {
  currentFormGroup: UntypedFormGroup;

  private lastForm;
  private registeredControlNames = [];

  [key: string]: any;

  protected buildForm(
    form: UntypedFormGroup,
    formControls: {[key: string]: AbstractControl},
    group: string,
  ) {
    this.lastForm = form;
    if (group) {
      this.currentFormGroup = new UntypedFormGroup(formControls);
      this.registerControl(form, group, this.currentFormGroup);
    } else {
      for (const name in formControls) {
        if (formControls.hasOwnProperty(name)) {
          this.registerControl(form, name, formControls[name]);
        }
      }
      this.currentFormGroup = form;
    }
  }

  ngOnDestroy() {
    this.removeRegisteredControls();
  }

  private removeRegisteredControls(): void {
    this.registeredControlNames.forEach(name => {
      this.lastForm.removeControl(name);
    });
  }

  protected defineGetters(formControls) {
    Object.keys(formControls).forEach(controlName => {
      const propertyName = 'fc' + controlName.substr(0, 1).toUpperCase() + controlName.substr(1);

      if (!Object.prototype.hasOwnProperty.call(this, propertyName)) {
        Object.defineProperty(this, propertyName, {
          get: () => formControls[controlName]
        });
      }

    });
  }

  private registerControl(form, name, control): void {
    form.addControl(name, control);
    this.registeredControlNames.push(name);
  }
}
