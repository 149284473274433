<form [formGroup]="form" *tjHasRole="'TICKET_OUTREACH_ATTEMPT_ADD'">
  <h5 class="mb-1">Select Contact Methods:</h5>
  <div formArrayName="contactMethods" class="grid m-0 pt-0">
    <div *ngFor="let method of contactMethods; let i = index" class="col-4">
      <label> <input type="checkbox" [formControlName]="i" /> {{ method }} </label>
    </div>
  </div>

  <h5 class="mt-1">Additional Note:</h5>
  <div class="col-12 pt-0 p-field">
    <textarea id="note" pInputTextarea formControlName="note" rows="1"></textarea>
  </div>

  <div class="col-12 text-right">
    <p-button type="button" (click)="save()" [disabled]="form.invalid || readOnly">Save</p-button>
  </div>
</form>

<hr *tjHasRole="'TICKET_OUTREACH_ATTEMPT_ADD'" />

<ng-container *ngIf="outreachAttempt && outreachAttempt.length > 0">
  <div class="col-12 mx-0 px-0">
    <h4>Contact History</h4>
    <div *ngFor="let attempt of outreachAttempt">
      <div class="p-badge p-badge-secondary">{{ attempt.createdBy }}</div>
      -
      <span
        ><b
          ><i>{{ attempt.createdAt }}</i></b
        ></span
      >

      <div class="grid col-12 m-0 p-0">
        <div *ngFor="let contactMethod of attempt.contactTypes">
          <p class="col-4">
            <i class="pi pi-check-square"
              ><span class="ml-2">{{ contactMethod }}</span></i
            >
          </p>
        </div>
      </div>

      <p *ngIf="attempt.note" class="mb-1"><b>Note:</b> {{ attempt.note }}</p>

      <hr class="mt-0" />
    </div>
  </div>
</ng-container>
