<div class="grid pt-3" *ngIf="invoiceStats">
  <div class="md:col sm:col-6 col-12">
    <tj-metric [text]="'Total tickets served'" [value]="invoiceStats.ticketsCount"></tj-metric>
  </div>

  <div class="md:col sm:col-6 col-12">
    <tj-metric
      [text]="'Avg Turn Around'"
      [value]="
        (
          invoiceStats.avgTurnAround /
          86400 /
          (invoiceStats.ticketsCount === 0 ? 1 : invoiceStats.ticketsCount)
        ).toFixed(2) + ' Days'
      "
    ></tj-metric>
  </div>

  <div class="md:col sm:col-6 col-12">
    <tj-metric
      [text]="'Avg completion rate'"
      tooltip="Rate of completed (invoiced) jobs based on inspection limit based on value of config 'Inspection max value'"
      [value]="invoiceStats.avgCompletionRate + ' %'"
    ></tj-metric>
  </div>

  <div class="md:col sm:col-6 col-12">
    <tj-metric
      [text]="'Private Lead-to-Job Rate'"
      tooltip="Rate of completed (invoiced) jobs based on inspection limit based on value of config 'Inspection max value'"
      [value]="invoiceStats.privateTicketsInvoicingRate | percent"
    ></tj-metric>
  </div>

  <div class="md:col sm:col-6 col-12">
    <tj-metric
      [text]="'Total Unpaid Invoices'"
      [value]="invoiceStats.totalUnpaid.count"
    ></tj-metric>
  </div>

  <div class="md:col sm:col-6 col-12">
    <tj-metric
      [text]="'Total amount of unpaid invoices'"
      [value]="invoiceStats.totalUnpaid.amount | currency: 'USD'"
    ></tj-metric>
  </div>
</div>

<p-panel header="Invoices stats" *ngIf="invoiceStats?.items">
  <div class="grid">
    <div class="col-12">
      <tj-invoice-amount-stats-chart [items]="invoiceStats.items"></tj-invoice-amount-stats-chart>
    </div>
  </div>
</p-panel>

<p-panel header="Domain stats" styleClass="mt-3">
  <div class="grid">
    <div class="col-12">
      <tj-domain-stats-chart></tj-domain-stats-chart>
    </div>
  </div>
</p-panel>

<p-panel header="Tickets stats" styleClass="mt-3">
  <div class="grid">
    <div class="col-6">
      <tj-tickets-per-account-stats-chart></tj-tickets-per-account-stats-chart>
    </div>
    <div class="col-6">
      <tj-tickets-per-status-stats-chart></tj-tickets-per-status-stats-chart>
    </div>
  </div>
</p-panel>
