import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ConfigService} from '../../../../core/services';
import {environment} from '../../../../../environments/environment';
import {CalendarTicketModel} from '../../../../api/model/scheduler/calendar.ticket.model';
import {CalendarTechnicianModel} from '../../../../api/model/scheduler/calendar.technician.model';
import {Router} from '@angular/router';

@Component({
  selector: 'tj-technician-stop-form-scheduler',
  templateUrl: './technician-stop-form.component.html',
  styleUrls: ['./technician-stop-form.component.scss'],
})
export class TechnicianStopFormComponent implements OnInit, OnDestroy {
  env = environment;

  @Input() selectedStops: CalendarTicketModel[];

  @Input() selectedTechnician: CalendarTechnicianModel;

  @Input() selectedDate: Date;

  constructor(
    public configService: ConfigService,
    public fb: UntypedFormBuilder,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  selectStop(stopModel: CalendarTicketModel) {}

  getJobLevel(selectedStop: CalendarTicketModel) {
    const duration = selectedStop.timeNeeded;

    let level = '';
    if (duration <= 1) {
      level = 'low';
    } else if (duration <= 2) {
      level = 'medium';
    } else {
      level = 'high';
    }
    return level;
  }

  navigateToTicket(event: Event, ticketId: number) {
    event.preventDefault();
    // This fixes the issue when from calendar's event popup user tries to navigate to ticket's page
    // in ticket's page scroll is not work
    document.body.classList.remove('p-overflow-hidden');
    this.router.navigate(['/tickets', ticketId]);
  }
}
