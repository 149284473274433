import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {CalendarOptions, EventClickArg} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import {ApiService} from '../../../api/service/api.service';
import {DailyTicketsModel} from '../../../api/model/scheduler/daily.tickets.model';
import {FullCalendarComponent} from '@fullcalendar/angular';
import * as dayjs from 'dayjs';
import {ConfigService, NotificationService, TjKeycloakService} from '../../../core/services';
import {Technician} from '../../../api/model/Technician.model';
import {Ticket} from '../../../api/model/Ticket.model';
import {CalendarSearchCriteria} from '../service/calendar-search-criteria';
import {CalendarSearchService} from '../service/calendar-search.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {TicketTechnician} from '../../../api/model/TicketTechnicians.model';
import {WeekDays} from '../../shared/enums/weekDays';
import {CalendarTicketModel} from '../../../api/model/scheduler/calendar.ticket.model';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, finalize, map, takeUntil} from 'rxjs/operators';
import {Vacation} from '../../../api/model/Vacation.model';
import {CalendarTechnicianModel} from '../../../api/model/scheduler/calendar.technician.model';
import {Colors} from '../../../common/colors';
import {CalendarEventModel} from '../../../api/model/scheduler/calendarEventModel';
import {Messages} from '../../../common/messages';
import {Observable} from 'rxjs-compat';
import {Area} from '../../../api/model/Area.model';

@Component({
  selector: 'tj-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly Colors = Colors;
  readonly INIT_SHOW_WEEKS = 1;
  readonly INIT_FILTER_SHOW_WEEKS = 2;
  readonly EVENT_NOTE_MAX_VISIBLE_CHAR_LIMIT = 11;
  @ViewChild(FullCalendarComponent) fullCalendarComponent: FullCalendarComponent;
  private isLoadingEvents = false;

  displayTagsBlock = false;
  displayVacationBlock = false;
  displayWeeklyOffBlock = false;
  displayAreaChipsBlock = false;

  weekCount = 1;
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin],
    initialView: 'customWeeks',
    firstDay: 1,
    dayCellContent: arg => {
      const date = arg.date;
      const day = date.getDate();
      const month = date.toLocaleString('default', {month: 'short'});
      const year = date.getFullYear();
      const weekday = date.toLocaleString('default', {weekday: 'short'});

      const specificDate = this.searchCriteria?.specificDate
        ? new Date(this.searchCriteria.specificDate)
        : null;

      const isSpecificDate =
        specificDate &&
        date.getFullYear() === specificDate.getFullYear() &&
        date.getMonth() === specificDate.getMonth() &&
        date.getDate() === specificDate.getDate();

      const additionalStyles = isSpecificDate ? 'border-right: 2px solid blue; border-left: 2px solid blue; padding: 0 2px; color: blue;' : '';

      return {
        html: `<div style="font-weight:bold; display: flex; justify-content: space-between; ${additionalStyles}">
              <span>${weekday}</span>
              <span>${month}/${day}/${year}</span>
           </div>`
      };
    },
    views: {
      customWeeks: {
        type: 'dayGrid',
        duration: {weeks: this.INIT_SHOW_WEEKS},
        titleFormat: {year: 'numeric', month: 'long', day: 'numeric'},
      },
    },
    visibleRange: this.getVisibleRange.bind(this, this.INIT_SHOW_WEEKS),
    headerToolbar: {
      left: '',
      right: '',
    },
    editable: true,
    selectable: true,
    eventDataTransform: (clickInfo: DailyTicketsModel) => this.mapToEvent(clickInfo),
    eventClick: this.handleEventClick.bind(this),
  };

  showCalendarEvents = false;
  selectedTechnician: Technician;
  selectedDate: Date;
  selectedStops: Ticket[];
  showStopsDialog = false;
  searchCriteria: CalendarSearchCriteria = {} as CalendarSearchCriteria;
  form: UntypedFormGroup;
  areaNoteForm: UntypedFormGroup;
  calendarStartDate;

  private eventsSubject = new BehaviorSubject<any[]>([]);
  readonly events$ = this.eventsSubject.asObservable();

  areas$: Observable<Area[]> = this.configService.areas$;
  formattedAreas$: Observable<{id: number; nameCode: string}[]> = this.areas$.pipe(
    map((areas: Area[]) =>
      areas.map(area => ({
        id: area.id,
        nameCode: `${area.name} / ${area.code}`,
      })),
    ),
  );

  private readonly destroy$ = new Subject<void>();
  eventClickInfo: EventClickArg;

  constructor(
    private api: ApiService,
    private calendarSearchService: CalendarSearchService,
    private fb: UntypedFormBuilder,
    public configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private keycloakService: TjKeycloakService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      ticketId: this.fb.control(this.searchCriteria.ticketId),
      area: this.fb.control(this.searchCriteria.area),
      technician: this.fb.control(this.searchCriteria.technician),
      specificDate: this.fb.control(this.searchCriteria.specificDate),
      // hideOthers: this.fb.control(this.searchCriteria.hideOthers),
    });
    if (
      this.form.get('area').value ||
      this.form.get('ticketId').value ||
      this.form.get('technician').value ||
      this.form.get('specificDate').value
    ) {
      this.showCalendarEvents = true;
    }
  }

  ngAfterViewInit(): void {
    this.subscribeSearchCriteria();
    this.events$.pipe(takeUntil(this.destroy$)).subscribe(events => {
      const calendarApi = this.fullCalendarComponent.getApi();
      calendarApi.removeAllEvents();
      calendarApi.addEventSource(events);
      this.cdr.detectChanges();
      setTimeout(() => {
        this.displayTagsBlock = true;
        this.displayVacationBlock = true;
        this.displayWeeklyOffBlock = true;
        this.displayAreaChipsBlock = true;
        this.cdr.detectChanges();
      }, 50);
    });
  }

  private getVisibleRange(currentDate: Date, showWeeks?: number) {
    const startOfWeek = dayjs(currentDate).startOf('week').toDate();
    return {
      start: startOfWeek,
      end: dayjs(startOfWeek).add(showWeeks, 'weeks').endOf('week').toDate(),
    };
  }

  // onSearch(): void {
  //   this.searchCriteria = this.form.value;
  //   this.loadEventsForCurrentView();
  // }

  today() {
    this.calendarStartDate = null;
    const calendarApi = this.fullCalendarComponent.getApi();
    this.showCalendarEvents = true;
    this.searchCriteria.dateRange = undefined;
    this.searchCriteria.serviceDate = undefined;
    this.searchCriteria.specificDate = undefined;
    calendarApi.removeAllEvents();
    this.form.get('ticketId').setValue(null);
    this.form.get('technician').setValue([]);
    this.calendarOptions.views['customWeeks'].duration = {weeks: this.INIT_FILTER_SHOW_WEEKS};
    this.calendarOptions.visibleRange = this.getVisibleRange(
      calendarApi.getCurrentData().currentDate,
      this.INIT_FILTER_SHOW_WEEKS,
    );
    calendarApi.today();
    this.loadEventsForCurrentView();
  }

  showAll() {
    this.form.reset();
    this.searchCriteria = {};
    this.calendarStartDate = null;

    const calendarApi = this.fullCalendarComponent.getApi();
    calendarApi.removeAllEvents();

    this.calendarOptions.views['customWeeks'].duration = {weeks: this.INIT_FILTER_SHOW_WEEKS};
    this.calendarOptions.visibleRange = this.getVisibleRange(
      calendarApi.getCurrentData().currentDate,
      this.INIT_FILTER_SHOW_WEEKS,
    );

    calendarApi.today();
    this.loadEventsForCurrentView();
  }

  prev() {
    if (this.searchCriteria?.ticketId) {
      this.searchCriteria.ticketId = undefined;
    }
    this.searchCriteria.specificDate = undefined;
    const calendarApi = this.fullCalendarComponent.getApi();
    this.calendarStartDate = dayjs(calendarApi.view.currentStart)
      .add(-1, 'weeks')
      .startOf('week')
      .add(1, 'day')
      .toDate();
    this.calendarOptions.visibleRange = {
      start: this.calendarStartDate,
      end: dayjs(this.calendarStartDate).add(1, 'weeks').endOf('week').toDate(),
    };
    calendarApi.gotoDate(this.calendarStartDate);
    this.loadEventsForCurrentView();
  }

  next() {
    if (this.searchCriteria?.ticketId) {
      this.searchCriteria.ticketId = undefined;
    }
    this.searchCriteria.specificDate = undefined;
    const calendarApi = this.fullCalendarComponent.getApi();
    this.calendarStartDate = dayjs(calendarApi.view.currentStart)
      .add(1, 'weeks')
      .startOf('week')
      .add(1, 'day')
      .toDate();
    this.calendarOptions.visibleRange = {
      start: this.calendarStartDate,
      end: dayjs(this.calendarStartDate).add(1, 'weeks').endOf('week').toDate(),
    };
    calendarApi.gotoDate(this.calendarStartDate);
    this.loadEventsForCurrentView();
  }

  private loadEventsForCurrentView() {
    this.displayTagsBlock = false;
    this.displayVacationBlock = false;
    this.displayWeeklyOffBlock = false;
    this.displayAreaChipsBlock = false;
    if (this.isLoadingEvents) {
      return;
    }
    this.isLoadingEvents = true;
    const calendarApi = this.fullCalendarComponent.getApi();
    let startDate = this.calendarStartDate
      ? dayjs(this.calendarStartDate).format('YYYY-MM-DD')
      : dayjs(calendarApi.view.currentStart).format('YYYY-MM-DD');
    let endDate = dayjs(startDate)
      .add(this.INIT_FILTER_SHOW_WEEKS, 'weeks')
      .subtract(1, 'days')
      .format('YYYY-MM-DD');

    if (((this.searchCriteria?.ticketId && this.searchCriteria?.serviceDate) || this.searchCriteria?.specificDate) &&
      this.searchCriteria?.weekStartDate && this.searchCriteria?.weekEndDate
    ) {
      const weekStartDate = this.searchCriteria.weekStartDate;
      const weekEndDate = this.searchCriteria.weekEndDate.add(1, 'weeks');
      startDate = weekStartDate.format('YYYY-MM-DD');
      endDate = weekEndDate.format('YYYY-MM-DD');

      calendarApi.gotoDate(startDate);
      this.calendarOptions.visibleRange = this.getVisibleRange(
        new Date(startDate),
        this.INIT_FILTER_SHOW_WEEKS,
      );
    } else if (
      this.searchCriteria?.area ||
      this.form.get('area').value ||
      this.searchCriteria?.technician ||
      this.form.get('technician').value
    ) {
      this.calendarOptions.visibleRange = this.getVisibleRange(
        new Date(startDate),
        this.INIT_FILTER_SHOW_WEEKS,
      );
      endDate = dayjs(startDate)
        .add(this.INIT_FILTER_SHOW_WEEKS, 'weeks')
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
    } else {
      this.calendarOptions.visibleRange = this.getVisibleRange(
        new Date(startDate),
        this.INIT_SHOW_WEEKS,
      );
    }
    this.calendarStartDate = startDate;
    const searchCriteria = this.form.value;

    this.api.scheduler
      .findInRange(startDate, endDate, searchCriteria)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoadingEvents = false;
        }), // Reset flag after completion
      )
      .subscribe(
        (dailyTickets = []) => {
          this.eventsSubject.next(dailyTickets);
        },
        error => {
          this.notificationService.error(error, Messages.CALENDAR_EVENTS_LOAD_FAILED);
        },
      );
  }

  private mapToEvent(ticket: DailyTicketsModel) {
    const ticketsPerArea: Map<string, {count: number; fullName: string}> =
      this.calculateTicketsPerArea(ticket);
    const sortedTicketsPerArea: any[] = [...ticketsPerArea].sort((a, b) => b[1].count - a[1].count);
    const hasStopsRecallGoingBackTag = ticket.stops.some(stop => stop.recallGoingBack);
    const hasStopsPickUpDeliveryTag = ticket.stops.some(stop => stop.pickUpDelivery);
    const hasStopsShopTag = ticket.stops.some(stop => stop.shop);
    const stopsWorkTime = ticket.stops.reduce((total, stop) => total + stop.timeNeeded, 0);
    const allAreaFullNames = sortedTicketsPerArea.map(item => item[1].fullName).join(', ');

    const technicianBySpecificDate = this.isTechnicianSupportAreasForDate(ticket?.calendarEvent);

    const found = ticket.stops.some(this.eventMatchesSearchCriteria.bind(this));
    if (!found && this.searchCriteria.hideOthers) {
      return null;
    }

    const techArr: TicketTechnician[] = this.extractTechnicians(ticket);

    return {
      title: ticket.technician.personnelCode || ticket.technician.name,
      date: ticket.date,
      textColor: Colors.BLACK,
      backgroundColor: this.getBgColor(
        ticket.stops.length,
        stopsWorkTime,
        ticket.technician,
        ticket.date,
      ),
      borderColor: this.getBorderColor(ticket.stops, technicianBySpecificDate),
      classNames: [found || technicianBySpecificDate ? 'border-2' : 'border-0', 'p-2'],
      extendedProps: {
        date: ticket.date,
        technician: ticket.technician,
        technicians: techArr,
        ticketsPerArea: sortedTicketsPerArea,
        allArea: allAreaFullNames,
        stops: ticket.stops,
        routeId: ticket.routeId,
        expanded: false,
        recallGoingBack: hasStopsRecallGoingBackTag,
        pickUpDelivery: hasStopsPickUpDeliveryTag,
        shop: hasStopsShopTag,
        stopsCount: ticket.stops.length,
        eventDetail: ticket.calendarEvent,
      },
    };
  }

  isTechnicianSupportAreasForDate(calendarEvent: CalendarEventModel): boolean {
    const areas = this.form.get('area')?.value as number[] ?? [];
    return areas.some(area =>
      calendarEvent?.intermediateAreas?.some(intermediateArea => intermediateArea.id === area)
    );
  }

  private calculateTicketsPerArea(
    ticket: DailyTicketsModel,
  ): Map<string, { count: number; fullName: string }> {
    const ticketsPerArea: Map<string, { count: number; fullName: string }> = new Map();

    // Add intermediate areas with count 0 if they don't already exist but specific added to technician
    if (ticket.calendarEvent?.intermediateAreas) {
      ticket.calendarEvent.intermediateAreas.forEach(area => {
        ticketsPerArea.set(area.code, {count: 0, fullName: area.name});
      });
    }

    ticket.stops.forEach(stop => {
      const areas = stop.customer.address?.areas || [
        {code: stop.customer.address.addressFormatted},
      ];
      areas.forEach(area => {
        const areaCode = area.code;
        if (areaCode) {
          const count = ticketsPerArea.get(areaCode)?.count || 0;
          ticketsPerArea.set(areaCode, {count: count + 1, fullName: area.name});
        }
      });
    });
    return ticketsPerArea;
  }

  private extractTechnicians(ticket: DailyTicketsModel): TicketTechnician[] {
    const techSet: Map<number, TicketTechnician> = new Map();
    ticket.stops.forEach(stop =>
      stop.technicians.forEach(t => {
        if (ticket.technician.id !== t.id) {
          techSet.set(t.id, t);
        }
      }),
    );
    return Array.from(techSet.values());
  }

  // eventMatchesSearchCriteria(ticket: CalendarTicketModel): boolean {
  //   const findByArea = this.searchCriteria.area
  //     ? this.searchCriteria.area.some(area => ticket.customer.address.tag.includes(area.toString()))
  //     : true;

  //   const findByTicket = this.searchCriteria.ticketId
  //     ? ticket.id === this.searchCriteria.ticketId
  //     : true;

  //   const findByTech = this.searchCriteria.technician
  //     ? ticket.technicians.some(it => {
  //         const matchesByCode = it.personnelCode
  //           ? this.searchCriteria.technician!.includes(Number(it.personnelCode))
  //           : false;
  //         const matchesByName = this.searchCriteria.technician!.includes(Number(it.name));
  //         return matchesByCode || matchesByName;
  //       })
  //     : true;

  //   return findByArea && findByTicket && findByTech;
  // }

  eventMatchesSearchCriteria(ticket: CalendarTicketModel): boolean {
    const findByArea =
      !this.searchCriteria.area ||
      this.searchCriteria.area?.some(area => {
        return ticket.customer.address?.areas?.find(ticketArea => ticketArea.id === area);
      });

    const findByTicket =
      !this.searchCriteria.ticketId || ticket.id === this.searchCriteria.ticketId;

    const findByTech =
      !this.searchCriteria.technician ||
      ticket.technicians.some(it => {
        const matchesByCode = it.personnelCode
          ? this.searchCriteria.technician!.includes(Number(it.personnelCode))
          : false;
        const matchesByName = this.searchCriteria.technician!.includes(Number(it.name));
        return matchesByCode || matchesByName;
      });
    if (findByArea || findByTicket || findByTech) {
      this.showCalendarEvents = true;
    }
    return findByArea || findByTicket || findByTech;
  }

  private handleEventClick(clickInfo: EventClickArg) {
    this.eventClickInfo = clickInfo;
    this.areaNoteForm = this.fb.group({
      dayNote: this.fb.control(clickInfo.event.extendedProps['eventDetail']?.note || ''),
      intermediateAreas: this.fb.control(
        clickInfo.event.extendedProps['eventDetail']?.intermediateAreas?.map(area => ({
          id: area.id,
          nameCode: `${area.name} / ${area.code}`,
        })),
      ),
    });

    this.selectedStops = clickInfo.event.extendedProps['stops'];
    this.selectedTechnician = clickInfo.event.extendedProps['technician'] as Technician;
    this.selectedDate = clickInfo.event.extendedProps['date'];

    this.showStopsDialog = true;
    this.cdr.detectChanges();
  }

  canEditCalendarEvent(): boolean {
    return this.keycloakService.hasRole('CALENDAR_EVENT_EDIT');
  }

  onSaveCalendarEvent() {
    const dayNotes = this.areaNoteForm.get('dayNote').value;
    let intermediateAreas = this.areaNoteForm.get('intermediateAreas').value;
    const calendarEvent = this.eventClickInfo.event.extendedProps['eventDetail'];
    const calendarEventModel = calendarEvent
      ? (calendarEvent as CalendarEventModel)
      : new CalendarEventModel();

    // Ensure tha intermediateAreas is an array
    intermediateAreas = Array.isArray(intermediateAreas) ? intermediateAreas : [];

    calendarEventModel.note = dayNotes;
    calendarEventModel.technicianId = this.selectedTechnician.id;
    calendarEventModel.date = this.selectedDate;
    calendarEventModel.intermediateAreas = [...intermediateAreas];

    this.api.scheduler.saveCalendarEvent(calendarEventModel).subscribe(
      response => {
        const eventDetail = response as CalendarEventModel;
        const calendarApi = this.fullCalendarComponent.getApi();
        const events = calendarApi.getEvents();
        const eventImpls = events.find(
          value => value._def.defId === this.eventClickInfo.event._def.defId,
        );

        if (eventImpls) {
          eventImpls.setExtendedProp('eventDetail', eventDetail);
          this.notificationService.success('Save/Update calendar event');
        } else {
          this.notificationService.error('Event not found');
        }
      },
      error => {
        this.notificationService.error(error, Messages.CALENDAR_EVENTS_SAVE_FAILED);
      },
    );
  }

  getBgColor(
    stopCount: number,
    stopsWorkTime: number,
    technician: CalendarTechnicianModel,
    eventDate: Date | string,
  ): string {
    const hasVacation = this.hasCurrentDateVacation(eventDate, technician.vacations);
    const hasDayOff = technician.weeklyOffs.some(
      weeklyOff =>
        (this.isMatchingDate(eventDate, weeklyOff.weekDay) && weeklyOff.timeOff?.wholeDay) ||
        !weeklyOff.timeOff?.timeRange.length,
    );

    if (hasVacation || hasDayOff) {
      return Colors.TECHNICIAN_OFF;
    }
    if (
      (technician.maxStops && technician.maxStops <= stopCount) ||
      (technician.maxWorkHours && technician.maxWorkHours <= stopsWorkTime)
    ) {
      return Colors.TECHNICIAN_STOPS_WORK_CRITICAL;
    }

    if (stopCount === 0) {
      return Colors.TECHNICIAN_STOPS_COUNT_NONE;
    } else if (stopCount <= 4) {
      return Colors.TECHNICIAN_STOPS_COUNT_LOW;
    } else if (stopCount > 4) {
      return Colors.TECHNICIAN_STOPS_COUNT_HIGH;
    }

    return Colors.TECHNICIAN_STOPS_WORK_CRITICAL;
  }

  hideDialog() {
    this.showStopsDialog = false;
  }

  get allTechnicians(): string {
    const events = this.fullCalendarComponent.getApi().getEvents();
    if (!events) {
      return '';
    }

    const technicians = events.reduce<string[]>((acc, event) => {
      const techs = ((event.extendedProps.technicians as TicketTechnician[]) || []).map(tech =>
        tech.personnelCode ? tech.personnelCode : tech.name,
      );
      return acc.concat(techs);
    }, []);

    return technicians.join(', ');
  }

  private subscribeSearchCriteria() {
    this.calendarSearchService
      .getSearchCriteria()
      .pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe(criteria => {
        if (
          this.form.get('area').value ||
          this.form.get('ticketId').value ||
          this.form.get('technician').value ||
          this.form.get('specificDate').value
        ) {
          this.calendarOptions.views['customWeeks'].duration = {weeks: this.INIT_FILTER_SHOW_WEEKS};
          this.searchCriteria = criteria;
          this.loadEventsForCurrentView();
        }
      });
  }

  formatTime(time: Date | string): string {
    const dateObj = typeof time === 'string' ? new Date(time) : time;
    return dateObj.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
  }

  isCurrentDateWithinVacation(
    eventDate: Date | string,
    startDate: string | Date,
    endDate: string | Date,
  ): boolean {
    const event = dayjs(eventDate).startOf('day');
    const start = dayjs(startDate).startOf('day');
    const end = dayjs(endDate).endOf('day');
    return (
      (event.isAfter(start) || event.isSame(start)) && (event.isBefore(end) || event.isSame(end))
    );
  }

  isMatchingDate(eventDate: Date | string, weekDay: WeekDays): boolean {
    const eventDay = dayjs(eventDate).day();
    const daysOfWeek = [
      'SUNDAY',
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
    ];
    return daysOfWeek[eventDay] === weekDay;
  }

  getTruncatedName(areaName: string, maxLength: number = 6): string {
    return areaName.length > maxLength ? areaName.slice(0, maxLength) : areaName;
  }

  hasCurrentDateVacation(eventDate: Date | string, vacations: Vacation[]): boolean {
    return vacations.some(vacation =>
      this.isCurrentDateWithinVacation(eventDate, vacation.startDate, vacation.endDate),
    );
  }

  getTechnicianInfo(technician: Technician): string {
    const {name, phoneNumber, email, speciality, notes, address} = technician;
    return `Name: ${name}\nPhone: ${phoneNumber}\nEmail: ${email}\nSpecialty/Restriction: ${speciality || ' - '}\nNotes: ${notes || ' - '}\nAddress: ${address.country || ' - '}, ${address.city || ' - '}, ${address.street || ' - '}`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.eventsSubject.complete();
  }

  private getBorderColor(
    stops: CalendarTicketModel[],
    isSupportedAreaTechnicianBySpecificDate: boolean,
  ) {
    const areas = this.form.get('area').value as number[];
    const ticketId = +this.form.get('ticketId').value;

    let matchByArea;
    if (areas) {
      matchByArea = stops.some(ticket => {
        return ticket.customer.address?.areas?.some(area => areas.indexOf(area.id) !== -1);
      });
    }

    let matchByTicket;
    if (ticketId) {
      matchByTicket = stops.some(ticket => {
        return ticket.id === ticketId;
      });
    }

    if (matchByTicket) {
      return Colors.BLUE;
    }

    if (matchByArea || isSupportedAreaTechnicianBySpecificDate) {
      return Colors.RED;
    }

    return Colors.TRANSPARENT;
  }

  resetFilter() {
    this.showCalendarEvents = false;
    this.searchCriteria.specificDate = null;
    this.searchCriteria.weekEndDate = null;
    this.searchCriteria.weekStartDate = null;
    const calendarApi = this.fullCalendarComponent.getApi();
    this.calendarStartDate = null;
    this.calendarOptions.views['customWeeks'].duration = {weeks: this.INIT_SHOW_WEEKS};
    this.calendarOptions.visibleRange = this.getVisibleRange(
      calendarApi.getCurrentData().currentDate,
    );
    calendarApi.removeAllEvents();
  }

  searchFilter() {
    if (
      this.form.get('area').value ||
      this.form.get('ticketId').value ||
      this.form.get('technician').value ||
      this.form.get('specificDate').value
    ) {
      this.showCalendarEvents = true;
    }
  }

  public getTooltipText(value: unknown) {
    return value[1].fullName ? value[1].fullName : value[0];
  }
}
