import {Pipe, PipeTransform} from '@angular/core';
import {PaymentModel} from '../../../api/model/Payment.model';
import {PaymentType} from '../../../api/status/payment.type';

@Pipe({
  name: 'netTermTypePipeText',
})
export class NetTermTypePipe implements PipeTransform {
  transform(payment: PaymentModel): any {
    return this.getNetTermTypeText(payment.netTermType);
  }

  getNetTermTypeText(code: string) {
    switch (code) {
      case 'DUE_ON_RECEIPT':
        return PaymentType.DUE_ON_RECEIPT;
      case 'PREPAID_CREDIT':
        return PaymentType.PREPAID_CREDIT;
      case 'CREDIT_CARD_ON_FILE':
        return PaymentType.CREDIT_CARD_ON_FILE;
      case 'PREPAID':
        return PaymentType.CREDIT_CARD_ON_FILE;
      case 'JOB_SITE':
        return PaymentType.JOB_SITE;
      case 'NET_TERM':
        return PaymentType.NET_TERM;
      default:
        return 'Not specified';
    }
  }
}
