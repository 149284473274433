import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SearchQuery} from '../model/shared/Page.model';
import {CommunicationFilterableService} from '../service/communication-filtarable.service';
import {DailyTicketsModel} from '../model/scheduler/daily.tickets.model';
import {CalendarSearchCriteria} from '../../main/schedulerandcalendar/service/calendar-search-criteria';
import {CalendarEventModel} from '../model/scheduler/calendarEventModel';

@Injectable({
  providedIn: 'root',
})
export class SchedulerApi extends CommunicationFilterableService implements SearchQuery {
  protected apiEndpoint = this.env.apiEndpoint + 'scheduler';

  findInRange(
    start: string | Date,
    end: string | Date,
    searchCriteria: CalendarSearchCriteria,
  ): Observable<DailyTicketsModel[]> {
    const url = `${this.apiEndpoint}/list`;
    return this.requestService
      .post(url, {
        ticket: searchCriteria.ticketId,
        areas: searchCriteria.area,
        technicians: searchCriteria.technician,
        start,
        end,
      })
      .pipe(
        map(response => response.response as DailyTicketsModel[]),
        catchError(this.handleError),
      );
  }

  saveCalendarEvent(saveCalendarEvent: CalendarEventModel) {
    const url = `${this.apiEndpoint}/event`;
    return this.requestService.post(url, saveCalendarEvent).pipe(catchError(this.handleError));
  }
}
