import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {Page} from '../../../../api/model/shared/Page.model';
import {RouteSearchService} from '../../services/route-search.service';
import {RouteFilter} from '../../../../api/model/route/route.filter';

@Component({
  selector: 'tj-route-list-filter',
  templateUrl: './route-list-filter.component.html',
  styleUrls: ['./route-list-filter.component.scss'],
})
export class RouteListFilterComponent implements OnInit {
  @Input() routeFilter: RouteFilter;

  @Input() page: Page<any>;

  filterForm: UntypedFormGroup;

  constructor(private routeSearchService: RouteSearchService) {}

  ngOnInit() {
    this.filterForm = new UntypedFormGroup({});
  }

  onSearchReset(): void {
    this.filterForm.reset();
    this.routeSearchService.reset();
  }

  onRemoveFilter(routeFilter): void {
    this.routeSearchService.search(routeFilter);
  }

  onFilterRoutes(): void {
    const formValue = this.filterForm.value;
    if (this.filterForm.valid) {
      this.routeSearchService.searchByFormValue(formValue.routeFilter);
    } else {
      FormHelpers.validateAllFormFields(this.filterForm);
    }
  }
}
