import {TechnicianMinimalListItem} from '../../../../api/model/TechnicianMinimalListItem';
import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ConfigService, NotificationService} from '../../../../core/services';
import {ContactType} from '../../enums/contact.type';

@Component({
  selector: 'tj-technician-combobox',
  templateUrl: './technician-combobox.component.html',
  styleUrls: ['./technician-combobox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TechnicianComboboxComponent),
      multi: true,
    },
  ],
})
export class TechnicianComboboxComponent implements OnInit, ControlValueAccessor {
  protected readonly TechnicianMinimalListItem = TechnicianMinimalListItem;

  @Output() technicianChange = new EventEmitter();

  @Input() disabled: boolean;

  @Input() showMarker = false;
  @Input() showInfo = false;

  @Input() showSpeciality = false;

  @Input() areas: string[] = [];
  @Input() forceShowTechnicianId: number;
  @Input() forceShow = false;
  @Input() supportedTechnicianByAreaAndDate: number[] = [];

  allTechnicians: TechnicianMinimalListItem[] = [];
  filteredTechnicians;

  private onTouchedCallback: Function;
  private onChangeCallback: Function;
  private innerValue = null;

  constructor(
    public configService: ConfigService,
    private notificationService: NotificationService,
  ) {}

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    this.innerValue = v;
    if (this.onChangeCallback) {
      const finalValue = v && v.id;
      this.onChangeCallback(finalValue);
    }
  }

  ngOnInit() {
    this.configService.technicians$.subscribe((value: TechnicianMinimalListItem[]) => {
      this.allTechnicians = value.map(tech => ({
        ...tech,
        displayName: `${tech.name}${tech.companyName ? ' / ' + tech.companyName : ''}`,
      }));
    });
  }

  getTechnicianInfo(): string {
    if (!this.value) {
      return '';
    }

    const {name, phoneNumber, email, speciality, notes, address, contactPersons} = this.value;

    let contactsInfo = '';
    if (contactPersons?.length) {
      contactsInfo = contactPersons.map(contact => {
        const contactDetails = contact.contacts?.length
          ? contact.contacts.map(c => `- - ${this.getContactTypeName(c.type)} : ${c.value}`).join('\n')
          : 'No contacts available';
        return `- ${contact.name || 'Unknown'} :\n${contactDetails}`;
      }).join('\n');
    }

    return `Name: ${name}\nPhone: ${phoneNumber}\nEmail: ${email}\nSpecialty/Restriction: ${speciality || ' - '}` +
      `\nNotes: ${notes || ' - '}\nAddress: ${address?.country || ' - '}, ${address?.city || ' - '}, ${address?.street || ' - '}` +
      `\nAdditional Contacts:\n ${contactsInfo || 'Not set'}`;
  }

  getContactTypeName(type: string): string {
    switch (type) {
      case ContactType.EMAIL:
        return 'E-mail';
      case ContactType.MOBILE_PHONE:
        return 'Mobile';
      case ContactType.PHONE:
        return 'Cell line';
      case ContactType.FAX:
        return 'Fax';
      default:
        return type;
    }
  }

  onCopySuccess(event: boolean) {
    if (event) {
      this.notificationService.info('Technician Email is copied.');
    } else {
      this.notificationService.error('Failed to copy.');
    }
  }

  onTechnicianChange(value) {
    this.technicianChange.emit(value);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  writeValue(id: any): void {
    this.innerValue = this.allTechnicians.find((p: TechnicianMinimalListItem) => p.id === id);
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onFilterSuggestions(event) {
    const query = event.query?.toLowerCase();
    const technicians = this.allTechnicians;
    let filtered: any[] = [];
    for (const technician of technicians) {
      const {name, personnelCode, companyName} = technician;

      // Check if query matches name, personnelCode, or companyName
      const matchesName = name?.toLowerCase().includes(query);
      const matchesPersonnelCode = personnelCode?.toLowerCase().includes(query);
      const matchesCompanyName = companyName?.toLowerCase().includes(query);

      if (matchesName || matchesPersonnelCode || matchesCompanyName) {
        filtered.push(technician);
      }
    }
    if (this.forceShow) {
      filtered = filtered.filter(technician => {
        return (
          technician.visibleInsideTicket ||
          (this.forceShowTechnicianId &&
            this.forceShowTechnicianId === technician.id &&
            !technician.visibleInsideTicket)
        );
      });
    }
    if (this.configService.isEnabledTerritory()) {
      const areas = this.areas || [];
      filtered.sort((techA, techB) => this.sortTechniciansByAreaCode(techA, techB, areas));

      if (this.supportedTechnicianByAreaAndDate) {
        const supportedTech = this.supportedTechnicianByAreaAndDate.map(id =>
          technicians.find(tech => tech.id === id),
        );
        filtered = [
          ...supportedTech,
          ...filtered.filter(tech => !this.supportedTechnicianByAreaAndDate.includes(tech.id)),
        ];
      }
    }
    this.filteredTechnicians = filtered;
  }

  hasAreaCode(value: TechnicianMinimalListItem) {
    const areas = this.areas || [];
    return value.areas?.find(area => areas.find(searchArea => area.code === searchArea));
  }

  hasSupportedArea(technicianId) {
    return this.supportedTechnicianByAreaAndDate?.includes(technicianId);
  }

  // Function to sort technicians based on area code
  private sortTechniciansByAreaCode(techA, techB, targetAreaCodes) {
    // Find the area codes for each technician
    const techAHasSpecifiedAreaCode = targetAreaCodes?.some(code =>
      techA.areas?.some(area => area.code === code),
    );
    const techBHasSpecifiedAreaCode = targetAreaCodes?.some(code =>
      techB.areas?.some(area => area.code === code),
    );

    // Handle the case when the area code is not found in either technician's areas
    if (!techAHasSpecifiedAreaCode && !techBHasSpecifiedAreaCode) {
      return 0; // No change in order
    } else if (!techAHasSpecifiedAreaCode) {
      return 1; // Move techA to a higher index
    } else if (!techBHasSpecifiedAreaCode) {
      return -1; // Move techB to a higher index
    }

    // Compare the area codes
    // Here, you might need to define the logic for comparing multiple area codes
    // For simplicity, let's assume we compare the first found area code in each technician's list
    const techAFirstAreaCode = techA.areas.find(area => targetAreaCodes.includes(area.code));
    const techBFirstAreaCode = techB.areas.find(area => targetAreaCodes.includes(area.code));
    return techAFirstAreaCode.code.localeCompare(techBFirstAreaCode.code);
  }

  get addStyleClass(): string {
    return this.value ? 'selected-item-bold' : '';
  }

  onCopyTechnicianCode(event: boolean) {
    if (event) {
      this.notificationService.copied(': Technician personal code');
    } else {
      this.notificationService.copyFailed();
    }
  }
}
