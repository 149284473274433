import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {Page, PageQuery, SortQuery} from '../../../../api/model/shared/Page.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PayFormList} from '../../models/pay-form-list.model';
import {PayFormSearchService} from '../../services/pay-form-search.service';
import {ConfigService, NotificationService} from '../../../../core/services';
import {Messages} from '../../../../common/messages';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'tj-payforms',
  templateUrl: './payforms.component.html',
  styleUrls: ['./payforms.component.scss'],
})
export class PayFormsComponent implements OnInit {
  protected readonly env = environment;

  page: Page<PayFormList>;

  payForms: PayFormList[];

  private pageQuery: PageQuery = {
    size: this.env.rowsPerPage[0],
    number: 0,
  };

  private sortQuery: SortQuery = {
    property: 'status',
    direction: 'desc',
  };

  searchFilter;

  private searchQuery;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    public configService: ConfigService,
    private notificationService: NotificationService,
    private payFormSearchService: PayFormSearchService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.payFormSearchService.listenQueryParams();
    this.payFormSearchService.searchData.subscribe(({filters, searchQuery}) => {
      this.searchFilter = filters;
      this.searchQuery = searchQuery;
    });
    this.route.queryParams.subscribe((params: Params) => {
      this.pageQuery.number = params.page || 0;
      this.pageQuery.size = params.size || this.env.rowsPerPage[0];
      this.getPayForms();
    });
  }

  onGoToPage(event) {
    this.router.navigate([], {
      queryParams: {page: event.page, size: event.rows},
      queryParamsHandling: 'merge',
    });
  }

  onCreateNewPayForm() {
    this.router.navigate(['payforms', 'new']);
  }

  sort($event) {
    this.sortQuery = {
      property: $event.field,
      direction: $event.order === -1 ? 'desc' : 'asc',
    };
    this.router.navigate([], {queryParams: this.sortQuery, queryParamsHandling: 'merge'});
  }

  updateList() {
    this.getPayForms();
    // this.selectedPayForm = null;
  }

  onTableRowSelect(event): void {
    this.router.navigate(['payforms', event.data.id]);
  }

  private getPayForms(): void {
    this.api.payForm.findAll(this.pageQuery, this.sortQuery, this.searchQuery).subscribe(
      (payFormsPage: Page<PayFormList>) => {
        this.page = payFormsPage;
        this.payForms = payFormsPage.response;
      },
      err => {
        this.notificationService.error(Messages.PAY_FORM_GET_ALL);
      },
    );
  }
}
