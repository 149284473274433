import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Comment} from '../../../../api/model/TicketComments.model';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {BaseFormGroup} from '../../../shared/utils/base-form-group';

@Component({
  selector: 'tj-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss'],
})
export class CommentFormComponent extends BaseFormGroup implements OnChanges {
  @Input() comment: Comment;
  @Input() form: UntypedFormGroup;
  @Input() group: string;
  @Output('onSaveComment') saveCommentEmitter: EventEmitter<any> = new EventEmitter();

  private formControls;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.comment.firstChange || this.comment !== changes.comment.previousValue) {
      const comment = this.comment;
      this.formControls = {
        message: new UntypedFormControl(comment.message || ''),
        chatId: new UntypedFormControl(comment.chatId),
        userId: new UntypedFormControl(this.comment.userId),
      };

      this.buildForm(this.form, this.formControls, this.group);
      this.defineGetters(this.formControls);
    }
  }

  handleKeyDown(event: KeyboardEvent): void {
    if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      this.saveCommentEmitter.emit();
      event.preventDefault();
    }
  }
}
