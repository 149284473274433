import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PaymentFlowService {
  constructor() {}

  private order = null;

  setOrder(order) {
    this.order = order;
  }

  getOrder() {
    return this.order;
  }
}
