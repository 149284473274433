<div class="grid" *ngIf="page">
  <div class="col-12 card">
    <tj-invoice-search [filter]="invoiceFilter"></tj-invoice-search>
    <p-toolbar styleClass="my-4">
      <ng-template pTemplate="left">
        <p-dropdown
          (onChange)="setBatchAction($event)"
          [options]="batchActions"
          placeholder="Select an action"
        ></p-dropdown>
      </ng-template>
    </p-toolbar>
    <div class="grid">
      <div class="col-12">
        <tj-invoice-list
          #invoicesListComponent
          (onSort)="sort($event)"
          [invoices]="invoices"
        ></tj-invoice-list>
      </div>
      <div class="col-12">
        <p-paginator
          [rows]="pageQuery.size"
          [rowsPerPageOptions]="env.extendedRowsPerPage"
          [totalRecords]="page.totalElements"
          (onPageChange)="onGoToPage($event)"
          [first]="pageQuery.number * pageQuery.size"
        ></p-paginator>
      </div>
    </div>
  </div>
</div>
