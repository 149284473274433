<div [formGroup]="currentFormGroup" class="grid align-items-center">
  <div class="lg:col-4 sm:col-12 col-12">
    <tj-field label="ZipCode" [control]="fcZipCode">
      <input pInputText placeholder="Zip Code" formControlName="zipCode" />
    </tj-field>
  </div>
  <div class="lg:col-4 sm:col-12 col-12">
    <tj-field label="TravelFee" [control]="fcTravelFee">
      <input pInputText placeholder="Travel Fee" formControlName="travelFee" />
    </tj-field>
  </div>
  <div class="lg:col-4 sm:col-12 col-12">
    <tj-field label="AreaCode" [control]="fcAreaCode">
      <input pInputText placeholder="Area Code" formControlName="areaCode" />
    </tj-field>
  </div>
</div>
