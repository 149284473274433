<p-panel [toggleable]="true" header="Filters">
  <form
    (ngSubmit)="onFilter()"
    (reset)="onSearchReset()"
    [formGroup]="filterForm"
    autocomplete="off"
    novalidate
  >
    <div class="grid">
      <div class="col-12">
        <tj-technician-search-form
          [filter]="filter"
          [form]="filterForm"
          group="technicianFilter"
        ></tj-technician-search-form>
      </div>
      <div class="col-12 py-0 flex">
        <tj-technician-search-labels
          (remove)="onRemoveFilter($event)"
          [filterForm]="filterForm"
          [technicianFilter]="filter"
        ></tj-technician-search-labels>
      </div>
      <div class="col-12 align-content-end flex justify-content-between">
        <div class="flex align-items-end">
          <span *ngIf="page">Total: {{ page.totalElements }}</span>
        </div>
        <div>
          <button class="p-button-secondary mr-2" label="Reset" pButton type="reset"></button>
          <button icon="pi pi-search" label="Search" pButton type="submit"></button>
        </div>
      </div>
    </div>
  </form>
</p-panel>
