import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {TicketStatus} from '../../api/status/ticketStatus';
import {ApiService} from '../../api/service/api.service';
import {NettermTypeModel} from '../../api/model/Netterm.model';
import {BehaviorSubject, of, zip} from 'rxjs';
import {DropdownModel} from '../../api/model/shared/dropdown.model';
import {TicketPartnerStatus} from '../../api/status/ticket-partner.status';
import {TechnicianMinimalListItem} from '../../api/model/TechnicianMinimalListItem';
import {PartnerMinimalListItem} from '../../api/model/PartnerLine.model';
import {PayFormDateRangeType} from '../../main/shared/enums/payFormDateRange.type';
import {ContactType} from '../../main/shared/enums/contact.type';
import {PartnerType} from '../../main/shared/enums/partner.type';
import {PayFormStatus} from '../../main/shared/enums/payForm.status';
import {Partner} from '../../api/model/Partner.model';
import {Technician} from '../../api/model/Technician.model';
import {Config} from '../../api/model/Config.model';
import {PayType} from '../../main/shared/enums/pay.type';
import {TjKeycloakService} from './tj-keycloak.service';
import {Tag} from '../../api/model/Tag.model';
import {NotificationService} from './notification.service';
import {Area} from '../../api/model/Area.model';
import {ClientSignture} from '../../api/model/ticket/client-signature';
import {PaymentConfigModel} from '../../api/model/Payment.config.model';

@Injectable()
export class ConfigService {
  private clientSignature: ClientSignture;
  public readonly invoiceStatuses$: BehaviorSubject<DropdownModel[]> = new BehaviorSubject<
    DropdownModel[]
  >([]);
  public readonly nettermsTypes$: BehaviorSubject<NettermTypeModel[]> = new BehaviorSubject<
    NettermTypeModel[]
  >([]);
  public readonly tags$: BehaviorSubject<Tag[]> = new BehaviorSubject<Tag[]>([]);
  public readonly areas$: BehaviorSubject<Area[]> = new BehaviorSubject<Area[]>([]);
  public readonly technicians$: BehaviorSubject<TechnicianMinimalListItem[]> = new BehaviorSubject<
    TechnicianMinimalListItem[]
  >([]);
  public readonly globalConfigs$: BehaviorSubject<Config[]> = new BehaviorSubject<Config[]>([]);
  public readonly partners$: BehaviorSubject<PartnerMinimalListItem[]> = new BehaviorSubject<
    PartnerMinimalListItem[]
  >([]);
  public readonly partnerTypes$: BehaviorSubject<DropdownModel[]> = new BehaviorSubject<
    DropdownModel[]
  >([]);
  public readonly ticketStatuses$: BehaviorSubject<DropdownModel[]> = new BehaviorSubject<
    DropdownModel[]
  >([]);

  public readonly dateFormat: string = environment.dateFormat;
  public readonly dateFormatFull: string = environment.dateFormatFull;
  public readonly dateTimeOnly: string = environment.dateTimeOnly;
  public readonly datePickerOptions = environment.datePickerOptions;
  public readonly money = environment.money;
  public readonly ticketEstimations = environment.ticketEstimations;
  public readonly defaultTicketEstimation = environment.defaultTicketEstimation;
  public readonly ticketStatusMap: DropdownModel[] = [
    {label: 'New', value: TicketStatus.NEW},
    {label: 'Assigned', value: TicketStatus.ASSIGNED},
    {label: 'Scheduled', value: TicketStatus.SCHEDULED},
    {label: 'Complete', value: TicketStatus.COMPLETED},
    {label: 'Verified', value: TicketStatus.VERIFIED},
    {label: 'Rescheduled', value: TicketStatus.RESCHEDULE},
    {label: 'Contacted / Waiting', value: TicketStatus.CUSTOMER_WILL_CALL},
    {label: 'Invoiced', value: TicketStatus.INVOICED},
    {label: 'Canceled', value: TicketStatus.CANCELED},
    {label: 'Incomplete', value: TicketStatus.INCOMPLETE},
  ];

  private readonly nettermsTypes: NettermTypeModel[] = [
    {private: false, netTermType: 'DUE_ON_RECEIPT', name: 'Due on receipt'},
    {private: false, netTermType: 'PREPAID_CREDIT', name: 'Prepaid credit'},
    {private: false, netTermType: 'CREDIT_CARD_ON_FILE', name: 'Credit card on file'},
    {private: false, netTermType: 'PREPAID', name: 'Prepaid'},
    {private: false, netTermType: 'JOB_SITE', name: 'Job site'},
    {private: false, netTermType: 'NET_TERM', name: 'Net Term'},
  ];

  private readonly invoiceStatuses: DropdownModel[] = [
    {label: 'Estimate', value: 'ESTIMATE'},
    {label: 'Overdue', value: 'OVERDUE'},
    {label: 'Paid', value: 'PAID'},
    {label: 'Prepaid', value: 'PREPAID'},
    {label: 'Unpaid', value: 'UNPAID'},
    {label: 'Void', value: 'VOID'},
  ];

  public readonly paymentTermMap: DropdownModel[] = [
    {label: 'Due on receipt', value: 'DUE_ON_RECEIPT'},
    {label: 'Prepaid credit', value: 'PREPAID_CREDIT'},
    {label: 'Credit card on file', value: 'CREDIT_CARD_ON_FILE'},
    {label: 'Prepaid', value: 'PREPAID'},
    {label: 'Job site', value: 'JOB_SITE'},
    {label: 'Net Term', value: 'NET_TERM'},
  ];

  payFormStatuses: DropdownModel[] = [
    {label: 'Open', value: PayFormStatus.OPEN},
    {label: 'Closed', value: PayFormStatus.CLOSED},
  ];
  payFormDateRangeTypes: DropdownModel[] = [{label: 'Weekly', value: PayFormDateRangeType.WEEKLY}];
  payTypes: DropdownModel[] = [
    {label: 'Commission based', value: PayType.COMMISSION_BASED},
    {label: 'Hour/Unit based', value: PayType.HOUR_BASED},
  ];

  times: DropdownModel[] = [
    {label: '12:00AM', value: '12:00AM'},
    {label: '12:30AM', value: '12:30AM'},
    {label: '1:00AM', value: '1:00AM'},
    {label: '1:30AM', value: '1:30AM'},
    {label: '2:00AM', value: '2:00AM'},
    {label: '2:30AM', value: '2:30AM'},
    {label: '3:00AM', value: '3:00AM'},
    {label: '3:30AM', value: '3:30AM'},
    {label: '4:00AM', value: '4:00AM'},
    {label: '4:30AM', value: '4:30AM'},
    {label: '5:00AM', value: '5:00AM'},
    {label: '5:30AM', value: '5:30AM'},
    {label: '6:00AM', value: '6:00AM'},
    {label: '6:30AM', value: '6:30AM'},
    {label: '7:00AM', value: '7:00AM'},
    {label: '7:30AM', value: '7:30AM'},
    {label: '8:00AM', value: '8:00AM'},
    {label: '8:30AM', value: '8:30AM'},
    {label: '9:00AM', value: '9:00AM'},
    {label: '9:30AM', value: '9:30AM'},
    {label: '10:00AM', value: '10:00AM'},
    {label: '10:30AM', value: '10:30AM'},
    {label: '11:00AM', value: '11:00AM'},
    {label: '11:30AM', value: '11:30AM'},
    {label: '12:00PM', value: '12:00PM'},
    {label: '12:30PM', value: '12:30PM'},
    {label: '1:00PM', value: '1:00PM'},
    {label: '1:30PM', value: '1:30PM'},
    {label: '2:00PM', value: '2:00PM'},
    {label: '2:30PM', value: '2:30PM'},
    {label: '3:00PM', value: '3:00PM'},
    {label: '3:30PM', value: '3:30PM'},
    {label: '4:00PM', value: '4:00PM'},
    {label: '4:30PM', value: '4:30PM'},
    {label: '5:00PM', value: '5:00PM'},
    {label: '5:30PM', value: '5:30PM'},
    {label: '6:00PM', value: '6:00PM'},
    {label: '6:30PM', value: '6:30PM'},
    {label: '7:00PM', value: '7:00PM'},
    {label: '7:30PM', value: '7:30PM'},
    {label: '8:00PM', value: '8:00PM'},
    {label: '8:30PM', value: '8:30PM'},
    {label: '9:00PM', value: '9:00PM'},
    {label: '9:30PM', value: '9:30PM'},
    {label: '10:00PM', value: '10:00PM'},
    {label: '10:30PM', value: '10:30PM'},
    {label: '11:00PM', value: '11:00PM'},
    {label: '11:30PM', value: '11:30PM'},
  ];
  contactTypes: DropdownModel[] = [
    {label: 'E-mail', value: ContactType.EMAIL},
    {label: 'Land line', value: ContactType.PHONE},
    {label: 'Cell phone', value: ContactType.MOBILE_PHONE},
    {label: 'Fax', value: ContactType.FAX},
  ];
  ticketPartnerStatuses: DropdownModel[] = [
    {label: 'Private', value: TicketPartnerStatus.PRIVATE},
    {label: 'Registered', value: TicketPartnerStatus.REGISTERED},
    {label: 'Private business', value: TicketPartnerStatus.PRIVATE_BUSINESS},
  ];
  partnerTypes: DropdownModel[] = [
    {label: 'Anonymous', value: PartnerType.ANONYMOUS},
    {label: 'Designer', value: PartnerType.DESIGNER},
    {label: 'Hospitality', value: PartnerType.HOSPITALITY},
    {label: 'Manufacture', value: PartnerType.MANUFACTURE},
    {label: 'Moving', value: PartnerType.MOVING},
    {label: 'Protection', value: PartnerType.PROTECTION},
    {label: 'Retail', value: PartnerType.RETAIL},
    {label: 'Warehouse / Delivery', value: PartnerType.WAREHOUSE_DELIVERY},
    {label: 'Warranty', value: PartnerType.WARRANTY},
  ];

  ticketPartner: DropdownModel[] = [
    {label: 'Customer', value: TicketPartnerStatus.PRIVATE},
    {label: 'Partner', value: TicketPartnerStatus.BUSINESS},
  ];

  constructor(
    private api: ApiService,
    private keycloakService: TjKeycloakService,
    private notificationService: NotificationService,
  ) {
    this.partnerTypes$.next(this.partnerTypes);
    this.nettermsTypes$.next(this.nettermsTypes);
    this.invoiceStatuses$.next(this.invoiceStatuses);
  }

  findTicketStatusByCode(status: TicketStatus): string {
    const dropdownModel: DropdownModel = this.ticketStatusMap.find((value: DropdownModel) => {
      return value.value === status;
    });
    return dropdownModel ? dropdownModel.label : 'Not defined';
  }

  public preloadGlobalData(): Promise<boolean> {
    const loadTechniciansList = () => {
      if (this.keycloakService.hasRole('TECHNICIAN_VIEW')) {
        return this.api.technician.findList();
      } else {
        return of([]);
      }
    };

    const loadAreaList = () => {
      return this.api.area.findList();
    };

    const loadPartnersList = () => {
      if (this.keycloakService.hasRole('PARTNER_VIEW')) {
        return this.api.partner.findList();
      } else if (this.keycloakService.hasRole('TICKET_CREATE_AUTO_ASSIGNED_PARTNER')) {
        return this.api.partner.getBaseMe();
      } else {
        return of([]);
      }
    };

    const loadGlobalConfigs = () => {
      if (this.keycloakService.hasRole('GLOBAL_CONFIG_VIEW')) {
        return this.api.globalConfig.findAll();
      } else {
        return of([]);
      }
    };

    return new Promise((resolve, reject) => {
      zip(
        this.api.tag.findList(),
        loadTechniciansList(),
        loadAreaList(),
        loadPartnersList(),
        this.api.ticket.findStatuses(),
        loadGlobalConfigs(),
      ).subscribe(
        ([tags, technicians, areas, partners, ticketStatusesResponse, globalConfigs]) => {
          this.technicians$.next(technicians);
          this.areas$.next(areas);
          this.globalConfigs$.next(globalConfigs);
          this.setTicketStatuses(ticketStatusesResponse.response);
          this.partners$.next(partners);
          this.tags$.next(tags);
          resolve(true);
        },
        err => {
          console.log(err);
          reject(err);
        },
      );
    });
  }

  private setTicketStatuses(statuses: DropdownModel[]) {
    this.ticketStatuses$.next(statuses);
  }

  getTechnicianDataById(technicianId) {
    const value = this.technicians$.getValue();
    const techniciansMap = {};
    value.reduce((acc, technician) => {
      acc[technician.id] = technician;
      return acc;
    }, techniciansMap);
    return techniciansMap[technicianId];
  }

  updatePartnersList(partner: Partner): void {
    const listItems = this.partners$.getValue();
    let partnerMinimalListItem: PartnerMinimalListItem;
    const index = listItems.findIndex(p => p.id === partner.id);
    if (index < 0) {
      partnerMinimalListItem = new PartnerMinimalListItem();
      partnerMinimalListItem.id = partner.id;
      listItems.unshift(partnerMinimalListItem);
    } else {
      partnerMinimalListItem = listItems[index];
    }
    partnerMinimalListItem.id = partner.id;
    partnerMinimalListItem.name = partner.name;
    partnerMinimalListItem.isPrivate = partner.isPrivate;
    listItems.sort((a, b) => a.name.localeCompare(b.name));

    // private partner has index: -1, it should be at first place
    const specialItemIndex = listItems.findIndex(p => p.id === -1);
    if (specialItemIndex > 0) {
      const [specialItem] = listItems.splice(specialItemIndex, 1);
      listItems.unshift(specialItem);
    }

    this.partners$.next(listItems);
  }

  recoverTechnicianOnList(technician: TechnicianMinimalListItem) {
    const listItems = this.technicians$.getValue();
    listItems.unshift(technician);
    this.technicians$.next(listItems);
  }

  removeTechnicianOnList(technicianId: number) {
    const listItems = this.technicians$.getValue();
    const updatedList = listItems.filter(tech => tech.id !== technicianId);
    this.technicians$.next(updatedList);
  }

  updateTechniciansList(technician: Technician): void {
    const listItems = this.technicians$.getValue();
    let technicianMinimalListItem: TechnicianMinimalListItem;
    const findTechIndex = listItems.findIndex(tech => tech.id === technician.id);
    if (findTechIndex < 0) {
      technicianMinimalListItem = new TechnicianMinimalListItem();
      technicianMinimalListItem.id = technician.id;
      listItems.unshift(technicianMinimalListItem);
    } else {
      technicianMinimalListItem = listItems[findTechIndex];
    }
    technicianMinimalListItem.name = technician.name;
    technicianMinimalListItem.active = technician.active;
    technicianMinimalListItem.percent = technician.percent;
    technicianMinimalListItem.unit = technician.unit;
    technicianMinimalListItem.payType = technician.payType;
    technicianMinimalListItem.speciality = technician.speciality;
    technicianMinimalListItem.maxStops = technician.maxStops;
    technicianMinimalListItem.maxWorkHours = technician.maxWorkHours;
    technicianMinimalListItem.weeklyOffs = technician.weeklyOffs;
    technicianMinimalListItem.vacations = technician.vacations;
    technicianMinimalListItem.areas = technician.areas;
    technicianMinimalListItem.visibleInsideTicket = technician.visibleInsideTicket;
    technicianMinimalListItem.showOnCalendar = technician.showOnCalendar;
    this.technicians$.next(listItems);
  }

  reloadTagsList() {
    this.api.tag.findList().subscribe(
      tags => {
        this.tags$.next(tags);
      },
      () => {
        this.notificationService.error('Cannot reload tags list');
      },
    );
  }

  reloadAreas() {
    this.api.area.findList().subscribe(
      areas => {
        this.areas$.next(areas);
      },
      () => {
        this.notificationService.error('Cannot reload areas list');
      },
    );
  }

  isConfigEnabled(configName: any): boolean {
    const globalConfig = this.globalConfigs$.getValue().find(config => config.code === configName);
    return globalConfig?.value === 'true';
  }

  getHeaderInfo(): {code: string; value: string}[] {
    const headerInfo: {code: string; value: string}[] = [];
    const globalConfigs = this.globalConfigs$.getValue();
    const mapping = {
      COMPANY_NAME: 'NAME',
      COMPANY_PHONE: 'PHONE',
      COMPANY_EMAIL: 'EMAIL',
      COMPANY_LOGO: 'LOGO',
    };

    globalConfigs.forEach(config => {
      if (mapping[config.code]) {
        headerInfo.push({
          code: mapping[config.code],
          value: config.value || '',
        });
      }
    });

    return headerInfo;
  }

  getClientZoneTimeConfig(): number {
    const globalConfig = this.globalConfigs$
      .getValue()
      .find(config => config.code === 'DEFAULT_CLIENT_TIMEZONE');
    return +globalConfig?.value || 0;
  }

  getClientZoneTimeLabelConfig(): string {
    const globalConfig = this.globalConfigs$
      .getValue()
      .find(config => config.code === 'DEFAULT_CLIENT_TIMEZONE_LABEL');
    return globalConfig?.value || 'GMC';
  }

  generateClientSignature() {
    this.clientSignature = ClientSignture.generateSignature();
  }

  getClientSignature(): ClientSignture {
    return this.clientSignature;
  }

  isEnabledWarranty(): boolean {
    return this.isConfigEnabled('WARRANTY_MANAGEMENT_ENABLED');
  }

  isEnabledTerritory(): boolean {
    return this.isConfigEnabled('TERRITORY_MANAGEMENT_ENABLED');
  }

  allowedToSeeAreas(): boolean {
    return this.keycloakService.hasRole('TERRITORY_MANAGEMENT_VIEW');
  }

  getPaypalClientId(isSandbox = true) {
    const globalConfig = this.globalConfigs$.getValue().find(config => {
      if (isSandbox) {
        return config.code === 'PAYMENT_PAYPAL_SANDBOX_CLIENT_ID';
      }
      return config.code === 'PAYMENT_PAYPAL_PRODUCTION_CLIENT_ID';
    });
    return globalConfig?.value;
  }

  getPrivateAccountDefaultCCFeeApplyConfig(): boolean {
    const globalConfig = this.globalConfigs$
      .getValue()
      .find(config => config.code === 'PRIVATE_PARTNER_CC_FEE_CHECKED');
    return globalConfig?.value === 'true';
  }

  getPrivateAccountDefaultNetTerm(): number {
    const globalConfig = this.globalConfigs$
      .getValue()
      .find(config => config.code === 'NET_TERM_PRIVATE');
    return +globalConfig?.value;
  }

  getRegisteredAccountDefaultNetTerm(): number {
    const globalConfig = this.globalConfigs$
      .getValue()
      .find(config => config.code === 'NET_TERM_REGISTERED');
    return +globalConfig?.value;
  }

  isSandbox() {
    return this.isConfigEnabled('PAYMENT_PAYPAL_SANDBOX');
  }

  getPaymentConfig(): PaymentConfigModel {
    const paymentConfig = new PaymentConfigModel(0, 0);

    this.globalConfigs$.getValue().forEach(config => {
      switch (config.code) {
        case 'PAYMENT_TAX_RATE':
          paymentConfig.taxRate = parseFloat(config.value);
          break;
        case 'PAYMENT_CREDIT_CARD_FEE':
          paymentConfig.creditCardFee = parseFloat(config.value);
          break;
      }
    });

    return paymentConfig;
  }
}
