import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {DocumentRequestModel} from '../../models/ImageRequest.model';
import {ProgressBarService} from '../../../../core/services/progressbar.service';
import {jsUtils} from '../../utils/Utils';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import downloadFile = jsUtils.downloadFile;

@Component({
  selector: 'tj-attachment-viewer',
  templateUrl: './attachment-viewer.component.html',
  styleUrls: ['./attachment-viewer.component.scss'],
})
export class AttachmentViewerComponent implements OnInit, OnChanges, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @Input() galleryId: number;
  @Input() ticketId: number;
  @Input() showStopDocuments = false;
  @Output() hideViewDocumentDialog = new EventEmitter<any>();

  attachments: any[] = [];
  document: DocumentRequestModel;
  displayPdfDialog = false;

  selectedDocuments: any[] = [];
  allSelected = false;
  isLoading = false;

  constructor(
    private apiService: ApiService,
    private progressBarService: ProgressBarService,
  ) {}

  ngOnInit() {
    this.viewDocuments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ticketId'] || changes['galleryId'] || changes['showStopDocuments']) {
      this.viewDocuments();
    }
  }

  viewDocuments() {
    if (this.galleryId && this.ticketId && this.showStopDocuments) {
      this.apiService.gallery
        .getDocuments(this.galleryId, this.ticketId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(responseModel => {
          this.attachments = responseModel.attachments;
          this.attachments = this.attachments.map(doc => ({
            ...doc,
            selected: false,
          }));
        });
    }
  }

  viewPdf(document: DocumentRequestModel): void {
    if (!document?.url) {
      console.error('Document URL is missing');
      return;
    }
    this.document = document;
    this.displayPdfDialog = true;
  }

  downloadSelected(): void {
    this.selectedDocuments.forEach(document => {
      const documentName: string = document.fileName + '_' + document.index + document.extension;
      downloadFile(document.url, documentName, this.progressBarService);
    });
  }

  download(fileType: AllowedFileTypes, document: DocumentRequestModel, index: number) {
    const documentName: string = document.fileName + '_' + index + document.extension;
    downloadFile(document.url, documentName, this.progressBarService);
  }

  toggleAllSelection(selectAll: boolean) {
    this.allSelected = selectAll;
    this.attachments = this.attachments.map(doc => ({
      ...doc,
      selected: selectAll,
    }));
    this.selectedDocuments = selectAll ? [...this.attachments] : [];
  }

  unselectAll() {
    this.selectedDocuments = [];
    this.attachments = this.attachments.map(doc => ({
      ...doc,
      selected: false,
    }));
    this.allSelected = false;
  }

  select(document: any, event: any, index: number) {
    document.selected = event.checked;
    if (document.selected) {
      document.index = index;
      this.selectedDocuments.push(document);
    } else {
      this.selectedDocuments = this.selectedDocuments.filter(doc => doc.id !== document.id);
    }
  }

  loading(event) {
    this.isLoading = event.loaded < event.total;
  }

  onPdfError(error: any): void {
    console.error('PDF viewer encountered an error:', error);
  }

  hideDialog() {
    this.showStopDocuments = false;
    this.selectedDocuments = [];
    this.allSelected = false;
    this.ticketId = null;
    this.galleryId = null;
    this.hideViewDocumentDialog.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
