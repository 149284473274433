import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {Invoice} from '../../../../api/model/Invoice.model';
import {LayoutService} from '../../../../layout/service/app.layout.service';
import {ConfigService, TjKeycloakService} from '../../../../core/services';
import {ContactInfoList} from '../../../../api/model/ContactValueList.model';
import {map, switchMap} from 'rxjs/operators';
import {InvoiceService} from '../../service/invoice.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'tj-invoice-print',
  templateUrl: './invoice-print.component.html',
  styleUrls: ['./invoice-print.component.scss'],
})
export class InvoicePrintComponent implements OnInit {
  @Input() invoiceId: number;

  @Input() configs: {[key: string]: string};

  @Input() pagebreak = true;

  invoice: Invoice;

  partnerInvoiceReceiverContacts: ContactInfoList[];

  payInstruction: string;

  estimateInstruction: string;

  instruction: string;

  constructor(
    protected invoiceService: InvoiceService,
    private api: ApiService,
    public configService: ConfigService,
    public layoutService: LayoutService,
    private keycloakService: TjKeycloakService,
  ) {
    this.layoutService.config.scale = 16;
    document.documentElement.style.fontSize = 16 + 'px';
    document.body.style.height = '100vh';
    document.body.style.margin = '0 !important';
    document.body.style.padding = '0 !important';
  }

  ngOnInit() {
    this.loadInvoices();
  }

  loadInvoices() {
    this.loadConfigs()
      .pipe(
        switchMap(() => {
          return this.api.invoice.getInvoice(this.invoiceId);
        }),
      )
      .subscribe((invoice: Invoice) => {
        this.invoice = invoice;
        this.invoice?.items?.sort(
          (a, b) => dayjs(b.serviceDate).unix() - dayjs(a.serviceDate).unix(),
        );
        this.setInvoiceContacts(invoice);
        // Set payInstruction based on invoice status
        this.payInstruction =
          this.invoice.status === 'ESTIMATE' ? this.estimateInstruction : this.instruction;
      });
  }

  setInvoiceContacts(invoice: Invoice) {
    const contactPersons: ContactInfoList[] = [];
    const contactPersonIds = new Set<number>();

    invoice.items.forEach(item => {
      const contactPersonId = item.mainPartnerContactPerson?.id;
      if (contactPersonId !== undefined && !contactPersonIds.has(contactPersonId)) {
        contactPersonIds.add(contactPersonId);
        const newContacts = invoice.partner?.getContactPersonsData(item.mainPartnerContactPerson);
        if (newContacts) {
          contactPersons.push(...newContacts);
        }
      }
    });

    this.partnerInvoiceReceiverContacts = contactPersons;
  }

  loadConfigs() {
    return this.api.globalConfig.values(['PAY_INSTRUCTION', 'ESTIMATE_PAY_INSTRUCTION']).pipe(
      map((response: any) => {
        // Find PAY_INSTRUCTION and ESTIMATE_PAY_INSTRUCTION configs
        const payConfig = response.configs.find(
          findConfig => findConfig.code === 'PAY_INSTRUCTION',
        );
        const estimateConfig = response.configs.find(
          findConfig => findConfig.code === 'ESTIMATE_PAY_INSTRUCTION',
        );

        // Extract values or default to empty string if not found
        this.instruction = payConfig?.value || '';
        this.estimateInstruction = estimateConfig?.value || '';
      }),
    );
  }

  isInvoiceOwned(): boolean {
    return this.keycloakService.hasRole('INVOICE_VIEW_OWNED');
  }
}
