import {Component, Input, OnInit} from '@angular/core';
import {AddressDetails} from '../../../../api/model/Address.model';
import {MapService} from '../../services/map.service';

@Component({
  selector: 'tj-address-map',
  templateUrl: './address-map.component.html',
  styleUrls: ['./address-map.component.scss'],
  providers: [MapService],
})
export class AddressMapComponent implements OnInit {
  lng: number;

  lat: number;

  @Input() mapHeight = 600;

  @Input() address: string | AddressDetails;

  formattedAddress: string;

  constructor(private mapService: MapService) {}

  ngOnInit() {
    this.mapService.findAddressLatLng(this.address).then(
      coords => {
        this.lng = coords.lng;
        this.lat = coords.lat;
        this.formattedAddress = this.mapService.getFormattedAddress(this.address);
      },
      err => {
        console.log('Cannot load coords by address', err);
      },
    );
  }
}
