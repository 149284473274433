import {Component, OnDestroy, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import {Subject} from 'rxjs';
import {DomainManagementModel} from '../../../../api/model/domainmanagement/domain.management.model';
import {EntityListResponseModel} from '../../../../api/model/shared/Response.model';
import {ApiService} from '../../../../api/service/api.service';

@Component({
  selector: 'tj-domain-stats-chart',
  templateUrl: './domain-stats.component.html',
})
export class DomainStatsComponent implements OnInit, OnDestroy {
  private readonly TOP = 10;

  private readonly destroy$ = new Subject<void>();

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.loadDomainsStats();
  }

  private loadDomainsStats() {
    this.api.domainManagementApi
      .findList()
      .subscribe((response: EntityListResponseModel<DomainManagementModel>) => {
        this.showDomainStats(response.response);
      });
  }

  private showDomainStats(domains: DomainManagementModel[]) {
    const topN = domains.sort((a, b) => b.count - a.count).slice(0, this.TOP);

    const categories = topN.map(it => it.domain);
    const data = topN.map(it => it.count);

    const chartOptions = this.getOptions(categories, data);
    Highcharts.chart('domains', chartOptions);
  }

  private getOptions(categories: string[], data: number[]): Highcharts.Options {
    return {
      chart: {
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: 'Tickets per domain',
      },
      xAxis: {
        categories: categories,
        crosshair: true,
        accessibility: {
          description: 'Accounts / 3rd Party',
        },
      },
      yAxis: [
        {
          // main yAxis
        },
        {
          minPadding: 0,
          maxPadding: 0,
          opposite: true,
          labels: {
            format: '{value}%',
          },
        },
      ],
      tooltip: {
        valueSuffix: ' $',
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: 'Tickets',
          type: 'column' as 'column', // Explicitly cast the type to 'column'
          data: data,
        },
      ],
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
