import {Component, Input, OnInit} from '@angular/core';
import {AddressDetails} from '../../../../api/model/Address.model';
import {NotificationService} from '../../../../core/services';

@Component({
  selector: 'tj-address-link',
  templateUrl: './address-link.component.html',
  styleUrls: ['./address-link.component.scss'],
})
export class AddressLinkComponent implements OnInit {
  @Input() address: AddressDetails;

  @Input() showAddress = true;

  display = false;

  formattedAddress: string;

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    if (this.address) {
      this.formattedAddress = this.address.getFormattedAddress();
    }
  }

  onClick() {
    this.display = true;
  }

  onCopySuccess(event: boolean) {
    if (event) {
      this.notificationService.info('Address is copied.');
    } else {
      this.notificationService.error('Failed to copy the address.');
    }
  }

  openMap() {
    const url = 'https://maps.google.com/maps?q=' + this.address?.addressFormatted;
    window.open(url, '_blank');
  }
}
