import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ZipCodeSearch} from '../../../../../../api/model/ZipCode.model';
import {ConfigService, NotificationService} from '../../../../../../core/services';
import {ApiService} from '../../../../../../api/service/api.service';

@Component({
  selector: 'tj-zip-code-covered-info',
  templateUrl: './zip-code-covered-info.component.html',
  styleUrls: ['./zip-code-covered-info.component.scss'],
})
export class ZipCodeCoveredInfoComponent implements OnChanges {
  @Input() zipCode: string;
  @Input() initialShow = true;

  findZipCode: ZipCodeSearch;

  constructor(
    private configService: ConfigService,
    private api: ApiService,
    private notificationService: NotificationService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.zipCode == null && changes.zipCode.isFirstChange() && !this.initialShow) {
      return;
    }
    if (changes.zipCode && changes.zipCode.currentValue) {
      this.getZipCodeInfo();
      return;
    }
    this.findZipCode = null;
  }

  getZipCodeInfo(): void {
    if (this.zipCode == null) {
      this.findZipCode = null;
      return;
    }
    this.api.zipCode.searchZipCode(this.zipCode).subscribe(
      zipCodesResponse => {
        this.findZipCode = zipCodesResponse;
      },
      errorMessage => {
        this.notificationService.error(errorMessage);
        this.findZipCode = null;
      },
    );
  }
}
