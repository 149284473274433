import {Component, ElementRef, HostListener, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from './service/app.layout.service';

@Component({
  selector: 'tj-sidebar',
  templateUrl: './app.sidebar.component.html',
})
export class AppSidebarComponent implements OnDestroy, OnInit {
  dynamicHeight: number;

  constructor(
    public layoutService: LayoutService,
    public el: ElementRef,
  ) {}

  ngOnInit() {
    this.calculateHeight();
  }

  @HostListener('window:resize')
  onResize() {
    this.calculateHeight();
  }

  calculateHeight() {
    const viewportHeight = window.innerHeight;
    const headerHeight = 50;
    this.dynamicHeight = viewportHeight - headerHeight;
  }

  get logoColor() {
    let logo;

    if (this.layoutService.config.colorScheme == 'light') {
      logo =
        this.layoutService.config.menuTheme === 'white' ||
        this.layoutService.config.menuTheme === 'orange'
          ? 'dark'
          : 'white';
    } else {
      logo = 'dark';
    }
    return logo;
  }

  resetOverlay() {
    if (this.layoutService.state.overlayMenuActive) {
      this.layoutService.state.overlayMenuActive = false;
    }
  }

  ngOnDestroy() {
    this.resetOverlay();
  }
}
