import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// import {AppComponent} from './app.component';
// import {AppMainComponent} from './app.main.component';
// import {AppConfigComponent} from './app.config.component';
// import {AppRightmenuComponent} from './app.rightmenu.component';
// import {AppMenuComponent} from './app.menu.component';
// import {AppMenuitemComponent} from './app.menuitem.component';
// import {AppTopBarComponent} from './app.topbar.component';
// import {AppFooterComponent} from './app.footer.component';
import {CoreModule} from './core/core.module';
import {TicketModule} from './main/ticket/ticket.module';
import {InvoiceModule} from './main/invoice/invoice.module';
import {DomainmanagementModule} from './main/domainmanagement/domainmanagement.module';
import {InventoryModule} from './main/inventory/inventory.module';
import {PartnerModule} from './main/partner/partner.module';
import {TechnicianModule} from './main/technician/technician.module';
import {PayFormModule} from './main/payform/payform.module';

// import {MenuService} from './app.menu.service';
import {TicketCommunicationModule} from './main/ticket-communication/ticket-communication.module';
import {ConfigurationsModule} from './main/configurations/configurations.module';
import {RoutesModule} from './main/routes/routes.module';
import {ConfigService, TjKeycloakService} from './core/services';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {KeycloakBearerInterceptor} from 'keycloak-angular';
import {AppLayoutModule} from './layout/app.layout.module';
import {AppLayoutComponent} from './layout/app.layout.component';
import {AppComponent} from './app.component';
import {AppNotFoundComponent} from './layout/app.notfound.component';
import {environment} from '../environments/environment';
import {KeycloakConfig} from 'keycloak-js';
import {TimeZoneHeaderAppenderInterceptor} from './core/time-zone-header-appender-interceptor.service';
import {LoaderHttpInterceptor} from './core/interceptor/LoaderHttpInterceptor';
import {PaymentModule} from './main/payment/payment.module';
import {ClientSignatureInterceptor} from './core/client-signature-interceptor.service';
import {SchedulerModule} from './main/schedulerandcalendar/scheduler.module';
import {TjRxStompService} from './main/shared/services/tj-rx-stomp.service';
import {TjRxStompServiceFactory} from './main/shared/services/tj-rx-stomp-service-factory';
import {StatisticsModule} from './main/statistics/statistics.module';
import {CatalogModule} from './main/catalog/catalog.module';
import {ReviewsModule} from './main/reviews/reviews.module';
import {InstructionModule} from './main/instruction/instruction.module';

const appRoutes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
  },
  {
    path: '404',
    component: AppNotFoundComponent,
  },
];

// hardcoded for now, will be a separate call to BE
const keycloakClients: {[key: string]: KeycloakConfig} = {
  local: {
    url: 'http://18.209.222.245:8080',
    realm: 'ticketjan',
    clientId: 'tj-front',
  },
  dev: {
    url: 'http://18.209.222.245:8080',
    realm: 'ticketjan',
    clientId: 'tj-front',
  },
  aquatechnyc: {
    url: 'https://auth.servicejan.com',
    realm: 'aquatechnyc',
    clientId: 'tj-front',
  },
  furniturerepairexpert: {
    url: 'https://auth.servicejan.com',
    realm: 'furniturerepairexpert',
    clientId: 'tj-front',
  },
  zonaexpresscorp: {
    url: 'https://auth.servicejan.com',
    realm: 'zonaexpresscorp',
    clientId: 'tj-front',
  },
  afs: {
    url: 'https://auth.servicejan.com',
    realm: 'afs',
    clientId: 'tj-front',
  },
  queenswireless: {
    url: 'https://auth.servicejan.com',
    realm: 'queenswireless',
    clientId: 'tj-front',
  },
  grillyan: {
    url: 'https://auth.servicejan.com',
    realm: 'grillyan',
    clientId: 'tj-front',
  },
  brothers: {
    url: 'https://auth.servicejan.com',
    realm: 'brothers',
    clientId: 'tj-front',
  },
  avprofile: {
    url: 'https://auth.servicejan.com',
    realm: 'avprofile',
    clientId: 'tj-front',
  },
  demo: {
    url: 'https://auth.servicejan.com',
    realm: 'demo',
    clientId: 'tj-front',
  },
};

export function initializer(
  keycloakService: TjKeycloakService,
  configService: ConfigService,
): () => Promise<any> {
  const {hostname} = new URL(window.location.href);
  let keycloakConfig: KeycloakConfig;
  if (environment.env === 'dev') {
    if (hostname === '18.209.222.245') {
      keycloakConfig = keycloakClients['dev'];
    } else {
      keycloakConfig = keycloakClients['local'];
    }
  } else {
    const [subdomain] = hostname.split('.');
    keycloakConfig = keycloakClients[subdomain];
    if (!keycloakConfig) {
      window.location.href = 'https://servicejan.com';
      return () => Promise.reject();
    }
  }

  return (): Promise<any> => {
    return keycloakService
      .initializeKeycloak(keycloakConfig)
      .then(() => keycloakService.checkIfTokenRefreshRequired())
      .then(() => preloadData(configService));
  };
}

function preloadData(configService: ConfigService): Promise<boolean> {
  return configService.preloadGlobalData();
}

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [AppComponent, AppNotFoundComponent],
  imports: [
    RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'}),
    AppLayoutModule,
    BrowserModule,
    CoreModule,
    TicketCommunicationModule,
    TicketModule,
    InvoiceModule,
    DomainmanagementModule,
    InventoryModule,
    InstructionModule,
    CatalogModule,
    PayFormModule,
    ReviewsModule,
    PartnerModule,
    TechnicianModule,
    RoutesModule,
    PaymentModule,
    SchedulerModule,
    ConfigurationsModule,
    StatisticsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [TjKeycloakService, ConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientSignatureInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeZoneHeaderAppenderInterceptor,
      multi: true,
    },
    {
      provide: TjRxStompService,
      useFactory: TjRxStompServiceFactory,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
