<p-tabView>
  <ng-container *ngFor="let chat of chats">
    <p-tabPanel
      *ngIf="canAccessChat(chat.chatType) && checkForPartner(chat.chatType)"
      header="{{ titleMap[chat.chatType] }}"
    >
      <tj-ticket-comments
        [chatId]="chat.id"
        [chatType]="chat.chatType"
        [technicians]="technicians"
      ></tj-ticket-comments>
    </p-tabPanel>
  </ng-container>
</p-tabView>
