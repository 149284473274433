import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ConfigService} from '../../../core/services';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PaymentModel} from '../../../api/model/Payment.model';
import {NettermTypeModel} from '../../../api/model/Netterm.model';
import {DropdownModel} from '../../../api/model/shared/dropdown.model';
import {BehaviorSubject, Subscription} from 'rxjs';
import {BaseFormGroup} from '../../shared/utils/base-form-group';

@Component({
  selector: 'tj-net-term',
  templateUrl: './net-term.component.html',
  styleUrls: ['./net-term.component.scss'],
})
export class NetTermComponent extends BaseFormGroup implements OnInit, OnChanges, OnDestroy {
  @Input() form: UntypedFormGroup;

  @Input() group: string;

  @Input() payment: PaymentModel;

  typesLoaded = false;
  formSubscription: Subscription;

  @Input() isPrivate: boolean;

  public readonly nettermsTypes$: BehaviorSubject<DropdownModel[]> = new BehaviorSubject<
    DropdownModel[]
  >([]);

  constructor(
    public configService: ConfigService,
    private fb: UntypedFormBuilder,
  ) {
    super();
  }

  ngOnInit() {
    this.getNettermTypes();
    this.initializeForm();
  }

  ngOnChanges(): void {}

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }

  private initializeForm(): void {
    const payment = this.payment || ({} as PaymentModel);
    const formControls = {
      transactionId: this.fb.control(payment.transactionId),
      stripeTransactionId: this.fb.control(payment.stripeTransactionId),
      approvalRequired: this.fb.control(payment.approvalRequired),
      netTermType: this.fb.control(payment.netTermType, [Validators.required]),
      money: this.fb.control(payment.money),
    };
    this.buildForm(this.form, formControls, this.group);
    this.defineGetters(formControls);
    this.formSubscription = this.currentFormGroup
      .get('netTermType')
      .valueChanges.subscribe(netTermType => this.updatePaymentValidations(netTermType, payment));
  }

  private updatePaymentValidations(netTermType, payment: PaymentModel): void {
    console.log('updatePaymentValidations');
    const removeControls = (names: string[]) =>
      names.forEach(name => {
        this.currentFormGroup.removeControl(name);
        this.currentFormGroup.updateValueAndValidity();
      });

    switch (netTermType) {
      case 'DUE_ON_RECEIPT':
        this.form.get('netTerm').setValue(0);
        break;
      case 'CREDIT_CARD_ON_FILE':
        removeControls(['money']);
        this.currentFormGroup.setControl('transactionId', this.fb.control(payment.transactionId));
        this.currentFormGroup.setControl(
          'stripeTransactionId',
          this.fb.control(payment.stripeTransactionId),
        );
        break;
      case 'PREPAID_CREDIT':
        removeControls(['transactionId', 'stripeTransactionId']);
        this.currentFormGroup.setControl(
          'money',
          this.fb.control(payment.money, [Validators.required]),
        );
        break;
      case 'NET_TERM':
        const netTerm = this.isPrivate
          ? this.configService.getPrivateAccountDefaultNetTerm()
          : this.configService.getRegisteredAccountDefaultNetTerm();
        this.form.get('netTerm').setValue(netTerm);
        break;
      default:
        removeControls(['transactionId', 'stripeTransactionId', 'money']);
    }
  }

  private getNettermTypes(): void {
    this.configService.nettermsTypes$.subscribe((nettermModels: NettermTypeModel[]) => {
      this.typesLoaded = false;
      const nettermsTypes: DropdownModel[] = [];
      if (this.isPrivate) {
        nettermModels.forEach((nettermModel: NettermTypeModel) => {
          nettermsTypes.push({value: nettermModel.netTermType, label: nettermModel.name});
        });
      } else {
        nettermModels.forEach((nettermModel: NettermTypeModel) => {
          if (!nettermModel['private']) {
            nettermsTypes.push({value: nettermModel.netTermType, label: nettermModel.name});
          }
        });
      }
      this.nettermsTypes$.next(nettermsTypes);
      this.typesLoaded = true;
    });
  }

  getControl(fcName: string): UntypedFormControl {
    return this.currentFormGroup.get(fcName) as UntypedFormControl;
  }
}
