// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  thumborEndpoint: 'https://api.servicejan.com/thumbor/unsafe',
  imgproxyEndpoint: 'https://api.servicejan.com/imgproxy/insecure',
  apiEndpoint: 'https://api.servicejan.com/backend/api/',
  wsApiEndpoint: 'https://api.servicejan.com/ws-api/ws',
  stripeKey: 'pk_live_51Jq1HnLaKvPwzSaV5VLfKLYBkU7jjaGkvHyh28BOzwTKSyCxkyVzcMHrSgjUJAAo9bJljmx093oaadMEd5FzOXIu00vft0TzzB',
  ticketSignatureWSPath: 'ticket-signature',
  payFromSignatureWSPath: 'pay-form-signature',
  ticketSignatureTrackType: 'WS', // Possible values WS or API
  tawkTo: {
    uri: 'https://embed.tawk.to/',
    propertyId: '65042074b1aaa13b7a76ff14',
    widgetId: '1hac1tiib',
    apiKey: '59f99ad870bdbbaf5b39162b555c88e0b10cbfb3'
  },
  money: {
    sign: '$',
    abbr: 'USD',
    name: 'US Dollars'
  },
  env: 'prod',
  dateFormat: 'MM/dd/yy',
  dateFormatFull: 'MM/dd/yy hh:mma',
  dateTimeOnly: 'hh:mma',
  rowsPerPage: [20, 30, 40, 50],
  extendedRowsPerPage: [20, 30, 40, 50, 100, 200, 250, 300, 400, 500],
  datePickerOptions: {
    ariaLabel: 'Use the arrow keys to pick a date',
    position: 'bottom left',
    reposition: true,
    format: 'MM/DD/YY',
    toString: null,
    parse: null,
    defaultDate: null,
    setDefaultDate: false,
    firstDay: 0,
    formatStrict: false,
    minDate: new Date(),
    maxDate: null,
    yearRange: 10,
    showWeekNumber: false,
    pickWholeWeek: false,
    yearSuffix: '',
    showMonthAfterYear: false,
    showDaysInNextAndPreviousMonths: false,
    enableSelectionDaysInNextAndPreviousMonths: false,
    numberOfMonths: 1,
    blurFieldOnSelect: true
  },
  ticketEstimations: [1, 2, 3, 4, 5, 6, 7, 8],
  defaultTicketEstimation: 1
};
