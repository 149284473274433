import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Page, PageQuery, SearchQuery, SortQuery} from '../model/shared/Page.model';
import {Area} from '../model/Area.model';
import {Injectable} from '@angular/core';
import {CommunicationFilterableService} from '../service/communication-filtarable.service';
import {AreaZipCodes} from '../model/AreaZipCodes.model';
import {ZipCode} from '../model/ZipCode.model';
import {AreaToZipCodeModel} from '../model/AreaToZipCode.model';
import {AreaToZipCodeFilter} from '../model/areatozipcode/areaToZipCode.filter';

@Injectable()
export class AreaApi extends CommunicationFilterableService implements SearchQuery {
  protected apiEndpoint = this.env.apiEndpoint + 'area';

  filterMapper = {
    name: 'name:',
    code: 'code:',
  };

  getAreaToZipCodes(
    pageQuery: PageQuery,
    sortQuery: SortQuery,
    filter: AreaToZipCodeFilter,
  ): Observable<Page<AreaToZipCodeModel>> {
    const params = {
      page: pageQuery.number.toString(),
      count: pageQuery.size.toString(),
      order: sortQuery.direction ? sortQuery.direction.toUpperCase() : null,
      sort: sortQuery.property ? sortQuery.property : null,
      search: filter,
    };
    const url = `${this.apiEndpoint}/zipCodes`;
    return this.requestService.get(url, {params}).pipe(
      map(response => response as Page<AreaToZipCodeModel>),
      catchError(this.handleError),
    );
  }

  findList(): Observable<Area[]> {
    return this.requestService.get(`${this.apiEndpoint}/list?sort=name&order=ASC`).pipe(
      map(response => response.response as Area[]),
      catchError(this.handleError),
    );
  }

  create(area: Area): Observable<Area> {
    const url = this.apiEndpoint;
    return this.requestService.post(url, area).pipe(
      map(response => response.response as Area),
      catchError(this.handleError),
    );
  }

  update(area: Area): Observable<void> {
    const url = `${this.apiEndpoint}/${area.id}`;
    return this.requestService.put(url, area).pipe(catchError(this.handleError));
  }

  delete(id: number): Observable<void> {
    return this.requestService
      .remove(`${this.apiEndpoint}/${id}`)
      .pipe(catchError(this.handleError));
  }

  importZipCodes(file: File, areaId: number): Observable<void> {
    const url = `${this.apiEndpoint}/${areaId}/import`;
    return this.requestService.uploadCsv(url, file).pipe(catchError(this.handleError));
  }

  exportAreaZipCodes(areaId: number): Observable<Blob> {
    const url = `${this.apiEndpoint}/${areaId}/zipcodes/export`;
    return this.requestService.download(url).pipe(
      map(response => response),
      catchError(this.handleError),
    );
  }

  getZipCodes(areaId: number): Observable<AreaZipCodes> {
    const url = `${this.apiEndpoint}/${areaId}/zipcodes`;
    return this.requestService.get(url).pipe(
      map(response => response.response as AreaZipCodes),
      catchError(this.handleError),
    );
  }

  updateZipCode(zipCode: ZipCode, checkExistAreaByZipCode: boolean): Observable<void> {
    const url = `${this.apiEndpoint}/zipcode/${zipCode.id}?checkExistAreaByZipCode=${checkExistAreaByZipCode}`;
    return this.requestService.put(url, zipCode).pipe(catchError(this.handleErrorWithStatusCode));
  }

  deleteZipCode(areaId: number, zipCodeId: number): Observable<void> {
    const url = `${this.apiEndpoint}/${areaId}/zipcode/${zipCodeId}`;
    return this.requestService.remove(url).pipe(catchError(this.handleError));
  }

  addAreaNewZipCode(areaId: number, zipCode: ZipCode): Observable<ZipCode> {
    const url = `${this.apiEndpoint}/${areaId}/zipcode`;
    return this.requestService.post(url, zipCode).pipe(
      map(response => response.response as ZipCode),
      catchError(this.handleError),
    );
  }

  addAreaInsertNewZipCodes(areaId: number, zipCodes: []): Observable<void> {
    const url = `${this.apiEndpoint}/${areaId}/insert/zipcode`;
    return this.requestService.post(url, zipCodes).pipe(catchError(this.handleError));
  }
}
