import {Component, OnDestroy, OnInit} from '@angular/core';
import {loadStripe} from '@stripe/stripe-js';
import {BaseFormGroup} from '../../shared/utils/base-form-group';

import {environment} from '../../../../environments/environment';
import {PaymentFlowService} from '../payment-flow.service';
import {NotificationService} from '../../../core/services';
import {ApiService} from '../../../api/service/api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'tj-stripe-details',
  templateUrl: './stripe-details.component.html',
  styleUrls: ['./stripe-details.component.scss'],
})
export class StripeDetailsComponent extends BaseFormGroup implements OnInit, OnDestroy {
  private stripe;

  private elements;

  private orderId: string;

  constructor(
    private paymentFlowService: PaymentFlowService,
    private router: Router,
    private notificationService: NotificationService,
    private apiService: ApiService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.initStripe();
    const order = this.paymentFlowService.getOrder();
    await this.createPaymentIntent(order);
  }

  ngOnDestroy() {
    // this.formChangesSubscription.unsubscribe();
  }

  private async initStripe() {
    this.stripe = await loadStripe(environment.stripeKey, {
      locale: 'en',
    });
  }

  private async createPaymentIntent(order) {
    console.log('createPaymentIntent', order);
    this.apiService.payment.createStripeOrder(order).subscribe(value => {
      this.orderId = value.orderId;
      const clientSecret = value.clientSecret;

      const appearance = {
        theme: 'stripe',
      };
      this.elements = this.stripe.elements({appearance, clientSecret});

      const paymentElementOptions = {
        layout: 'tabs',
        link: {enabled: false},
      };

      const paymentElement = this.elements.create('payment', paymentElementOptions);
      paymentElement.mount('#payment-element');
    });
  }

  async handleSubmit() {
    // setLoading(true);
    const itemType = 'invoice';
    const orderId = this.orderId;

    const {error, paymentIntent} = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        // Remove return_url to prevent redirection
      },
      redirect: 'if_required', // Prevents auto redirection
    });

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        this.notificationService.error('Card error', error.message);
        console.log(error.message);
      } else {
        console.log('An unexpected error occurred.');
      }
    } else if (paymentIntent?.status === 'succeeded') {
      // Navigate to success page using Angular Router
      this.router.navigate(['/payment/stripe/completed'], {
        queryParams: {orderId, itemType},
      });
    } else if (paymentIntent?.status === 'processing') {
      this.notificationService.info('Payment is being processed. Please wait.');
    } else {
      this.notificationService.error('Payment failed.');
    }

    // setLoading(false);
  }
}
