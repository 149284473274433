<div *ngIf="signature">
  <p-panel header="Signature">
    <div>
      <div class="align-items-center border-black-alpha-20 border-top-1 mt-4 pt-4 lg:px-8 px-1">
        <tj-signature
          [customerSignature]="signature"
          [ticket]="ticket"
          [galleryId]="galleryId"
          (updateSignatureView)="updateSignature($event)"
        ></tj-signature>
      </div>

      <div class="col-12 text-center">
        <button (click)="saveReportAndSignature()" label="Save" pButton></button>
      </div>

      <div
        class="align-items-center border-black-alpha-20 border-top-1 mt-4 py-4 lg:px-8 px-1"
        *ngIf="signature.id"
      >
        <div class="col-12 text-center align-items-center">
          <img
            style="width: 100%; max-width: 400px"
            loading="lazy"
            id="{{ signature.id }}"
            src="{{ signature.url }}"
            cache="no"
          />
        </div>
      </div>
    </div>
  </p-panel>
</div>
<p-confirmDialog appendTo="body" key="confirmDeletionSignature"></p-confirmDialog>
