<div *ngIf="showInvoices">
  <ng-container *ngIf="invoiceId">
    <div class="grid">
      <div class="col-12">
        <div class="grid flex-column">
          <div class="col">
            <tj-page-header>
              <tj-page-title>Invoice # {{ invoiceId }}</tj-page-title>
              <tj-page-title-right *ngIf="invoice?.items">
                <ng-container *ngIf="isPrivateBusiness(); else privateOnly">
                  <span class="private">PRIVATE</span> - 3rd Party - None Account
                  <hr />
                </ng-container>
                <ng-template #privateOnly>
                  <ng-container *ngIf="isPrivate()">
                    <span class="private">PRIVATE</span>
                    <hr />
                  </ng-container>
                </ng-template>
              </tj-page-title-right>
            </tj-page-header>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div style="max-width: 1196px; margin: 0 auto">
    <tj-invoice-grid
      (downloadPDFReport)="downloadPDF()"
      (printReport)="printReport()"
      (sendToEmail)="sendToEmail($event)"
      (sendToPartner)="sendToPartner()"
      (sendToCustomer)="sendToCustomer()"
      (changeStatusEventEmitter)="onChangeStatus($event)"
      [forPrint]="false"
      [invoice]="invoice"
    ></tj-invoice-grid>
  </div>
</div>
