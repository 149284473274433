import {Component, Input} from '@angular/core';
import {ConfigService} from '../../../../core/services';
import {TicketLine} from '../../../../api/model/TicketLine.model';
import {Ticket} from '../../../../api/model/Ticket.model';

@Component({
  selector: 'tj-ticket-service-date',
  templateUrl: './ticket-service-date.component.html',
  styleUrls: ['./ticket-service-date.component.scss'],
})
export class TicketServiceDateComponent {
  @Input() ticket: TicketLine | Ticket;
  @Input() showServiceDate = true;
  @Input() showServiceDateTime = true;

  constructor(public config: ConfigService) {}
}
