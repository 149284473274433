<div class="col-12">
  <p-toolbar styleClass="mb-4 gap-2">
    <ng-template pTemplate="left"></ng-template>
    <ng-template pTemplate="right">
      <button
        pButton
        pRipple
        label="New"
        icon="pi pi-plus"
        class="p-button-success mr-2"
        (click)="onNewTag()"
      ></button>
    </ng-template>
  </p-toolbar>

  <p-table
    #dt
    [value]="tags"
    [rows]="env.rowsPerPage[0]"
    [paginator]="true"
    [globalFilterFields]="['label']"
    styleClass="p-datatable-gridlines p-datatable-striped"
    [rowsPerPageOptions]="env.rowsPerPage"
    [rowHover]="true"
    dataKey="id"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="caption">
      <div class="flex">
        <button
          pButton
          label="Clear"
          class="p-button-outlined"
          icon="pi pi-filter-slash"
          (click)="dt.clear()"
        ></button>
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search keyword"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="id">
          Id
          <p-sortIcon field="id"></p-sortIcon>
        </th>
        <th pSortableColumn="label">
          Label
          <p-sortIcon field="label"></p-sortIcon>
        </th>

        <th style="width: 75px"></th>
        <th style="width: 75px"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-tag>
      <tr>
        <td>{{ tag.id }}</td>
        <td [pTooltip]="tag.builtin ? 'Managed by system' : ''" tooltipPosition="left">
          <span [ngStyle]="{'background-color': tag.color}" class="w-fit status-pill">{{
            tag.label
          }}</span>
        </td>
        <td class="text-center">
          <button
            *tjHasRole="'GLOBAL_CONFIG_EDIT'"
            pButton
            pRipple
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success"
            (click)="onEditTag(tag)"
          ></button>
        </td>
        <td class="text-center">
          <button
            *tjHasRole="'GLOBAL_CONFIG_EDIT'"
            pButton
            pRipple
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger"
            [disabled]="tag.builtin"
            (click)="onDeleteTag(tag)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="tagDialog"
  [style]="{width: '650px'}"
  header="Tag Details"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <form #tagForm="ngForm" (ngSubmit)="tagForm.form.valid && onSaveTag()" name="tagForm">
      <div class="field flex">
        <div class="col-11">
          <label for="name">Label</label>
          <input
            class="col-10"
            type="text"
            pInputText
            name="name"
            id="name"
            pattern="[A-Za-z0-9\s]{3,50}"
            [(ngModel)]="tag.label"
            required
            autofocus
          />
          <small id="name-help" class="block"
            >Only letters, numbers and spaces are allowed. Min 3, max 50 symbols.</small
          >
        </div>
        <div class="col-1 flex flex-column h-4rem justify-content-start align-items-center">
          <span>Color</span>
          <span class="p-1">
            <p-colorPicker
              name="color"
              [(ngModel)]="tag.color"
              appendTo="body"
              required
              autofocus
            ></p-colorPicker>
          </span>
        </div>
      </div>
      <div class="flex flex-grow-1 justify-content-end">
        <div>
          <button
            pButton
            pRipple
            type="button"
            label="Cancel"
            icon="pi pi-times"
            class="p-button-text"
            (click)="onHideDialog()"
          ></button>
        </div>
        <div>
          <button
            pButton
            pRipple
            [disabled]="!tagForm.form.valid"
            type="submit"
            label="Save"
            icon="pi pi-check"
            class="p-button-text"
          ></button>
        </div>
      </div>
    </form>
  </ng-template>
</p-dialog>

<p-confirmDialog appendTo="body" key="confirmDeletion"></p-confirmDialog>
