import {Component, Input, OnInit} from '@angular/core';
import 'fabric';
import {FabricHelper} from '../../../shared/utils/FabricHelper';
import {Canvas, Image, Text} from 'fabric/fabric-impl';

declare const fabric: any;

@Component({
  selector: 'tj-image-overlay',
  templateUrl: './tj-image-overlay.component.html',
  styleUrls: ['./tj-image-overlay.component.scss'],
})
export class TjImageOverlayComponent implements OnInit {
  private readonly TEXT_BEFORE = 'BEFORE';

  private readonly TEXT_AFTER = 'AFTER';

  private readonly COLOR_RED = '#b10000';

  @Input() croppedUrl: string;

  canvas: Canvas;

  color = '#000';

  // Property to store rotation degree
  rotationDegree = 0;

  constructor() {}

  ngOnInit(): void {
    this.initCanvas();
  }

  private initCanvas(): void {
    this.createCanvas();
    this.setCanvasBackground();
  }

  private createCanvas(): void {
    this.canvas = new fabric.Canvas('canvas', {selection: false});
    FabricHelper.setCanvasBounds(this.canvas);
  }

  private setCanvasBackground(): void {
    const canvas = this.canvas;
    fabric.Image.fromURL(this.croppedUrl, (image: Image) => {
      canvas.setHeight(image.height);
      canvas.setWidth(image.width);
      canvas.setBackgroundImage(image, canvas.renderAll.bind(canvas), {
        scaleX: canvas.getWidth() / image.width,
        scaleY: canvas.getHeight() / image.height,
      });
    });
  }

  addText(value: string): void {
    if (value && value.trim() !== '') {
      const text: Text = new fabric.Text(value);
      text.set({fill: this.color});
      this.canvas.add(text);
    }
  }

  removeSelectedObject() {
    this.canvas.remove(this.canvas.getActiveObject());
  }

  public getImgBase64(): string {
    const image = this.canvas.toDataURL({
      format: 'jpeg',
    });
    this.canvas.setBackgroundImage(null, null);
    return image;
  }

  public getJSONData(): string {
    const canvasData = this.canvas.toDataURL();

    this.canvas.setBackgroundImage(null, null);
    return JSON.stringify(this.canvas);
  }

  addArrow(): void {
    const that = this;
    fabric.loadSVGFromURL(FabricHelper.arrowPath, (objects, options) => {
      objects.forEach(svg => {
        svg.set({
          fill: that.color,
          top: 90,
          left: 90,
          originX: 'center',
          originY: 'center',
        });
        svg.scaleToWidth(50);
        svg.scaleToHeight(50);
        that.canvas.add(svg).renderAll();
      });
    });
  }

  addBefore(): void {
    this.addPredefinedText(this.TEXT_BEFORE);
  }

  addAfter(): void {
    this.addPredefinedText(this.TEXT_AFTER);
  }

  private addPredefinedText(value: string): void {
    const text: Text = new fabric.Text(value);
    text.set({
      fill: this.COLOR_RED,
      fontWeight: '800',
      overline: true,
      underline: true,
      angle: 10,
      top: 90,
      left: 90,
      originX: 'center',
      originY: 'center',
    });
    this.canvas.add(text);
  }

  rotateImage(degrees: number): void {
    const canvas = this.canvas;
    this.rotationDegree = (this.rotationDegree + degrees) % 360;
    const backgroundImage = canvas.backgroundImage as fabric.Image;

    if (backgroundImage) {
      const imageWidth = backgroundImage.width || 0;
      const imageHeight = backgroundImage.height || 0;

      if (this.rotationDegree === 90 || this.rotationDegree === 270) {
        // Swap width and height for 90 and 270 degrees rotation
        canvas.setWidth(imageHeight);
        canvas.setHeight(imageWidth);
      } else {
        // Set original width and height for 0 and 180 degrees rotation
        canvas.setWidth(imageWidth);
        canvas.setHeight(imageHeight);
      }

      // Rotate the background image
      backgroundImage.rotate(this.rotationDegree);

      // Center the background image on the canvas
      backgroundImage.set({
        left: canvas.getWidth() / 2,
        top: canvas.getHeight() / 2,
        originX: 'center',
        originY: 'center',
      });

      // Render the canvas
      canvas.renderAll();
    }
  }
}
