import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LayoutService} from './service/app.layout.service';
import {AppSidebarComponent} from './app.sidebar.component';
import {ConfigService, NotificationService, TjKeycloakService, UserService} from '../core/services';
import {User} from '../api/model/User.model';
import {TawkService} from '../main/shared/services';

@Component({
  selector: 'tj-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.scss'],
  providers: [TawkService],
})
export class AppTopbarComponent implements OnInit {
  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

  user: User;
  timeZoneLabel: string;
  timeZone: number;
  companyInfo: {code: string; value: string}[] = [];
  hideCompanyInfo = false;

  constructor(
    public layoutService: LayoutService,
    public el: ElementRef,
    private userService: UserService,
    private keycloakService: TjKeycloakService,
    private tawkService: TawkService,
    private serviceConfig: ConfigService,
    private notificationService: NotificationService,
    private configService: ConfigService,
  ) {
  }

  ngOnInit(): void {
    this.userService.fetchCurrentUser().subscribe((user: User) => {
      this.user = user;
      this.tawkService.updateUser(user);
    });
    this.companyInfo = this.serviceConfig.getHeaderInfo();
    if (this.layoutService.isMobile()) {
      this.hideCompanyInfo = true;
    }

    this.timeZone = this.configService.getClientZoneTimeConfig();
    this.timeZoneLabel = this.configService.getClientZoneTimeLabelConfig();
  }

  getValueByCode(code: string): string {
    const info = this.companyInfo.find(item => item.code === code);
    return info ? info.value : '';
  }

  get displayName() {
    const user = this.user;
    if (user.name) {
      return user.name;
    } else if (this.user.attributes?.['companyName']) {
      return this.user.attributes['companyName'];
    }
    return this.user.username;
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showRightMenu();
  }

  onSearchClick() {
    this.layoutService.toggleSearchBar();
  }

  logout() {
    this.keycloakService.logout();
  }

  onCopySuccess(event: boolean) {
    if (event) {
      this.notificationService.info('Successfully copied.');
    } else {
      this.notificationService.error('Failed to copy.');
    }
  }

  back() {
    window.history.back();
  }
}
