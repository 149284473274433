import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfigService} from '../../../../core/services';
import {TicketEditContextService} from '../../services/ticket-edit-context.service';
import {ActivatedRoute} from '@angular/router';
import {LayoutService} from '../../../../layout/service/app.layout.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'tj-tickets-print',
  templateUrl: './tickets-print.component.html',
  styleUrls: ['./tickets-print.component.scss'],
  providers: [TicketEditContextService],
})
export class TicketsPrintComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  configs: {[key: string]: string};

  showTotalAmount = true;

  ids = [];

  constructor(
    private route: ActivatedRoute,
    public configService: ConfigService,
    public layoutService: LayoutService,
  ) {
    this.layoutService.config.scale = 16;
    document.documentElement.style.fontSize = 16 + 'px';
    document.body.style.height = '100vh';
    document.body.style.margin = '0 !important';
    document.body.style.padding = '0 !important';
  }

  ngOnInit() {
    this.loadConfigs();
    this.route.queryParams.subscribe(params => {
      this.showTotalAmount = params.showTotalAmount;

      const idsParam = params.id;
      if (Array.isArray(idsParam)) {
        this.ids = idsParam.map(id => +id);
      } else if (idsParam) {
        this.ids = [+idsParam];
      } else {
        this.ids = [];
      }
    });
  }

  private loadConfigs() {
    this.configService.globalConfigs$.pipe(takeUntil(this.destroy$)).subscribe(
      globalConfigs =>
        (this.configs = globalConfigs.reduce((acc, config) => {
          acc[config.code] = config.value;
          return acc;
        }, {})),
    );
  }

  back() {
    window.history.back();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
