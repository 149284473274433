<ng-container *ngIf="ticket && configs">
  <div class="col-12 pagebreak-after" *ngIf="pagebreak && index !== 0"></div>
  <div class="col-fixed page">
    <div class="grid">
      <div class="col-12 text-center mt-5">
        <ng-container *ngIf="configs['COMPANY_NAME']">
          <span class="font-bold">{{ configs['COMPANY_NAME'] }}</span> <br />
        </ng-container>

        <ng-container *ngIf="configs['COMPANY_ADDRESS']">
          <strong>Address: </strong> {{ configs['COMPANY_ADDRESS'] }} <br />
        </ng-container>

        <ng-container *ngIf="configs['COMPANY_PHONE']">
          <strong>Tel: </strong>{{ configs['COMPANY_PHONE'] }} &nbsp;
        </ng-container>

        <ng-container *ngIf="configs['COMPANY_FAX']">
          <strong>Fax: </strong>{{ configs['COMPANY_FAX'] }} &nbsp; <br />
        </ng-container>

        <ng-container *ngIf="configs['COMPANY_WEBSITE']">
          <strong>Web: </strong>{{ configs['COMPANY_WEBSITE'] }} &nbsp;
        </ng-container>

        <ng-container *ngIf="configs['COMPANY_EMAIL']">
          <strong>E-Mail: </strong>{{ configs['COMPANY_EMAIL'] }}
        </ng-container>
      </div>
      <hr />
      <div class="col-12">
        <div class="font-bold">
          SERVICE TICKET #{{ ticket.id }}
          <span *ngIf="ticket.isPrivate()" class="private">PRIVATE</span>
        </div>
      </div>
      <div class="col-6">
        <ul class="list-none p-0 m-0">
          <li class="flex align-items-center flex-wrap" *ngIf="showTotalAmount">
            <div class="w-3 font-medium">Total amount:</div>
            <div class="w-8">$ {{ ticket.getTotalAmount() }}</div>
          </li>
          <li class="flex align-items-center flex-wrap">
            <div class="w-3 font-medium">Date of issue:</div>
            <div class="w-8">{{ ticket.createdAt | date: configService.dateFormat }}</div>
          </li>
          <li class="flex align-items-center flex-wrap">
            <div class="w-3 font-medium">Date of service:</div>
            <div class="w-8">
              <tj-ticket-service-date
                [showServiceDateTime]="false"
                [ticket]="ticket"
              ></tj-ticket-service-date>
            </div>
          </li>
          <li class="flex align-items-center flex-wrap">
            <div class="w-3 font-medium">Time:</div>
            <div class="w-8">
              <tj-ticket-service-date
                [showServiceDate]="false"
                [ticket]="ticket"
              ></tj-ticket-service-date>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-6">
        <ul class="list-none p-0 m-0">
          <li class="flex align-items-center flex-wrap">
            <div class="w-3 font-medium">Documents:</div>
            <div class="status-pill mb-1 mr-2" style="background: #1779ba">
              {{ ticket.galleryItemsCounts?.documentCount ? 'Yes' : 'No' }}
            </div>
          </li>
          <li class="flex align-items-center flex-wrap">
            <div class="w-3 font-medium">Images:</div>
            <div class="status-pill mb-1 mr-2" style="background: #1779ba">
              {{ ticket.hasAttachment ? 'Yes' : 'No' }}
            </div>
          </li>
          <li class="flex align-items-center flex-wrap" *ngIf="ticket.tags">
            <div class="w-3 font-medium">Tags:</div>
            <div class="w-8">
              <tj-ticket-tags [tags]="ticket.tags"></tj-ticket-tags>
            </div>
          </li>
          <li class="flex align-items-center flex-wrap" *ngIf="ticket.technicians">
            <div class="w-3 font-medium">Technician code:</div>
            <div class="w-8">
              <tj-technician-print [technicians]="ticket.technicians"></tj-technician-print>
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="ticket.appointmentDetails" class="col-12">
        <hr />
        <div class="col-12">
          <div class="font-bold mb-3">Appointment details</div>
          <p>{{ ticket.appointmentDetails }}</p>
        </div>
      </div>
      <div *ngIf="ticket.additionalNotes" class="col-12">
        <hr />
        <div class="col-12">
          <div class="font-bold mb-3">Additional notes</div>
          <p>{{ ticket.additionalNotes }}</p>
        </div>
      </div>

      <hr />
      <div class="col-6">
        <div class="font-bold mb-3">Customer</div>

        <ul class="list-none p-0 m-0">
          <li class="flex align-items-center flex-wrap">
            <div class="w-3 font-medium">Name:</div>
            <div class="w-16">{{ customer.name }}</div>
          </li>
          <li class="flex align-items-center flex-wrap">
            <div class="w-3 font-medium">Address:</div>
            <div class="w-16">{{ customer.address }}</div>
          </li>
          <li class="flex align-items-center flex-wrap" *ngIf="configService.isEnabledTerritory()">
            <div class="w-3 font-medium">Area Codes:</div>
            <div class="w-16">{{ getAreas() }}</div>
          </li>
          <li class="flex align-items-center flex-wrap pt-2 pb-2">
            <div class="w-3 font-medium">Additional address / notes:</div>
            <div class="w-8">{{ customer.address.additionalAddress }}</div>
          </li>
          <div class="font-bold mb-2 mt-3">Main contact</div>
          <li class="flex align-items-center flex-wrap">
            <div class="w-3 font-medium">Name:</div>
            <div class="w-16 underline">{{ mainCustomerContactPerson.name }}</div>
          </li>
          <li
            class="flex align-items-center flex-wrap"
            *ngFor="let contact of mainCustomerContactPerson.contacts"
          >
            <div class="w-3 font-medium">
              <span *ngIf="contact.type === contactType.EMAIL"> - E-mail:</span>
              <span *ngIf="contact.type === contactType.MOBILE_PHONE"> - Mobile:</span>
              <span *ngIf="contact.type === contactType.PHONE"> - Cell line:</span>
              <span *ngIf="contact.type === contactType.FAX"> - Fax:</span>
            </div>
            <div class="w-8">{{ contact.value }}</div>
          </li>
        </ul>

        <div class="font-bold mb-2 mt-3" *ngIf="customer.contactPersons.length > 1">
          Additional contacts
        </div>
        <ng-container *ngFor="let contactPerson of customer.contactPersons">
          <ul class="list-none p-0 mt-2" *ngIf="contactPerson.id !== mainCustomerContactPerson.id">
            <li class="flex align-items-center flex-wrap">
              <div class="w-3 font-medium">Name:</div>
              <div class="w-16 underline">{{ contactPerson.name }}</div>
            </li>

            <li
              class="flex align-items-center flex-wrap"
              *ngFor="let contact of contactPerson.contacts"
            >
              <div class="w-3 font-medium">
                <span *ngIf="contact.type === contactType.EMAIL"> - E-mail:</span>
                <span *ngIf="contact.type === contactType.MOBILE_PHONE"> - Mobile:</span>
                <span *ngIf="contact.type === contactType.PHONE"> - Cell line:</span>
                <span *ngIf="contact.type === contactType.FAX"> - Fax:</span>
              </div>
              <div class="w-8">{{ contact.value }}</div>
            </li>
          </ul>
        </ng-container>
      </div>

      <div class="col-6" *ngIf="partner">
        <div class="font-bold mb-3">Account / Requested BY</div>

        <ul class="list-none p-0 m-0">
          <li class="flex align-items-center flex-wrap">
            <div class="w-3 font-medium">Company:</div>
            <div class="w-16">{{ partner.name }}</div>
          </li>
          <li class="flex align-items-center flex-wrap">
            <div class="w-3 font-medium">Business type:</div>
            <div class="w-16">{{ partner.partnerType }}</div>
          </li>
          <li class="flex align-items-center flex-wrap">
            <div class="w-3 font-medium">Address:</div>
            <div class="w-16">{{ partner.address }}</div>
          </li>
        </ul>

        <ng-container *ngIf="partner.notes">
          <div class="font-bold mb-2 mt-3">Notes</div>
          <div class="w-16">{{ partner.notes }}</div>
        </ng-container>

        <div class="font-bold mb-2 mt-3">Contacts</div>
        <ul class="list-none p-0 m-0">
          <ng-container *ngIf="getPartnerContactPeople()">
            <ng-container *ngFor="let partnerContact of getPartnerContactPeople()">
              <li class="flex align-items-center flex-wrap mt-2">
                <div class="w-3 font-medium">Name:</div>
                <div class="w-16 underline">{{ partnerContact.name }}</div>
              </li>

              <li
                class="flex align-items-center flex-wrap"
                *ngFor="let contact of partnerContact.contacts"
              >
                <div class="w-3 font-medium">
                  <span *ngIf="contact.type === contactType.EMAIL"> - E-mail:</span>
                  <span *ngIf="contact.type === contactType.MOBILE_PHONE"> - Mobile:</span>
                  <span *ngIf="contact.type === contactType.PHONE"> - Cell line:</span>
                  <span *ngIf="contact.type === contactType.FAX"> - Fax:</span>
                </div>
                <div class="w-8">{{ contact.value }}</div>
              </li>
            </ng-container>
          </ng-container>
        </ul>
        <div
          class="font-bold mb-2 mt-3"
          *ngIf="
            !this.partner?.printAllContactPersons &&
            ticket.ticketPartnerStatus === TicketPartnerStatus.PRIVATE_BUSINESS &&
            partner.contactPersons.length > 1
          "
        >
          Additional contacts
        </div>
        <ng-container
          *ngIf="
            !this.partner?.printAllContactPersons &&
            ticket.ticketPartnerStatus === TicketPartnerStatus.PRIVATE_BUSINESS &&
            partner.contactPersons.length > 1
          "
        >
          <ng-container *ngFor="let contactPerson of partner.contactPersons">
            <ul class="list-none p-0 mt-2" *ngIf="contactPerson.id !== mainPartnerContactPerson.id">
              <li class="flex align-items-center flex-wrap">
                <div class="w-3 font-medium">Name:</div>
                <div class="w-16 underline">{{ contactPerson.name }}</div>
              </li>

              <li
                class="flex align-items-center flex-wrap"
                *ngFor="let contact of contactPerson.contacts"
              >
                <div class="w-3 font-medium">
                  <span *ngIf="contact.type === contactType.EMAIL"> - E-mail:</span>
                  <span *ngIf="contact.type === contactType.MOBILE_PHONE"> - Mobile:</span>
                  <span *ngIf="contact.type === contactType.PHONE"> - Cell line:</span>
                  <span *ngIf="contact.type === contactType.FAX"> - Fax:</span>
                </div>
                <div class="w-8">{{ contact.value }}</div>
              </li>
            </ul>
          </ng-container>
        </ng-container>
      </div>
      <hr />
      <div class="col-12">
        <ul class="list-none flex justify-content-between p-0 m-0">
          <li class="flex align-items-center flex-grow-1">
            <div class="font-medium">PO/WO/SALES:&nbsp;</div>
            <div class="w-16">{{ ticket.externalReferenceCode || '-' }}</div>
          </li>
          <ng-container *ngIf="configService.isEnabledWarranty()">
            <li class="flex align-items-center flex-grow-1">
              <div class="font-medium">Delivery date:&nbsp;</div>
              <div class="w-16">{{ ticket.deliveryDate | date: configService.dateFormat }}</div>
            </li>
            <li class="flex align-items-center flex-grow-1">
              <div class="font-medium">Warranty:&nbsp;</div>
              <div class="w-16">{{ ticket.warrantyTerms }}</div>
            </li>
          </ng-container>
        </ul>
      </div>
      <hr />
      <div class="col-12">
        <div class="font-bold mb-3">Issue / Problem</div>
        <span style="white-space: pre-line">{{ ticket.issue }}</span>
      </div>
      <hr />
      <div class="col-12">
        <div class="font-bold mb-3">Service report</div>
        <span style="white-space: pre-line">{{
          ticket.report ? ticket.report : 'No report available yet.'
        }}</span>
      </div>
      <hr />

      <div class="col-6 h-7rem flex">
        <div class="font-medium align-self-end">Customer signature:</div>
        <div style="width: 180px; border-bottom: 1px solid black; margin: 20px 0 0 20px">
          <div style="width: 80%; padding: 0 5px; float: left; display: block">
            <img
              *ngIf="signature != null"
              alt="'signature.jpg'"
              src="{{ signature }}"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 pagebreak" *ngIf="pagebreak"></div>
</ng-container>
