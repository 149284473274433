import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RequestService} from './service/request.service';
import {CommunicationService} from './service/communication.service';
import {ApiService} from './service/api.service';
import {
  AreaApi,
  CatalogApi,
  ChatApi,
  CommentApi,
  DomainManagementApi,
  GalleryApi,
  GeocodingApi,
  GlobalConfigApi,
  InstructionApi,
  InventoryApi,
  InvoiceApi,
  PartnerApi,
  PayFormApi,
  PaymentApi,
  ReviewsApi,
  RouteApi,
  SchedulerApi,
  ServiceCategoryApi,
  SignatureApi,
  StatsApi,
  TagApi,
  TechnicianApi,
  TicketApi,
  UserApi,
  ZipCodeApi,
} from './endpoints';
import {HttpClientModule} from '@angular/common/http';
import {CommunicationFilterableService} from './service/communication-filtarable.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
  providers: [
    InvoiceApi,
    PartnerApi,
    PayFormApi,
    StatsApi,
    GalleryApi,
    SignatureApi,
    TechnicianApi,
    ReviewsApi,
    TicketApi,
    GeocodingApi,
    UserApi,
    InventoryApi,
    InstructionApi,
    CatalogApi,
    PaymentApi,
    CommentApi,
    ChatApi,
    DomainManagementApi,
    SchedulerApi,
    ZipCodeApi,
    AreaApi,
    GlobalConfigApi,
    ServiceCategoryApi,
    TagApi,
    RequestService,
    CommunicationService,
    CommunicationFilterableService,
    RouteApi,
    ApiService,
  ],
})
export class ApiModule {
}
