<div class="grid">
  <div class="col">
    <label for="mapAddress"
      ><span *ngIf="showMap">Autofill address search</span>
      <input
        #search
        [value]="address?.addressFormatted || ''"
        autocomplete="false"
        id="mapAddress"
        pInputText
        [disabled]="disabled"
        placeholder="Type address to search"
        type="text"
      />
    </label>
  </div>

  <div *ngIf="showMap" class="col-fixed pt-5">
    <tj-address-link [address]="latestAddress" [showAddress]="false"></tj-address-link>
  </div>
</div>
