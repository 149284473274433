import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {EntityListResponseModel, IdResponseModel} from '../../../../api/model/shared/Response.model';
import {ReviewItemModel, ReviewModel} from '../../../../api/model/reviews/review.item.model';
import {NotificationService} from '../../../../core/services';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'tj-reviews-list',
  templateUrl: './tj-reviews-list.component.html',
  styleUrls: ['./tj-reviews-list.component.scss'],
})
export class TjReviewsListComponent implements OnInit, OnDestroy {
  reviews: ReviewItemModel[] = [];

  showForm: boolean = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private api: ApiService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    this.getReviews();
  }

  getReviews() {
    this.api.reviews
      .getReviews()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: EntityListResponseModel<ReviewItemModel>) => {
          this.reviews = data.response;
        },
        error => {
          console.error('Failed to fetch reviews', error);
        },
      );
  }

  addReview(newReview: ReviewModel) {
    this.api.reviews
      .create(newReview)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: IdResponseModel) => {
          const review: ReviewItemModel = {
            id: response.response.id,
            url: newReview.url,
            name: newReview.name,
          };

          this.reviews.push(review);
          this.showForm = false;
          this.notificationService.success('Successfully created');
        },
        error => {
          console.error('Failed to create review', error);
        },
      );
  }

  cancelReview() {
    this.showForm = false;
  }

  deleteReview(index: number) {
    const reviewToDelete = this.reviews[index];
    this.api.reviews
      .delete(reviewToDelete['id'])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.reviews.splice(index, 1);
          this.notificationService.success('Successfully deleted');
        },
        error => {
          console.error('Failed to delete review', error);
        },
      );
  }

  onCopySuccess() {
    this.notificationService.copied();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
