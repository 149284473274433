import {RxStompConfig} from '@stomp/rx-stomp';
import SockJS from 'sockjs-client/dist/sockjs';
import {environment} from '../../../../environments/environment';

export const TjRxStompConfig: RxStompConfig = {
  webSocketFactory: () => new SockJS(environment.wsApiEndpoint),

  heartbeatIncoming: 0,

  reconnectDelay: 200,

  //Should be removed after all testings
  //     debug: (msg: string): void => {
  //       console.log(new Date(), msg);
  //     },
};
