import {Component, Input, OnInit} from '@angular/core';
import {TicketTechnician} from '../../../../../../api/model/TicketTechnicians.model';

@Component({
  selector: 'tj-technician-print',
  templateUrl: './ticket-technician-print.component.html',
  styleUrls: ['./ticket-technician-print.component.scss'],
})
export class TicketTechnicianPrintComponent implements OnInit {
  _technicians: TicketTechnician[] = [];

  @Input()
  set technicians(technicians: TicketTechnician[]) {
    this._technicians = technicians.filter(it => it.personnelCode);
  }

  get technicians(): TicketTechnician[] {
    return this._technicians;
  }

  constructor() {}

  ngOnInit() {}
}
