import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Page} from '../../../../api/model/shared/Page.model';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {NotificationService} from '../../../../core/services';
import {UntypedFormGroup} from '@angular/forms';
import {TechnicianSearchService} from '../../services/technician-search.service';
import {TechnicianSearchFilterModel} from '../../models/technician-search-filter.model';

@Component({
  selector: 'tj-technician-search',
  templateUrl: './technician-search.component.html',
  styleUrls: ['./technician-search.component.scss'],
})
export class TechnicianSearchComponent implements OnInit {
  @Input() filter: TechnicianSearchFilterModel;

  @Input() page: Page<any>;

  @Output() selectTechnician = new EventEmitter();

  filterForm: UntypedFormGroup;

  constructor(
    private notificationService: NotificationService,
    private technicianSearchService: TechnicianSearchService,
  ) {}

  ngOnInit() {
    this.filterForm = new UntypedFormGroup({});
  }

  onSearchReset(): void {
    this.filterForm.reset();
    this.selectTechnician.emit(null);
    this.technicianSearchService.reset();
  }

  onRemoveFilter(filter): void {
    this.selectTechnician.emit(null);
    this.technicianSearchService.search(filter);
  }

  onFilter() {
    const formValue = this.filterForm.value;
    if (this.filterForm.valid) {
      this.selectTechnician.emit(null);
      this.technicianSearchService.searchByFormValue(formValue.technicianFilter);
    } else {
      FormHelpers.validateAllFormFields(this.filterForm);
    }
  }
}
