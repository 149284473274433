import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'tj-zip-code-insert-example',
  templateUrl: './zip-code-insert-example.component.html',
  styleUrls: ['./zip-code-insert-example.component.scss'],
})
export class ZipCodeInsertExampleComponent implements OnInit, OnDestroy {
  insertInfoDialog: boolean;

  title = 'The structure of valid insert or copy paste Zip Code in input';

  constructor() {}

  ngOnInit() {}

  show() {
    this.insertInfoDialog = true;
  }

  hide() {
    this.insertInfoDialog = false;
  }

  ngOnDestroy(): void {
    this.hide();
  }
}
