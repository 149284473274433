import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {AreaToZipCodeFilter} from '../../../../../api/model/areatozipcode/areaToZipCode.filter';
import {ListSearchService} from '../../../../shared/services/list-search.service';

@Injectable({
  providedIn: 'root',
})
export class AreaToZipCodeSearchService extends ListSearchService<AreaToZipCodeFilter> {
  constructor(route: ActivatedRoute, router: Router) {
    super(route, router);
  }

  searchByFormValue(formValue) {
    const filter: AreaToZipCodeFilter = {
      zipCode: formValue.zipCode,
      travelFee: formValue.travelFee,
      areaCode: formValue.areaCode,
    };

    this.search(filter);
  }

  getSearchUrl() {
    return ['configurations/area/zipCodes'];
  }

  getSearchConfig() {
    return {
      zipCode: {
        searchQueryPrefix: 'zipCode:',
      },
      travelFee: {
        searchQueryPrefix: 'travelFee:',
      },
      areaCode: {
        searchQueryPrefix: 'code:',
      },
    };
  }
}
