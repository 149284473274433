<ul class="list-none p-0 m-0" *ngIf="isMobile">
  <li
    *ngFor="let ticket of tickets"
    class="flex flex-column md:flex-row py-2 border-top-1 border-bottom-1 surface-border md:align-items-center"
  >
    <div class="flex-auto py-1 md:pl-5">
      <div
        class="flex flex-wrap align-items-start sm:align-items-center sm:flex-row sm:justify-content-between surface-border pb-3"
      >
        <div class="w-full sm:w-6 flex align-items-start justify-content-between mt-3 sm:mt-0">
          <div class="flex flex-column">
            <div class="mb-2">
              <span class="mr-3"
                ><p-checkbox [(ngModel)]="selectedTickets" [value]="ticket"></p-checkbox
              ></span>
              <span class="text-900 text-xl font-medium mb-2 sm:mb-3">
                <a [routerLink]="['/tickets', ticket.id]" class="list-link">{{
                  ticket.customer.name
                }}</a>
              </span>
            </div>
            <span class="text-700" *ngIf="isPrivate(ticket)">{{
              ticket.partner && ticket.partner.name ? '(PRIVATE) ' + ticket.partner.name : 'PRIVATE'
            }}</span>
            <span class="text-700" *ngIf="!isPrivate(ticket) && ticket.partner">{{
              ticket.partner.name
            }}</span>
          </div>
          <div class="flex flex-column sm:align-items-end">
            <span class="text-900 text-right text-xl font-medium mb-2">
              <a [routerLink]="['/tickets', ticket.id]" class="list-link">{{ ticket.id }}</a>
            </span>
            <span
              class="text-700 text-s font-medium mb-2 sm:mb-3"
              *ngIf="ticket.externalReferenceCode"
            >
              {{ ticket.externalReferenceCode }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex flex-column">
        <span class="flex justify-content-between align-items-center mb-3">
          <tj-ticket-status [status]="ticket.status"></tj-ticket-status>
          <div>
            <span style="font-size: small" class="px-0 text-center">
              <ng-container *ngIf="ticket.outreachAttempt">
                <span
                  pTooltip="{{ ticket.outreachAttempt.createdBy }}"
                  appendTo="body"
                  tooltipPosition="bottom"
                >
                  {{ ticket.outreachAttempt.createdAt }}
                </span>
                <ng-container *ngIf="ticket.outreachAttempt.contactTypes?.length">
                  <span class="text-center">
                    <ng-container
                      *ngFor="let type of ticket.outreachAttempt.contactTypes; let last = last"
                    >
                      <i
                        [ngClass]="getCustomerContactMethodIconByType(type)"
                        class="mr-1 text-sm hover:text-blue-700"
                      ></i>
                    </ng-container>
                  </span>
                </ng-container>
                <i
                  *ngIf="ticket.outreachAttempt.note"
                  class="pi pi-info-circle hover:text-blue-700"
                  tooltipPosition="left"
                  appendTo="body"
                  pTooltip="{{ ticket.outreachAttempt.note }}"
                ></i>
              </ng-container>
            </span>
          </div>
        </span>

        <span class="inline-flex align-items-center mb-3">
          <i class="pi pi-user text-700 mr-2"></i>
          <span class="text-700 mr-2"
            ><tj-ticket-technicians
              [ticketStatus]="ticket.status"
              [technicians]="ticket.technicians"
            ></tj-ticket-technicians
          ></span>
        </span>

        <span class="inline-flex align-items-center mb-3">
          <i class="pi pi-calendar-times text-700 mr-2"></i>
          <span class="text-700 font-medium mr-2"
            ><tj-ticket-service-date [ticket]="ticket"></tj-ticket-service-date
          ></span>
          <ng-container *tjHasRole="'TICKET_PRINT_PARTNER'">
            <span
              *ngIf="ticket.printedByPartner"
              class="pi pi-check font-bold"
              style="color: var(--primary-color)"
            ></span>
          </ng-container>
          <ng-container *tjHasRole="'TICKET_PRINT_TECHNICIAN'">
            <span
              *ngIf="ticket.printedByTechnician"
              class="pi pi-check font-bold"
              style="color: var(--primary-color)"
            ></span>
          </ng-container>
          <ng-container *tjHasRole="'TICKET_PRINT_ADMIN'">
            <span
              *ngIf="ticket.printedByAdmin"
              class="pi pi-check font-bold"
              style="color: var(--primary-color)"
            ></span>
          </ng-container>
        </span>

        <span
          class="inline-flex align-items-center mb-3"
          *ngIf="ticket.tags && ticket.tags.length > 0"
        >
          <i class="pi pi-tags text-700 mr-2"></i>
          <span class="text-700 mr-2"><tj-ticket-tags [tags]="ticket.tags"></tj-ticket-tags></span>
        </span>

        <span class="flex align-items-center mb-3">
          <span *ngIf="ticket.category">
            <i class="pi pi-info-circle text-700 mr-2"></i>
            <span class="text-700 mr-2">{{ ticket.category }}</span>
          </span>

          <div class="flex-1 inline-flex justify-content-end sm:align-items-end">
            <span
              class="cursor-pointer ml-4 text-blue-700"
              [pTooltip]="'See ticket image(s)'"
              tooltipPosition="left"
              (click)="openMediaDialog(ticket.id, ticket.galleryId)"
              *ngIf="ticket.hasAttachment"
              ><i alt="Photos" class="pi pi-images"></i
            ></span>
            <span class="ml-4" *ngIf="ticket.hasComment"
              ><i alt="Comments" class="pi pi-comments"></i
            ></span>
          </div>
        </span>
      </div>
    </div>
  </li>
</ul>

<p-table
  *ngIf="!isMobile"
  [scrollable]="true"
  scrollDirection="horizontal"
  (onSort)="sort($event)"
  [(selection)]="selectedTickets"
  [autoLayout]="true"
  [customSort]="true"
  [responsive]="true"
  [value]="tickets"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th pSortableColumn="id">
        Ticket
        <p-sortIcon field="id"></p-sortIcon>
      </th>
      <th pSortableColumn="externalReferenceCode">
        WO/PO/SALES
        <p-sortIcon field="externalReferenceCode"></p-sortIcon>
      </th>
      <th pSortableColumn="partner">
        Account
        <p-sortIcon field="partner"></p-sortIcon>
      </th>
      <th>Customer</th>
      <th pSortableColumn="createdAt">
        Created
        <p-sortIcon field="createdAt"></p-sortIcon>
      </th>
      <th>Contacted</th>
      <th pSortableColumn="status">
        Status
        <p-sortIcon field="status"></p-sortIcon>
      </th>
      <th>Techs</th>
      <th pSortableColumn="serviceDate">
        Service
        <p-sortIcon field="serviceDate"></p-sortIcon>
      </th>
      <th>Tags</th>
      <th>Media/Comment</th>
      <th style="width: 3rem">Printed<span class="ml-1">/</span> Marked</th>
    </tr>
  </ng-template>

  <ng-template let-ticket pTemplate="body">
    <tr>
      <td>
        <p-checkbox [(ngModel)]="selectedTickets" [value]="ticket"></p-checkbox>
      </td>
      <td>
        <a [routerLink]="['/tickets', ticket.id]" class="list-link">{{ ticket.id }}</a>
      </td>
      <td>
        <span *ngIf="ticket.externalReferenceCode">
          <a [routerLink]="['/tickets', ticket.id]" class="list-link">{{
            ticket.externalReferenceCode
          }}</a>
        </span>
      </td>
      <td>
        <span *ngIf="isPrivate(ticket)">{{
          ticket.partner && ticket.partner.name ? '(PRIVATE) ' + ticket.partner.name : 'PRIVATE'
        }}</span>
        <span *ngIf="!isPrivate(ticket) && ticket.partner" style="font-weight: bold">{{
          ticket.partner.name
        }}</span>
      </td>
      <td>
        <span style="font-weight: bold"
          ><a [routerLink]="['/tickets', ticket.id]" class="list-link">{{
            ticket.customer.name
          }}</a></span
        >
        <p *ngIf="ticket.category">
          <span style="font-size: small; font-style: italic">{{ ticket.category }}</span>
        </p>
      </td>
      <td>
        {{ ticket.createdAt | date: configService.dateFormat }}
      </td>
      <td style="font-size: smaller" class="px-0 text-center">
        <ng-container *ngIf="ticket.outreachAttempt">
          <span pTooltip="{{ ticket.outreachAttempt.createdBy }}">{{
            ticket.outreachAttempt.createdAt
          }}</span>
          <hr class="m-0 mb-1" />
          <ng-container *ngIf="ticket.outreachAttempt.contactTypes?.length">
            <span class="text-center">
              <ng-container
                *ngFor="let type of ticket.outreachAttempt.contactTypes; let last = last"
              >
                <i
                  [ngClass]="getCustomerContactMethodIconByType(type)"
                  class="mr-1 hover:text-blue-700"
                ></i>
              </ng-container>
            </span>
          </ng-container>
          <i
            *ngIf="ticket.outreachAttempt.note"
            class="pi pi-info-circle hover:text-blue-700"
            pTooltip="{{ ticket.outreachAttempt.note }}"
          ></i>
        </ng-container>
      </td>
      <td>
        <tj-ticket-status [status]="ticket.status"></tj-ticket-status>
      </td>
      <td>
        <tj-ticket-technicians
          [ticketStatus]="ticket.status"
          [technicians]="ticket.technicians"
        ></tj-ticket-technicians>
      </td>
      <td>
        <tj-ticket-service-date [ticket]="ticket"></tj-ticket-service-date>
      </td>
      <td>
        <tj-ticket-tags [tags]="ticket.tags"></tj-ticket-tags>
      </td>
      <td class="text-center">
        <div class="flex">
          <div
            class="mr-2 cursor-pointer"
            [pTooltip]="'See ticket image(s)'"
            tooltipPosition="left"
            (click)="openMediaDialog(ticket.id, ticket.galleryId)"
            *ngIf="ticket.hasAttachment"
          >
            <i alt="Photos" class="pi pi-images"></i>
          </div>
          <div *ngIf="ticket.hasComment"><i alt="Comments" class="pi pi-comments"></i></div>
        </div>
      </td>
      <td class="text-center">
        <ng-container *tjHasRole="'TICKET_PRINT_PARTNER'">
          <span
            *ngIf="ticket.printedByPartner"
            class="pi pi-check"
            style="color: var(--primary-color)"
          ></span>
        </ng-container>
        <ng-container *tjHasRole="'TICKET_PRINT_TECHNICIAN'">
          <span
            *ngIf="ticket.printedByTechnician"
            class="pi pi-check"
            style="color: var(--primary-color)"
          ></span>
        </ng-container>
        <ng-container *tjHasRole="'TICKET_PRINT_ADMIN'">
          <span
            *ngIf="ticket.printedByAdmin"
            class="pi pi-check"
            style="color: var(--primary-color)"
          ></span>
        </ng-container>
      </td>
    </tr>
  </ng-template>
</p-table>
