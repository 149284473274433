<p-panel header="Filters" toggleable="true">
  <form
    (ngSubmit)="onFilter()"
    (reset)="onSearchReset()"
    [formGroup]="filterForm"
    autocomplete="off"
    novalidate
  >
    <div class="grid">
      <div class="col-12">
        <tj-invoice-search-form
          [filter]="filter"
          [form]="filterForm"
          group="invoiceFilter"
        ></tj-invoice-search-form>
      </div>
      <div class="col-12 py-0 flex">
        <tj-invoice-search-labels
          [filterForm]="filterForm"
          (remove)="onRemoveFilter($event)"
          [invoiceFilter]="filter"
        ></tj-invoice-search-labels>
      </div>
      <div class="col-12 align-content-end flex justify-content-between">
        <div class="flex align-items-end">
          <span *ngIf="page">Total: {{ page.totalElements }}</span>
        </div>
        <div>
          <button class="p-button-secondary mr-2" label="Reset" pButton type="reset"></button>
          <button pButton type="submit" label="Search" icon="pi pi-search"></button>
        </div>
      </div>
    </div>
  </form>
</p-panel>
