<div class="p-inputgroup">
  <input
    pInputText
    #input
    [ngModel]="innerValue"
    [disabled]="disabled"
    (keyup)="onChange($event, input.value)"
    placeholder="Percentage"
    (blur)="onBlur()"
    type="text"
    pattern="^\d+(?:\.\d{1,3})?$"
  />
  <span class="p-inputgroup-addon">%</span>
</div>
