<div class="col-12">
  <p-table
    #dt
    [value]="categories"
    [rows]="env.rowsPerPage[0]"
    [paginator]="true"
    [globalFilterFields]="['value', 'name']"
    styleClass="p-datatable-gridlines p-datatable-striped"
    [rowsPerPageOptions]="env.rowsPerPage"
    [rowHover]="true"
    dataKey="id"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="caption">
      <div class="flex">
        <button
          pButton
          label="Clear"
          class="p-button-outlined"
          icon="pi pi-filter-slash"
          (click)="dt.clear()"
        ></button>
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search keyword"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">
          Category
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="value" class="text-center">
          Supported
          <p-sortIcon field="value"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-category>
      <tr>
        <td>{{ category.name }}</td>
        <td class="text-center">
          <p-inputSwitch
            [(ngModel)]="category.supported"
            (onChange)="support(category.id, $event.checked)"
          ></p-inputSwitch>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
