<p-table
  #dt
  [value]="zipCodes"
  [rows]="env.rowsPerPage[0]"
  [rowsPerPageOptions]="env.rowsPerPage"
  [paginator]="true"
  [globalFilterFields]="['zipCode']"
  styleClass="p-datatable-gridlines p-datatable-striped"
  [rowHover]="true"
  dataKey="id"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [showCurrentPageReport]="true"
>
  <ng-template pTemplate="caption">
    <p-panel header="Filters">
      <div class="flex col-12">
        <div class="col-2">
          <button
            pButton
            label="Clear"
            class="p-button-outlined"
            icon="pi pi-filter-slash"
            (click)="dt.clear()"
          ></button>
        </div>
        <div [ngClass]="showAreaCode ? 'col-3' : 'col-4'">
          <p-columnFilter
            type="text"
            field="zipCode"
            placeholder="Zip Code"
            [matchMode]="'contains'"
          ></p-columnFilter>
        </div>
        <div [ngClass]="showAreaCode ? 'col-3' : 'col-4'">
          <p-columnFilter
            type="text"
            field="travelFee"
            placeholder="Travel Fee"
            [matchMode]="'contains'"
          ></p-columnFilter>
        </div>
        <div class="col-3" *ngIf="showAreaCode">
          <p-columnFilter
            type="text"
            field="areaCode"
            placeholder="Area Code"
            [matchMode]="'contains'"
          ></p-columnFilter>
        </div>
      </div>
    </p-panel>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="active">
        Active
        <p-sortIcon field="active"></p-sortIcon>
      </th>
      <th pSortableColumn="zipCode">
        Zip Code
        <p-sortIcon field="zipCode"></p-sortIcon>
      </th>
      <th pSortableColumn="travelFee">
        Additional Travel Fee
        <p-sortIcon field="travelFee"></p-sortIcon>
      </th>
      <th pSortableColumn="areaCode" *ngIf="showAreaCode">
        Area Code
        <p-sortIcon field="areaCode"></p-sortIcon>
      </th>
      <th style="width: 75px" *tjHasRole="'ZIP_CODE_EDIT'"></th>
      <th style="width: 75px" *tjHasRole="'ZIP_CODE_EDIT'"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-zipCode>
    <tr>
      <td style="text-align: center">
        <ng-container *ngIf="zipCode.active">
          <i class="pi pi-check" style="color: var(--primary-color); font-size: 1.3rem"></i>
        </ng-container>
        <ng-container *ngIf="!zipCode.active">
          <i class="pi pi-minus" style="color: red; font-size: 1.3rem"></i>
        </ng-container>
      </td>
      <td>{{ zipCode.zipCode }}</td>
      <td>{{ zipCode.travelFee | currency: 'USD' }}</td>
      <td *ngIf="showAreaCode">{{ zipCode.areaCode }}</td>
      <td class="text-center" *tjHasRole="'ZIP_CODE_EDIT'">
        <button
          *tjHasRole="'GLOBAL_CONFIG_EDIT'"
          pButton
          pRipple
          icon="pi pi-pencil"
          class="p-button-rounded p-button-success"
          (click)="onEditZipCode(zipCode)"
        ></button>
      </td>
      <td class="text-center" *tjHasRole="'ZIP_CODE_EDIT'">
        <button
          *tjHasRole="'GLOBAL_CONFIG_EDIT'"
          pButton
          pRipple
          icon="pi pi-trash"
          class="p-button-rounded p-button-danger"
          (click)="onDeleteZipCode(zipCode)"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>
