import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgSignaturePadOptions, SignaturePadComponent} from '@almothafar/angular-signature-pad';
import {NotificationService} from '../../../../core/services';
import {SignatureModel} from '../../models/Signature.model';
import {LayoutService} from '../../../../layout/service/app.layout.service';
import {Messages} from '../../../../common/messages';
import {ConfirmationService} from 'primeng/api';
import {Ticket} from '../../../../api/model/Ticket.model';
import {ApiService} from '../../../../api/service/api.service';

@Component({
  selector: 'tj-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit {
  @Input() customerSignature: SignatureModel;
  @Input() galleryId: number;
  @Input() ticket: Ticket;
  @Output() updateSignatureView = new EventEmitter();

  @ViewChild('signature')
  signaturePad!: SignaturePadComponent;

  signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 1,
    canvasWidth: 400,
    canvasHeight: 200,
    penColor: 'blue',
    dotSize: 1,
    maxWidth: 1,
    velocityFilterWeight: 1,
  };

  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService,
    private layoutService: LayoutService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    if (this.layoutService.isMobile()) {
      this.signaturePadOptions.canvasWidth = 250;
      this.signaturePadOptions.canvasHeight = 170;
    }
  }

  base64ToBlob(base64String, contentType) {
    contentType = contentType || '';
    const base64WithoutPrefix = base64String.split(',')[1]; // Remove data URI prefix
    const byteCharacters = atob(base64WithoutPrefix);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], {type: contentType});
  }

  saveSignature() {
    if (!this.signaturePad.isEmpty()) {
      const contentType = 'image/jpeg';
      const blob = this.base64ToBlob(this.signaturePad.toDataURL(), contentType);
      const file = new File([blob], 'signature', {
        type: 'image/jpeg',
      });
      if (this.customerSignature.id) {
        this.updateSignature(file);
      } else {
        this.uploadSignature(file);
      }
      this.clear();
    }
  }

  updateSignature(file: File) {
    this.apiService.signatureApi
      .updateSignature(this.ticket.id, this.galleryId, this.customerSignature.id, file)
      .subscribe(
        signature => {
          if (signature) {
            this.updateSignatureView.emit(signature);
          }
        },
        error => {
          this.notificationService.error(error);
        },
      );
  }

  uploadSignature(file: File) {
    this.apiService.signatureApi.uploadFile([file], this.galleryId, this.ticket.id).subscribe(
      signature => {
        if (signature) {
          this.updateSignatureView.emit(signature);
        }
      },
      error => {
        this.notificationService.error(error);
      },
    );
  }

  clear() {
    // Clear the signature pad
    this.signaturePad.clear();
  }

  removeSelected(): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete selected signature?',
      header: 'Signature deletion',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmDeletionSignature',
      accept: () => {
        this.apiService.signatureApi
          .remove(this.galleryId, this.customerSignature.id, this.ticket.id)
          .subscribe(
            file => {
              this.customerSignature.id = null;
              this.customerSignature.url = null;
              this.customerSignature.path = null;
              this.updateSignatureView.emit({response: this.customerSignature});
              this.notificationService.success(Messages.FILE_REMOVED);
            },
            () => {
              this.notificationService.error('Failed to remove the file. Please try again.');
            },
          );
      },
    });
  }
}
