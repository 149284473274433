<div class="p-inputgroup">
  <span class="p-inputgroup-addon">$</span>
  <input
    pInputText
    #input
    [ngModel]="innerValue"
    [disabled]="disabled"
    (keyup)="onChange($event, input.value)"
    (keyup.enter)="onEnterPress()"
    (click)="selectText()"
    (blur)="onBlur()"
    type="text"
    pattern="^\d+(?:\.\d{1,2})?$"
  />
</div>
