import {Pipe, PipeTransform} from '@angular/core';
import {transformDistance} from '../../../api/model/shared/functions';

@Pipe({
  name: 'distance',
})
export class DistancePipe implements PipeTransform {
  transform(value: number, unit: 'km' | 'mi'): string {
    return transformDistance(value, unit);
  }
}
