import {Injectable} from '@angular/core';
import {ApiService} from '../../../api/service/api.service';
import {ConfigService, UserService} from '../../../core/services';
import {Observable, Subject, Subscription} from 'rxjs';
import {ClientSignture} from '../../../api/model/ticket/client-signature';
import {TjRxStompService} from '../../shared/services/tj-rx-stomp.service';
import {Message} from '@stomp/stompjs';
import {environment} from '../../../../environments/environment';
import {User} from '../../../api/model/User.model';
import {PayFormEditModel} from '../../payform/models';

@Injectable()
export class PayFormUpdateCheckService {
  private signature$: Subject<ClientSignture> = new Subject<ClientSignture>();

  private payFormChanged$: Subject<boolean> = new Subject<boolean>();

  private readonly payFormSignature: Observable<ClientSignture> = this.signature$.asObservable();

  private readonly payFormChanged: Observable<boolean> = this.payFormChanged$.asObservable();

  private timeInterval: ReturnType<typeof setInterval>;

  private latestSignature: ClientSignture;

  private mySignature: ClientSignture;

  private topicSubscription: Subscription;

  constructor(
    private api: ApiService,
    private configService: ConfigService,
    private rxStompService: TjRxStompService,
    private userService: UserService,
  ) {}

  public initPayFormUpdateTracking(payForm: PayFormEditModel) {
    this.mySignature = this.configService.getClientSignature();
    this.latestSignature = this.mySignature;
    this.payFormSignature.subscribe((actualPayFormSignature: ClientSignture) => {
      // set initial signature
      if (!this.latestSignature) {
        this.latestSignature = actualPayFormSignature;
        return;
      }

      // Was the payForm changed?
      if (!actualPayFormSignature.isSame(this.latestSignature)) {
        // Was the payForm changed by the same client?
        if (!this.mySignature.isSameClient(actualPayFormSignature)) {
          this.latestSignature = actualPayFormSignature;
          this.payFormChanged$.next(true);
        }
      }
    });
    this.userService.fetchCurrentUser().subscribe((user: User) => {
      this.topicSubscription = this.rxStompService
        .watch('/topic/' + user.realm + '/' + environment.payFromSignatureWSPath + '/' + payForm.id)
        .subscribe((message: Message) => {
          const value = ClientSignture.buildFromBase64String(JSON.parse(message.body).signature);
          this.signature$.next(value);
        });
    });
    return this.payFormChanged;
  }

  private loadLastPayFormSignature(id: number): void {
    this.api.payForm.getLastSignature(id).subscribe(
      (value: ClientSignture) => {
        this.signature$.next(value);
      },
      err => {
        console.log('Cannot load pay form signature. Checks will be cancelled.', err);
        clearInterval(this.timeInterval);
      },
    );
  }

  destroy() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
    if (this.topicSubscription) {
      this.topicSubscription.unsubscribe();
    }
  }
}
