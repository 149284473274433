<div
  class="status-pill"
  [pTooltip]="getTooltip()"
  [ngClass]="{
    'status-new': status === TicketStatus.NEW,
    'status-customer-will-call': status === TicketStatus.CUSTOMER_WILL_CALL,
    'status-canceled': status === TicketStatus.CANCELED,
    'status-assigned': status === TicketStatus.ASSIGNED,
    'status-scheduled': status === TicketStatus.SCHEDULED,
    'status-rescheduled': status === TicketStatus.RESCHEDULE,
    'status-complete': status === TicketStatus.COMPLETED,
    'status-verified': status === TicketStatus.VERIFIED,
    'status-incomplete': status === TicketStatus.INCOMPLETE,
    'status-invoiced': status === TicketStatus.INVOICED,
  }"
>
  {{ config.findTicketStatusByCode(status) }}
</div>
