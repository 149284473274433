import {Component, Input, OnInit} from '@angular/core';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {ContactType} from '../../enums/contact.type';
import {NotificationService} from '../../../../core/services';

@Component({
  selector: 'tj-contact-person-details',
  templateUrl: './contact-person-details.component.html',
  styleUrls: ['./contact-person-details.component.scss'],
})
export class ContactPersonDetailsComponent implements OnInit {
  @Input() contactPerson: ContactPerson;

  @Input() title = 'Contact person';

  public contactType = ContactType;

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {}

  onCopySuccess(event: boolean, contactType: string) {
    if (event) {
      if (contactType === ContactType.PHONE || contactType === ContactType.MOBILE_PHONE) {
        this.notificationService.info('Phone number is copied.');
        return;
      }

      if (contactType === ContactType.EMAIL) {
        this.notificationService.info('Email is copied.');
        return;
      }

      if (contactType === ContactType.FAX) {
        this.notificationService.info('Fax is copied.');
        return;
      }
    } else {
      this.notificationService.error('Failed to copy the text.');
    }
  }
}
