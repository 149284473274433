<p-calendar
  #datepicker
  [ngModel]="innerValue"
  [placeholder]="placeholder"
  [showIcon]="true"
  [minDate]="options.minDate"
  (onInput)="onInput($event.srcElement.value)"
  [disabled]="disabled"
  (onClearClick)="onClearDate()"
  (onSelect)="onSetDate($event)"
  showButtonBar="true"
  appendTo="body"
  [timeOnly]="timeOnly"
></p-calendar>
