import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Page} from '../../../../api/model/shared/Page.model';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {NotificationService} from '../../../../core/services';
import {UntypedFormGroup} from '@angular/forms';
import {PartnerSearchService} from '../../services/partner-search.service';
import {PartnerSearchFilterModel} from '../../models/partner-search-filter.model';

@Component({
  selector: 'tj-partner-search',
  templateUrl: './partner-search.component.html',
  styleUrls: ['./partner-search.component.scss'],
})
export class PartnerSearchComponent implements OnInit {
  @Input() filter: PartnerSearchFilterModel;

  @Input() page: Page<any>;

  @Output() selectPartner = new EventEmitter();

  filterForm: UntypedFormGroup;

  constructor(
    private notificationService: NotificationService,
    private partnerSearchService: PartnerSearchService,
  ) {
    this.filterForm = new UntypedFormGroup({});
  }

  ngOnInit() {}

  onRemoveFilter(filter): void {
    this.selectPartner.emit(null);
    this.partnerSearchService.search(filter);
  }

  onSearchReset(): void {
    this.selectPartner.emit(null);
    this.filterForm.reset();
    this.partnerSearchService.reset();
  }

  onFilter(): void {
    const formValue = this.filterForm.value;
    if (this.filterForm.valid) {
      this.selectPartner.emit(null);
      this.partnerSearchService.searchByFormValue(formValue);
    } else {
      FormHelpers.validateAllFormFields(this.filterForm);
    }
  }
}
