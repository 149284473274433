import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

import {AreaToZipCodeModel} from '../../../../../../api/model/AreaToZipCode.model';
import {ConfigService} from '../../../../../../core/services';
import {AreaToZipCodeFilter} from '../../../../../../api/model/areatozipcode/areaToZipCode.filter';

@Component({
  selector: 'tj-area-to-zip-code-search-chips',
  templateUrl: './area-to-zip-code-search-chips.component.html',
  styleUrls: ['./area-to-zip-code-search-chips.component.scss'],
})
export class AreaToZipCodeSearchChipsComponent implements OnInit, OnChanges {
  @Input() areaToZipCodeFilter: AreaToZipCodeFilter;

  @Output() remove = new EventEmitter();

  areaToZipCodesMap: {[id: number]: AreaToZipCodeModel} = {};

  filterNames = {
    zipCode: 'Zip Code',
    travelFee: 'Travel Fee',
    areaCode: 'Area Code',
  };

  constructor(public config: ConfigService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter && changes.filter.currentValue === null) {
      this.areaToZipCodeFilter = {} as AreaToZipCodeModel;
    }
  }

  ngOnInit() {}

  onRemoveChip(filterName) {
    const copyOfFilter = {...this.areaToZipCodeFilter};
    if (copyOfFilter[filterName]) {
      copyOfFilter[filterName] = null;
    }
    this.remove.emit(copyOfFilter);
  }
}
