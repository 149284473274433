<form [formGroup]="customEmailForm" *ngIf="customEmailForm" (ngSubmit)="submit()">
  <div class="field">
    <div class="col-12">
      <tj-field label="Email" [control]="getCustomEmailFormControl('email')">
        <input type="text" pInputText formControlName="email" />
      </tj-field>
    </div>
  </div>

  <div class="flex flex-grow-1 justify-content-end">
    <div>
      <button
        pButton
        [disabled]="!customEmailForm.valid"
        type="submit"
        label="Send"
        icon="pi pi-send"
      ></button>
    </div>
  </div>
</form>
