import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {ImageCropInfo} from '../../models';

@Component({
  selector: 'tj-image-cropper',
  templateUrl: './tj-image-cropper.component.html',
  styleUrls: ['./tj-image-cropper.component.scss']
})
export class TjImageCropperComponent implements OnInit {

  outputType = 'base64';
  imageQuality: 100;
  aspectRatio: number = 4 / 3;
  format: 'png';

  // angle = 0; // is it used anywhere?
  cropImgPreview: any = '';

  @ViewChild(ImageCropperComponent)
  imageCropper: ImageCropperComponent;

  @Output() crop: EventEmitter<ImageCropInfo> = new EventEmitter<ImageCropInfo>();

  cropInfo: ImageCroppedEvent;

  @Input() imageUrl: string;

  image64: any;

  constructor() {
  }

  ngOnInit() {
    this.urlToBase64();
  }

  rotateLeft() {
    // rotateLeft method is not supported anymore needs to be fixed
    // this.imageCropper.rotateLeft(); // not supported by cropper anymore. Need to implement custom rotation
    // this.angle -= 90; is it used anywhere?
  }

  rotateRight() {
    // rotateRight method is not supported anymore needs to be fixed
    // this.imageCropper.rotateRight(); // not supported by cropper anymore. Need to implement custom rotation
    // this.angle += 90; // is it used anywhere?
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.cropInfo = event;
    this.cropImgPreview = event.base64;
  }


  cropImage(): void {
    this.crop.emit(this.cropImgPreview);
  }

  private urlToBase64(): void {
    const toDataURL: Function = url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }));
    toDataURL(this.imageUrl)
      .then(dataUrl => {
        this.image64 = dataUrl;
      });
  }
}
