import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ConfigService} from '../../../../core/services';

@Component({
  selector: 'tj-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() formControlName;

  @Input() onlyFuture = true;

  @Input() placeholder;

  @Input() timeOnly = false;

  @ViewChild('datepicker') elementRef;

  @Input() disabled: boolean;

  @Input() utc = false;

  @Output() clearDate = new EventEmitter();

  @Output() dateChange = new EventEmitter();

  private propagateChanges;

  options;

  innerValue;

  constructor(public config: ConfigService) {
    this.options = {...config.datePickerOptions};
  }

  ngOnInit() {
    if (!this.onlyFuture) {
      this.options.minDate = null;
    }
  }

  onSetDate(date) {
    // propagateChange is not registered on the initial call (view -> model)
    if (this.propagateChanges) {
      // wrapped in try catch as writeValue called before onDestroy that's why propagate change fires error
      try {
        // convert current date object to UTC and then create new date so timezone offset ignored
        if (date) {
          // setting time to the middle of the day so it is most likely the same day in different timezones
          // hate this solution
          const utcDate = new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 13, 0, 0, 0),
          );
          this.dateChange.emit(utcDate.toISOString());
          this.propagateChanges(utcDate.toISOString());
        } else {
          this.propagateChanges(null);
        }
      } catch (e) {}
    }
  }

  onClearDate() {
    this.innerValue = null;
    if (this.propagateChanges) {
      this.propagateChanges(null);
    }
    this.clearDate.emit();
  }

  ngOnDestroy() {}

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  writeValue(value: any): void {
    this.innerValue = value ? new Date(value) : null;
  }

  registerOnChange(fn: any): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: any): void {}

  onInput(value: any) {
    if (!value) {
      this.onSetDate(null);
    }
  }
}
