export class ClientSignture {
  signature: string;

  timestamp: string;

  constructor(signature: string, timestamp: string) {
    this.signature = signature;
    this.timestamp = timestamp;
  }

  public static generateSignature(): ClientSignture {
    function uniqueClientId() {
      return Date.now().toString(36) + Math.random().toString(36).substring(2);
    }

    return new ClientSignture(uniqueClientId(), new Date().toISOString());
  }

  public static buildFromBase64String(base64: string): ClientSignture {
    try {
      if (!base64) {
        return new ClientSignture(null, null);
      }
      const signature = JSON.parse(window.atob(base64));
      return new ClientSignture(signature.signature, signature.timestamp);
    } catch (e) {
      throw new Error('Invalid base64 string');
    }
  }

  isSameClient(signature: ClientSignture): boolean {
    return this.signature === signature.signature;
  }

  isSameTimestamp(signature: ClientSignture): boolean {
    return this.timestamp === signature.timestamp;
  }

  isSame(signature: ClientSignture): boolean {
    return this.isSameClient(signature) && this.isSameTimestamp(signature);
  }

  refreshTimestamp() {
    this.timestamp = new Date().toISOString();
  }

  convertToBase64(): string {
    return window.btoa(JSON.stringify(this));
  }
}
