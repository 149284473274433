import {ContactPerson} from './ContactPerson.model';
import {AddressDetails} from './Address.model';
import {plainToClass, Type} from 'class-transformer';
import {ContactType} from '../../main/shared/enums/contact.type';
import {OutreachAttemptModel} from './outreachattempt/outreachAttemptModel';

export class Customer {
  id: number;

  name: string;

  @Type(() => AddressDetails)
  address: AddressDetails;

  @Type(() => ContactPerson)
  contactPersons: ContactPerson[];

  outreachAttempts: OutreachAttemptModel[];

  mainContactPersonId: number;

  addressConfirmed: boolean;

  static buildMinimalCustomer() {
    const customer = new Customer();
    customer.contactPersons = plainToClass(ContactPerson, [
      {
        name: null,
        isMain: true,
        notificationReceiver: false,
        contacts: [
          {type: ContactType.EMAIL, value: ''},
          {type: ContactType.MOBILE_PHONE, value: ''},
        ],
      },
    ]);
    return customer;
  }

  static buildCopy(originalCustomer: Customer): Customer {
    return plainToClass(Customer, {...originalCustomer});
  }

  getMainContactPerson() {
    return this.contactPersons.find(contactPerson => contactPerson.isMain === true);
  }

  getSecondaryContactPersons() {
    // add filter once backend return correct info
    return this.contactPersons.filter(contactPerson => {
      return contactPerson.id !== this.mainContactPersonId;
    });
  }
}
