import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ZipCode} from '../../../../../../api/model/ZipCode.model';
import {environment} from '../../../../../../../environments/environment';

@Component({
  selector: 'tj-zip-code-list',
  templateUrl: './zip-code-list.component.html',
  styleUrls: ['./zip-code-list.component.scss'],
})
export class ZipCodeListComponent implements OnInit {
  protected readonly env = environment;

  @Input('zipCodeDialog') zipCodeDialog: boolean;

  zipCode: ZipCode;

  @Input('zipCodes') zipCodes: ZipCode[];

  @Input('showAreaCode') showAreaCode = true;

  @Output('onUpdate') updateEmitter: EventEmitter<ZipCode> = new EventEmitter<any>();

  @Output('onDelete') deleteEmitter: EventEmitter<ZipCode> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onEditZipCode(zipCode: ZipCode) {
    this.zipCode = {...zipCode};
    this.zipCodeDialog = true;
    this.updateEmitter.emit(zipCode);
  }

  onDeleteZipCode(zipCodeToRemove: ZipCode) {
    this.deleteEmitter.emit(zipCodeToRemove);
  }
}
