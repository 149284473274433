import {Partner} from '../../../../api/model/Partner.model';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {ConfigService} from '../../../../core/services';

@Component({
  selector: 'tj-partner-contact-person-selector',
  templateUrl: './partner-contact-person-selector.component.html',
  styleUrls: ['./partner-contact-person-selector.component.scss'],
})
export class PartnerContactPersonSelectorComponent implements OnInit {
  @Input() partner: Partner;

  @Input() mainContactPerson: ContactPerson;

  @Input() form: UntypedFormGroup;

  @Output() contactPersonChange = new EventEmitter();

  originalContactPerson = null;

  useRegisteredContactPerson = true;

  constructor(protected configService: ConfigService) {}

  ngOnInit() {
    if (this.partner && this.mainContactPerson) {
      this.useRegisteredContactPerson = this.partner.contactPersons.some(contactPerson => {
        return contactPerson.id === this.mainContactPerson.id;
      });
      this.selectMainContactPerson(this.mainContactPerson);
    }
  }

  onUseRegisteredContactPerson() {
    if (this.originalContactPerson) {
      this.mainContactPerson = this.originalContactPerson;
      this.selectMainContactPerson(this.mainContactPerson);
    }
    this.useRegisteredContactPerson = true;
  }

  onUseNewContactPerson() {
    if (this.mainContactPerson) {
      this.originalContactPerson = this.mainContactPerson;
    }
    this.useRegisteredContactPerson = false;
    this.mainContactPerson = null;
    this.resetMainContactPerson();
  }

  onContactPersonChange(contactPerson) {
    this.contactPersonChange.emit(contactPerson);
    this.selectMainContactPerson(contactPerson);
  }

  private selectMainContactPerson(contactPerson) {
    if (!contactPerson) {
      return this.resetMainContactPerson();
    }
    this.partner.contactPersons.forEach(cp => {
      cp.isMain = cp.id === contactPerson.id;
    });
  }

  private resetMainContactPerson() {
    this.partner.contactPersons.forEach(cp => {
      cp.isMain = false;
    });
  }
}
