<nav class="navbar p-toolbar fixed-top navbar-light bg-light hide-print">
  <button
    class="mr-2 p-button-info hide-print"
    label="Back"
    type="button"
    icon="pi pi-arrow-circle-left"
    pButton
    (click)="back()"
    pRipple
  ></button>
</nav>
<ng-container *ngFor="let id of ids; index as i">
  <tj-invoice-print
    [invoiceId]="id"
    [configs]="configs"
    [pagebreak]="!(i === ids.length - 1)"
  ></tj-invoice-print>
</ng-container>
