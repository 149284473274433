import {BrowserModule} from '@angular/platform-browser';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {FullCalendarModule} from '@fullcalendar/angular';
import {CalendarComponent} from './pages/calendar.component';
import {RouterModule} from '@angular/router';
import {AuthGuardService} from '../../core/services';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {TicketModule} from '../ticket/ticket.module';
import {TechnicianStopFormComponent} from './stops/stop/technician-stop-form.component';
import {CalendarSearchFormComponent} from './form/calendar-search-form.component';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [TechnicianStopFormComponent, CalendarComponent, CalendarSearchFormComponent],
  imports: [
    BrowserModule,
    FullCalendarModule, // register FullCalendar with your app
    RouterModule.forRoot([
      {
        path: 'scheduler/calendar',
        component: CalendarComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [],
          breadcrumb: 'Scheduler & Calendar',
        },
      },
    ]),
    ReactiveFormsModule,
    SharedModule,
    TicketModule,
  ],
  providers: [],
  bootstrap: [CalendarComponent],
})
export class SchedulerModule {}
