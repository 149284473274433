<div *ngIf="attachments">
  <p-tabView (onChange)="onTabChange($event.index)">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <span
          [ngStyle]="{
            'font-weight':
              galleryItemsCounts?.imageCount > 0 || newAttachments.includes('IMAGE')
                ? 'bold'
                : 'normal',
            color:
              galleryItemsCounts?.imageCount > 0 || newAttachments.includes('IMAGE') ? 'black' : '',
          }"
          >Images</span
        >
      </ng-template>
      <tj-image-edit
        #editComponent
        [galleryId]="galleryId"
        (imageEditDone)="onImageEditDone($event)"
        [ticketId]="ticket.id"
      ></tj-image-edit>
      <div>
        <div
          *ngIf="galleryManager.selectedAttachments?.size > 0"
          class="col-12 flex lg:justify-content-end md:justify-content-end justify-content-center mb-2"
        >
          <button
            pButton
            class="p-button-success"
            (click)="downloadImageZipFile()"
            icon="pi pi-file-export"
            label="Download Zip"
          ></button>
        </div>
        <div
          class="col-12 flex lg:justify-content-end md:justify-content-end justify-content-center"
        >
          <button pButton (click)="selectAll()" label="Select all"></button>

          <button
            pButton
            class="next-button ml-4"
            *ngIf="galleryManager.selectedAttachments?.size > 0"
            (click)="unselectAll()"
            label="Unselect all"
          ></button>

          <button
            (click)="removeSelected()"
            *ngIf="galleryManager.selectedAttachments?.size > 0 && canEditAttachments()"
            class="p-button-danger next-button ml-4"
            icon="pi pi-trash"
            pButton
          ></button>

          <button
            pButton
            icon="pi pi-download"
            class="ml-4"
            (click)="download('IMAGE')"
            *ngIf="galleryManager.selectedAttachments?.size > 0"
          ></button>
        </div>
        <div
          class="align-items-center border-black-alpha-20 border-top-1 mt-4 py-4 lg:px-8 px-1"
          *ngFor="let image of paginatedAttachments(); let index = index"
        >
          <div class="col-12 text-center" *ngIf="index === 0">
            <span class="font-bold private"
              >For higher resolution images please select and download pictures</span
            >
          </div>
          <div class="flex align-items-center justify-content-between">
            <div class="flex align-items-center">
              <span class="mr-2 text-700 text-lg"
                >{{ (currentPage - 1) * itemsPerPage + index + 1 }}.</span
              >
              <p-checkbox
                binary="true"
                [(ngModel)]="image.selected"
                (onChange)="select(image, $event.checked, index + 1)"
              ></p-checkbox>
            </div>
            <div class="flex align-items-center">
              <p-button
                (onClick)="editImage(image, index)"
                class="ml-3"
                icon="pi pi-pencil"
              ></p-button>
              <p-button
                (onClick)="previewImage(image, index)"
                class="ml-3"
                icon="pi pi-eye"
              ></p-button>
              <button
                (click)="remove(image, index)"
                class="p-button-danger next-button ml-3"
                icon="pi pi-trash"
                pButton
              ></button>
            </div>
          </div>
          <div
            class="col-12 text-center flex flex-column align-items-center"
            *ngIf="image && image.cropPath"
          >
            <img
              id="image-{{ image.id }}"
              [src]="getImageThumbnailUrl(image, index) + '?v=' + image.version"
              style="width: inherit"
              alt="{{ image.fileName + '_' + (index + 1) + image.extension }}"
            />
            <div
              *tjHasRole="'TICKET_VIEW_ATTACHMENTS_METADATA'"
              class="flex align-items-center"
              [pTooltip]="image.createdBy ? image.createdBy : image.modifiedBy"
            >
              <span class="text-center" *ngIf="image.modifiedBy !== 'SYSTEM'">
                {{ image.modifiedBy ? image.modifiedBy : image.createdBy }} -
              </span>
              <span class="ml-1">{{
                image.updatedAt
                  ? (image.updatedAt | date: configService.dateFormatFull)
                  : (image.createdAt | date: configService.dateFormatFull)
              }}</span>
              <span
                *ngIf="image.updatedAt || image.createdAt || image.modifiedBy || image.createdBy"
                class="cursor-pointer ml-1 pi pi-eye text-purple-500"
              >
              </span>
            </div>
          </div>
        </div>
        <p-paginator
          *ngIf="paginatedAttachments().length > 0"
          [rows]="itemsPerPage"
          [totalRecords]="attachments.length"
          [pageLinkSize]="3"
          (onPageChange)="onPageChange($event)"
          [rowsPerPageOptions]="[10, 20, 30]"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
        </p-paginator>
      </div>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <span
          [ngStyle]="{
            'font-weight':
              galleryItemsCounts?.videoCount > 0 || newAttachments.includes('VIDEO')
                ? 'bold'
                : 'normal',
            color:
              galleryItemsCounts?.videoCount > 0 || newAttachments.includes('VIDEO') ? 'black' : '',
          }"
          >Videos</span
        >
      </ng-template>
      <div>
        <div class="flex justify-content-end">
          <button pButton class="" label="Select all" (click)="selectAll()"></button>
          <button
            pButton
            class="p-button next-button ml-4"
            *ngIf="galleryManager.selectedAttachments?.size > 0"
            (click)="unselectAll()"
            label="Unselect all"
          ></button>
          <button
            (click)="removeSelected()"
            *ngIf="galleryManager.selectedAttachments?.size > 0 && canEditAttachments()"
            class="p-button-danger ml-4"
            icon="pi pi-trash"
            pButton
          ></button>
          <button
            pButton
            class="ml-4"
            icon="pi pi-download"
            (click)="download('VIDEO')"
            *ngIf="galleryManager.selectedAttachments?.size > 0"
          ></button>
        </div>
        <div
          class="align-items-center border-black-alpha-20 border-top-1 mt-4 py-4 lg:px-8 px-1"
          *ngFor="let video of attachments; let index = index"
        >
          <div class="align-items-center flex justify-content-between">
            <p-checkbox
              binary="true"
              [(ngModel)]="video.selected"
              (onChange)="select(video, $event, index + 1)"
            ></p-checkbox>
            <button
              (click)="remove(video, index)"
              class="p-button-danger next-button ml-3"
              icon="pi pi-trash"
              pButton
            ></button>
          </div>
          <div class="col-12 text-center flex flex-column align-items-center">
            <video width="320" height="240" controls preload="none">
              <source [src]="video.url" />
            </video>
            <div
              *tjHasRole="'TICKET_VIEW_ATTACHMENTS_METADATA'"
              class="flex align-items-center"
              [pTooltip]="video.createdBy ? video.createdBy : video.modifiedBy"
            >
              <span class="text-center">
                {{ video.modifiedBy ? video.modifiedBy : video.createdBy }} -
              </span>
              <span class="ml-1">{{
                video.updatedAt
                  ? (video.updatedAt | date: configService.dateFormatFull)
                  : (video.createdAt | date: configService.dateFormatFull)
              }}</span>
              <span
                *ngIf="video.updatedAt || video.createdAt || video.modifiedBy || video.createdBy"
                class="cursor-pointer ml-1 pi pi-eye text-purple-500"
              >
              </span>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <span
          [ngStyle]="{
            'font-weight':
              galleryItemsCounts?.documentCount > 0 || newAttachments.includes('DOCUMENT')
                ? 'bold'
                : 'normal',
            color:
              galleryItemsCounts?.documentCount > 0 || newAttachments.includes('DOCUMENT')
                ? 'black'
                : '',
          }"
          >Documents</span
        >
      </ng-template>
      <div>
        <div class="flex justify-content-end">
          <button pButton (click)="selectAll()" label="Select all"></button>
          <button
            pButton
            class="p-button next-button ml-4"
            *ngIf="galleryManager.selectedAttachments?.size > 0"
            (click)="unselectAll()"
            label="Unselect all"
          ></button>
          <button
            (click)="removeSelected()"
            *ngIf="galleryManager.selectedAttachments?.size > 0 && canEditAttachments()"
            class="p-button-danger ml-4"
            icon="pi pi-trash"
            pButton
          ></button>
          <button
            pButton
            icon="pi pi-download"
            class="ml-4"
            (click)="download('DOCUMENT')"
            *ngIf="galleryManager.selectedAttachments?.size > 0"
          ></button>
        </div>
        <div
          class="align-items-center border-black-alpha-20 border-top-1 mt-4 py-4 lg:px-8 px-1"
          *ngFor="let document of attachments; let index = index"
        >
          <div class="align-items-center flex justify-content-between">
            <div>
              <span class="mr-2 text-700 text-lg">{{ index + 1 }}.</span>
              <p-checkbox
                binary="true"
                [(ngModel)]="document.selected"
                (onChange)="select(document, $event, index + 1)"
              ></p-checkbox>
            </div>
            <div class="flex align-items-center">
              <p-button
                *ngIf="document?.extension === '.pdf'"
                (onClick)="viewPdf(document)"
                class="ml-3"
                icon="pi pi-eye"
              ></p-button>
              <button
                (click)="remove(document, index)"
                class="p-button-danger next-button ml-3"
                icon="pi pi-trash"
                pButton
              ></button>
            </div>
          </div>
          <div class="col-12 text-center flex flex-column align-items-center">
            <span>{{ document.fileName + document.extension }}</span>
            <div
              *tjHasRole="'TICKET_VIEW_ATTACHMENTS_METADATA'"
              class="flex align-items-center"
              [pTooltip]="document.createdBy ? document.createdBy : document.modifiedBy"
            >
              <span class="text-center">
                {{ document.modifiedBy ? document.modifiedBy : document.createdBy }} -
              </span>
              <span>{{
                document.updatedAt
                  ? (document.updatedAt | date: configService.dateFormatFull)
                  : (document.createdAt | date: configService.dateFormatFull)
              }}</span>
              <span
                class="ml-1"
                *ngIf="
                  document.updatedAt ||
                  document.createdAt ||
                  document.modifiedBy ||
                  document.createdBy
                "
                class="cursor-pointer ml-1 pi pi-eye text-purple-500"
              >
              </span>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <span
          [ngStyle]="{
            'font-weight':
              galleryItemsCounts?.audioCount > 0 || newAttachments.includes('AUDIO')
                ? 'bold'
                : 'normal',
            color:
              galleryItemsCounts?.audioCount > 0 || newAttachments.includes('AUDIO') ? 'black' : '',
          }"
          >Audio</span
        >
      </ng-template>
      <div>
        <div class="flex justify-content-end">
          <button pButton label="Select all" (click)="selectAll()"></button>
          <button
            pButton
            class="p-button next-button ml-4"
            *ngIf="galleryManager.selectedAttachments?.size > 0"
            (click)="unselectAll()"
            label="Unselect all"
          ></button>
          <button
            (click)="removeSelected()"
            *ngIf="galleryManager.selectedAttachments?.size > 0 && canEditAttachments()"
            class="p-button-danger ml-4"
            icon="pi pi-trash"
            pButton
          ></button>
          <button
            pButton
            class="ml-4"
            icon="pi pi-download"
            (click)="download('AUDIO')"
            *ngIf="galleryManager.selectedAttachments?.size > 0"
          ></button>
        </div>
        <ng-container *ngIf="selectedTabIndex === 3">
          <div
            class="align-items-center border-black-alpha-20 border-top-1 py-4 lg:px-8 px-1"
            *ngFor="let audio of attachments; let index = index"
          >
            <div class="align-items-center flex justify-content-between">
              <p-checkbox
                binary="true"
                [(ngModel)]="audio.selected"
                (onChange)="select(audio, $event, index + 1)"
              ></p-checkbox>
              <button
                (click)="remove(audio, index)"
                class="p-button-danger next-button ml-3"
                icon="pi pi-trash"
                pButton
              ></button>
            </div>
            <div class="col-12 text-center flex flex-column align-items-center">
              <audio controls>
                <source [src]="audio.url" />
              </audio>
              <div
                *tjHasRole="'TICKET_VIEW_ATTACHMENTS_METADATA'"
                class="flex align-items-center"
                [pTooltip]="audio.createdBy ? audio.createdBy : audio.modifiedBy"
              >
                <span class="text-center">
                  {{ audio.modifiedBy ? audio.modifiedBy : audio.createdBy }} -
                </span>
                <span class="ml-1">{{
                  audio.updatedAt
                    ? (audio.updatedAt | date: configService.dateFormatFull)
                    : (audio.createdAt | date: configService.dateFormatFull)
                }}</span>
                <span
                  *ngIf="audio.updatedAt || audio.createdAt || audio.modifiedBy || audio.createdBy"
                  class="cursor-pointer ml-1 pi pi-eye text-purple-500"
                >
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </p-tabPanel>
  </p-tabView>

  <!-- AUDIOS -->
  <!--<div class="col" *ngIf="gallery.audios?.length > 0">
    <tj-subtitle>Audios</tj-subtitle>
    <p-panel [toggleable]="true" [collapsed]="true" *ngIf="gallery.audios?.length > 0">
      <p-header class="flex justify-content-between">
        <div class="ui-toolbar-group-left mr-4"></div>
        <div class="flex">
          <div class="ui-toolbar-group-left"></div>
          <div class="ui-toolbar-group-left"></div>
        </div>
      </p-header>

      <div class="grid" *ngFor="let audio of attachments; let index = index">
        <div class="lg:col-1">
          <p-checkbox
            binary="true"
            [(ngModel)]="audio.selected"
            (onChange)="select(audio, 'AUDIO', $event)"
          ></p-checkbox>
        </div>
        <div class="lg:col-7">
          <audio controls>
            <source [src]="audio.url" />
          </audio>
        </div>
      </div>
    </p-panel>
  </div>-->

  <!-- DOCUMENTS -->
</div>
<p-confirmDialog appendTo="body" key="confirmDeletion"></p-confirmDialog>

<p-dialog
  [(visible)]="displayImageDialog"
  (onHide)="hidePreviewImage()"
  [dismissableMask]="true"
  [responsive]="true"
  [modal]="true"
  [style]="{width: '90vw'}"
>
  <div *ngIf="selectedImage">
    <img [src]="getImageUrl()" alt="Selected Image" style="max-width: 100%; height: auto" />
  </div>
</p-dialog>

<p-dialog
  [(visible)]="displayPdfDialog"
  header="View PDF"
  [modal]="true"
  [style]="{width: '90vw', height: '90vh'}"
>
  <ng-container *ngIf="document">
    <pdf-viewer
      [src]="document.url"
      [render-text]="true"
      [original-size]="true"
      [show-all]="true"
      (error)="onPdfError($event)"
      (on-progress)="loading($event)"
      style="display: block; width: 100%; height: 100%"
    >
    </pdf-viewer>

    <div *ngIf="isLoading" class="loading-spinner-container">
      <p-progressSpinner styleClass="loading-spinner"></p-progressSpinner>
    </div>
  </ng-container>
</p-dialog>
