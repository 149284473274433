<div class="grid" [formGroup]="currentFormGroup">
  <div class="col">
    <div class="grid">
      <div class="col">
        <tj-field label="Company name" [control]="fcName">
          <input
            pInputText
            type="text"
            placeholder="Company name"
            id="name"
            formControlName="name"
          />
        </tj-field>
      </div>
    </div>
    <div class="grid">
      <div class="col">
        <tj-address-create-form
          [form]="currentFormGroup"
          [address]="partner.address"
          group="address"
        ></tj-address-create-form>
      </div>
    </div>
    <div class="grid">
      <div class="col">
        <tj-subtitle>Payment</tj-subtitle>
        <div class="col-fixed">
          <tj-field label="Net Term / Invoice Due Within" [control]="fcNetTerm">
            <p-inputNumber
              placeholder="Select days"
              mode="decimal"
              styleClass="block"
              class="block"
              formControlName="netTerm"
              [useGrouping]="false"
            ></p-inputNumber>
          </tj-field>
        </div>
        <tj-net-term
          [form]="currentFormGroup"
          [payment]="partner.payment"
          [isPrivate]="true"
          group="payment"
        ></tj-net-term>
      </div>
    </div>
    <div class="grid">
      <div class="col">
        <h5>Contacts</h5>
        <tj-contact-persons-form
          [contactPersons]="partner.contactPersons"
          [form]="currentFormGroup"
          [mainContactPerson]="mainContactPerson"
          [pdfReceiver]="configService.isConfigEnabled('PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT')"
          [receiveNotificationsRequestedRep]="
            configService.isConfigEnabled('PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT')
          "
          [receiveInvoiceRequestedRep]="
            configService.isConfigEnabled('PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT')
          "
          [receivesCommentsIfRep]="
            configService.isConfigEnabled('PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT')
          "
        ></tj-contact-persons-form>
      </div>
    </div>
  </div>
</div>
