import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WeekDays} from '../../../../shared/enums/weekDays';
import * as dayjs from 'dayjs';
import {WeeklyOff} from '../../../../../api/model/WeeklyOff.model';
import {ConfigService} from '../../../../../core/services';
import {Technician} from '../../../../../api/model/Technician.model';

const TIME_FORMAT = 'h:mmA';

@Component({
  selector: 'tj-weekly-off-form',
  templateUrl: './weekly-off-form.component.html',
  styleUrls: ['./weekly-off-form.component.scss'],
})
export class WeeklyOffFormComponent implements OnInit, OnChanges {
  @Input('form') form: FormGroup;
  @Input('weeklyOffs') weeklyOffs: WeeklyOff[];
  @Input('technician') technician: Technician;
  weeklyOffArrayForm: FormArray;
  weekDays: {label: string; value: string; disabled: boolean}[];
  currentDate = dayjs(new Date());

  constructor(
    private fb: FormBuilder,
    protected configService: ConfigService,
  ) {}

  ngOnInit() {
    this.form.removeControl('weeklyOffs');
    this.weeklyOffArrayForm = this.fb.array([]);
    this.initializeWeeklyOffs();
    this.updateWeekDays();
  }

  initializeWeeklyOffs() {
    const weeklyOffControls = this.generateWeeklyOffFormArray(this.weeklyOffs);
    this.weeklyOffArrayForm = this.fb.array(weeklyOffControls);
    this.form.setControl('weeklyOffs', this.weeklyOffArrayForm);
  }

  generateWeeklyOffFormArray(weeklyOffs) {
    if (!weeklyOffs || !weeklyOffs.length) {
      return [];
    }
    return weeklyOffs.map((weeklyOff: WeeklyOff) => this.generateWeeklyOff(weeklyOff));
  }

  generateWeeklyOff({
    id = null,
    weekDay = null,
    timeOff = {
      id: null,
      wholeDay: false,
      timeRange: [
        {
          id: null,
          startTime: null,
          endTime: null,
        },
      ],
    },
  } = {}) {
    return this.fb.group({
      id: this.fb.control(id),
      weekDay: this.fb.control(weekDay, [Validators.required]),
      timeOff: this.fb.group({
        id: this.fb.control(timeOff.id),
        wholeDay: this.fb.control(timeOff.wholeDay),
        timeRange: this.fb.array(
          timeOff.timeRange?.length > 0
            ? timeOff.timeRange.map(t => this.generateTimeFormGroup(t))
            : [this.generateTimeFormGroup()],
        ),
      }),
    });
  }

  generateTimeFormGroup({
    id = null,
    startTime = this.getDateTime(this.configService.times[0].value),
    endTime = this.getDateTime(this.configService.times[this.configService.times.length - 1].value),
  } = {}) {
    return this.fb.group({
      id: this.fb.control(id),
      startTime: this.fb.control(startTime, [Validators.required]),
      endTime: this.fb.control(endTime, [Validators.required]),
    });
  }

  addWeeklyOff() {
    const newWeeklyOff = this.generateWeeklyOff();
    this.weeklyOffArrayForm.push(newWeeklyOff);
    this.updateWeeklyOffs();
    this.updateWeekDays();
  }

  deleteWeeklyOff(index: number) {
    this.weeklyOffArrayForm.removeAt(index);
    this.updateWeeklyOffs();
    this.updateWeekDays();
  }

  updateWeeklyOffs() {
    this.weeklyOffs = this.weeklyOffArrayForm?.value.map(weeklyOff => ({
      id: weeklyOff.id,
      weekDay: weeklyOff.weekDay,
      timeOff: {
        id: weeklyOff.timeOff.id,
        wholeDay: weeklyOff.timeOff.wholeDay,
        timeRange: weeklyOff.timeOff.wholeDay
          ? []
          : weeklyOff.timeOff.timeRange.map(timeRange => ({
              id: timeRange.id,
              startTime: timeRange.startTime,
              endTime: timeRange.endTime,
            })),
      },
    }));
  }

  addTime(weeklyOffIndex: number) {
    const weeklyOffGroup = this.weeklyOffArrayForm.at(weeklyOffIndex) as FormGroup;
    const timeArray = weeklyOffGroup.get('timeOff.timeRange') as FormArray;
    timeArray.push(this.generateTimeFormGroup());
    this.updateWeeklyOffs();
  }

  removeTime(weeklyOffIndex: number, timeIndex: number) {
    const weeklyOffGroup = this.weeklyOffArrayForm.at(weeklyOffIndex) as FormGroup;
    const timeArray = weeklyOffGroup.get('timeOff.timeRange') as FormArray;
    timeArray.removeAt(timeIndex);
    this.updateWeeklyOffs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.weeklyOffs && !changes.weeklyOffs.firstChange && changes.weeklyOffs.currentValue) ||
      (changes.form && changes.form.currentValue) ||
      (changes.technician && !changes.technician.firstChange && changes.technician.currentValue)
    ) {
      this.ngOnInit();
    }
  }

  updateWeekDays() {
    this.weekDays = Object.keys(WeekDays).map(key => ({
      label: WeekDays[key],
      value: key,
      disabled: this.weeklyOffs?.some(off => off.weekDay === key),
    }));
  }

  isWeekDayDisabled(selectedValue: string): boolean {
    const selectedItem = this.weekDays?.find(item => item.value === selectedValue);
    return selectedItem ? selectedItem.disabled : false;
  }

  onWholeDayChange(weeklyOffGroup: FormGroup) {
    const timeOffGroup = weeklyOffGroup.get('timeOff') as FormGroup;
    const wholeDay = timeOffGroup.get('wholeDay').value;
    const timeArray = timeOffGroup.get('timeRange') as FormArray;

    if (wholeDay) {
      // Clear existing times and add a single time from 00:00 to 23:59
      timeArray.clear();
      timeArray.push(
        this.generateTimeFormGroup({
          startTime: this.getDateTime('00:00AM'),
          endTime: this.getDateTime('11:59PM'),
        }),
      );
    } else {
      // If not whole day, add a default time form group
      timeArray.clear();
      timeArray.push(this.generateTimeFormGroup());
    }
  }

  getDateTime(time: any) {
    const newTime = dayjs(time, [TIME_FORMAT]);
    const currentDate = this.currentDate || Date.now();
    return dayjs(currentDate)
      .set('hour', newTime.get('hour'))
      .set('minute', newTime.get('minute'))
      .set('second', 0)
      .set('millisecond', 0)
      .tz('UTC', true)
      .toJSON();
  }
}
