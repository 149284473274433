export const HEADER_NAMES = {
  REQUEST_TYPE: 'X-Request-type',
};

export const HEADER_VALUES = {
  REQUEST_TYPE: {
    BACKGROUND: 'background',
    FOREGROUND: 'foreground',
  },
};
