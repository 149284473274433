import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../api/service/api.service';
import {PaymentOrderModel} from '../../../api/model/payment/payment.order.model';
import {PaymentVendor} from '../models/payment-vendor';
import {PaymentItemType} from '../models/payment-type';

@Component({
  selector: 'tj-payment-completed',
  templateUrl: './payment-completed.component.html',
})
export class PaymentCompletedComponent implements OnInit {
  orderData: PaymentOrderModel;

  paymentVendor: PaymentVendor;

  itemType: PaymentItemType;

  items: number[];

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.paymentVendor = params.paymentVendor;
      this.itemType = PaymentItemType[params.itemType.toUpperCase()];
      this.getOrder(params.orderId);
    });
  }

  getOrder(orderId: string) {
    this.api.payment.getStripeOrder(orderId).subscribe(value => {
      this.paymentVendor = PaymentVendor.STRIPE;
      this.orderData = value;
    });
  }

  backToPayment() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/payment'], {
        // queryParams: {itemType: this.orderData.itemType.toString().toLowerCase(), itemId: this.itemId[0]},
        // queryParamsHandling: 'merge'
      });
    });
  }

  back() {
    window.history.back();
  }
}
