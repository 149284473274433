import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {ChatListItemModel} from '../../../../api/model/chat.model';
import {TjKeycloakService} from '../../../../core/services';
import {TicketTechnician} from '../../../../api/model/TicketTechnicians.model';

export enum ChatType {
  ADMIN_TECHNICIAN = 0,
  ADMIN_PARTNER = 1,
  ADMIN_ADMIN = 2,
  ADMIN_CUSTOMER = 3,
}

@Component({
  selector: 'tj-ticket-chats',
  templateUrl: './ticket-chats.component.html',
  styleUrls: ['./ticket-chats.component.scss'],
})
export class TicketChatsComponent implements OnInit {
  @Input()
  ticketId: number;

  @Input()
  technicians: TicketTechnician[];

  @Input()
  partnerId: number;

  chats: ChatListItemModel[];

  roleMap = {
    ADMIN_TECHNICIAN: 'TICKET_COMMENT_TECHNICIAN',
    ADMIN_ADMIN: 'TICKET_COMMENT_ADMIN',
    ADMIN_PARTNER: 'TICKET_COMMENT_PARTNER',
    ADMIN_CUSTOMER: 'TICKET_COMMENT_CUSTOMER',
  };

  titleMap = {
    ADMIN_ADMIN: 'Admin',
    ADMIN_TECHNICIAN: 'Technician',
    ADMIN_PARTNER: 'Account',
    ADMIN_CUSTOMER: 'Customer',
  };

  constructor(
    private api: ApiService,
    private keycloakService: TjKeycloakService,
  ) {}

  ngOnInit() {
    this.api.chat.findChats(this.ticketId).subscribe((chats: ChatListItemModel[]) => {
      this.chats = this.sortChats(chats);
    });
  }

  sortChats(chats: ChatListItemModel[]): ChatListItemModel[] {
    return chats.sort((a, b) => {
      const chatTypeA = ChatType[a.chatType];
      const chatTypeB = ChatType[b.chatType];
      return chatTypeA - chatTypeB;
    });
  }

  canAccessChat(chatType: string) {
    const role = this.roleMap[chatType];
    return this.keycloakService.hasRole(role);
  }

  checkForPartner(chatType: string): boolean {
    if (chatType !== 'ADMIN_PARTNER') {
      return true;
    }
    return this.partnerId !== null;
  }
}
