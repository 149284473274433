import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';

import {ActivatedRoute, Router} from '@angular/router';

import {PayFormEditModel} from '../../models';

import {throwError} from 'rxjs/internal/observable/throwError';
import {ConfigService, NotificationService} from '../../../../core/services';
import {Messages} from '../../../../common/messages';
import {ConfirmationService} from 'primeng/api';
import {TechnicianMinimalListItem} from '../../../../api/model/TechnicianMinimalListItem';

@Component({
  selector: 'tj-payform',
  templateUrl: './payform.component.html',
  styleUrls: ['./payform.component.scss'],
})
export class PayFormComponent implements OnInit {
  selectedPayForm: PayFormEditModel;

  payFormId;

  technician: TechnicianMinimalListItem;

  constructor(
    private api: ApiService,
    public configService: ConfigService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params.id;
      if (id === 'new') {
        this.createNewPayForm();
      } else {
        this.payFormId = parseInt(params.id, 10);
        this.loadPayForm(this.payFormId);
      }
    });
  }

  private selectPayForm(payForm: PayFormEditModel) {
    this.selectedPayForm = payForm;
    if (this.selectedPayForm && this.selectedPayForm.technicianId) {
      this.technician = this.configService.getTechnicianDataById(this.selectedPayForm.technicianId);
    }
  }

  private createNewPayForm() {
    const payForm = PayFormEditModel.buildMinimalPayForm();
    this.selectPayForm(payForm);
  }

  onAddForNext() {
    this.api.payForm.createNext(this.selectedPayForm.id).subscribe(value => {
      if (value.success) {
        this.notificationService.success(Messages.PAY_FORM_CREATED);
        this.router.navigate(['payform', value.response.id]);
      } else {
        this.notificationService.error(Messages.PAY_FORM_NOT_CREATED);
      }
    });
  }

  onDeletePayForm() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the pay form?',
      header: 'Pay form deletion',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmDeletion',
      accept: () => {
        this.api.payForm.delete(this.selectedPayForm.id).subscribe(
          () => {
            this.notificationService.success(Messages.PAY_FORM_DELETED);
            this.router.navigate(['payforms']);
          },
          () => {
            this.notificationService.error(Messages.PAY_FORM_DELETE_ERROR);
          },
        );
      },
    });
  }

  private loadPayForm(id: number) {
    this.api.payForm.findOne(id).subscribe(
      (value: PayFormEditModel) => {
        value.info.nextPayFormTransferDetails = value.nextPayFormTransferDetails;
        this.selectPayForm(value);
      },
      err => {
        this.notificationService.error(Messages.PAY_FORM_GET);
        return throwError(err);
      },
    );
  }

  showPrint() {
    window.print();
  }

  refresh(id: number) {
    this.loadPayForm(id);
  }
}
