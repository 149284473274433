import {ActivatedRoute, Params, Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export abstract class ListSearchService<F> {
  private searchData$: BehaviorSubject<any> = new BehaviorSubject(null);

  protected searchContext;

  protected route: ActivatedRoute;

  protected router: Router;

  public readonly searchData: Observable<any> = this.searchData$.asObservable();

  protected constructor(route: ActivatedRoute, router: Router) {
    this.route = route;
    this.router = router;
  }

  abstract getSearchConfig();

  abstract getSearchUrl();

  listenQueryParams() {
    const searchConfig = this.getSearchConfig();
    this.route.queryParams.subscribe((params: Params) => {
      this.searchContext = Object.keys(params)
        .filter(key => searchConfig[key])
        .reduce((acc, key) => {
          const originalValue = params[key];
          const config = searchConfig[key];
          acc[key] = {
            originalValue,
            value: this.makeValue(key, originalValue, config),
            searchQueryValue: this.makeSearchQueryValue(key, originalValue, config),
          };
          return acc;
        }, {});
      this.searchData$.next({
        filters: this.getFilters(),
        searchQuery: this.getSearchQuery(),
      });
    });
  }

  makeSearchQueryValue(key, originalValue, config) {
    const transformFn = config.transformSearchQuery;
    const searchQueryPrefix = config.searchQueryPrefix;

    const searchQuery = value => {
      if (transformFn) {
        return transformFn(searchQueryPrefix, value);
      }
      return `${searchQueryPrefix}${value}`;
    };

    if (this.isArrayByConfig(config)) {
      return originalValue.split(',').map(searchQuery);
    } else {
      return searchQuery(originalValue);
    }
  }

  makeValue(key, originalValue, config) {
    const transformFn = config.transform || ((k, v) => v);
    if (this.isArrayByConfig(config)) {
      return originalValue.split(',').map(v => transformFn(key, v));
    } else {
      return transformFn(key, originalValue);
    }
  }

  getFilters() {
    return Object.keys(this.searchContext).reduce((acc, key) => {
      acc[key] = this.searchContext[key].value;
      return acc;
    }, {});
  }

  getSearchQuery() {
    return Object.keys(this.searchContext)
      .map(key => this.searchContext[key].searchQueryValue)
      .join(',');
  }

  search(filter: F) {
    const searchQuery = {};
    const url = this.getSearchUrl();
    Object.keys(filter).forEach(key => {
      // filter out empty values
      if (filter[key]) {
        searchQuery[key] = typeof filter[key] === 'string' ? filter[key].trim() : filter[key];
      }
    });
    this.navigate(searchQuery);
  }

  reset() {
    const url = this.getSearchUrl();
    this.router.navigate(url, {queryParamsHandling: ''});
  }

  navigate(params) {
    const url = this.getSearchUrl();
    this.router.navigate(url, {queryParams: params});
  }

  private isArrayByConfig(config) {
    return config.type && config.type === 'array';
  }
}
