<form
  novalidate
  autocomplete="off"
  [formGroup]="filterForm"
  (reset)="onSearchReset()"
  (ngSubmit)="onFilter()"
>
  <div class="grid">
    <div class="col-12">
      <tj-partner-search-form [filter]="filter" [form]="filterForm"></tj-partner-search-form>
    </div>
    <div class="col-12 py-0 flex">
      <tj-partner-search-labels
        (remove)="onRemoveFilter($event)"
        [filterForm]="filterForm"
        [partnerFilter]="filter"
      ></tj-partner-search-labels>
    </div>
    <div class="col-12 align-content-end flex justify-content-between">
      <div class="flex align-items-end">
        <span *ngIf="page">Total: {{ page.totalElements }}</span>
      </div>
      <div>
        <button class="p-button-secondary mr-2" label="Reset" pButton type="reset"></button>
        <button icon="pi pi-search" label="Search" pButton type="submit"></button>
      </div>
    </div>
  </div>
</form>
