import {AddressDetails} from '../Address.model';

export class RouteRequestModel {
  id?: number;
  technicianId: number;
  email: string;
  date: Date;
  startRoute: RouteItemModel;
  endRoute: RouteItemModel;
  routes: RouteItemModel[];
  roundTrip: boolean;
  avoidTolls: boolean;
  avoidHighways: boolean;
  avoidFerries: boolean;
  optimizeRoute: boolean;
}

export class RouteItemModel {
  id?: number;
  order: number;
  name?: string;
  address: AddressDetails;
  addressUrl: string;
  timeNeeded: number;
  duration: number = 0;
  distance: number = 0;
  ticketId: number;
  asStart: boolean;
  asEnd: boolean;
}
