<div class="col-12">
  <p-toolbar styleClass="mb-4 gap-2" *tjHasRole="'ZIP_CODE_EDIT'">
    <ng-template pTemplate="left"> </ng-template>
    <ng-template pTemplate="right">
      <button
        pButton
        pRipple
        label="New"
        icon="pi pi-plus"
        class="p-button-success mr-2"
        (click)="onNewArea()"
      ></button>
    </ng-template>
  </p-toolbar>

  <tj-area-list
    [areas]="areas"
    [zipCodesDialog]="zipCodesDialog"
    (onEditZipCodes)="onEditZipCodes($event)"
    (onUpdate)="onEditArea($event)"
    (onDelete)="onDeleteArea($event)"
  ></tj-area-list>
</div>

<p-dialog
  [(visible)]="areaEditDialog"
  [style]="{width: '650px'}"
  header="Area Details"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <tj-area-form
      (onUpdate)="updateArea($event)"
      (onCreate)="createArea($event)"
      (onCancel)="hideAreaEditDialog()"
      [area]="area"
    >
    </tj-area-form>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="zipCodesDialog"
  [style]="{width: '90%'}"
  [modal]="true"
  styleClass="fluid"
  class="areaZipCodes"
>
  <ng-template pTemplate="header">
    <div class="col-12 w-fit">
      <h2>
        <strong>{{ area.name }} / {{ area.code }}</strong>
      </h2>
    </div>
  </ng-template>

  <tj-zipCode-menu-component
    [isSaving]="isSaving"
    (onExportAllZipCodes)="onExportAreaZipCodes(area)"
    (onImportZipCodes)="onImportZipCodes($event, area.id)"
    (onImportInfo)="onImportInfo()"
    (onNewInsertZipCode)="onEditInsertZipCodeDialog()"
    (onNewZipCode)="onAreaNewZipCode($event)"
  ></tj-zipCode-menu-component>

  <ng-template pTemplate="content">
    <div class="col-12">
      <tj-zip-code-list
        [zipCodes]="zipCodes"
        [showAreaCode]="false"
        [zipCodeDialog]="zipCodeDialog"
        (onUpdate)="onEditZipCode($event)"
        (onDelete)="onDeleteZipCode($event)"
      ></tj-zip-code-list>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="showZipCodeEditDialog"
  [style]="{width: '650px'}"
  header="Zip code Details"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <tj-zip-code-form
      (onCancel)="hideZipCodeEditDialog()"
      (onCreateZipCode)="addAreaNewZipCode($event)"
      (onUpdateZipCode)="updateAreaZipCode($event, true)"
      [zipCode]="zipCode"
    >
    </tj-zip-code-form>
  </ng-template>
</p-dialog>

<tj-zip-code-import-example></tj-zip-code-import-example>
<tj-zip-code-insert-example></tj-zip-code-insert-example>

<p-confirmDialog appendTo="body" key="confirmDeletion"></p-confirmDialog>
<p-dialog
  [(visible)]="showInsertZipCodeDialog"
  [style]="{width: '650px'}"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <div class="">
      <div>
        <div class="flex justify-content-center align-items-center">
          <h4 class="m-0">Insert or Copy / Paste Zip codes (in proper format)</h4>
          <button pButton pRipple class="p-button-info mr-2 ml-2 w-fit" (click)="onInsertInfo()">
            <i class="pi pi-info-circle" style="font-size: 1.3rem"></i>
          </button>
        </div>
      </div>
      <div class="col-12">
        <tj-zip-code-chips-form
          (onCancel)="hideInsertZipCodeDialog()"
          (onCreateInsertZipCodes)="onNewInsertZipCode($event)"
        >
        </tj-zip-code-chips-form>
      </div>
    </div>
  </ng-template>
</p-dialog>
