import {Injectable} from '@angular/core';
import {RouteDetails} from './models/route-data';
import {AddressedMarker} from './components/routes/addressed-marker.model';

@Injectable()
export class RouteMetricsService {
  public computeTotalDistanceAndDuration(
    legs: google.maps.DirectionsLeg[],
    directionComponents: AddressedMarker[],
  ): RouteDetails {
    let totalDistance = 0;
    let totalDuration = 0;

    for (const item of legs) {
      // tslint:disable-next-line:no-non-null-assertion
      totalDistance += item!.distance!.value;
      // tslint:disable-next-line:no-non-null-assertion
      totalDuration += item!.duration!.value;
    }
    for (const item of directionComponents) {
      totalDuration += item.timeNeeded * 3600;
    }

    return {
      distance: totalDistance,
      duration: totalDuration,
    };
  }

  secToTime(duration) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    if (hours) {
      return hours + ' hours' + '  ' + minutes + ' mins';
    }
    return minutes + ' mins';
  }
}
