import {Component, Input} from '@angular/core';

@Component({
  selector: 'tj-metric',
  templateUrl: './metric.component.html',
  styleUrls: ['./metric.component.scss'],
})
export class MetricComponent {
  @Input() text: string;

  @Input() value: string | number;

  @Input() tooltip: string;

  @Input() color = 'black';
}
