<form *ngIf="form" [formGroup]="form">
  <tj-pay-form-edit-form
    [payForm]="payForm"
    [form]="form"
    (createEvent)="save()"
    [isSaving]="isSaving"
    (onTransfer)="onTransfer($event)"
    (onPayInFull)="onPayInFull($event)"
  ></tj-pay-form-edit-form>
</form>
