import {TicketSearchService} from '../../services/ticket-search.service';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ApiService} from '../../../../api/service/api.service';
import {ConfigService, NotificationService, TjKeycloakService} from '../../../../core/services';
import {Page, PageQuery, SortQuery} from '../../../../api/model/shared/Page.model';
import {forkJoin} from 'rxjs';
import {TicketLine} from '../../../../api/model/TicketLine.model';
import {TicketFilter} from '../../../../api/model/TicketFilter';
import {DropdownModel} from '../../../../api/model/shared/dropdown.model';
import {LayoutService} from '../../../../layout/service/app.layout.service';
import {TicketListComponent} from '../ticket-list/ticket-list.component';
import {TicketStatus} from '../../../../api/status/ticketStatus';
import {Messages} from '../../../../common/messages';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'tj-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
  providers: [TicketSearchService],
})
export class TicketsComponent implements OnInit {
  protected readonly env = environment;

  ticketFilter: TicketFilter;

  tickets: TicketLine[];

  page: Page<TicketLine>;

  @ViewChild('ticketListComponent') ticketListComponent: TicketListComponent;

  batchActions: DropdownModel[] = [
    {label: 'Print / Mark', value: 'PRINT'},
    {label: 'Select all', value: 'SELECT_ALL'},
    {label: 'Select all unprinted', value: 'SELECT_UNPRINTED'},
  ];

  isMobile = false;

  protected pageQuery: PageQuery = {
    size: this.env.rowsPerPage[0],
    number: 0,
  };

  private sortQuery: SortQuery = {
    property: 'id',
    direction: 'desc',
  };

  private searchQuery;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    public configService: ConfigService,
    private notificationService: NotificationService,
    private ticketSearchService: TicketSearchService,
    private keycloakService: TjKeycloakService,
    private layoutService: LayoutService,
  ) {
    if (this.keycloakService.hasRole('TICKET_VIEW_ASSIGNED')) {
      this.router.navigate(['/tickets'], {
        queryParams: {
          property: 'serviceDate',
          direction: 'asc',
        },
      });
    }
  }

  ngOnInit() {
    this.ticketSearchService.listenQueryParams();
    this.ticketSearchService.searchData.subscribe(({filters, searchQuery}) => {
      this.ticketFilter = filters;
      this.searchQuery = searchQuery;
    });
    this.route.queryParams.subscribe((params: Params) => {
      this.pageQuery.number = params.page || 0;
      this.pageQuery.size = +params.size || this.pageQuery.size;
      this.sortQuery.property = params.property || 'id';
      this.sortQuery.direction = params.direction || 'desc';
      this.loadTickets();
    });

    if (this.keycloakService.hasRole('INVOICE_EDIT')) {
      this.batchActions.push({label: 'Invoice', value: 'INVOICE'});
    }

    this.isMobile = this.layoutService.isMobile();
  }

  sort($event) {
    this.sortQuery = {
      property: $event.field,
      direction: $event.order === -1 ? 'desc' : 'asc',
    };
    this.router.navigate([], {queryParams: this.sortQuery, queryParamsHandling: 'merge'});
  }

  onGoToPage(event) {
    this.router.navigate([], {
      queryParams: {page: event.page, size: event.rows},
      queryParamsHandling: 'merge',
    });
  }

  setBatchAction(event: any): void {
    const selectedTickets = this.ticketListComponent.getSelectedTickets();
    switch (event.value) {
      case 'SELECT_UNPRINTED':
        this.loadNonPrintedTickets();
        break;
      case 'SELECT_ALL':
        this.ticketListComponent.selectAllTickets();
        break;
      case 'INVOICE':
        if (selectedTickets.length === 0) {
          this.notificationService.warning('No tickets selected');
          break;
        }
        this.invoiceTickets(selectedTickets);
        break;
      case 'PRINT':
        if (selectedTickets.length === 0) {
          this.notificationService.info('No tickets selected');
          break;
        }
        const subs = selectedTickets.map(ticket => this.api.ticket.markAsPrinted(ticket.id));
        forkJoin(subs).subscribe(result => {
          const queryParams = selectedTickets.map(t => `id=${t.id}`).join('&');
          const printUrl = `/tickets/print?${queryParams}`;
          this.loadTickets();
          window.open(printUrl, '_parent');
        });
        break;
    }
  }

  private loadTickets() {
    this.api.ticket.findAll(this.pageQuery, this.sortQuery, this.searchQuery).subscribe(
      ticketPage => {
        this.page = ticketPage;
        this.tickets = ticketPage.response;
        if (this.ticketListComponent) {
          this.ticketListComponent.selectedTickets = [];
        }
      },
      error => this.notificationService.error(error),
    );
  }

  private loadNonPrintedTickets() {
    this.searchQuery = 'notPrintedByAdmin: false';
    let searchKey = 'notPrintedByAdmin';
    if (this.keycloakService.hasRole('TICKET_PRINT_TECHNICIAN')) {
      searchKey = 'notPrintedByTechnician';
      this.searchQuery = searchKey + ': false';
    } else if (this.keycloakService.hasRole('TICKET_PRINT_PARTNER')) {
      searchKey = 'notPrintedByPartner';
      this.searchQuery = searchKey + ': false';
    }
    this.pageQuery.size = 300;
    this.router
      .navigate(['tickets'], {
        queryParams: {
          page: 0,
          size: 100,
          [searchKey]: 'true',
        },
        queryParamsHandling: 'merge',
      })
      .then(value => {
        this.api.ticket.findAll(this.pageQuery, this.sortQuery, this.searchQuery).subscribe(
          ticketPage => {
            this.page = ticketPage;
            this.tickets = ticketPage.response;
            this.ticketListComponent.selectUnprinted(ticketPage.response);
          },
          error => this.notificationService.error(error),
        );
      });
  }

  private invoiceTickets(selectedTickets: TicketLine[]) {
    const nonInvocableTickets = selectedTickets.some(it => it.status !== TicketStatus.VERIFIED);
    if (nonInvocableTickets) {
      this.notificationService.warning('Some of tickets can not be invoiced: should be verified');
      return;
    }
    const tickets = selectedTickets.map(it => it.id);
    this.api.invoice.invoice(tickets).subscribe(value => {
      this.notificationService.success(Messages.TICKETS_INVOICED);
      this.backOnTickets();
    });
  }

  private backOnTickets() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
