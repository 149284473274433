import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ConfigService} from '../../../../core/services';
import {OutreachAttemptModel} from '../../../../api/model/outreachattempt/outreachAttemptModel';
import {CustomerContactMethod} from '../../enums/customer.contact.method';
import {ApiService} from '../../../../api/service/api.service';
import {KeycloakService} from 'keycloak-angular';
import {formatDate} from '@angular/common';

@Component({
  selector: 'tj-outreach-attempt',
  templateUrl: './outreach-attempt.component.html',
  styleUrls: ['./outreach-attempt.component.scss'],
})
export class OutreachAttemptComponent implements OnInit {
  @Input() outreachAttempt: OutreachAttemptModel[];
  @Input() ticketId: number;
  @Input() customerId: number;
  @Input() readOnly: boolean;
  form: FormGroup;
  contactMethods = Object.values(CustomerContactMethod);

  constructor(
    public configService: ConfigService,
    private keycloakService: KeycloakService,
    private fb: FormBuilder,
    private api: ApiService,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      contactMethods: this.fb.array(
        this.contactMethods.map(() => new FormControl(false)),
        [this.atLeastOneCheckboxChecked],
      ),
      note: new FormControl(''),
    });
  }

  atLeastOneCheckboxChecked(control: AbstractControl): {[key: string]: boolean} | null {
    const checkboxes = (control as FormArray).value;
    return checkboxes.some(checked => checked) ? null : {atLeastOneRequired: true};
  }

  save(): void {
    if (this.form.invalid || this.readOnly) {
      return;
    }

    const selectedContacts = this.contactMethods.filter(
      (_, i) => this.form.value.contactMethods[i],
    );
    const note = this.form.value.note;

    this.api.ticket
      .createOutreachAttempt(this.ticketId, this.customerId, selectedContacts, note)
      .subscribe();
    this.form.reset();
    const outreachAttemptModel = new OutreachAttemptModel();
    outreachAttemptModel.contactTypes = selectedContacts;
    outreachAttemptModel.note = note;
    outreachAttemptModel.createdAt = formatDate(
      new Date(),
      this.configService.dateFormatFull,
      'en-US',
    );
    outreachAttemptModel.createdBy = this.keycloakService.getUsername();
    this.outreachAttempt.unshift(outreachAttemptModel);
  }
}
