import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ListSearchService} from '../../shared/services/list-search.service';
import {PartnerSearchFilterModel} from '../models/partner-search-filter.model';

@Injectable()
export class PartnerSearchService extends ListSearchService<PartnerSearchFilterModel> {
  constructor(route: ActivatedRoute, router: Router) {
    super(route, router);
  }

  searchByFormValue(formValue) {
    const filter: PartnerSearchFilterModel = {
      id: formValue.id,
      name: formValue.name,
      username: formValue.username,
      email: formValue.email,
      address: formValue.address,
      deleted: formValue.deleted,
      netTermType: formValue.netTermType,
    } as PartnerSearchFilterModel;
    this.search(filter);
  }

  getSearchUrl() {
    return ['/accounts'];
  }

  getSearchConfig() {
    return {
      id: {
        searchQueryPrefix: 'id:',
      },
      name: {
        searchQueryPrefix: 'name~',
      },
      username: {
        searchQueryPrefix: 'username~',
      },
      email: {
        searchQueryPrefix: 'contactPersons.contacts.value~',
      },
      address: {
        searchQueryPrefix: 'address.addressFormatted~',
      },
      netTermType: {
        searchQueryPrefix: 'payment.netTermType:',
      },
      deleted: {
        searchQueryPrefix: 'active!=',
      },
    };
  }
}
