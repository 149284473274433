<p-table
  (onRowSelect)="onSelect($event)"
  (onSort)="sort($event)"
  [autoLayout]="true"
  [customSort]="true"
  [selectionMode]="'single'"
  [value]="partners"
  [responsive]="true"
>
  <ng-template pTemplate="header">
    <tr>
      <!--<th pSortableColumn="id">ID
        <p-sortIcon field="id"></p-sortIcon>
      </th>-->
      <th pSortableColumn="name">
        Name
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th pSortableColumn="partnerType">
        Account type
        <p-sortIcon field="partnerType"></p-sortIcon>
      </th>
      <th>Payment Term</th>
      <th pSortableColumn="netTerm">
        Net term
        <p-sortIcon field="netTerm"></p-sortIcon>
      </th>
      <th pSortableColumn="active">
        Status
        <p-sortIcon field="active"></p-sortIcon>
      </th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-partner>
    <tr [pSelectableRow]="partner">
      <!--      <td>{{partner.id}}</td>-->
      <td>{{ partner.name }}</td>
      <td>{{ partner.partnerType | titlecase }}</td>
      <td class="text-center">
        <span
          *ngIf="partner.payment.netTermType === 'CREDIT_CARD_ON_FILE'"
          class="pi pi-credit-card"
          [ngStyle]="{color: partner.payment.approvalRequired ? 'red' : 'var(--primary-color)'}"
          style="font-size: 1.5rem"
          pTooltip="This account pays by credit card"
          tooltipPosition="top"
        >
        </span>
        <span *ngIf="partner.payment.netTermType !== 'CREDIT_CARD_ON_FILE'">
          {{ partner.payment | netTermTypePipeText }}
        </span>
      </td>
      <td>{{ partner.netTerm }}</td>
      <td>{{ partner.active ? 'Active' : 'Inactive' }}</td>
      <td style="width: 58px" class="centered-column">
        <ng-container *tjHasRole="'PARTNER_DELETE'">
          <button
            *ngIf="partner.active"
            class="p-button-danger"
            icon="pi pi-trash"
            pButton
            (click)="onDeactivatePartner(partner.id)"
          ></button>
          <button
            *ngIf="!partner.active"
            class="p-button-info"
            icon="pi pi-undo"
            pButton
            (click)="onRecoverPartner(partner.id)"
          ></button>
        </ng-container>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog appendTo="body" key="confirmDeactivation"></p-confirmDialog>
