import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CommunicationService} from '../service/communication.service';
import {StatsModel, StatsRequestModel} from '../model/stats/stats.model';
import {ChartModel} from '../model/stats/chart.model';

@Injectable()
export class StatsApi extends CommunicationService {
  protected apiEndpoint = this.env.apiEndpoint + 'stats';

  getStats(requestModel: StatsRequestModel): Observable<StatsModel> {
    const url = `${this.apiEndpoint}`;
    return this.requestService.post(url, requestModel).pipe(
      map(response => response.response as StatsModel),
      catchError(this.handleError),
    );
  }

  getTicketsPerAccount(requestModel: StatsRequestModel): Observable<ChartModel> {
    const url = `${this.apiEndpoint}/ticketsPerAccount`;
    return this.requestService.post(url, requestModel).pipe(
      map(response => response.response as ChartModel),
      catchError(this.handleError),
    );
  }

  getTicketsPerStatus(requestModel: StatsRequestModel): Observable<ChartModel> {
    const url = `${this.apiEndpoint}/ticketsPerStatus`;
    return this.requestService.post(url, requestModel).pipe(
      map(response => response.response as ChartModel),
      catchError(this.handleError),
    );
  }
}
