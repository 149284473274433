import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseFormGroup} from '../../../shared/utils/base-form-group';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {Contact} from '../../../../api/model/Contact.model';
import {ConfigService} from '../../../../core/services';
import {ContactType} from '../../../shared/enums/contact.type';

@Component({
  selector: 'tj-single-contact-person-form',
  templateUrl: './single-contact-person-form.component.html',
  styleUrls: ['./single-contact-person-form.component.scss'],
})
export class SingleContactPersonFormComponent extends BaseFormGroup implements OnInit {
  @Input() contactPerson: ContactPerson;
  @Input() form: UntypedFormGroup;
  @Input() group: string;
  @Input() pdfReceiver = false;
  @Input() receiveNotificationsRequestedRep = false;
  @Input() receiveInvoiceRequestedRep = false;
  @Input() receivesCommentsIfRep = false;

  @HostBinding('class') classes = 'contact-persons-formGroup';
  formControls;

  constructor(
    private fb: UntypedFormBuilder,
    public configService: ConfigService,
  ) {
    super();
  }

  ngOnInit() {
    const contactPerson = this.contactPerson || ({} as ContactPerson);
    this.formControls = {
      id: this.fb.control(contactPerson.id || null),
      name: this.fb.control(contactPerson.name || null, Validators.required),
      notes: this.fb.control(contactPerson.notes || null),
      contacts: this.generateContactsFormArray(contactPerson.contacts),
      pdfReceiver: this.fb.control(this.pdfReceiver),
      receiveNotificationsRequestedRep: this.fb.control(this.receiveNotificationsRequestedRep),
      receiveInvoiceRequestedRep: this.fb.control(this.receiveInvoiceRequestedRep),
      receivesCommentsIfRep: this.fb.control(this.receivesCommentsIfRep),
      isMain: this.fb.control(true),
    };
    this.buildForm(this.form, this.formControls, this.group);
    this.defineGetters(this.formControls);
  }

  onRemoveContact(contactPersonIndex, contactIndex) {
    const contacts: UntypedFormArray = this.currentFormGroup.get('contacts') as UntypedFormArray;
    contacts.removeAt(contactIndex);
  }

  onAddContact() {
    const contacts: UntypedFormArray = this.currentFormGroup.get('contacts') as UntypedFormArray;
    contacts.push(this.generateContactControl());
  }

  private generateContactsFormArray(contacts = []) {
    if (contacts.length === 0) {
      contacts = [
        {type: ContactType.EMAIL, value: ''} as Contact,
        {type: ContactType.MOBILE_PHONE, value: ''} as Contact,
      ];
    }
    return this.fb.array(
      contacts.map((contact: Contact) => {
        return this.generateContactControl(contact.type, contact.value, contact.id);
      }),
    );
  }

  private generateContactControl(type = null, value = '', id?) {
    return this.fb.group({
      id: this.fb.control(id),
      type: this.fb.control(type, Validators.required),
      value: this.fb.control(
        value,
        type === 'EMAIL' ? [Validators.required, Validators.email] : Validators.required,
      ),
    });
  }

  public onKeyDown(event: KeyboardEvent, type?: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      return;
    }
    if (type === 'EMAIL' && (event.key === ' ' || event.key === ',')) {
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent, formGroup: UntypedFormGroup) {
    const pastedText = event.clipboardData.getData('text/plain');
    const trimmedText = pastedText.trim();
    formGroup.get('value').setValue(trimmedText);
    event.preventDefault();
  }

  changeContactType(event: any, contactIndex: number) {
    const contacts: UntypedFormArray = this.currentFormGroup.get('contacts') as UntypedFormArray;
    const contact = contacts.at(contactIndex) as UntypedFormGroup;

    const valueControl = contact.get('value') as UntypedFormControl;

    valueControl.setValidators(null);

    if (event.value === 'EMAIL') {
      valueControl.setValidators([Validators.required, Validators.email]);
    } else {
      valueControl.setValidators([Validators.required]);
    }
    valueControl.markAsTouched();
    valueControl.updateValueAndValidity();
  }
}
