import {Injectable} from '@angular/core';
import {Ticket} from '../../../api/model/Ticket.model';
import {TicketInvoice} from '../../../api/model/TicketInvoice.model';
import {PaymentType} from '../../../api/status/payment.type';
import {Invoice} from '../../../api/model/Invoice.model';

export interface TicketInvoiceWithTech extends TicketInvoice {
  ticket: Ticket;
  subtotal: number;
}

export interface InvoicesTotal {
  amount: number;
  expenses: number;
  tax: number;
  invoice: number;
}

@Injectable()
export class InvoiceService {
  convertTicketToTicketInvoice(ticket: Ticket): TicketInvoiceWithTech {
    const ticketInvoice = {} as TicketInvoiceWithTech;
    ticketInvoice.ticket = ticket;
    ticketInvoice.amount = ticket.invoiceAmount;
    ticketInvoice.expenses = ticket.invoiceExpenses || 0;
    ticketInvoice.netTerm = ticket.invoiceNetTerm || 0;
    ticketInvoice.tax = ticket.invoiceTax || 0;
    ticketInvoice.subtotal = this.calculateSubtotal(
      ticketInvoice.amount,
      ticketInvoice.expenses,
      ticketInvoice.tax,
    );

    return ticketInvoice;
  }

  calculateSubtotal(amount: number, expenses: number, tax: number): number {
    const total = amount + expenses;
    return total + total * (tax / 100);
  }

  public getPaymentTerm(invoice: Invoice) {
    const {netTerm, partner} = invoice;
    if (!partner) {
      return netTerm === 0 ? PaymentType.DUE_ON_RECEIPT : PaymentType.NET_TERM;
    }
    const {netTermType} = partner.payment;
    if (netTermType === 'NET_TERM') {
      return netTerm === 0 ? PaymentType.DUE_ON_RECEIPT : PaymentType.NET_TERM;
    }
    return PaymentType[netTermType];
  }

  public checkEligibilityForPayment(invoices: Invoice[]): boolean {
    const partners = invoices.map(it => it.partner);

    const privatePartners = partners.filter(it => it === null);
    const nonPrivatePartners = partners.filter(it => it !== null);

    const hasMultiplePrivatePartners = privatePartners.length > 1;
    const hasPartnersWithDifferentType =
      privatePartners.length > 0 && nonPrivatePartners.length > 0;
    const hasMultiplePartners = new Set(nonPrivatePartners.map(it => it.id)).size > 1;

    return hasPartnersWithDifferentType || hasMultiplePrivatePartners || hasMultiplePartners;
  }
}
