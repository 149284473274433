import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {Customer} from '../../../../api/model/Customer.model';
import {TicketEditContextService} from '../../services/ticket-edit-context.service';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {ConfigService, NotificationService} from '../../../../core/services';
import {Messages} from '../../../../common/messages';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService} from 'primeng/api';
import {ApiService} from '../../../../api/service/api.service';
import {AddressDetails} from '../../../../api/model/Address.model';
import {TicketRouteModel} from '../../../../api/model/ticket/ticket.route.details.model';

@Component({
  selector: 'tj-ticket-edit-customer-modal',
  templateUrl: './ticket-edit-customer-modal.component.html',
  styleUrls: ['./ticket-edit-customer-modal.component.scss'],
})
export class TicketEditCustomerModalComponent implements OnInit, OnDestroy {
  @Input('visible') visible: boolean;
  @Input('ticketId') ticketId: number;

  @Output() hide = new EventEmitter();

  @Output('onSave') onSaveEmitter = new EventEmitter();

  private destroy$ = new Subject<void>();

  form: UntypedFormGroup;

  originalCustomer: Customer;

  customer: Customer;
  isSaving = false;

  originalMainContactPerson: ContactPerson;
  associatedRoutesByTicket = false;
  routeInfo: TicketRouteModel[] = [];

  mainContactPerson: ContactPerson;

  constructor(
    private context: TicketEditContextService,
    private fb: UntypedFormBuilder,
    public configService: ConfigService,
    private apiService: ApiService,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {}

  onShow(): void {
    this.form = this.fb.group({});
    this.context.customer.pipe(takeUntil(this.destroy$)).subscribe(customer => {
      if (customer) {
        this.originalCustomer = customer;
        this.customer = Customer.buildCopy(customer);
      }
    });

    this.context.mainCustomerContactPerson
      .pipe(takeUntil(this.destroy$))
      .subscribe(contactPerson => {
        this.originalMainContactPerson = contactPerson;
        this.mainContactPerson = contactPerson;
      });
  }

  onHide(): void {
    this.form = undefined;
    this.hide.emit();
  }

  saveAction(): void {
    if (this.hasChangeCustomerAddress()) {
      this.showAssociatedRouteById();
    } else {
      this.save();
    }
  }

  save(): void {
    this.isSaving = true;
    const formValue = this.form.value;
    if (this.form.valid) {
      const customer: Customer = formValue.customer;
      customer.id = this.customer.id;
      customer.address.id = this.customer.address.id;
      this.context.saveCustomer(customer).subscribe(() => {
        this.onHide();
        this.notificationService.success(Messages.CUSTOMER_SAVED);
        this.onSaveEmitter.emit();
        this.isSaving = false;
      });
    } else {
      FormHelpers.validateAllFormFields(formValue.customer);
      this.isSaving = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showAssociatedRouteById(): void {
    this.apiService.routeApi.getAssociatedRoutesByTicketId(this.ticketId).subscribe(routes => {
      if (routes && routes.length > 0) {
        this.routeInfo = routes;
        this.associatedRoutesByTicket = true;
      } else {
        this.routeInfo = [];
        this.save();
      }
    });
  }

  cancelCustomerChanges() {
    this.associatedRoutesByTicket = false;
    this.routeInfo = [];
  }

  saveCustomerChanges() {
    this.associatedRoutesByTicket = false;
    this.routeInfo = [];
    this.save();
  }

  hasChangeCustomerAddress(): boolean {
    const customerForm = this.form?.value?.customer;
    const currentAddress: AddressDetails = this.customer?.address;
    const newAddress: AddressDetails = customerForm?.address;

    if (!currentAddress || !newAddress) {
      return false;
    }

    return (
      this.isDifferent(currentAddress.country, newAddress.country) ||
      this.isDifferent(currentAddress.city, newAddress.city) ||
      this.isDifferent(currentAddress.state, newAddress.state) ||
      this.isDifferent(currentAddress.street, newAddress.street) ||
      this.isDifferent(currentAddress.apt, newAddress.apt) ||
      this.isDifferent(currentAddress.postalCode, newAddress.postalCode)
    );
  }

  private isDifferent(value1: string | null, value2: string | null): boolean {
    return (value1 ?? '') !== (value2 ?? '');
  }

  openInNewTab(event: Event, routeId: number) {
    event.preventDefault();
    window.open('/routes/' + routeId, '_blank');
  }
}
