import {throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {NotificationService} from '../../core/services';
import {RequestService} from './request.service';
import {environment} from '../../../environments/environment';
import {Messages} from '../../common/messages';

@Injectable()
export class CommunicationService {
  protected apiEndpoint: string;
  protected env: any;

  constructor(
    protected requestService: RequestService,
    protected notificationService: NotificationService,
  ) {
    this.env = environment;
  }

  protected handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = error.message
      ? error.message
      : error.status
        ? `${error.status} - ${error.statusText}`
        : 'Server error';
    switch (error.status) {
      case 400:
      case 405:
      case 409:
        return observableThrowError(error.error.message);
      case 422:
        return observableThrowError(error.error.message);
      case 401:
        break;
      case 403:
        return observableThrowError(Messages.NO_ACCESS);
    }
    if (this.notificationService) {
      this.notificationService.error(error.error.message);
    }
    return observableThrowError(errMsg);
  }

  protected handleErrorWithStatusCode(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    switch (error.status) {
      case 400:
      case 405:
      case 409:
        return observableThrowError(error);
      case 422:
        return observableThrowError(error);
      case 401:
        break;
      case 403:
        return observableThrowError(Messages.NO_ACCESS);
    }
    if (this.notificationService) {
      this.notificationService.error(error);
    }
    return observableThrowError(error);
  }
}
