<p-dialog
  [(visible)]="showStopDocuments"
  header="Ticket attached file(s)"
  [modal]="true"
  [closable]="true"
  closeOnEscape="true"
  [dismissableMask]="true"
  (onHide)="hideDialog()"
  [style]="{width: '650px'}"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <div>
      <div class="col-12 flex justify-content-end mb-3">
        <button
          pButton
          class="w-fit p-button next-button ml-4"
          *ngIf="selectedDocuments.length > 0"
          (click)="unselectAll()"
          label="Unselect all"
        ></button>
        <button
          pButton
          icon="pi pi-download"
          class="ml-4 p-button-success w-fit"
          (click)="downloadSelected()"
          *ngIf="selectedDocuments.length > 0"
          label="Download selected file(s)"
        ></button>
      </div>

      <p-table [value]="attachments" [(selection)]="selectedDocuments" dataKey="id">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 5%">
              <p-checkbox
                [binary]="true"
                [(ngModel)]="allSelected"
                (ngModelChange)="toggleAllSelection($event)"
              ></p-checkbox>
            </th>
            <th style="width: 60%">File Name</th>
            <th style="width: 35%" class="text-right">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-document let-i="rowIndex">
          <tr>
            <td>
              <p-checkbox
                [binary]="true"
                [(ngModel)]="document.selected"
                (onChange)="select(document, $event, i + 1)"
              >
              </p-checkbox>
            </td>
            <td>
              <span class="text-700 text-lg">{{ document.fileName + document.extension }}</span>
            </td>
            <td class="text-right">
              <p-button
                *ngIf="document?.extension === '.pdf'"
                (onClick)="viewPdf(document)"
                icon="pi pi-eye"
              >
              </p-button>
              <button
                pButton
                icon="pi pi-download"
                class="ml-2 p-button-success"
                (click)="download('DOCUMENT', document, i + 1)"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="displayPdfDialog"
  header="View PDF"
  [modal]="true"
  [style]="{width: '90vw', height: '90vh'}"
>
  <ng-container *ngIf="document">
    <pdf-viewer
      [src]="document.url"
      [render-text]="true"
      [original-size]="true"
      [show-all]="true"
      (error)="onPdfError($event)"
      (on-progress)="loading($event)"
      style="display: block; width: 100%; height: 100%"
    >
    </pdf-viewer>

    <div *ngIf="isLoading" class="loading-spinner-container">
      <p-progressSpinner styleClass="loading-spinner"></p-progressSpinner>
    </div>
  </ng-container>
</p-dialog>
