<ng-container *ngIf="orderData">
  <h1>
    Payment
    <span *ngIf="paymentVendor === 'PAYPAL'">{{ orderData.status }}</span>
    <span *ngIf="paymentVendor === 'STRIPE'">{{ orderData.stripePaymentStatus }}</span>
  </h1>
  <div class="flex flex-wrap">
    <div class="col-12">
      <div class="flex flex-column align-items-start mr-4">
        <div class="flex justify-content-start align-items-center mb-2">
          <span class="font-semibold w-8rem">Status: </span>
          <span class="text-right w-6rem" *ngIf="paymentVendor === 'PAYPAL'">{{
            orderData.status
          }}</span>
          <span class="text-right w-6rem" *ngIf="paymentVendor === 'STRIPE'">{{
            orderData.stripePaymentStatus
          }}</span>
        </div>
        <div class="flex justify-content-start align-items-center mb-2">
          <span class="font-semibold w-8rem">Transaction ID: </span>
          <span class="text-right w-6rem">{{ orderData.transactionId }}</span>
        </div>
        <div class="flex justify-content-start align-items-center mb-2" *ngIf="orderData.vaultId">
          <span class="font-semibold w-8rem">Vault ID: </span>
          <span class="text-right w-6rem">{{ orderData.vaultId }}</span>
        </div>
        <div
          class="flex justify-content-start align-items-center mb-2"
          *ngIf="orderData.cardLastDigits"
        >
          <span class="font-semibold w-8rem">Card last digits: </span>
          <span class="text-right w-6rem">{{ orderData.cardLastDigits }}</span>
        </div>
        <div class="flex justify-content-start align-items-center mb-2">
          <span class="font-semibold w-8rem">Net Amount: </span>
          <span class="text-right w-6rem">{{ orderData.netAmount | currency: 'USD' }}</span>
        </div>
        <div class="flex justify-content-start align-items-center mb-2">
          <span class="font-semibold w-8rem">Tax: </span>
          <span class="text-right w-6rem">{{ orderData.tax | currency: 'USD' }}</span>
        </div>
        <div class="flex justify-content-start align-items-center mb-2">
          <span class="font-semibold w-8rem">Credit card fee: </span>
          <span class="text-right w-6rem">{{ orderData.ccFee | currency: 'USD' }}</span>
        </div>
        <div class="flex justify-content-start align-items-center mb-2">
          <span class="font-semibold w-8rem">Total: </span>
          <span class="text-right w-6rem">{{ orderData.total | currency: 'USD' }}</span>
        </div>
        <div
          class="flex justify-content-start align-items-center mb-2"
          *ngIf="itemType == 'TICKET'"
        >
          <button pButton (click)="backToPayment()">Go back to payment</button>
          <button
            (click)="back()"
            pButton
            label="Go back to ticket"
            class="ml-2"
            rel="noopener noreferrer"
            *ngIf="orderData.units.length === 1"
          ></button>
          <a [routerLink]="['/tickets']" rel="noopener noreferrer" class="p-button ml-2"
            >Go back to tickets</a
          >
        </div>
        <div
          class="flex justify-content-start align-items-center mb-2"
          *ngIf="itemType == 'INVOICE'"
        >
          <button pButton (click)="backToPayment()">Go back to payment</button>
          <button
            (click)="back()"
            pButton
            label="Go back to invoice"
            class="ml-2"
            rel="noopener noreferrer"
            *ngIf="orderData.units.length === 1"
          ></button>
          <a [routerLink]="['/invoices']" rel="noopener noreferrer" class="p-button ml-2"
            >Go back to invoices</a
          >
        </div>
      </div>
    </div>
  </div>
</ng-container>
