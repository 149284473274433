<p-table
  (onSort)="sort($event)"
  [(selection)]="selectedInvoices"
  [autoLayout]="true"
  [customSort]="true"
  [responsive]="true"
  [value]="invoices"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th class="white-space-nowrap" pSortableColumn="id">
        Invoice #
        <p-sortIcon field="id"></p-sortIcon>
      </th>
      <th pSortableColumn="partner">
        Account's name
        <p-sortIcon field="partner"></p-sortIcon>
      </th>
      <th>Ticket #</th>
      <th>Extra-Process</th>
      <th>Payment Term</th>
      <th pSortableColumn="netTerm">
        Net term
        <p-sortIcon field="netTerm"></p-sortIcon>
      </th>
      <th pSortableColumn="createdAt">
        Invoiced on
        <p-sortIcon field="createdAt"></p-sortIcon>
      </th>
      <th class="white-space-nowrap">Total amount</th>
      <th class="white-space-nowrap" pSortableColumn="status">
        Status
        <p-sortIcon field="status"></p-sortIcon>
      </th>
      <th class="text-center" *tjHasRole="'PAYMENT_PROCESS'">Pay</th>
      <th class="text-center" style="width: 3rem">Printed<span class="ml-1">/</span> Marked</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-invoice>
    <tr>
      <td>
        <p-tableCheckbox [value]="invoice"></p-tableCheckbox>
      </td>
      <td>
        <a [routerLink]="['/invoice', invoice.id]" class="list-link">
          <strong>{{ invoice.id }}</strong>
        </a>
      </td>
      <td>
        <span class="text-700" *ngIf="isPrivatePartner(invoice.ticketPartnerStatus)">{{
          invoice.partner ? '(PRIVATE) ' + invoice.partner : '(PRIVATE)'
        }}</span>
        <span
          class="text-700"
          *ngIf="!isPrivatePartner(invoice.ticketPartnerStatus) && invoice.partner"
          >{{ invoice.partner }}</span
        >
      </td>

      <td>
        <a
          *ngFor="let item of invoice.items; let last = last"
          [routerLink]="['/tickets', item.ticketId]"
        >
          <strong>{{ item.ticketId }}</strong
          ><span *ngIf="!last">, &nbsp;</span>
        </a>
      </td>
      <td>
        <span *ngIf="invoice.partnerExtraProcess" class="ml-3">
          <p-tag
            value="CRM"
            class="ml-2"
            [pTooltip]="canViewPartnerExtraProcessNote() ? invoice.partnerExtraProcessNote : ''"
          />
        </span>
        <span *ngIf="!invoice.partnerExtraProcess"> Not specified </span>
      </td>
      <td>
        <ng-container *ngIf="invoice.paymentType; else notSpecifiedPayment">
          <span
            *ngIf="invoice.paymentType.netTermType === 'CREDIT_CARD_ON_FILE'"
            class="pi pi-credit-card ml-5"
            [ngStyle]="{color: invoice.approvalRequired ? 'red' : 'var(--primary-color)'}"
            style="font-size: 1.5rem"
            pTooltip="This account pays by credit card"
            tooltipPosition="top"
          >
          </span>
          <span class="ml-3" *ngIf="invoice.paymentType.netTermType !== 'CREDIT_CARD_ON_FILE'">
            {{ invoice.paymentType | netTermTypePipeText }}
          </span>
        </ng-container>
        <ng-template #notSpecifiedPayment> Not specified </ng-template>
      </td>
      <td>{{ invoice.netTerm ? invoice.netTerm + ' Days' : invoice.netTerm + ' Day' }}</td>
      <td class="white-space-nowrap">{{ invoice.invoiceDate | date: config.dateFormatFull }}</td>
      <td>{{ invoice.totalAmount | currency: 'USD' }}</td>
      <td>
        <p-dropdown
          *ngIf="canEditStatus()"
          #invoiceStatus
          class="custom-invoice-dropdown"
          [disabled]="invoice.status === 'ESTIMATE'"
          [ngClass]="{
            'status-unpaid': invoice.status === 'UNPAID',
            'status-overdue': invoice.status === 'OVERDUE',
            'status-paid': invoice.status === 'PAID',
            'status-void': invoice.status === 'VOID',
            'status-prepaid': invoice.status === 'PREPAID',
            'status-estimate': invoice.status === 'ESTIMATE',
          }"
          [options]="config.invoiceStatuses$ | async"
          [ngModel]="invoice.status"
          placeholder="Select an status"
          (ngModelChange)="onChangeStatus($event, invoice.id, invoiceStatus)"
        ></p-dropdown>

        <p-dropdown
          *ngIf="!canEditStatus()"
          [disabled]="true"
          class="custom-invoice-dropdown"
          [ngClass]="{
            'status-unpaid': invoice.status === 'UNPAID',
            'status-overdue': invoice.status === 'OVERDUE',
            'status-paid': invoice.status === 'PAID',
            'status-void': invoice.status === 'VOID',
            'status-prepaid': invoice.status === 'PREPAID',
            'status-estimate': invoice.status === 'ESTIMATE',
          }"
          [ngModel]="invoice.status"
          [options]="config.invoiceStatuses$ | async"
        ></p-dropdown>
      </td>
      <td class="text-center" *tjHasRole="'PAYMENT_PROCESS'">
        <button
          [ngStyle]="{
            'background-color': invoice.approvalRequired ? 'red' : 'var(--primary-color)',
          }"
          pButton
          icon="pi pi-paypal"
          (click)="toPaymentPage(invoice.id, invoice.approvalRequired)"
          *ngIf="invoice.status != 'PAID'"
        ></button>
      </td>
      <td class="text-center">
        <span
          class="pi pi-check"
          style="color: var(--primary-color)"
          *ngIf="invoice.printed"
        ></span>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog appendTo="body" key="confirmation"></p-confirmDialog>
