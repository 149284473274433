import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {TechnicianMinimalListItem} from '../../../../api/model/TechnicianMinimalListItem';
import {Page, PageQuery, SortQuery} from '../../../../api/model/shared/Page.model';
import {ConfigService, NotificationService} from '../../../../core/services';
import {ActivatedRoute, Router} from '@angular/router';
import {Technician} from '../../../../api/model/Technician.model';
import {catchError, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {TechnicianSearchService} from '../../services/technician-search.service';
import {throwError} from 'rxjs/internal/observable/throwError';
import {Messages} from '../../../../common/messages';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'tj-technicians',
  templateUrl: './technicians.component.html',
  styleUrls: ['./technicians.component.scss'],
  providers: [TechnicianSearchService],
})
export class TechniciansComponent implements OnInit {
  protected readonly env = environment;

  page: Page<TechnicianMinimalListItem>;

  technicians: TechnicianMinimalListItem[];

  selectedTechnician: Technician;

  show: boolean;

  private pageQuery: PageQuery = {
    size: this.env.rowsPerPage[0],
    number: 0,
  };

  private sortQuery: SortQuery = {
    property: 'firstName',
    direction: 'asc',
  };

  searchFilter;

  searchQuery;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService,
    private technicianSearchService: TechnicianSearchService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    this.technicianSearchService.listenQueryParams();
    this.technicianSearchService.searchData.subscribe(({filters, searchQuery}) => {
      this.searchFilter = filters;
      this.searchQuery = searchQuery;
    });

    this.route.queryParams.subscribe(params => {
      this.pageQuery.number = params.page || 0;
      this.pageQuery.size = params.size || this.env.rowsPerPage[0];
      this.loadTechnicians();
    });
  }

  sort($event) {
    this.sortQuery = {
      property: $event.field,
      direction: $event.order === -1 ? 'desc' : 'asc',
    };
    this.router.navigate([], {queryParams: this.sortQuery, queryParamsHandling: 'merge'});
  }

  onGoToPage(event) {
    this.router.navigate([], {
      queryParams: {page: event.page, size: event.rows},
      queryParamsHandling: 'merge',
    });
  }

  onCreateNewTechnician() {
    this.selectTechnician(Technician.buildMinimalTechnician());
  }

  onSelectTechnician(event) {
    if (!event) {
      this.selectedTechnician = undefined;
      return;
    }
    // this.router.navigate(['technicians', event.data.id]);
    this.loadTechnician(event.data.id).subscribe(technician => {
      this.selectTechnician(technician);
    });
  }

  private selectTechnician(technician: Technician) {
    this.selectedTechnician = technician;
  }

  private loadTechnician(id): Observable<Technician> {
    return this.api.technician.findOne(id).pipe(
      tap((technician: Technician) => {
        return technician;
      }),
      catchError(err => {
        this.notificationService.error(Messages.TECHNICIAN_GET_ERROR);
        return throwError(err);
      }),
    );
  }

  private loadTechnicians() {
    this.show = true;
    this.api.technician.findAll(this.pageQuery, this.sortQuery, this.searchQuery).subscribe(
      (techniciansPage: Page<TechnicianMinimalListItem>) => {
        this.page = techniciansPage;
        this.technicians = techniciansPage.response;
      },
      err => {
        console.log(err);
        this.notificationService.error(Messages.TECHNICIANS_GET_ERROR);
      },
      () => {
        this.show = false;
      },
    );
  }

  onSave(technician: Technician): void {
    this.loadTechnicians();
    this.configService.updateTechniciansList(technician);
  }
}
