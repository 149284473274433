<div class="flex flex-wrap">
  <div *ngFor="let filter of partnerFilter | keys" class="mr-2 mb-2">
    <ng-container [ngSwitch]="filter.key">
      <p-chip
        *ngSwitchCase="'deleted'"
        [removable]="true"
        (onRemove)="onRemoveFilter(filter.key)"
        label="Deleted"
      ></p-chip>
      <p-chip
        [removable]="true"
        (onRemove)="onRemoveFilter(filter.key)"
        *ngSwitchDefault
        label="{{ filterNames[filter.key] }}: {{ filter.value }}"
      ></p-chip>
    </ng-container>
  </div>
</div>
