<div [formGroup]="form" class="grid">
  <div class="col">
    <div class="grid">
      <div class="col">
        <tj-subtitle>Account main data</tj-subtitle>
        <div class="grid">
          <div class="col-12 md:col-6">
            <tj-field [control]="getControl('name')" label="Company name">
              <input
                pInputText
                type="text"
                placeholder="Company name"
                id="name"
                formControlName="name"
              />
            </tj-field>
          </div>
          <div class="col-12 md:col-6">
            <tj-field [control]="getControl('email')" label="Email">
              <input
                pInputText
                type="text"
                placeholder="Email"
                id="email"
                formControlName="email"
              />
            </tj-field>
          </div>
          <div class="col-12 md:col-6">
            <tj-field [control]="getControl('username')" label="Username">
              <input
                formControlName="username"
                id="username"
                pInputText
                placeholder="Username"
                type="text"
              />
            </tj-field>
          </div>
          <div class="col-12">
            <tj-field [control]="getControl('password')" label="Password">
              <input
                pInputText
                type="password"
                placeholder="Password"
                id="password"
                formControlName="password"
              />
            </tj-field>
          </div>
          <div class="col-12 md:col-6">
            <tj-field [control]="getControl('partnerType')" label="Industry / Type">
              <tj-partner-type-combobox formControlName="partnerType"></tj-partner-type-combobox>
            </tj-field>
          </div>
          <div class="col-12">
            <tj-field [control]="getControl('notes')" label="Notes (shown on work order)">
              <textarea pInputTextarea formControlName="notes"></textarea>
            </tj-field>
          </div>
          <div class="col-12" *ngIf="canViewEditInternalNote()">
            <tj-field
              [control]="getControl('internalNote')"
              label="Additional Internal note (shown only here)"
            >
              <textarea
                pInputTextarea
                formControlName="internalNote"
                class="tj-internal-note-textarea"
              ></textarea>
            </tj-field>
          </div>
          <div class="col-12" *ngIf="canViewInternalNote() && !canViewEditInternalNote()">
            <label>Additional Internal note (shown only here)</label>
            <textarea
              pInputTextarea
              value="{{ this.partner?.internalNote }}"
              class="tj-internal-note-textarea"
              disabled
            ></textarea>
          </div>
        </div>
      </div>
      <div class="col-12">
        <tj-subtitle>Options</tj-subtitle>
        <div class="grid">
          <div class="col-fixed mr-4">
            <p-checkbox
              formControlName="combinedInvoice"
              label="Combined invoices allowed"
              binary="true"
            ></p-checkbox>
          </div>
          <div class="col-fixed mr-4">
            <p-checkbox
              formControlName="printAllContactPersons"
              label="Show all contacts in print"
              binary="true"
            ></p-checkbox>
          </div>
          <div class="col-fixed mr-4">
            <p-checkbox formControlName="copyByFax" label="Copy by fax" binary="true"></p-checkbox>
          </div>
          <div class="col-12 p-0 m-0 flex justify-content-center align-items-center">
            <div class="col-4">
              <p-checkbox
                formControlName="extraProcess"
                label="Extra-Process"
                binary="true"
              ></p-checkbox>
              <p-tag value="CRM" class="ml-2" />
            </div>
            <div class="col-8">
              <tj-field [control]="getControl('extraProcessNote')" label="Extra-Process Note">
                <input
                  pInputText
                  type="text"
                  placeholder="Extra-Process Note"
                  formControlName="extraProcessNote"
                />
              </tj-field>
            </div>
          </div>
          <!--          <div class="col-fixed mr-4">-->
          <!--            <p-checkbox formControlName="dateOfServiceNotification" label="Send date of service notifications" binary="true"></p-checkbox>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col">
        <tj-subtitle>Payment</tj-subtitle>
        <div class="col-fixed">
          <tj-field label="Net Term / Invoice Due Within" [control]="getControl('netTerm')">
            <p-inputNumber
              placeholder="Select days"
              mode="decimal"
              styleClass="block"
              class="block"
              formControlName="netTerm"
              [useGrouping]="false"
            ></p-inputNumber>
          </tj-field>
        </div>
        <tj-net-term
          [form]="form"
          [payment]="partner.payment"
          [isPrivate]="false"
          group="payment"
        ></tj-net-term>
      </div>
    </div>
    <div class="grid">
      <div class="col">
        <tj-subtitle>Address</tj-subtitle>
        <tj-address-create-form
          [form]="form"
          [address]="partner.address"
          group="address"
        ></tj-address-create-form>
      </div>
    </div>
    <div class="grid">
      <div class="col">
        <tj-subtitle>Contact persons</tj-subtitle>
        <tj-contact-persons-form
          [canChangeMainContactPerson]="false"
          [contactPersons]="partner.contactPersons"
          [pdfReceiver]="configService.isConfigEnabled('PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT')"
          [receiveNotificationsRequestedRep]="
            configService.isConfigEnabled('PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT')
          "
          [receiveInvoiceRequestedRep]="
            configService.isConfigEnabled('PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT')
          "
          [receivesCommentsIfRep]="
            configService.isConfigEnabled('PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT')
          "
          [form]="form"
        ></tj-contact-persons-form>
      </div>
    </div>
  </div>
</div>
