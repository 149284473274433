<div class="col-12" *ngIf="page && areaToZipCodes">
  <p-toolbar styleClass="mb-4 block" *tjHasRole="'ZIP_CODE_EDIT'">
    <p-panel header="Filters">
      <tj-area-to-zip-code-list-filter
        [areaToZipCodeFilter]="areaToZipCodeFilter"
        [page]="page"
      ></tj-area-to-zip-code-list-filter>
    </p-panel>
  </p-toolbar>

  <tj-area-to-zip-code-list
    [areaToZipCodes]="areaToZipCodes"
    (onSort)="sort($event)"
    (onDelete)="onDeleteZipCode($event)"
  ></tj-area-to-zip-code-list>
  <p-paginator
    [totalRecords]="page.totalElements"
    [rows]="env.rowsPerPage[0]"
    [rowsPerPageOptions]="env.rowsPerPage"
    (onPageChange)="onGoToPage($event)"
    [showCurrentPageReport]="true"
    [first]="pageQuery.number * pageQuery.size"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  >
  </p-paginator>
</div>
<p-confirmDialog appendTo="body" key="confirmDeletion"></p-confirmDialog>
