import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaymentDetailsComponent} from './payment-details/payment-details.component';
import {RouterModule} from '@angular/router';
import {AuthGuardService} from '../../core/services';
import {SharedModule} from '../shared/shared.module';
import {StripeDetailsComponent} from './stripe-details/stripe-details.component';
import {PaymentCompletedComponent} from './payment-completed/payment-completed.component';
import {PaymentFlowService} from './payment-flow.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: 'payment',
        component: PaymentDetailsComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['PAYMENT_PROCESS'],
        },
      },

      {
        path: 'payment/stripe',
        component: StripeDetailsComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['PAYMENT_PROCESS'],
        },
      },
      {
        path: 'payment/stripe/completed',
        component: PaymentCompletedComponent,
        canActivate: [AuthGuardService],
        data: {
          // roles: ['PAYMENT_PROCESS'],
        },
      },
    ]),
  ],
  declarations: [PaymentDetailsComponent, PaymentCompletedComponent, StripeDetailsComponent],
  providers: [PaymentFlowService],
  exports: [PaymentDetailsComponent],
})
export class PaymentModule {}
