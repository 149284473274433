<div [formGroup]="currentFormGroup" class="grid">
  <div class="col-12">
    <tj-subtitle>Customer</tj-subtitle>
    <tj-field label="Name" [control]="fcName" class="auto cell">
      <input pInputText type="text" placeholder="Name *" id="customerName" formControlName="name" />
    </tj-field>
  </div>

  <div class="col-12">
    <tj-subtitle>Address</tj-subtitle>
    <div class="grid">
      <div class="col-12">
        <tj-field [control]="fcAddressConfirmed">
          <p-checkbox
            formControlName="addressConfirmed"
            label="Address confirmed?"
            binary="true"
          ></p-checkbox>
        </tj-field>
      </div>
      <div class="col-12">
        <tj-address-edit-form
          group="address"
          (zipCode)="changeZipCode($event)"
          [form]="currentFormGroup"
          [address]="customer.address"
        >
          <tj-zip-code-covered-info
            [zipCode]="customerAddressZipCode"
            *ngIf="configService.isEnabledTerritory()"
          ></tj-zip-code-covered-info>
        </tj-address-edit-form>
      </div>
    </div>
  </div>

  <div class="col-12">
    <tj-subtitle>Contact persons</tj-subtitle>
    <tj-contact-persons-form
      [canChangeReceivers]="false"
      [customerName]="currentFormGroup.get('name').getRawValue()"
      [form]="currentFormGroup"
      [mainContactPerson]="mainContactPerson"
      [contactPersons]="customer.contactPersons"
    ></tj-contact-persons-form>
  </div>
</div>
