import {Component, Input, OnInit} from '@angular/core';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {Partner} from '../../../../api/model/Partner.model';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';
import {PartnerInvoiceListComponent} from '../../../invoice/components';
import {NavigationStart, Router} from '@angular/router';
import {DialogService} from 'primeng/dynamicdialog';

@Component({
  selector: 'tj-partner-details',
  templateUrl: './partner-details.component.html',
  styleUrls: ['./partner-details.component.scss'],
})
export class PartnerDetailsComponent implements OnInit {
  @Input() partner: Partner;

  @Input() mainContactPerson: ContactPerson;

  @Input() partnerBusinessStatus: string;
  @Input() showOnlyMain = false;
  secondaryContactPersons: ContactPerson[] = [];
  showSecondaryContactPersons = false;

  constructor(
    private dialogService: DialogService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.partner) {
      this.secondaryContactPersons = this.getSecondaryContactPersons();
    }
  }

  isPrivateBusiness(): boolean {
    return this.partnerBusinessStatus === TicketPartnerStatus[TicketPartnerStatus.PRIVATE_BUSINESS];
  }

  isPrivate(): boolean {
    return this.partnerBusinessStatus === TicketPartnerStatus[TicketPartnerStatus.PRIVATE];
  }

  onShowSecondaryContactPersons() {
    this.showSecondaryContactPersons = !this.showSecondaryContactPersons;
  }

  getSecondaryContactPersons() {
    // add filter once backend return correct info
    return this.partner.contactPersons?.filter(contactPerson => {
      return contactPerson.id !== this.mainContactPerson?.id;
    });
  }

  showAccountOverdueInvoiceList() {
    const type = this.partnerBusinessStatus;
    const userId = this.partner.id;

    const startDate: string = null;
    const endDate: string = null;
    const ref = this.dialogService.open(PartnerInvoiceListComponent, {
      width: '70%',
      data: {
        startDate,
        endDate,
        type,
        userId,
      },
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        ref.close();
      }
    });
  }
}
