import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ConfigService} from '../../../../core/services';
import {InvoiceSearchFilterModel} from '../../models/invoice-search-filter.model';
import {PartnerMinimalListItem} from '../../../../api/model/PartnerLine.model';
import {TechnicianMinimalListItem} from '../../../../api/model/TechnicianMinimalListItem';
import {Tag} from '../../../../api/model/Tag.model';
import {environment} from '../../../../../environments/environment';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'tj-invoice-search-labels',
  templateUrl: './invoice-search-labels.component.html',
  styleUrls: ['./invoice-search-labels.component.scss'],
})
export class InvoiceSearchLabelsComponent implements OnChanges, OnInit {
  private readonly ticketStatusMap: Map<string, string> = new Map<string, string>();

  partnersMap: {[id: number]: PartnerMinimalListItem} = {};

  techniciansMap: {[id: number]: TechnicianMinimalListItem} = {};

  tags: Tag[] = [];

  protected readonly environment = environment;

  @Input() invoiceFilter: InvoiceSearchFilterModel;
  @Input() filterForm: UntypedFormGroup;
  @Output() remove = new EventEmitter();

  filterNames = {
    id: 'Id',
    ticketId: 'Ticket #',
    status: 'Status',
    customer: 'Customer',
    serviceDate: 'Service date',
    issueDate: 'Invoiced date',
    contact: 'Contact',
    comment: 'Comment',
    partner: 'Account',
    address: 'Address',
    technician: 'Technician',
    partnerId: 'Partner',
    externalReferenceCode: 'PO/WO/SALES',
    isExtraProcess: 'Extra-Process',
    partnerType: 'Account type',
    invoiceAmount: 'Invoice amount',
    privatePartner: 'Private',
  };

  constructor(public config: ConfigService) {}

  ngOnInit() {}

  ngOnChanges() {
    this.config.ticketStatusMap.forEach(it => {
      this.ticketStatusMap.set(it.value as string, it.label);
    });
    this.config.partners$.subscribe(value => {
      this.partnersMap = value.reduce((acc, partner) => {
        acc[partner.id] = partner;
        return acc;
      }, this.partnersMap);
    });
    this.config.technicians$.subscribe(value => {
      this.techniciansMap = value.reduce((acc, technician) => {
        acc[technician.id] = technician;
        return acc;
      }, this.techniciansMap);
    });
  }

  onRemoveFilter(filterName: string, item?: string) {
    const updatedFilter = {...this.invoiceFilter};

    if (updatedFilter[filterName]) {
      if (filterName === 'status' && item) {
        updatedFilter[filterName] = updatedFilter[filterName].filter(
          (status: string) => status !== item,
        );

        if (updatedFilter[filterName].length === 0) {
          updatedFilter[filterName] = null;
        }
      } else {
        updatedFilter[filterName] = null;
      }

      const formControl = this.filterForm?.get(`invoiceFilter.${filterName}`);
      if (formControl) {
        formControl.setValue(updatedFilter[filterName]);
      }
    }
    this.remove.emit(updatedFilter);
  }
}
