import {Injectable} from '@angular/core';
import {CommunicationService} from './communication.service';
import {SearchQuery} from '../model/shared/Page.model';
import {Filter} from '../model/shared/Filter.model';

@Injectable()
export class CommunicationFilterableService extends CommunicationService implements SearchQuery {
  filterMapper: {};

  getSearchQuery(filter: Filter) {
    const queryParams = [] as string[];
    if (!filter) {
      return '';
    }
    const mapper = this.filterMapper;
    for (const key in filter) {
      if (filter.hasOwnProperty(key) && filter[key] && mapper.hasOwnProperty(key) && mapper[key]) {
        if (Array.isArray(filter[key])) {
          filter[key].forEach(value => {
            queryParams.push(mapper[key] + value);
          });
        } else {
          queryParams.push(mapper[key] + filter[key]);
        }
      }
    }
    return queryParams.join(',');
  }
}
