import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {PartnersComponent} from './pages/partners/partners.component';
import {SharedModule} from '../shared/shared.module';
import {AuthGuardService} from '../../core/services';
import {InvoiceModule} from '../invoice/invoice.module';
import {RouterModule} from '@angular/router';
import {TicketModule} from '../ticket/ticket.module';
import {
  PartnerEditComponent,
  PartnerEditFormComponent,
  PartnerListComponent,
  PartnerSearchComponent,
  PartnerSearchFormComponent,
  PartnerSearchLabelsComponent,
} from './components';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    SharedModule,
    InvoiceModule,
    RouterModule.forRoot([
      // {path: 'partners/create', component: TicketCreateComponent, canActivate: [AuthGuardService]},
      {
        path: 'accounts',
        component: PartnersComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['PARTNER_VIEW'],
          breadcrumb: 'Accounts',
        },
      },
    ]),
    TicketModule,
  ],
  declarations: [
    PartnersComponent,
    PartnerEditFormComponent,
    PartnerEditComponent,
    PartnerSearchFormComponent,
    PartnerSearchComponent,
    PartnerSearchLabelsComponent,
    PartnerListComponent,
  ],
})
export class PartnerModule {}
