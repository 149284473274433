<p-dialog
  [(visible)]="insertInfoDialog"
  [style]="{width: '670px'}"
  [header]="title"
  [modal]="true"
  (onHide)="hide()"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <hr />
    <div class="col-12">
      <h3 class="font-italic">Insert / Copy / Paste</h3>
      <div>
        <div>
          <p>
            Enter as many zip codes as you like, comma-separated. If additional travel fees exist,
            add them after the # symbol. NO SPACES in between.
          </p>
          <p class="ml-4">
            <strong>example: 19891#150,34250,90084#200,32063,33576#20,32764,20857</strong>
          </p>
        </div>
      </div>
    </div>
    <hr />
  </ng-template>
</p-dialog>
