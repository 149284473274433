import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CommunicationService} from '../service/communication.service';
import {Config} from '../model/Config.model';

@Injectable()
export class GlobalConfigApi extends CommunicationService {
  protected apiEndpoint = this.env.apiEndpoint + 'globalconfigs';

  findAll(): Observable<Config[]> {
    return this.requestService.get(`${this.apiEndpoint}`).pipe(
      map(response => response.response as Config[]),
      catchError(this.handleError),
    );
  }

  findAllAsAMap(): Observable<{[key: string]: string}> {
    return this.findAll().pipe(
      map(configs => {
        return configs.reduce((acc, config) => {
          acc[config.code] = config.value;
          return acc;
        }, {});
      }, catchError(this.handleError)),
    );
  }

  update(config: Config): Observable<Config> {
    const url = this.apiEndpoint;
    return this.requestService.put(url, config).pipe(catchError(this.handleError));
  }

  values(configCodes: string[]) {
    return this.requestService.patch(`${this.apiEndpoint}/values`, {configCodes: configCodes}).pipe(
      map(response => response.response),
      catchError(this.handleError),
    );
  }
}
