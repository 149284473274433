<div class="mb-4">
  <p-panel header="Report" styleClass="mb-4">
    <div class="col-12">
      <tj-field>
        <textarea [formControl]="reportFc" pInputTextarea placeholder="Report" rows="10"></textarea>
      </tj-field>
    </div>
  </p-panel>
  <tj-signature-viewer
    *ngIf="ticket && ticket.galleryId"
    [galleryId]="ticket.galleryId"
    [ticket]="ticket"
    (saveReport)="saveReport()"
  >
  </tj-signature-viewer>
</div>
