import {Component, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {BreadcrumbService} from './service/app.breadcrumb.service';
import {MenuItem} from 'primeng/api';
import {NotificationService} from '../core/services';

interface Breadcrumb {
  label: string;
  url?: string;
}

@Component({
  selector: 'tj-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
})
export class AppBreadcrumbComponent implements OnDestroy {
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  subscription: Subscription;

  showCopyToClipboard = false;

  text: string;

  textClass: string;

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private notificationService: NotificationService,
  ) {
    let breadcrumbs: Breadcrumb[];
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      breadcrumbs = [];
      this.showCopyToClipboard = false;
      const root = this.router.routerState.snapshot.root;
      this.addBreadcrumb(root, [], breadcrumbs);

      this._breadcrumbs$.next(breadcrumbs);
    });

    this.subscription = this.breadcrumbService.itemsHandler.subscribe((response: MenuItem) => {
      breadcrumbs.push({
        label: response.label,
        url: response.url,
      });
    });
    this.subscription = this.breadcrumbService.showCopyToClipboardHandler.subscribe(response => {
      this.showCopyToClipboard = response;
    });
    this.subscription = this.breadcrumbService.textSourceHandler.subscribe(text => {
      this.text = text;
    });

    this.subscription = this.breadcrumbService.textClassSourceHandler.subscribe(textClass => {
      this.textClass = textClass;
    });
  }

  private addBreadcrumb(
    route: ActivatedRouteSnapshot,
    parentUrl: string[],
    breadcrumbs: Breadcrumb[],
  ) {
    const routeUrl = parentUrl.concat(route.url.map(url => url.path));
    const breadcrumb = route.data['breadcrumb'];
    const parentBreadcrumb =
      route.parent && route.parent.data ? route.parent.data['breadcrumb'] : null;

    if (breadcrumb && breadcrumb !== parentBreadcrumb) {
      breadcrumbs.push({
        label: route.data['breadcrumb'],
        url: '/' + routeUrl.join('/'),
      });
    }

    if (route.firstChild) {
      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  getTicketNumberText(): string {
    let breadcrumbsText = '';
    this.breadcrumbs$.subscribe(breadcrumbs => {
      breadcrumbsText = breadcrumbs && breadcrumbs[0] ? breadcrumbs[0].label : '';
    });
    return this.extractFirstTicketNumber(breadcrumbsText);
  }

  onCopySuccess(event: boolean) {
    if (event) {
      this.notificationService.info('Ticket/Invoice number is copied');
    } else {
      this.notificationService.error('Failed to copy Ticket/Invoice number.');
    }
  }

  extractFirstTicketNumber(text: string): string | null {
    const match = text.match(/#(\d+)/);
    return match ? match[1] : null;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
