import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ConfigService} from '../../../../core/services';
import * as dayjs from 'dayjs';
import {FormHelpers} from '../../../shared/utils/form-helpers';

@Component({
  selector: 'tj-ticket-edit-dates-modal',
  templateUrl: './ticket-edit-service-time-modal.component.html',
  styleUrls: ['./ticket-edit-service-time-modal.component.scss'],
})
export class TicketEditServiceTimeModalComponent implements OnInit {
  editForm: UntypedFormGroup;
  private serviceTimeEnd: Date;
  private serviceTimeStart: Date;
  private timeNeeded: number;

  ticketEstimations;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    configService: ConfigService,
    private fb: UntypedFormBuilder,
  ) {
    this.ticketEstimations = configService.ticketEstimations.map(value => {
      return {label: value, value};
    });

    this.editForm = this.fb.group({
      serviceTimeStart: [''],
      serviceTimeEnd: [''],
      timeNeeded: [''],
    });
    this.editForm.setValidators(this.validateTimeRange());
  }

  ngOnInit() {
    this.serviceTimeStart = this.config.data?.serviceTimeStart;
    this.serviceTimeEnd = this.config.data?.serviceTimeEnd;
    this.timeNeeded = this.config.data?.timeNeeded;

    this.editForm.patchValue({
      serviceTimeStart: this.serviceTimeStart,
      serviceTimeEnd: this.serviceTimeEnd,
      timeNeeded: this.timeNeeded,
    });
  }

  save(notifyCustomer: boolean) {
    if (this.editForm.valid) {
      this.ref.close({
        serviceTimeStart: this.editForm.get('serviceTimeStart').getRawValue(),
        serviceTimeEnd: this.editForm.get('serviceTimeEnd').getRawValue(),
        timeNeeded: this.editForm.get('timeNeeded').getRawValue(),
        notifyCustomer,
      });
    } else {
      FormHelpers.validateAllFormFields(this.editForm);
    }
  }

  getControl(controlName: string) {
    return this.editForm.get(controlName) as UntypedFormControl;
  }

  private validateTimeRange(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const startTimeControl = group.get('serviceTimeStart');
      const endTimeControl = group.get('serviceTimeEnd');
      if (startTimeControl && endTimeControl && startTimeControl.value && endTimeControl.value) {
        const startTime = dayjs(startTimeControl.value).utc().format('HH:mm:ss');
        const endTime = dayjs(endTimeControl.value).utc().format('HH:mm:ss');
        return startTime < endTime ? null : {timeRangeError: {valid: false}};
      }
      return null;
    };
  }
}
