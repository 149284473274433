<div class="flex flex-column h-full">
  <div #commentContainer class="overflow-y-auto" style="max-height: 25rem; min-height: 10rem">
    <div *ngFor="let comment of comments; let i = index">
      <div *ngIf="comment.userId !== user.id" class="grid grid-nogutter mb-2">
        <div class="col mt-3">
          <p class="text-900 font-semibold mb-2">{{ comment.username || 'UNKNOWN' }}</p>
          <span
            class="text-700 inline-block font-medium border-1 surface-border p-2 border-round"
            style="word-break: break-word; max-width: 80%; white-space: break-spaces"
            >{{ comment.message }}</span
          >
          <p class="text-700 mt-2 text-xs">
            {{ comment.createdAt | date: config.dateFormatFull }}
          </p>
        </div>
      </div>

      <div *ngIf="comment.userId === user.id" class="grid grid-nogutter mb-2">
        <div class="col mt-2 text-right mr-2">
          <p class="text-900 font-semibold mb-2">{{ comment.username || 'UNKNOWN' }}</p>
          <span
            class="inline-block text-left font-medium border-1 surface-border bg-primary-100 text-primary-900 p-2 border-round"
            style="word-break: break-word; max-width: 80%; white-space: break-spaces"
            >{{ comment.message }}</span
          >
          <p class="text-700 mt-2 text-xs">
            {{ comment.createdAt | date: config.dateFormatFull }}
          </p>
        </div>
      </div>
    </div>
    <div
      *ngIf="!comments || comments.length === 0"
      class="flex flex-column h-10rem justify-content-center text-center"
    >
      <p class="font-italic">No comments yet.</p>
    </div>
  </div>
  <div class="mt-4">
    <form novalidate [formGroup]="commentForm" (submit)="onCommentSave()">
      <tj-comment-form
        [form]="commentForm"
        (onSaveComment)="onCommentSave()"
        [comment]="comment"
        group="comment"
      ></tj-comment-form>
    </form>
  </div>
</div>

<p-dialog
  [(visible)]="showTechnicianModal"
  header="Select Technicians"
  [modal]="true"
  [closable]="true"
  [style]="{width: '400px'}"
>
  <div class="flex flex-column justify-content-between" style="height: 300px">
    <div class="flex flex-column overflow-y-auto" style="max-height: 250px">
      <p-checkbox
        class="mb-3"
        *ngFor="let technician of technicians"
        [(ngModel)]="selectedTechnicians"
        [value]="technician.id"
        [label]="technician.name"
      >
      </p-checkbox>
    </div>
    <div class="flex justify-content-end">
      <button
        pButton
        [disabled]="selectedTechnicians?.length == 0"
        label="Confirm"
        icon="pi pi-check"
        (click)="confirmTechnicianSelection()"
      ></button>
    </div>
  </div>
</p-dialog>
