<div [formGroup]="currentFormGroup">
  <div class="grid" *ngIf="configService.isEnabledWarranty()">
    <div class="col-6">
      <tj-field label="Warranty start date" [control]="fcDeliveryDate">
        <tj-datepicker
          [onlyFuture]="false"
          formControlName="deliveryDate"
          [ngClass]="fcDeliveryDate.value ? 'selected-item-bold' : ''"
          placeholder="Warranty start date"
        ></tj-datepicker>
      </tj-field>
    </div>
    <div class="col-6">
      <tj-field label="Warranty terms" [control]="fcWarrantyTerms">
        <input
          pInputText
          type="text"
          placeholder="Warranty terms"
          id="warrantyTerms"
          formControlName="warrantyTerms"
        />
      </tj-field>
    </div>
  </div>
  <div>
    <div class="mt-4 mb-4">
      <tj-field label="PO/WO/SALES" [control]="fcExternalReferenceCode">
        <input
          pInputText
          type="text"
          placeholder="PO/WO/SALES"
          id="externalReferenceCode"
          formControlName="externalReferenceCode"
        />
      </tj-field>
    </div>
    <tj-field [control]="fcIssue" label="Issue description">
      <textarea
        class="issue-textarea"
        formControlName="issue"
        id="issue"
        pInputTextarea
        placeholder="Issue description *"
      ></textarea>
    </tj-field>
    <div *ngIf="!ticket.id" class="mt-3">
      <p-message
        severity="info"
        text="Pictures/Videos/Documents upload available after saving the work order"
        styleClass="uploadInfo"
      ></p-message>
    </div>
    <div class="mt-4" *ngIf="canEditCategory()">
      <tj-field label="Type of Service/Note" [control]="fcCategory">
        <input formControlName="category" id="category" pInputText placeholder="Type of Service" />
      </tj-field>
    </div>
    <div class="mt-4">
      <tj-field label="Additional notes" [control]="fcAdditionalNotes">
        <textarea
          pInputTextarea
          placeholder="Additional notes"
          id="additionalNotes"
          formControlName="additionalNotes"
        ></textarea>
      </tj-field>
    </div>
  </div>
</div>
