import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class SearchService<T> {
  constructor(protected router: Router) {}

  redirectWithSearchQuery(route: String, searchQuery: T) {
    const filteredSearchQuery = {};
    Object.keys(searchQuery).forEach(key => {
      if (searchQuery[key]) {
        filteredSearchQuery[key] = searchQuery[key];
      }
    });
    this.router.navigate([route], {queryParams: filteredSearchQuery});
  }
}
