import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SearchQuery} from '../model/shared/Page.model';
import {Injectable} from '@angular/core';
import {CommunicationFilterableService} from '../service/communication-filtarable.service';
import {EntityListResponseModel, IdResponseModel} from '../model/shared/Response.model';
import {ReviewItemModel, ReviewModel} from '../model/reviews/review.item.model';

@Injectable()
export class ReviewsApi extends CommunicationFilterableService implements SearchQuery {
  protected apiEndpoint = this.env.apiEndpoint + 'reviews';

  getReviews(): Observable<EntityListResponseModel<ReviewItemModel>> {
    const url = `${this.apiEndpoint}`;
    return this.requestService.get(url).pipe(
      map(response => response as EntityListResponseModel<ReviewItemModel>),
      catchError(this.handleError),
    );
  }

  create(model: ReviewModel): Observable<IdResponseModel> {
    const url = `${this.apiEndpoint}`;
    return this.requestService.post(url, model).pipe(
      map(response => response as IdResponseModel),
      catchError(this.handleError),
    );
  }

  delete(id: number): Observable<void> {
    const url = `${this.apiEndpoint}/${id}`;
    return this.requestService.remove(url).pipe(catchError(this.handleError));
  }
}
