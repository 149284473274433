<ng-container *ngIf="payment">
  <div [formGroup]="currentFormGroup">
    <div class="col-12">
      <tj-field label="Payment Term" [control]="fcNetTermType">
        <p-dropdown [options]="nettermsTypes$ | async" formControlName="netTermType"></p-dropdown>
      </tj-field>
    </div>
    <div class="col-12">
      <ng-container [ngSwitch]="fcNetTermType.value">
        <ng-container *ngSwitchCase="'CREDIT_CARD_ON_FILE'">
          <div class="grid">
            <div class="col-12 md:col-6">
              <tj-field label="Paypal transaction / Vault id" [control]="fcTransactionId">
                <input pInputText type="text" formControlName="transactionId" />
              </tj-field>
            </div>
            <div class="col-12 md:col-6">
              <tj-field label="Stripe transaction / Vault id" [control]="fcStripeTransactionId">
                <input pInputText type="text" formControlName="stripeTransactionId" />
              </tj-field>
            </div>
          </div>
          <div class="grid">
            <div class="mt-2">
              <tj-field [control]="fcApprovalRequired">
                <p-checkbox
                  formControlName="approvalRequired"
                  label="Approval Required Prior of charging the card"
                  binary="true"
                ></p-checkbox>
              </tj-field>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'PREPAID_CREDIT'">
          <tj-field label="Money" [control]="fcMoney">
            <input pInputText type="text" formControlName="money" />
          </tj-field>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
