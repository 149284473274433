import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Area} from '../../../../../api/model/Area.model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ConfigService} from '../../../../../core/services';

@Component({
  selector: 'tj-area-form',
  templateUrl: './area-form.component.html',
  styleUrls: ['./area-form.component.scss'],
})
export class AreaFormComponent implements OnInit {
  @Output('onCreate') createEmitter: EventEmitter<Area> = new EventEmitter<Area>();

  @Output('onUpdate') updateEmitter: EventEmitter<Area> = new EventEmitter<Area>();

  @Output('onCancel') cancelEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() area: Area;

  areaForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public config: ConfigService,
  ) {}

  ngOnInit(): void {
    this.areaForm = this.fb.group({
      name: this.fb.control(this.area.name, [
        Validators.required,
        Validators.pattern(/^[A-Za-z0-9\s]{3,50}$/),
      ]),
      code: this.fb.control(this.area.code, [
        Validators.required,
        Validators.pattern(/^[A-Za-z0-9\s]{2,50}$/),
      ]),
    });
  }

  getControl(fcName: string): UntypedFormControl {
    return this.areaForm?.get(fcName) as UntypedFormControl;
  }

  onCancel() {
    this.cancelEmitter.emit();
  }

  onSaveArea() {
    const area = this.areaForm.value as Area;
    area.id = this.area.id;
    if (this.area.id) {
      this.updateEmitter.emit(area);
    } else {
      this.createEmitter.emit(area);
    }
  }
}
