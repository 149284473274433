import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageEditComponent, TjImageCropperComponent, TjImageOverlayComponent} from './components';
import {CoreModule} from '../../core/core.module';
import {ImageCropperComponent} from 'ngx-image-cropper';
import {ColorPickerModule} from 'primeng/colorpicker';


@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    CoreModule,
    CommonModule,
    ColorPickerModule,
    ImageCropperComponent
  ],
  declarations: [
    TjImageCropperComponent,
    TjImageOverlayComponent,
    ImageEditComponent,
  ],
  exports: [
    TjImageCropperComponent,
    TjImageOverlayComponent,
    ImageEditComponent,
    ImageCropperComponent,
    ColorPickerModule
  ]
})
export class UploaderModule {
}
