export class ContactInfoList {
  id: number;

  personName: string;

  email: string[] = [];

  phone: string[] = [];

  mobilePhone: string[] = [];

  fax: string[] = [];
}
