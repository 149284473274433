import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Injectable()
export class GalleryService {
  private readonly ORIGINAL = 'tj-images-original';

  private readonly RESIZED = 'tj-attachments';

  imgproxyEndpoint: string = environment.imgproxyEndpoint;

  public getImageUrl(
    imagePath: string,
    {width = 0, height = 0, quality = 80, resized = false},
    filename: string,
    index?: number,
  ): string {
    const resize = resized ? '-400' : '';
    const imageIndex = index ? `_${index}` : '';
    const name = filename ? `/filename:${this.replaceName(filename)}${imageIndex}` : '';
    const bucket = resized ? this.RESIZED : this.ORIGINAL;
    return (
      this.imgproxyEndpoint +
      `/w:${width}/h:${height}/q:${quality}${name}/plain/s3://${bucket}/${imagePath}${resize}`
    );
  }

  private replaceName(name: string) {
    return name ? name.replace(/[^\w.-]/g, '_') : name;
  }
}
