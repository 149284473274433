import * as dayjs from 'dayjs';
import * as weekday from 'dayjs/plugin/weekday';
import * as utc from 'dayjs/plugin/utc';
import {Area} from '../../api/model/Area.model';

dayjs.extend(weekday);
dayjs.extend(utc);

export class PayFormEditModel {
  id: number;
  balance: number;
  payBalance: number;
  cashTotal: number;
  inCome: number;
  technicianId: number;
  percent: number;
  startDate: string;
  endDate: string;
  status: string;
  nextPayFormTransferDetails?: PayFormTransferDetails;
  info: PayFormInfo;
  payFormItems: PayFormItemEditModel[] = [];

  static buildMinimalPayForm(): PayFormEditModel {
    const payFormEditModel = new PayFormEditModel();
    payFormEditModel.startDate = dayjs().utc().weekday(1).hour(13).minute(0).second(0).format();
    payFormEditModel.endDate = dayjs().utc().weekday(7).hour(13).minute(0).second(0).format();
    return payFormEditModel;
  }

  getInfo(): PayFormInfo {
    if (this.info == null) {
      this.info = new PayFormInfo();
    }
    return this.info;
  }
}

export class PayFormTransferDetails {
  payFormId: number;
  transfer: number;
  transferredDate: Date | string;
}

export class PayFormInfo {
  bonus: number;
  bonus2: number;
  deduct: number;
  deduct2: number;
  bonusDescription: string;
  bonusDescription2: string;
  nextPayFormTransferDetails?: PayFormTransferDetails;
  deductDescription: string;
  deductDescription2: string;
  transfer: number;
  transferred: boolean;
  transferredFromId: number;
  transferDate: string;
  paidAmount: number;
  paidDate: string;
}

export class PayFormItemEditModel {
  id: number;
  area: string;
  date: string;
  totalIncomeCash?: number;
  totalIncomeCheck?: number;
  totalExpense?: number;
  totalTechnicianExpense?: number;
  technicianTotalIncomeCash?: number;
  technicianTotalIncomeCheck?: number;
  technicianTotalExpense?: number;
  technicianTotalTechnicianExpense?: number;
  payOff?: number;
  incomeDayTotal?: number;
  payFormItemTickets: PayFormItemTicketEditModel[] = [];
}

export class PayFormItemTicketEditModel {
  id: number;
  note: string;
  ticketId: number;
  incomeCash: number;
  incomeCheck: number;
  expenses: number;
  technicianExpenses: number;
  areas: Area[];
}

export class SentToPayformModel {
  technicianId: number;
  payformId: number;
  payFormCreatedAt: Date;
}
