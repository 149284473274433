import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {FileUpload} from 'primeng/fileupload';
import {GalleryUploadResponseModel} from '../../../shared/models/Gallery.model';
import {Messages} from '../../../../common/messages';
import {BehaviorSubject, timer} from 'rxjs';
import {NotificationService, TjKeycloakService} from '../../../../core/services';
import {AttachmentModel, DocumentRequestModel} from '../../../shared/models/ImageRequest.model';
import {ProgressBarService} from '../../../../core/services/progressbar.service';
import {jsUtils} from '../../../shared/utils/Utils';
import {ConfirmationService} from 'primeng/api';
import {CatalogModel} from '../../../../api/model/catalog/catalogModel';
import downloadFile = jsUtils.downloadFile;

@Component({
  selector: 'tj-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent implements OnInit {
  private isFileShowing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  catalog: CatalogModel;

  isUploading = false;

  document: DocumentRequestModel;
  displayPdfDialog = false;

  isLoading: boolean = false;

  constructor(
    private api: ApiService,
    private progressBarService: ProgressBarService,
    private keycloakService: TjKeycloakService,
    private confirmationService: ConfirmationService,
    private cdRef: ChangeDetectorRef,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    this.isUploading = true;
    if (this.keycloakService.hasRole('CATALOG_VIEW')) {
      this.loadFiles();
    }
  }

  private loadFiles() {
    this.api.catalog.getFiles().subscribe((response: CatalogModel) => {
      this.catalog = response;
      this.isUploading = false;
    });
  }

  public uploadHandler(files: File[], uploader: FileUpload): void {
    this.isFileShowing$.next(true);
    this.isUploading = true;
    this.api.catalog.uploadFile(files).subscribe({
      next: (galleryUploadResponseModel: GalleryUploadResponseModel) => {
        if (galleryUploadResponseModel.success) {
          this.loadFiles();
          this.notificationService.success(Messages.FILE_UPLOADING);
        } else {
          this.notificationService.error(Messages.FILE_SIZE);
        }
      },
      error: err => {
        this.isUploading = false;
        this.notificationService.error(`File upload failed: ${err.message || 'Unknown error'}`);
      },
      complete: () => {
        this.isUploading = false;
        uploader.clear();
        timer(3000).subscribe(() => {
          this.isFileShowing$.next(false);
        });
      },
    });
  }

  getDocumentIcon(fileType: string): string {
    if (!fileType) {
      return '';
    }
    const extension = fileType.toLowerCase().replace('.', '');
    switch (extension) {
      case 'pdf':
        return 'pi pi-file-pdf';
      case 'doc':
      case 'docx':
        return 'pi pi-file-word';
      case 'xls':
      case 'xlsx':
        return 'pi pi-file-excel';
      case 'png':
      case 'jpg':
      case 'jpeg':
        return 'pi pi-image';
      default:
        return 'pi pi-file';
    }
  }

  download(document: AttachmentModel) {
    downloadFile(document.url, document.fileName, this.progressBarService);
  }

  remove(document: AttachmentModel) {
    this.api.catalog.delete(document.id).subscribe(_ => {
      const index = this.catalog.documents.findIndex(it => it.id === document.id);
      this.catalog.documents.splice(index, 1);
      this.notificationService.success('Successfully removed');
    });
  }

  confirmRemove(event: Event, document: AttachmentModel) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.remove(document);
      },
      reject: () => {
        // reject action
      },
    });
  }

  viewPdf(document: DocumentRequestModel): void {
    if (!document?.url) {
      console.error('Document URL is missing');
      return;
    }
    this.document = document;

    this.displayPdfDialog = true;
    this.cdRef.detectChanges();
  }

  loading(event) {
    this.isLoading = event.loaded < event.total;
  }

  onPdfError(error: any): void {
    console.error('PDF viewer encountered an error:', error);
  }
}
