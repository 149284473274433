<div class="grid">
  <div class="col-12 text-center">
    <canvas width="400" height="400" id="canvas" class="mx-auto" style="border: 1px solid black"></canvas>
  </div>
  <div class="col-12">
    <div class="grid mb-4">
      <div class="lg:col-8 col-7">
        <input pInputText type="text" placeholder="Tag text" #input>
      </div>
      <div class="lg:col-4 col-3">
        <button pButton class="p-button-secondary p-button-raised" (click)="addText(input.value)">
          <span>Add text</span>
        </button>
      </div>
      <div class="lg:col-2 col-2">
        <p-colorPicker appendTo="body" [(ngModel)]="color"></p-colorPicker>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 flex">
        <div class="mr-2">
          <button pButton class="p-button-secondary p-button-raised" (click)="addArrow()">
            <span>Add arrow</span>
          </button>
        </div>
        <div class="mr-2">
          <button pButton class="p-button-secondary p-button-raised" (click)="addBefore()">
            <span>Add before</span>
          </button>
        </div>
        <div class="mr-2">
          <button pButton class="p-button-secondary p-button-raised" (click)="addAfter()">
            <span>Add after</span>
          </button>
        </div>
        <div class="mr-2">
          <button pButton class="p-button-info p-button-raised" (click)="removeSelectedObject()">
            <span>Remove tag</span>
          </button>
        </div>
      </div>
      <div class="col-12">
        <button pButton class="p-button-warning p-button-raised" (click)="rotateImage(90)">
          <span>Rotate</span>
          <i class="pi pi-sync"></i>
        </button>
      </div>
    </div>
  </div>
</div>
