import {Component, Input, OnInit} from '@angular/core';
import {Ticket} from '../../../../api/model/Ticket.model';
import {Partner} from '../../../../api/model/Partner.model';
import {ConfigService, NotificationService, TjKeycloakService} from '../../../../core/services';
import {ApiService} from '../../../../api/service/api.service';
import {UntypedFormGroup} from '@angular/forms';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';

@Component({
  selector: 'tj-ticket-create-partner',
  templateUrl: './ticket-create-partner.component.html',
  styleUrls: ['./ticket-create-partner.component.scss'],
})
export class TicketCreatePartnerComponent implements OnInit {
  @Input() ticket: Ticket;

  @Input() form: UntypedFormGroup;

  preselectedContactPerson: ContactPerson;

  isPartner = false;
  checked = this.isAutoAssignedPartner();

  constructor(
    private notificationService: NotificationService,
    private configService: ConfigService,
    private api: ApiService,
    private keycloakService: TjKeycloakService,
  ) {}

  ngOnInit() {
    if (this.isAutoAssignedPartner()) {
      this.onBusinessTicket();
    }
  }

  onBusinessTicket() {
    this.isPartner = !this.isPartner;
    if (!this.isPartner) {
      this.resetPartner();
    }
  }

  onPartnerChange(partnerId: number) {
    this.changePartner(partnerId);
  }

  onUsePrivatePartner() {
    this.ticket.ticketPartnerStatus = TicketPartnerStatus.PRIVATE_BUSINESS;
    const receiveNotifications = this.configService.isConfigEnabled(
      'PARTNER_RECEIVE_NOTIFICATIONS_BY_DEFAULT',
    );
    this.ticket.partner = Partner.buildMinimalPartner(receiveNotifications);
  }

  onResetPartner() {
    this.resetPartner();
  }

  private changePartner(partnerId: number) {
    const partnerSubscription = this.isAutoAssignedPartner()
      ? this.api.partner.getMe()
      : this.api.partner.findOne(partnerId);

    partnerSubscription.subscribe(
      partner => {
        this.ticket.ticketPartnerStatus = TicketPartnerStatus.REGISTERED;
        this.ticket.partner = partner;
        if (partner.contactPersons.length === 1) {
          this.preselectedContactPerson = partner.contactPersons[0];
        }
      },
      error => {
        this.notificationService.error(error);
      },
    );
  }

  public isAutoAssignedPartner(): boolean {
    return this.keycloakService.hasRole('TICKET_CREATE_AUTO_ASSIGNED_PARTNER');
  }

  private resetPartner() {
    this.ticket.ticketPartnerStatus = TicketPartnerStatus.PRIVATE;
    this.ticket.partner = null;
  }
}
