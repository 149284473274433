import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NotificationService} from '../../../../core/services';
import {ApiService} from '../../../../api/service/api.service';
import {environment} from '../../../../../environments/environment';
import {ServiceCategory} from '../../../../api/model/servicecategory/service-category.model';

@Component({
  selector: 'tj-service-categories',
  templateUrl: './service-categories.component.html',
})
export class ServiceCategoriesComponent implements OnInit, OnDestroy {
  protected readonly env = environment;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  protected categories: ServiceCategory[];

  constructor(
    private api: ApiService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    this.api.serviceCategoryApi
      .findAll()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        response => {
          this.categories = response.response;
        },
        errorMessage => {
          this.notificationService.error(errorMessage);
        },
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  support(categoryId: number, checked: boolean) {
    this.api.serviceCategoryApi
      .update(categoryId, checked)
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        const added = 'The category is now supported';
        const removed = 'The category is removed from list of supported categories';
        this.notificationService.success(checked ? added : removed);
      });
  }
}
