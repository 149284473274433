import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AreaToZipCodeModel} from '../../../../../api/model/AreaToZipCode.model';
import {Messages} from '../../../../../common/messages';
import {AreaToZipCodeFilter} from '../../../../../api/model/areatozipcode/areaToZipCode.filter';
import {AreaToZipCodeSearchService} from '../services/area-to-zip-code-search.service';
import {Subject} from 'rxjs';
import {Page, PageQuery, SortQuery} from '../../../../../api/model/shared/Page.model';
import {ApiService} from '../../../../../api/service/api.service';
import {NotificationService} from '../../../../../core/services';
import {ConfirmationService} from 'primeng/api';
import {environment} from '../../../../../../environments/environment';

@Component({
  selector: 'tj-area-to-zip-code-page',
  templateUrl: './area-to-zip-code-page.component.html',
  styleUrls: ['./area-to-zip-code-page.component.scss'],
})
export class AreaToZipCodePageComponent implements OnInit, OnDestroy {
  protected readonly env = environment;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  areaToZipCodes: AreaToZipCodeModel[] = [];

  areaToZipCode: AreaToZipCodeModel;

  page: Page<AreaToZipCodeModel>;

  areaToZipCodeFilter: AreaToZipCodeFilter;

  private pageQuery: PageQuery = {
    size: this.env.rowsPerPage[0],
    number: 0,
  };

  private sortQuery: SortQuery = {
    property: 'id',
    direction: 'desc',
  };

  private searchQuery;

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private areaToZipCodeSearchService: AreaToZipCodeSearchService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit() {
    this.areaToZipCodeSearchService.listenQueryParams();
    this.areaToZipCodeSearchService.searchData.subscribe(({filters, searchQuery}) => {
      this.areaToZipCodeFilter = filters;
      this.searchQuery = searchQuery;
    });
    this.route.queryParams.subscribe((params: Params) => {
      this.pageQuery.number = params.page || 0;
      this.pageQuery.size = params.size || this.env.rowsPerPage[0];
      this.loadAllAreaToZipCode();
    });
  }

  onGoToPage(event) {
    this.router.navigate([], {
      queryParams: {page: event.page, size: event.rows},
      queryParamsHandling: 'merge',
    });
  }

  private loadAllAreaToZipCode() {
    this.api.area.getAreaToZipCodes(this.pageQuery, this.sortQuery, this.searchQuery).subscribe(
      areaZipCodes => {
        this.page = areaZipCodes;
        this.areaToZipCodes = areaZipCodes.response;
      },
      () => {
        this.notificationService.error('Cannot reload area to zip code list');
      },
    );
  }

  sort($event: any) {
    this.sortQuery = {
      property: $event.field,
      direction: $event.order === -1 ? 'desc' : 'asc',
    };
    this.router.navigate([], {queryParams: this.sortQuery, queryParamsHandling: 'merge'});
  }

  onDeleteZipCode(areaToZipCodeToRemove: AreaToZipCodeModel) {
    this.confirmationService.confirm({
      message:
        'Are you sure you want to delete the [ ' + areaToZipCodeToRemove.zipCode + ' ] zipCode?',
      header: 'ZipCode deletion',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmDeletion',
      accept: () => {
        this.areaToZipCode = {...areaToZipCodeToRemove};
        this.deleteAreaToZipCode(areaToZipCodeToRemove.areaId, areaToZipCodeToRemove.zipCodeId);
      },
    });
  }

  private deleteAreaToZipCode(areaId: number, zipCodeId: number) {
    this.api.area.deleteZipCode(areaId, zipCodeId).subscribe(
      () => {
        this.loadAllAreaToZipCode();
        this.notificationService.success(Messages.ZIP_CODE_DELETED);
      },
      errorMessage => {
        this.notificationService.error(errorMessage);
      },
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
