import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {CatalogComponent} from './components/catalog-list/catalog.component';
import {ConfirmPopupModule} from 'primeng/confirmpopup';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    SharedModule,
    RouterModule.forRoot([
      {
        path: 'catalog',
        component: CatalogComponent,
        data: {},
      },
    ]),
    ConfirmPopupModule,
  ],
  declarations: [CatalogComponent],
  providers: [],
  exports: [CatalogComponent],
})
export class CatalogModule {}
