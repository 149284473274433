<div class="grid" [formGroup]="currentFormGroup">
  <div class="col-9">
    <tj-field label="Name" [control]="fcName">
      <input pInputText type="text" placeholder="Name" formControlName="name" />
    </tj-field>
  </div>
  <ng-container
    formArrayName="contacts"
    *ngFor="let contact of fcContacts.controls; let j = index; let last = last"
  >
    <ng-container [formGroupName]="j">
      <div class="col-12">
        <div class="grid align-items-end">
          <div class="lg:col-3 col-8">
            <tj-field label="Contact" [control]="contact.controls.type">
              <p-dropdown
                id="contact"
                [options]="configService.contactTypes"
                appendTo="body"
                placeholder="Contact type"
                formControlName="type"
                [autoWidth]="false"
                (onChange)="changeContactType($event, j)"
                [disabled]="
                  (fcContacts.controls.length != 1 || fcContacts.controls.length != 2) && j < 2
                "
              ></p-dropdown>
            </tj-field>
          </div>
          <div class="lg:col-4 col-8">
            <tj-field label="Number/Email" [control]="contact.controls.value">
              <input
                pInputText
                [type]="contact.controls.type.value === 'EMAIL' ? 'email' : 'text'"
                placeholder="Number/Email *"
                formControlName="value"
                id="value"
                (keydown)="onKeyDown($event, contact.controls.type.value)"
                (paste)="onPaste($event, contact)"
              />
            </tj-field>
          </div>
          <div class="col-fixed">
            <button
              (click)="onRemoveContact(i, j)"
              [disabled]="
                (fcContacts.controls.length != 1 || fcContacts.controls.length != 2) && j < 2
              "
              class="p-button-danger"
              icon="pi pi-trash"
              pButton
            ></button>
          </div>
          <div class="col-fixed">
            <button
              (click)="onAddContact(i)"
              *ngIf="last"
              class="p-button-success"
              icon="pi pi-plus"
              pButton
            ></button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
