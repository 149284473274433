import {Injectable} from '@angular/core';
import {Page, PageQuery, SearchQuery, SortQuery} from '../model/shared/Page.model';
import {CommunicationFilterableService} from '../service/communication-filtarable.service';
import {RouteRequestModel} from '../model/route/route.request.model';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {RouteListModel} from '../model/route/route.line.model';
import {RouteFilter} from '../model/route/route.filter';
import {IdModel} from '../model/shared/Response.model';
import {TicketRouteModel} from '../model/ticket/ticket.route.details.model';
import {RoutePDFOptions} from '../status/RoutePDFOptions';

@Injectable()
export class RouteApi extends CommunicationFilterableService implements SearchQuery {
  protected apiEndpoint = this.env.apiEndpoint + 'routes';

  getRoutes(
    pageQuery: PageQuery,
    sortQuery: SortQuery,
    filter: RouteFilter,
  ): Observable<Page<RouteListModel>> {
    const params = {
      page: pageQuery.number.toString(),
      count: pageQuery.size.toString(),
      order: sortQuery.direction ? sortQuery.direction.toUpperCase() : null,
      sort: sortQuery.property ? sortQuery.property : null,
      search: filter,
    };
    const url = this.apiEndpoint;
    return this.requestService.get(url, {params}).pipe(
      map(response => response as Page<RouteListModel>),
      catchError(this.handleError),
    );
  }

  getRouteByDate(day: string): Observable<RouteListModel> {
    const url = this.apiEndpoint + `/technician?date=${day}`;
    return this.requestService.get(url).pipe(
      map(response => response.response),
      catchError(this.handleError),
    );
  }

  getRoute(routeId: number): Observable<RouteListModel> {
    const url = `${this.apiEndpoint}/${routeId}`;
    return this.requestService.get(url).pipe(
      map(response => response.response as RouteListModel),
      catchError(this.handleError),
    );
  }

  create(routeRequestModel: RouteRequestModel, notify: boolean): Observable<IdModel> {
    const url = `${this.apiEndpoint}?notify=${notify}`;
    return this.requestService.post(url, routeRequestModel).pipe(
      map(response => response.response),
      catchError(this.handleError),
    );
  }

  update(routeRequestModel: RouteRequestModel, notify: boolean): Observable<IdModel> {
    const url = `${this.apiEndpoint}?notify=${notify}`;
    return this.requestService.put(url, routeRequestModel).pipe(catchError(this.handleError));
  }

  sendRoute(routeId: number, email: string): Observable<any> {
    const url = `${this.apiEndpoint}/${routeId}/send`;
    return this.requestService
      .post(url, {
        email,
      })
      .pipe(catchError(this.handleError));
  }

  delete(ids: number[]) {
    const url = this.apiEndpoint + '/delete';
    return this.requestService
      .post(url, {
        ids,
      })
      .pipe(catchError(this.handleError));
  }

  getAssociatedRoutesByTicketId(ticketId: number): Observable<TicketRouteModel[]> {
    const url = this.apiEndpoint + `/associated/${ticketId}`;
    return this.requestService.get(url).pipe(
      map(response => response.response as TicketRouteModel[]),
      catchError(this.handleError),
    );
  }

  getAvailableRoutes(serviceDate: any, technicianIds: number[]) {
    const url = this.apiEndpoint + `/available`;
    const technicians = technicianIds ? technicianIds.join(',') : [];
    const params = {
      date: serviceDate,
      technicianIds: technicians,
    };
    return this.requestService.get(url, {params}).pipe(
      map(response => response.response as TicketRouteModel[]),
      catchError(this.handleError),
    );
  }

  sendPdfToEmail(routeId: number, email: string, option: RoutePDFOptions) {
    const url = `${this.apiEndpoint}/${routeId}/send/email`;
    return this.requestService.post(url, {
      email,
      option,
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  sendPdfToTechnician(routeId: number, option: RoutePDFOptions) {
    const url = `${this.apiEndpoint}/${routeId}/send/technician`;
    return this.requestService.post(url, {
      option
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  downloadPDF(routeId: number, option: RoutePDFOptions) {
    const url = this.apiEndpoint + '/download';
    return this.requestService.download(url, {
      id: routeId,
      option,
    }).pipe(
      map(response => response),
      catchError(this.handleError));
  }
}
