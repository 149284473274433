import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {ConfigService, TjKeycloakService} from '../../../../core/services';
import {EntityResponseModel} from '../../../../api/model/shared/Response.model';
import {InvoiceStats} from '../../models/invoice.stats';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import * as dayjs from 'dayjs';
import {DialogService} from 'primeng/dynamicdialog';
import {PartnerInvoiceListComponent} from '../partner-invoice-list/partner-invoice-list.component';
import {NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'tj-invoice-stats',
  templateUrl: './invoice-stats.component.html',
  styleUrls: ['./invoice-stats.component.scss'],
})
export class InvoiceStatsComponent implements OnInit {
  stats: InvoiceStats;

  formGroup: UntypedFormGroup;

  constructor(
    private api: ApiService,
    public config: ConfigService,
    private router: Router,
    public dialogService: DialogService,
    private fb: UntypedFormBuilder,
    private keycloakServices: TjKeycloakService,
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      startDate: this.fb.control(''),
      endDate: this.fb.control(''),
      partnerId: this.fb.control(''),
    });
    this.loadStats();
  }

  onRowSelect(event) {
    const type = event.data.type;
    const userId = event.data.partner ? event.data.partner.id : event.data.customer.id;
    const startVal = this.formGroup.get('startDate').value;
    const endVal = this.formGroup.get('endDate').value;

    const startDate: string = startVal ? dayjs(startVal).format('YYYY-MM-DD') : null;
    const endDate: string = endVal ? dayjs(endVal).format('YYYY-MM-DD') : null;
    const ref = this.dialogService.open(PartnerInvoiceListComponent, {
      width: '70%',
      data: {
        startDate,
        endDate,
        type,
        userId,
      },
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        ref.close();
      }
    });
  }

  loadStats(): void {
    const startVal = this.formGroup.get('startDate').value;
    const endVal = this.formGroup.get('endDate').value;
    const partnerId = this.formGroup.get('partnerId').value;

    const startDate: string = startVal ? dayjs(startVal).format('YYYY-MM-DD') : null;
    const endDate: string = endVal ? dayjs(endVal).format('YYYY-MM-DD') : null;

    this.api.invoice.getStats(startDate, endDate, partnerId).subscribe(
      (response: EntityResponseModel<InvoiceStats>) => {
        this.stats = response.response;
      },
      err => {
        console.error(err);
      },
    );
  }

  search() {
    this.loadStats();
  }

  reset(): void {
    this.formGroup.reset();
    this.loadStats();
  }

  canViewPartnerExtraProcessNote(): boolean {
    return this.keycloakServices.hasRole('PARTNER_VIEW_EXTRA_PROCESS_NOTE');
  }
}
