import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {GoogleMap} from '@angular/google-maps';

const DEFAULT_ZOOM = 12;
const DEFAULT_CENTER = {lat: 40.712776, lng: -74.005974}; // New York

@Component({
  selector: 'tj-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges {
  @Input() lat: number | null = null;
  @Input() lng: number | null = null;
  @Input() mapHeight: number = 320;

  @ViewChild(GoogleMap) googleMap!: GoogleMap;

  options = {
    center: DEFAULT_CENTER,
    zoom: DEFAULT_ZOOM,
  };

  overlays: any[] = [];

  constructor() {}

  ngOnInit() {
    this.initializeMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lat || changes.lng) {
      this.updateMap();
    }
  }

  private initializeMap() {
    if (this.isValidLatLng(this.lat, this.lng)) {
      this.options.center = {lat: this.lat!, lng: this.lng!};
      this.overlays = [{position: {lat: this.lat!, lng: this.lng!}}];
    }
  }

  private updateMap() {
    if (!this.googleMap || !this.isValidLatLng(this.lat, this.lng)) {
      console.warn('Invalid lat/lng values, skipping map update.');
      return;
    }

    this.googleMap.googleMap?.setCenter({lat: this.lat!, lng: this.lng!});

    this.overlays = [{position: {lat: this.lat!, lng: this.lng!}}];
  }

  private isValidLatLng(lat: number | null, lng: number | null): boolean {
    return typeof lat === 'number' && !isNaN(lat) && typeof lng === 'number' && !isNaN(lng);
  }
}
