import {Component} from '@angular/core';
import {LayoutService} from './service/app.layout.service';

@Component({
  selector: 'tj-footer',
  templateUrl: './app.footer.component.html',
})
export class AppFooterComponent {
  protected currentYear = new Date().getFullYear();

  constructor(public layoutService: LayoutService) {}

  get logo() {
    return this.layoutService.config.colorScheme === 'light' ? 'dark' : 'white';
  }
}
