import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {PayFormSearchFilter} from '../../../models/pay-form-search.filter';
import {ConfigService} from '../../../../../core/services';

@Component({
  selector: 'tj-pay-form-search-form',
  templateUrl: './pay-form-search-form.component.html',
  styleUrls: ['./pay-form-search-form.component.scss'],
})
export class PayFormSearchFormComponent implements OnInit, OnChanges {
  @Input() filter: PayFormSearchFilter;

  @Input() form: UntypedFormGroup;

  @Input() group: string;

  constructor(
    public configService: ConfigService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    const filter = this.filter || ({} as PayFormSearchFilter);
    this.form.addControl(
      this.group,
      this.fb.group({
        technicianId: this.fb.control(filter.technicianId || ''),
        technicianCompanyName: this.fb.control(filter.technicianCompanyName || ''),
        technicianPersonnelCode: this.fb.control(filter.technicianPersonnelCode || ''),
        status: this.fb.control(filter.status || ''),
        startDate: this.fb.control(filter.startDate || null),
        endDate: this.fb.control(filter.endDate || null),
        ticketId: this.fb.control(filter.ticketId || ''),
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter && changes.filter.currentValue === null) {
      this.filter = {} as PayFormSearchFilter;
    }
  }

  getControl(fcName: string): UntypedFormControl {
    return <UntypedFormControl>this.form.get(fcName);
  }
}
