import {Injectable} from '@angular/core';
import {ListSearchService} from '../../shared/services/list-search.service';
import {ActivatedRoute, Router} from '@angular/router';
import {InvoiceSearchFilterModel} from '../models/invoice-search-filter.model';

@Injectable({
  providedIn: 'root',
})
export class InvoiceSearchService extends ListSearchService<InvoiceSearchFilterModel> {
  constructor(route: ActivatedRoute, router: Router) {
    super(route, router);
  }

  searchByFormValue(formValue: InvoiceSearchFilterModel) {
    const filter = {
      id: formValue.id,
      ticketId: formValue.ticketId,
      status: formValue.status,
      customer: formValue.customer,
      comment: formValue.comment,
      partner: formValue.partner,
      netTerm: formValue.netTerm,
      address: formValue.address,
      serviceDate: formValue.serviceDate,
      technician: formValue.technician,
      issueDate: formValue.issueDate,
      netTermType: formValue.netTermType,
      contact: formValue.contact,
      partnerId: formValue.partnerId,
      privatePartner: formValue.privatePartner,
      partnerType: formValue.partnerType,
      invoiceAmount: formValue.invoiceAmount,
      externalReferenceCode: formValue.externalReferenceCode,
      isExtraProcess: formValue.isExtraProcess,
    } as InvoiceSearchFilterModel;
    this.search(filter);
  }

  getSearchUrl() {
    return ['/invoices'];
  }

  getSearchConfig() {
    return {
      id: {
        searchQueryPrefix: 'id:',
      },
      ticketId: {
        searchQueryPrefix: 'items.ticket.id:',
      },
      status: {
        searchQueryPrefix: 'status:',
        transform: (key, value) => {
          if (!!value) {
            return value;
          }
          return undefined;
        },
        transformSearchQuery: (searchQueryPrefix, value) => {
          if (!!value) {
            if (Array.isArray(value)) {
              return `${searchQueryPrefix}${value.join('||')}`;
            }
            return `${searchQueryPrefix}${value.split(',').join('||')}`;
          }
        },
      },
      address: {
        searchQueryPrefix: 'items.ticket.customer.address.addressFormatted~',
      },
      customer: {
        searchQueryPrefix: 'items.ticket.customer.name~',
      },
      netTermType: {
        searchQueryPrefix: 'partner.payment.netTermType:',
      },
      comment: {
        searchQueryPrefix: 'items.ticket.chats.comments.message~',
      },
      technician: {
        searchQueryPrefix: 'items.ticket.ticketToTechnicians.technician.id:',
      },
      externalReferenceCode: {
        searchQueryPrefix: 'items.ticket.externalReferenceCode~',
      },
      partner: {
        searchQueryPrefix: 'items.ticket.partner.name~',
      },
      isExtraProcess: {
        searchQueryPrefix: 'isExtraProcess:',
      },
      netTerm: {
        searchQueryPrefix: 'netTerm:',
      },
      invoiceAmount: {
        searchQueryPrefix: 'totalAmount:',
      },
      contact: {
        searchQueryPrefix: 'items.ticket.customer.contactPersons.contacts.value~',
      },
      serviceDate: {
        searchQueryPrefix: 'items.ticket.serviceDate:',
      },
      issueDate: {
        searchQueryPrefix: 'createdAt..',
      },
      partnerId: {
        searchQueryPrefix: 'items.ticket.partner.id:',
      },
      partnerType: {
        searchQueryPrefix: 'items.ticket.partner.partnerType~',
      },
      privatePartner: {
        searchQueryPrefix: 'items.ticket.ticketPartnerStatus:',
      },
    };
  }
}
