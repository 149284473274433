import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigService, NotificationService, TjKeycloakService} from '../../../core/services';
import {CalendarSearchService} from '../service/calendar-search.service';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {Area} from '../../../api/model/Area.model';
import {TechnicianMinimalListItem} from '../../../api/model/TechnicianMinimalListItem';
import {ApiService} from '../../../api/service/api.service';
import {CalendarSearchCriteria} from '../service/calendar-search-criteria';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

@Component({
  selector: 'tj-calendar-search-form',
  templateUrl: './calendar-search-form.component.html',
  styleUrls: ['./calendar-search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarSearchFormComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Output() resetFilter = new EventEmitter<any>();
  @Output() searchFilter = new EventEmitter<any>();
  @Output() showAll = new EventEmitter<any>();

  private readonly destroy$ = new Subject<void>();

  areas$: Observable<Area[]> = this.configService.areas$;

  formattedAreas$: Observable<{id: number; nameCode: string}[]> = this.areas$.pipe(
    map((areas: Area[]) =>
      areas.map(area => ({
        id: area.id,
        nameCode: `${area.name} / ${area.code}`,
      })),
    ),
  );

  technicans$: Observable<TechnicianMinimalListItem[]> = this.configService.technicians$.pipe(
    map(technicians => technicians.filter(it => it.showOnCalendar)),
  );

  formattedTechnician$: Observable<{id: number; nameCode: string}[]> = this.technicans$.pipe(
    map((techs: TechnicianMinimalListItem[]) =>
      techs.map(tech => ({
        id: tech.id,
        nameCode: `${tech.name} / ${tech.personnelCode}`,
      })),
    ),
  );

  constructor(
    public configService: ConfigService,
    private api: ApiService,
    private notificationService: NotificationService,
    private calendarSearchService: CalendarSearchService,
    private keycloakService: TjKeycloakService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    // Initialize the form with empty arrays for multi-select controls and other fields
    this.form =
      this.form ||
      new UntypedFormGroup({
        area: new UntypedFormControl([]),
        technician: new UntypedFormControl([]),
        ticketId: new UntypedFormControl(''),
        specificDate: new UntypedFormControl(null),
        hideOthers: new UntypedFormControl(false),
      });

    // Subscribe to query parameters and update form controls
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      // Handle ticketId query param
      const ticketIdParam = params['ticketId'];
      if (ticketIdParam) {
        this.getControl('ticketId').setValue(ticketIdParam);
      }

      // Handle specific date query param
      const specificDateParam = params['specificDate'];
      if (specificDateParam) {
        this.getControl('specificDate').setValue(specificDateParam);
      }

      // Handle area query param
      const areaParam = params['area'];
      if (areaParam) {
        const paramAreas = (areaParam as string).split(',').map(id => parseInt(id.trim(), 10));
        this.areas$.pipe(takeUntil(this.destroy$)).subscribe(areas => {
          const selectedAreaIds = areas
            .filter(area => paramAreas.includes(area.id))
            .map(area => area.id);
          if (selectedAreaIds.length) {
            this.getControl('area').setValue(selectedAreaIds);
          }
        });
      }

      // Handle technician query param
      const technicianParam = params['technician'];
      if (technicianParam) {
        const paramTechnicians = (technicianParam as string)
          .split(',')
          .map(id => parseInt(id.trim(), 10));
        this.technicans$.pipe(takeUntil(this.destroy$)).subscribe(techs => {
          const selectedTechnicianIds = techs
            .filter(tech => paramTechnicians.includes(tech.id))
            .map(tech => tech.id);
          if (selectedTechnicianIds.length) {
            this.getControl('technician').setValue(selectedTechnicianIds);
          }
        });
      }
    });

    // Subscribe to form changes to update the URL query parameters
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(formValue => {
      this.updateQueryParams(formValue);
    });

    const calendarSearchCriteria = this.form.value as CalendarSearchCriteria;
    const ticketId = this.getControl('ticketId').value;
    const specificDate = this.form.get('specificDate')?.value;

    if (ticketId) {
      this.getServiceDate(ticketId, calendarSearchCriteria);
    } else if (specificDate) {
      const weekStartAndEnd = this.getWeekStartAndEnd(specificDate);
      calendarSearchCriteria.weekStartDate = dayjs(weekStartAndEnd.startOfWeek);
      calendarSearchCriteria.weekEndDate = dayjs(weekStartAndEnd.endOfWeek);
      calendarSearchCriteria.specificDate = dayjs(specificDate).toISOString();
      this.calendarSearchService.setSearchCriteria(calendarSearchCriteria);
    }
  }

  updateQueryParams(formValue: any): void {
    const queryParams: any = {};

    if (formValue.area && formValue.area.length) {
      // Ensure area values are passed as comma-separated IDs
      queryParams.area = formValue.area.join(',');
    } else {
      queryParams.area = null;
    }

    if (formValue.technician && formValue.technician.length) {
      queryParams.technician = formValue.technician.join(',');
    } else {
      queryParams.technician = null;
    }

    if (formValue.ticketId) {
      queryParams.ticketId = formValue.ticketId;
    } else {
      queryParams.ticketId = null;
    }

    if (formValue.specificDate) {
      queryParams.specificDate = formValue.specificDate;
    } else {
      queryParams.specificDate = null;
    }

    if (formValue.hideOthers) {
      queryParams.hideOthers = formValue.hideOthers;
    } else {
      queryParams.hideOthers = null;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge', // Merge existing query params
    });
  }

  getControl(fcName: string): UntypedFormControl {
    return this.form.get(fcName) as UntypedFormControl;
  }

  search(): void {
    const ticketId = this.form.get('ticketId')?.value;
    const calendarSearchCriteria = this.form.value as CalendarSearchCriteria;
    if (ticketId) {
      this.getServiceDate(ticketId, calendarSearchCriteria);
      this.searchFilter.emit();
      return;
    }
    const specificDate = this.form.get('specificDate')?.value;
    if (specificDate) {
      const weekStartAndEnd = this.getWeekStartAndEnd(specificDate);
      calendarSearchCriteria.specificDate = specificDate;
      calendarSearchCriteria.weekStartDate = dayjs(weekStartAndEnd.startOfWeek);
      calendarSearchCriteria.weekEndDate = dayjs(weekStartAndEnd.endOfWeek);
    }
    this.calendarSearchService.setSearchCriteria(calendarSearchCriteria);
    this.searchFilter.emit();
  }

  isFormValid(): boolean {
    return (
      this.form.get('area')?.value?.length ||
      this.form.get('ticketId')?.value ||
      this.form.get('technician')?.value?.length ||
      this.form.get('specificDate')?.value ||
      this.form.get('hideOthers')?.value === true
    );
  }

  reset() {
    this.form.reset();
    this.calendarSearchService.setSearchCriteria({});
    this.resetFilter.emit();
    this.router.navigate(['/scheduler/calendar']);
  }

  allowedToUseTechFilter() {
    return !this.keycloakService.hasRole('TICKET_VIEW_ASSIGNED');
  }

  getWeekStartAndEnd(serviceDate: string | Date): {startOfWeek: string; endOfWeek: string} {
    const date = dayjs(serviceDate);

    const startOfWeek = date.startOf('isoWeek').format('YYYY-MM-DD');
    const endOfWeek = date.endOf('isoWeek').format('YYYY-MM-DD');

    return {startOfWeek, endOfWeek};
  }

  private getServiceDate(ticketId: number, calendarSearchCriteria: CalendarSearchCriteria): void {
    this.api.ticket
      .getTicketServiceDate(ticketId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        response => {
          const serviceDate = response.serviceDate;

          const weekStartAndEnd = this.getWeekStartAndEnd(serviceDate);

          if (serviceDate) {
            calendarSearchCriteria.weekStartDate = dayjs(weekStartAndEnd.startOfWeek);
            calendarSearchCriteria.weekEndDate = dayjs(weekStartAndEnd.endOfWeek);
            calendarSearchCriteria.serviceDate = dayjs(serviceDate);
            calendarSearchCriteria.area = response.ticketAreas;
            this.form.get('area').setValue(response.ticketAreas);
            this.updateQueryParams(this.form.value);
          } else {
            calendarSearchCriteria.area = response.ticketAreas;
            this.form.get('area').setValue(response.ticketAreas);
            this.updateQueryParams(this.form.value);
          }
          this.calendarSearchService.setSearchCriteria(calendarSearchCriteria);
        },
        () => {
          calendarSearchCriteria.weekStartDate = null;
          calendarSearchCriteria.weekEndDate = null;
          calendarSearchCriteria.serviceDate = null;
          this.notificationService.error('Not found ticket');
        },
      );
  }

  resetAndShowAll() {
    this.showAll.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
