import {TechnicianMinimalListItem} from '../../../../api/model/TechnicianMinimalListItem';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ConfigService} from '../../../../core/services';
import {RouteFilter} from '../../../../api/model/route/route.filter';

@Component({
  selector: 'tj-route-search-chips',
  templateUrl: './route-search-chips.component.html',
  styleUrls: ['./route-search-chips.component.scss'],
})
export class RouteSearchChipsComponent implements OnInit, OnChanges {
  @Input() routeFilter: RouteFilter;

  @Output() remove = new EventEmitter();

  techniciansMap: {[id: number]: TechnicianMinimalListItem} = {};

  filterNames = {
    id: 'Route',
    date: 'Date',
    ticketId: 'Ticket',
    technicianId: 'Technician',
    isEdited: 'Edited',
  };

  constructor(public config: ConfigService) {}

  ngOnChanges(changes: SimpleChanges) {}

  ngOnInit() {
    this.config.technicians$.subscribe(value => {
      this.techniciansMap = value.reduce((acc, technician) => {
        acc[technician.id] = technician;
        return acc;
      }, this.techniciansMap);
    });
  }

  onRemoveChip(filterName) {
    const copyOfFilter = {...this.routeFilter};
    if (copyOfFilter[filterName]) {
      copyOfFilter[filterName] = null;
    }
    this.remove.emit(copyOfFilter);
  }
}
