import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {TicketsMainStats} from '../../../../api/model/ticket/stats/tickets.main.stats';
import {ActivatedRoute, Router} from '@angular/router';
import * as dayjs from 'dayjs';
import {TicketStatus} from '../../../../api/status/ticketStatus';
import {ConfigService, TjKeycloakService} from '../../../../core/services';

@Component({
  selector: 'tj-tickets-main-stats',
  templateUrl: './tickets-main-stats.component.html',
  styleUrls: ['./tickets-main-stats.component.scss'],
})
export class TicketsMainStatsComponent implements OnInit {
  readonly TicketStatus = TicketStatus;

  mainStats: TicketsMainStats;

  tabs = [];

  activeIndex;

  constructor(
    private api: ApiService,
    protected router: Router,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private keycloakService: TjKeycloakService,
  ) {}

  ngOnInit() {
    this.api.ticket.getMainStats().subscribe(value => {
      this.mainStats = value;
      this.tabs.push({label: 'All / Total', code: 'ALL', count: this.mainStats.totalCount});
      if (this.keycloakService.hasRole('TICKET_VIEW_MAIN_STATS_OVERDUE')) {
        this.tabs.push({label: 'Overdue', code: 'OVERDUE', count: this.mainStats.overdueCount});
      }
      if (this.keycloakService.hasRole('TICKET_VIEW_MAIN_STATS_COMPLETED')) {
        this.tabs.push({
          label: 'Completed',
          code: 'COMPLETED',
          count: this.mainStats.completedCount,
        });
      }
      if (this.keycloakService.hasRole('TICKET_VIEW_MAIN_STATS_VERIFIED')) {
        this.tabs.push({label: 'Verified', code: 'VERIFIED', count: this.mainStats.verifiedCount});
      }
      if (this.keycloakService.hasRole('TICKET_VIEW_MAIN_STATS_SCHEDULED')) {
        this.tabs.push({
          label: 'Scheduled',
          code: 'SCHEDULED',
          count: this.mainStats.scheduledCount,
        });
      }
      if (this.keycloakService.hasRole('TICKET_VIEW_MAIN_STATS_RESCHEDULED')) {
        this.tabs.push({
          label: 'Rescheduled',
          code: 'RESCHEDULE',
          count: this.mainStats.rescheduledCount,
        });
      }
      if (this.keycloakService.hasRole('TICKET_VIEW_MAIN_STATS_ASSIGNED')) {
        this.tabs.push({label: 'Assigned', code: 'ASSIGNED', count: this.mainStats.assignedCount});
      }
      if (this.keycloakService.hasRole('TICKET_VIEW_MAIN_STATS_CUSTOMER_WILL_CALL')) {
        this.tabs.push({
          label: 'Contacting / Waiting',
          code: 'CUSTOMER_WILL_CALL',
          count: this.mainStats.customerWillCallCount,
        });
      }
      this.route.queryParams.subscribe(params => {
        const status = params['status'] || 'ALL';
        this.activeIndex = this.tabs.findIndex(tab => tab.code === status);
      });
    });
  }

  onTabChange(event) {
    this.activeIndex = event.index || 0;
    const tabCode = this.tabs[this.activeIndex].code;
    switch (tabCode) {
      case 'OVERDUE':
        this.getRouteForOverdueTickets();
        break;
      case 'ASSIGNED':
      case 'RESCHEDULE':
      case 'COMPLETED':
      case 'VERIFIED':
      case 'SCHEDULED':
      case 'CUSTOMER_WILL_CALL':
        this.getRouteForTicketsWithStatus(tabCode);
        break;
      default:
        this.router.navigate(['/tickets']);
    }
  }

  getRouteForTicketsWithStatus(ticketStatus) {
    this.router.navigate(['/tickets'], {
      queryParams: {
        status: ticketStatus,
      },
    });
  }

  getRouteForOverdueTickets() {
    const clientZoneTimeConfig = this.configService.getClientZoneTimeConfig();
    const yesterday = dayjs()
      .utc()
      .add(clientZoneTimeConfig, 'hour')
      .add(-1, 'day')
      .hour(13)
      .minute(0)
      .second(0)
      .millisecond(0)
      .format();
    this.router.navigate(['/tickets'], {
      queryParams: {
        property: 'serviceDate',
        direction: 'desc',
        serviceDateBefore: yesterday,
        status: 'SCHEDULED',
      },
    });
  }
}
