import {Injectable} from '@angular/core';
import {ConfirmationService, Message} from 'primeng/api';
import {ConfigService} from '../../../core/services';
import {ProgressBarService} from '../../../core/services/progressbar.service';

@Injectable()
export class DeactivationConfirmationService {
  confirming = false;
  messages: Message[] = [];

  constructor(
    private confirmationService: ConfirmationService,
    private configService: ConfigService,
    private progressBarService: ProgressBarService,
  ) {}

  confirm(accept?: Function, isRecover?: boolean) {
    this.confirming = true;
    let confirmHeader = 'Deactivation';
    let confirmMessage = 'Are you sure you want to deactivate ?';
    if (isRecover) {
      confirmHeader = 'Recover';
      confirmMessage = 'Are you sure you want to recover ?';
    }
    this.confirmationService.confirm({
      message: confirmMessage,
      header: confirmHeader,
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmDeactivation',
      accept: () => {
        const password = prompt('Enter password');
        const configPassword = this.configService.globalConfigs$
          .getValue()
          .find(config => config.code === 'PARTNER_AND_TECHNICIAN_DEACTIVATE_PASSWORD').value;
        if (configPassword && configPassword === password) {
          accept();
        } else {
          this.progressBarService.hide();
          alert('Wrong password');
        }
      },
      reject: () => {
        this.progressBarService.hide();
      },
    });
  }
}
