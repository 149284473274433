import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {BaseFormGroup} from '../../../shared/utils/base-form-group';
import {TicketInvoiceWithTech} from '../../../invoice/service/invoice.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TicketStatus} from '../../../../api/status/ticketStatus';
import {ConfirmationService} from 'primeng/api';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';
import {PaymentType} from '../../../../api/status/payment.type';
import {ConfigService, NotificationService} from '../../../../core/services';
import {Partner} from '../../../../api/model/Partner.model';

@Component({
  selector: 'tj-quick-invoice-form',
  templateUrl: './quick-invoice-form.component.html',
  styleUrls: ['./quick-invoice-form.component.scss']
})
export class QuickInvoiceFormComponent implements OnChanges {

  @Input() ticketInvoice: TicketInvoiceWithTech;
  @Input() partner: Partner;
  @Input() ticketPartnerStatus: string;
  @Input() form: UntypedFormGroup;
  @Input() readonly = false;
  @Input() group: string;

  readonly TicketStatus = TicketStatus;

  @Output() invoiceChange = new EventEmitter();

  TicketPartnerStatus = TicketPartnerStatus;

  PaymentType = PaymentType;
  invoiceNetTerm: number;

  private registeredAccountDefaultNetTerm = 0;
  private privateAccountDefaultNetTerm = 0;

  constructor(private confirmationService: ConfirmationService,
              private configService: ConfigService,
              private notificationService: NotificationService) {
    this.registeredAccountDefaultNetTerm = configService.getRegisteredAccountDefaultNetTerm();
    this.privateAccountDefaultNetTerm = configService.getPrivateAccountDefaultNetTerm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ticketInvoice?.firstChange || this.ticketInvoice !== changes.ticketInvoice?.previousValue) {
      const ticketInvoice = this.ticketInvoice;
      this.invoiceNetTerm = 0;
      if (this.ticketInvoice.ticket.invoiceId) {
        this.invoiceNetTerm = this.ticketInvoice.netTerm;
      } else {
        if (ticketInvoice.ticket.ticketPartnerStatus === TicketPartnerStatus.REGISTERED) {
          this.invoiceNetTerm = ticketInvoice.ticket.partner.netTerm;
        } else {
          this.invoiceNetTerm = this.privateAccountDefaultNetTerm;
        }
      }
      this.form.addControl('invoiceAmount', new UntypedFormControl(ticketInvoice.amount || 0));
      this.form.addControl('invoiceExpenses', new UntypedFormControl(ticketInvoice.expenses || 0));
      this.form.addControl('invoiceTax', new UntypedFormControl(ticketInvoice.tax || 0));
      this.form.addControl('invoiceNetTerm', new UntypedFormControl(this.invoiceNetTerm || 0));
      this.form.addControl('isInvoicePaid', new UntypedFormControl(false));

      this.form.valueChanges.subscribe((invoiceChanges) => {
        this.invoiceChange.emit(invoiceChanges);
      });
    }
  }

  getControl(fcName: string): UntypedFormControl {
    return this.form.get(fcName) as UntypedFormControl;
  }

  confirmPaid($event: any) {
    if (this.readonly) {
      this.notificationService.warning('This ticket is readonly. You can\'t save changes.');
      return;
    }
    if ($event.checked) {
      this.confirmationService.confirm({
        message: `<span class="font-semibold text-2xl text-red-600" style="font-size:20px;">Paid in full? </br>  Mark INVOICE as Paid?</span>`,
        header: 'Change invoice status',
        icon: 'pi pi-exclamation-triangle text-7xl text-red-600',
        key: 'confirmChangeInvoiceStatusToPaid',
        accept: () => {
          this.confirmNonCreditOrApprovalRequiredPayment();
        },
        reject: () => {
          this.form.get('isInvoicePaid').setValue(false);
        },
      });
    }
  }

  confirmNonCreditOrApprovalRequiredPayment() {
    if (this.ticketPartnerStatus === TicketPartnerStatus[TicketPartnerStatus.REGISTERED]) {
      if (this.partner?.payment?.netTermType !== 'CREDIT_CARD_ON_FILE' || (this.partner?.payment?.netTermType === 'CREDIT_CARD_ON_FILE' && this.partner?.payment?.approvalRequired)) {
        this.confirmationService.confirm({
          message: `<span class="font-semibold text-2xl text-orange-600" style="font-size:20px;">Account Ticket: Confirm to mark the invoice as Paid.</br></span>`,
          header: 'Account warning',
          icon: 'pi pi-exclamation-triangle text-7xl text-orange-600',
          key: 'confirmNonCreditOrApprovalRequiredPayment',
          acceptLabel: 'Yes',
          rejectLabel: 'Cancel',
          accept: () => {
          },
          reject: () => {
            this.form.get('isInvoicePaid').setValue(false);
          },
        });
      }
    }
  }
}
