<div class="col-12 py-0 flex flex-wrap">
  <div class="col-12 pt-0">
    <p class="text-xl info-red mb-1">
      {{
        selectedTechnician.address.addressFormatted +
          ' | ' +
          selectedTechnician.email +
          ' | ' +
          selectedTechnician.phoneNumber
      }}
    </p>
    <p class="text-xl info-red mb-1">
      Speciality/Restriction: {{ selectedTechnician.speciality || ' - ' }}
    </p>
    <p class="text-xl info-red mb-0">Notes: {{ selectedTechnician.notes || ' - ' }}</p>
  </div>
</div>
<div class="col-12 py-0">
  <p-divider styleClass="my-0"></p-divider>
</div>
<div class="flex justify-content-between flex-wrap">
  <div class="col-12">
    <div class="flex flex-wrap">
      <div class="col-2"><b>Ticket#</b></div>
      <div class="col-1"><b>Area</b></div>
      <div class="col-3"><b>Address</b></div>
      <div class="col-2"><b>Service time</b></div>
      <div class="col-1"><b>Time req.</b></div>
      <div class="col-2"><b>Extra technicians</b></div>
      <div class="col-1"><b>Tags</b></div>
    </div>

    <div
      *ngFor="let event of selectedStops; let last = last"
      class="flex flex-wrap"
      (click)="selectStop(event)"
    >
      <div class="col-2 text-left">
        <small
        ><a href="/tickets/{{ event.id }}" (click)="navigateToTicket($event, event.id)"
            class="list-link">{{ event.id }}</a></small
        >
      </div>
      <div class="col-1">
        <small *ngIf="event.customer.address?.areas">
          <ng-container *ngFor="let area of event.customer.address?.areas">
            <span tooltipPosition="top" [pTooltip]="area.name">{{ area.code }}</span>
          </ng-container>
        </small>
      </div>
      <div class="col-3">
        <small>{{ event.customer.address?.addressFormatted }}</small>
      </div>
      <div class="col-2">
        <small class="p-text-secondary"
        >{{ event.serviceTimeStart | date: env.dateTimeOnly : 'UTC' }} -
          {{ event.serviceTimeEnd | date: env.dateTimeOnly : 'UTC' }}</small
        >
      </div>
      <div class="col-1">
        <div class="level-wrapper">
          <div>
            <small class="p-text-secondary level-{{ getJobLevel(event) }}"
              >{{ event.timeNeeded }}h</small
            >
          </div>
        </div>
      </div>
      <div class="col-2 pt-0">
        <div class="flex-column justify-content-end">
          <p-avatarGroup styleClass=" block">
            <ng-container *ngFor="let addTech of event.technicians">
              <!--not show head tech in as additional tech-->
              <ng-container *ngIf="addTech.id !== selectedTechnician.id">
                <p-avatar
                  shape="circle"
                  [pTooltip]="
                    addTech.personnelCode
                      ? addTech.name + ' | ' + addTech.personnelCode
                      : addTech.name
                  "
                  tooltipPosition="top"
                  styleClass="m-1"
                >
                  {{ addTech.name[0] }}
                </p-avatar>
              </ng-container>
            </ng-container>
          </p-avatarGroup>
        </div>
      </div>
      <div class="col-1">
        <span class="text-blue-500" pTooltip="Pick up / Delivery" *ngIf="event.pickUpDelivery">
          P/D
          <ng-container *ngIf="event.recallGoingBack || event.shop">,</ng-container>
        </span>
        <span class="text-blue-500" pTooltip="Recall / Going Back" *ngIf="event.recallGoingBack">
          G/B
          <ng-container *ngIf="event.shop">,</ng-container>
        </span>
        <span class="text-blue-500" pTooltip="Shop" *ngIf="event.shop"> Shop </span>
      </div>
      <div class="col-12 pt-0" *ngIf="event.appointmentDetails">
        <span class="info-red"
          ><span class="text-red-700 font-semibold">Appointment Details: </span
          >{{ event.appointmentDetails }}</span
        >
      </div>
      <div class="col-12 py-0" *ngIf="!last">
        <p-divider styleClass="my-0"></p-divider>
      </div>
    </div>
  </div>
</div>
